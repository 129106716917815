<template>
<Header />
<section class="myResume">
  <div class="container">
    <h3 class="section__title">{{$t('my_resumes')}}</h3>

    <ListResumes
      :resumes="resumes"
      :reload="() => {fetchData()}"
    />

    <Pagination
      v-if="resumes"
      v-model="filter.page"
      :perPage="filter.per_page"
      :total="total"
      @newPage="fetchData"
    />
  </div>
</section>
</template>

<script setup>
import ListResumes from '@/components/uiEmployee/Resume/List/ListResumes.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getResumes } from '@/actions/employee'

const route = useRoute()

// Filter
const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20
  }
)
const total = ref()

const resumes = ref(null)
const fetchData = async () => {
  resumes.value = null
  const response = await getResumes(filter.page, undefined, filter.per_page)
  total.value = response.data.meta.total
  resumes.value = response.data.data
  router.push({ path: '/user/my-resumes/result', query: { page: filter.page } })
}

onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'MyResume') {
      filter.page = route.query.page ?? 1
      fetchData()
    }
  }
)
</script>
