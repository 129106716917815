<template>
<div class="nav">
  <router-link
    class="nav__link"
    to="/company/search-vacancy/result?search=&type&period=year&page=1"
  >
    {{$t('vacancies')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/company/search-resumes/result?type=date_asc&period=year&salary_type=0&gender&country=0&page=1"
  >
    {{$t('applicants_resumes')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/company/letters-templates/result?page=1"
  >
    {{$t('letter_templates')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/company/test-templates/result?page=1"
  >
    {{$t('test_templates')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/company/services"
  >
    {{$t('services.title')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/company/working-with-the-site"
  >
    {{$t('working_with_site')}}
  </router-link>
</div>
</template>
