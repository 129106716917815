<template>
  <Header />
  <section class="testTemplates">
    <div class="container">
      <div class="testTemplates__header">
          <h3 class="section__title">{{$t('test_templates')}}</h3>
          <Button class="btn" :name="$t('create_test')" type="gray" go="/company/create-test"/>
      </div>

      <NothingHere v-if="tests && tests.length === 0"/>
      <div v-else-if="tests">
        <RowTest
          v-for="test of tests"
          :key="test.id"
          :test="test"
          :finally="() => {fetchData()}"
        />
        <Pagination
          v-model="filter.page"
          :perPage="filter.per_page"
          :total="total"
          @newPage="() => {fetchData(filter.page)}"
        />
      </div>
      <div class="loading" v-else>
        <div class="template" v-for="test of 10" :key="test">
          <div class="link skeleton middle-text" style="width:100%;max-width:250px"></div>
          <div class="skeleton small-text"></div>
        </div>
      </div>
    </div>
  </section>
    <Modal
      :hide="()=>{quastion = false}"
      :show="quastion"
      quastion
      :agreement="() => {deleteTest(deleteTestId)}"/>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import RowTest from '@/components/uiCompany/Tests/List/RowTest.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getTests, deleteTest } from '@/actions/company'

const route = useRoute()

const tests = ref(null)
const quastion = ref(false)
const deleteTestId = ref(null)

// Filter
const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20
  }
)
const total = ref()

const fetchData = async (page = 1) => {
  filter.page = page
  tests.value = null
  const response = (await getTests(filter)).data.tests
  tests.value = response.data
  total.value = response.total
  router.push({ path: '/company/test-templates/result', query: { page: page } })
}

onMounted(
  () => fetchData(filter.page)
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'TestsList') {
      filter.page = route.query.page ?? 1
      fetchData(filter.page)
    }
  }
)
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(5%) scale(90%);
}
.testTemplates__header{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.section__title{
  margin-right: 40px;
  margin-bottom: 0;
}
@media (max-width: 480px){
  .testTemplates__header{
    display: block;
  }
  .section__title{
    margin-right: 0;
    margin-bottom: 15px;
  }
}
form{
  margin-bottom: 40px;
}
</style>
