<template>
<div class="editVacancy__item">
  <h2 class="editVacancy__title">{{$t('place_of_workd')}}</h2>
  <div>
    <label for="professional_area_id">{{$t('where_to_look_for_an_employee')}}</label>
    <div class="placeSelect">
      <button
        v-if="formData.country.id"
        class="placeSelect__title"
        @click="deleteCountry"
        type="button"
      >
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
        </svg>
        <span>{{formData.country.title}}</span>
      </button>
      <Select
        v-if="!formData.country.id"
        id="professional_area_id"
        v-model="formData.country.id"
        v-model:option="formData.country"
        :options="helpers.countries"
        @change="clickCountry"
        :placeholder="$t('choose_region')"
      />
      <Select
        v-else
        id="professional_area_id"
        v-model="formData.city_id"
        v-model:validation="success"
        :options="cities"
        :placeholder="$t('choose_city')"
      />
    </div>
  </div>
  <div>
    <label for="professional_area_id">{{$t('vacancy_adderss')}}</label>
    <div class="checkbox_block">
      <div><Checkbox v-model="formData.show_address" dark minus/> <p>{{$t('do_not_include_an_address')}}</p></div>
      <div><Checkbox v-model="formData.show_address" dark /> <p>{{$t('specify_the_address')}}</p></div>
    </div>
  </div>
</div>
</template>

<script setup>
import Checkbox from '@/components/common/Checkbox.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'
import { getCities } from '@/actions/help'

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  validation: {
    type: Boolean,
    default: false
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
const success = ref(props.validation)
const cities = ref(props.helpers.cities)

watch(
  () => [formData.value, success.value],
  () => {
    emit('update:modelValue', formData.value)
    emit('update:validation', success.value)
  }
)

const clickCountry = async () => {
  if (formData.value.country.id) cities.value = (await getCities(formData.value.country.id)).data.cities
}

const deleteCountry = () => {
  formData.value.country.id = null
  formData.value.city_id = null
  cities.value = null
  success.value = false
}
</script>

<style scoped lang="scss">
.placeSelect{
  &__title{
    font-size: 16px;
    font-weight: 700;
    color: rgba(33, 37, 41, 0.7);
    margin-bottom: 10px;

    display: flex;
    grid-gap: 10px;
    align-items: center;
  }
  & svg{
    display: block;
    height: 12px;
    width: 10px;
    fill: rgba(33, 37, 41, 0.7);
    stroke: rgba(33, 37, 41, 0.7);
    transform: rotate(180deg);
    transition: all .2s ease-in-out;
  }

  @media (max-width: 992px){
    &__title{
      font-size: 14px;
      margin-bottom: 8px;
      grid-gap: 8px;
    }
    & svg{
      height: 10px;
      width: 8px;
    }
  }
  @media (max-width: 768px){
    &__title{
      font-size: 12px;
      margin-bottom: 6px;
      grid-gap: 6px;
    }
  }
  @media (max-width: 576px){

  }

  @media (max-width: 400px){

  }
}
</style>
