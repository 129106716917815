import axios from 'axios'
import router from '@/router/index.js'
import { useToast } from '@/modules/toast.js'
import { i18n } from '@/locales/index.js'

const { toast } = useToast()
const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL + '/api'
})
const request = function (httpType, url, data, callbackSuccess, callbackError, muteError, redirectT0404) {
  if (httpType === 'get') {
    data = { params: data }
  }

  apiAxios[httpType](url, data)
    .then(function (response) {
      if (!muteError) {
        if (response.data.message) {
          toast({
            message: response.data.message,
            type: 'success'
          })
        }
      }

      try {
        callbackSuccess(response)
      } catch (err) {
        console.error(err)
      }
    })
    .catch(function (error) {
      if (!muteError) {
        if (!error.response) {
          toast({
            message: i18n.global.t('message.request_failed')
          })
        } else if (error.response.status === 500) {
          toast({
            message: i18n.global.t('message.server_error')
          })
        } else if (error.response.status === 401) {
          localStorage.setItem('access_token', null)
          localStorage.setItem('role_id', null)
          localStorage.setItem('email', null)
          router.push('/authorization/login')
          toast({
            message: i18n.global.t('message.you_first_need_to_login')
          })
        } else if (error.response.status === 404 && redirectT0404) {
          router.push('/404')
          toast({
            message: i18n.global.t('message.we_do_not_have_such_a_page')
          })
        } else if (error.response.status === 403) {
          router.push('/')
          toast({
            message: i18n.global.t('message.you_do_not_have_access_to_this_page')
          })
        } else if (error.response.data.errors) {
          if (Array.isArray(error.response.data.errors)) {
            error.response.data.errors.forEach(message => {
              toast({
                message
              })
            })
          } else if (typeof error.response.data.errors === 'object') {
            Object.entries(error.response.data.errors).forEach(([key, value]) => {
              toast({
                message: `${value}`
              })
            })
          }
        } else if (error.response.data.message) {
          toast({
            message: error.response.data.message
          })
        } else {
          toast({
            message: i18n.global.t('message.an_error_occurred_while_requesting')
          })
        }
      }
      callbackError(error)
    })
}
if (localStorage.getItem('access_token') != null) {
  apiAxios.defaults.headers.common.Authorization = 'Bearer' + ' ' + localStorage.getItem('access_token')
  apiAxios.defaults.headers.common['X-localization'] = localStorage.getItem('lang')
}

apiAxios.requestGet = function (url, data = {}, callbackSuccess = function () {}, callbackError = function () {}, muteError = false, redirectT0404 = true) {
  request('get', url, data, callbackSuccess, callbackError, muteError, redirectT0404)
}
apiAxios.requestPost = function (url, data = {}, callbackSuccess = function () {}, callbackError = function () {}, muteError = false, redirectT0404 = true) {
  request('post', url, data, callbackSuccess, callbackError, muteError, redirectT0404)
}
apiAxios.requestPut = function (url, data = {}, callbackSuccess = function () {}, callbackError = function () {}, muteError = false) {
  request('put', url, data, callbackSuccess, callbackError, muteError)
}
apiAxios.requestPatch = function (url, data = {}, callbackSuccess = function () {}, callbackError = function () {}, muteError = false) {
  request('patch', url, data, callbackSuccess, callbackError, muteError)
}
apiAxios.requestDelete = function (url, data = {}, callbackSuccess = function () {}, callbackError = function () {}, muteError = false) {
  request('delete', url, data, callbackSuccess, callbackError, muteError)
}

apiAxios.asyncGet = (url, data = {}, mute = false, full = false, redirect = true) =>
  new Promise((resolve, reject) => apiAxios.requestGet(url, data, response => full ? resolve(response) : resolve(response.data.data), error => reject(error.response.data), mute, redirect))
apiAxios.asyncPost = (url, data = {}, mute = false, redirect = true) =>
  new Promise((resolve, reject) => apiAxios.requestPost(url, data, response => resolve(response.data), error => reject(error.response.data), mute, redirect))
apiAxios.asyncPut = (url, data = {}, mute = false) =>
  new Promise((resolve, reject) => apiAxios.requestPut(url, data, response => resolve(response.data), error => reject(error.response.data), mute))
apiAxios.asyncPatch = (url, data = {}, mute = false) =>
  new Promise((resolve, reject) => apiAxios.requestPatch(url, data, response => resolve(response.data), error => reject(error.response.data), mute))
apiAxios.asyncDelete = (url, data = {}, mute = false) =>
  new Promise((resolve, reject) => apiAxios.requestDelete(url, data, response => resolve(response.data), error => reject(error.response.data), mute))

export default apiAxios

export const apiUrl = process.env.VUE_APP_API_URL
