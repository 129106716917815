<template>
<div>
  <label for="address">{{title}}</label>

  <!-- ------------  Selected Option ------------------- -->
  <button
    v-if="input.selectedOption"
    type="button"
    class="selectedOption"
    @click="deleteSelectedOption()"
  >
    <span>{{ input.selectedOption.title }}</span>
    <svg>
      <use xlink:href="/img/tmp/sprite.svg#close"></use>
    </svg>
  </button>

  <!-- ------------  Country Not Selected ------------------- -->
  <div v-if="!options" class="message">{{$t('select_a_region_first')}}</div>

  <!-- ------------  Search Options ------------------- -->
  <div v-else>
    <Input
      class="search__input"
      v-model="input.search"
      :placeholder="$t('search')"
    />

    <!-- ------------  List Options ------------------- -->
    <div
      class="search__options"
      :class="{showOptions: input.showOptions}"
      v-if="input.options"
    >
      <div class="options">
        <button
          class="region"
          v-for="option of input.showOptions ? input.options : input.options.slice(0, 3)"
          :key="option"
          @click="clickOption(option)"
        >
          {{option.title}}
        </button>
      </div>
    </div>

    <!-- ------------  Null Options ------------------- -->
    <div class="region" v-else>
      {{`${title} ${$t('not_found')}`}}
    </div>

    <!-- ------------  Showing All Options ------------------- -->
    <button
      class="showMore"
      @click="input.showOptions = true"
      v-if="!input.showOptions"
    >
      {{$t('select_show_all')}}
      <span>{{`(${options.length})`}}</span>
    </button>
  </div>
</div>
</template>

<script setup>
import { defineProps, defineEmits, watch, reactive } from 'vue'

const props = defineProps({
  modelValue: {
    type: [Number, Object],
    default: () => {}
  },
  options: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update:modelValue'])

// --------------- Input ----------------
const input = reactive({
  search: '',
  showOptions: false,
  options: props.options,
  selectedOption: null
})

// --------------- Input Search Event ----------------
const searchOption = () => {
  input.options = []
  const search = (input.search.toLowerCase()).trim()
  const l = search.length

  if (props.options) {
    props.options.forEach((item, i) => {
      if (item.title.toLowerCase().slice(0, l) === search && item.title.toLowerCase() !== search) input.options.push(item)
    })

    props.options.forEach((item, i) => {
      if (item.title.indexOf(search) !== -1 && input.options.indexOf(item) === -1) input.options.push(item)
    })

    if (input.options.length === 0) {
      props.options.forEach(item => {
        if (item.title.toLowerCase().slice(0, 1) === search.slice(0, 1) && item.title.toLowerCase() !== search) input.options.push(item)
      })
    }
    if (input.options.length === 0) input.options = null
  }
}

watch(
  () => input.search,
  () => {
    searchOption()
  }
)
watch(
  () => props.options,
  () => {
    input.input = ''
    emit('update:modelValue', null)
    searchOption()
  }
)

// --------------- Option Events ----------------
const clickOption = (option) => {
  input.selectedOption = option
  input.search = ''
  emit('update:modelValue', option.id)
}

const deleteSelectedOption = () => {
  input.selectedOption = null
  emit('update:modelValue', null)
}
</script>

<style lang="scss" scoped>
label{
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #212529;
  margin-bottom: 13px;
}
.search__input{
  margin-bottom: 16px;
}
.region{
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
  text-align: left;
  transition: all .2s linear;

  & span{
    font-style: italic;
    font-weight: 300;
  }

  &:hover{
    color: #AD1717;
  }
}
.search__options{
  position: relative;
  &::before,
  &::after{
    content: '';
    display: block;
    height: 20px;
    width: 100%;

    position: absolute;
    left: 0;
    z-index: 2;
  }
  &::before{
    top: 0;
    background: linear-gradient(#fff, rgba(#fff, .1));
  }
  &::after{
    bottom: 0;
    background: linear-gradient(rgba(#fff, .1), #fff);
  }
  & .options{
    max-height: 200px;
    overflow: auto;
    padding-top: 10px;
  }
}
.showMore{
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.message{
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.selectedOption {
  color: #929292;
  font-size: 14px;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;

  padding: 12px;
  border-radius: 3px;
  background-color: #F0F0F0;
  transition: all .2s linear;
  margin-bottom: 10px;

  & svg{
    display: block;
    width: 15px;
    height: 15px;
    fill: #929292;
    transition: all .2s linear;
  }

  &:hover{
    color: #AD1717;
    background-color: #f9bbbb;

    & svg{
      fill: #AD1717;
    }
  }
}
@media (max-width: 420px){
  .companySearch{
    &__item{
      & .bottom{
        flex-direction: column;
        grid-gap: 10px;
        align-items: initial;
      }
    }
  }
}
</style>
