import api from '../modules/api'
import { filterResume } from '@/helpers/filterResume'
import { filterPhoto } from '@/helpers/filterPhoto'

export const getPersonalData = () =>
  api.asyncGet('/v1/employees/current')

export const putPersonalData = (data) =>
  api.asyncPut('/v1/employees/update', data)

export const postEmployeeUploadImage = async data => {
  data = await filterPhoto(data)
  return api.asyncPost('/v1/employees/image', data)
}

// Resumes
export const getResumes = (page, filters, perPage) =>
  api.asyncGet('/v1/employees/resumes', { page, ...filters, per_page: perPage }, false, true)

export const getResume = (id) =>
  api.asyncGet('/v1/employees/resumes/' + id, {}, false, true)

export const postResume = (data) => {
  data = filterResume(data)
  return api.asyncPost('/v1/employees/resumes', data)
}

export const putResume = async (id, data) => {
  data = await filterResume(data)
  return api.asyncPut('/v1/employees/resumes/' + id, data)
}

// Resumes Deletes
export const deleteResume = id =>
  api.asyncDelete(`/v1/employees/resumes/${id}`)

export const deleteResumeExperience = (resumeId, experienceId) =>
  api.asyncDelete(`/v1/employees/resumes/${resumeId}/experiences/${experienceId}`, null, true)

export const deleteResumeEducation = (resumeId, educationId) =>
  api.asyncDelete(`/v1/employees/resumes/${resumeId}/educations/${educationId}`, null, true)

export const deleteResumeCourse = (resumeId, courseId) =>
  api.asyncDelete(`/v1/employees/resumes/${resumeId}/courses/${courseId}`, null, true)

export const deleteResumeAward = (resumeId, awardId) =>
  api.asyncDelete(`/v1/employees/resumes/${resumeId}/awards/${awardId}`, null, true)

export const deleteResumeLanguage = (resumeId, languageId) =>
  api.asyncDelete(`/v1/employees/resumes/${resumeId}/languages/${languageId}`, null, true)

// Search Company
export const getSearchCompany = (formData) => {
  const data = formData
  if (!data.city_id) data.city_id = null
  if (!data.country_id) data.country_id = null
  return api.asyncGet('/v1/employees/search/companies', data, false, true)
}

export const getCompanyDetail = (id) =>
  api.asyncGet('/v1/employees/search/companies/' + id, null, false, true)

// Search Vacancy
export const getSearchVacancy = (formData) => {
  const data = formData
  data.position = data.name
  if (data.salary_max === 0) data.salary_max = null
  return api.asyncGet('/v1/employees/search/vacancies', data, false, true)
}

export const getSearchVacancyDetail = (id) =>
  api.asyncGet('/v1/employees/search/vacancies/' + id, null, false, true)

// Feedback Company
export const postSendMessageToCompany = (data) =>
  api.asyncPost('/v1/employees/send-message', data)

// Favorite Company
export const getFavoriteCompanies = (data) =>
  api.asyncGet('/v1/employees/favorites-companies', data)

export const postFavoriteCompany = (data) =>
  api.asyncPost('/v1/employees/favorites-companies', data)

export const deleteFavoriteCompany = (id) =>
  api.asyncDelete('/v1/employees/favorites-companies/' + id)

// Favorite Vacancy
export const getFavoriteVacancies = (data = {}) =>
  api.asyncGet('/v1/employees/favorites', data, false, true)

export const postFavoriteVacancy = (data) =>
  api.asyncPost('/v1/employees/favorites', data)

export const deleteFavoriteVacancy = (id) =>
  api.asyncDelete('/v1/employees/favorites/' + id)

// Responses Vacancy
export const getResponsesVacancies = (page, filters, perPage) =>
  api.asyncGet('/v1/employees/vacancies/responses', { page, ...filters, per_page: perPage }, false, true)

export const getResponseVacancy = (id) =>
  api.asyncGet('/v1/employees/vacancies/responses/' + id, null, false, true)

export const postResponseVacancy = (id, data) =>
  api.asyncPost('/v1/employees/vacancies/' + id + '/responses', data)

export const deleteResponseVacancy = (id) =>
  api.asyncDelete('/v1/employees/vacancies/responses/' + id)

// Test
export const getTest = (id) =>
  api.asyncGet(`/v1/employees/vacancies/${id}/test`, null, false, true)

// Services
export const getServices = data =>
  api.asyncGet('/v1/employees/packages', data, false, true)

export const getConnectedServices = data =>
  api.asyncGet('/v1/employees/my-packages', data, false, true)

export const postConnectService = (id) =>
  api.asyncPost('/v1/employees/packages/' + id)

// Send Request For Payment
export const postRequestForPayment = (data) =>
  api.asyncPost('/v1/payments', data)
