import api from '../modules/api'

export const getToken = (email, password) => {
  return api.asyncPost('/auth/login', { email, password })
}

export const deleteToken = () =>
  api.asyncGet('/auth/logout')

export const postRegisterUser = (data) => {
  data.role_id = 3
  return api.asyncPost('/auth/register/user', data)
}

export const postRegisterCompany = (data) => {
  data.BIN = data.BIN.slice(0, 4) + data.BIN.slice(5, 9) + data.BIN.slice(10, 14) + data.BIN.slice(15, 19)
  return api.asyncPost('/auth/register/company', data)
}
export const postForgotPassword = (email) =>
  api.asyncPost('/auth/password/forgot', { email })

export const getSocialNetwork = socialNetwork => {
  return api.asyncGet('/auth/socialite/redirect/' + socialNetwork, {}, false, true)
}
