<template>
  <Header />
  <section class="listCompany">
    <div class="container">
    <h3 class="section__title">{{$t('companies')}}</h3>
      <NothingHere v-if="companies && companies.length === 0"/>
      <div v-else>
        <ListCompanies :companies="companies" />
        <Pagination
          v-if="companies"
          v-model="filter.page"
          :perPage="filter.per_page"
          :total="total"
          @newPage="fetchData"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ListCompanies from '@/components/uiConsultant/Companies/ListCompanies.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import router from '@/router/index.js'
import { useRoute } from 'vue-router'

import { getListCompany } from '@/actions/consultant'

const route = useRoute()

const companies = ref(null)
const total = ref(0)

const filter = reactive(
  {
    page: Number(route.query.page),
    per_page: 20
  }
)

const fetchData = async () => {
  companies.value = null
  const response = (await getListCompany(filter)).data.companies
  companies.value = response.data
  total.value = response.total
  router.push({ path: '/consultant/list-companies/result', query: { page: filter.page } })
}

onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (route.name === 'ListCompanies') {
      filter.page = Number(route.query.page)
      fetchData()
    }
  }
)
</script>

<style lang="scss" scoped>
.listCompany{
  &__item:first-child{
    border-top: 0.5px solid #919599;
  }
}
</style>
