<template>
  <Header />
  <section class="connectedServices">
    <div class="container">
      <h3 class="section__title">{{$t('connected_services')}}</h3>
      <ListServices :services="connectedServices"/>
      <Pagination
        v-if="connectedServices"
        v-model="filter.page"
        :perPage="filter.per_page"
        :total="total"
        @newPage="fetchData"
      />
    </div>
  </section>
</template>

<script setup>
import ListServices from '@/components/uiCompany/ConectedServices/ListServices.vue'

import { ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getMyServices } from '@/actions/company'

const route = useRoute()

const filter = (
  {
    page: 1,
    per_page: 20
  }
)
const total = ref(0)
const connectedServices = ref(null)

// Fetch Data
const fetchData = async () => {
  connectedServices.value = null
  const response = (await getMyServices(filter)).data
  connectedServices.value = response.data
  total.value = response.total
  router.push({ path: '/company/working-with-the-site/connected-services/result', query: { page: filter.page } })
}

onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'ConnectedServices') {
      filter.page = route.query.page ?? 1
      fetchData()
    }
  }
)
</script>

<style lang="scss" scoped>

</style>
