<template>
  <div v-if="(type === 'text' || type === 'number') && !required" class="block__">
    <input
      v-if="trySalary"
      @input="$emit('update:modelValue', $event.target.value)"
      v-model="input.text"
      type="text"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <input
      v-else
      @input="$emit('update:modelValue', $event.target.value)"
      v-model="input.text"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
    />
  </div>
  <div v-else-if="(type === 'text' || type === 'number')" class="block__">
    <input v-if="!maskInput"
      :type="type"
      @input="handleInput"
      :placeholder="placeholder"
      :class="vText$.text.$errors[0] && input.isDirty ? 'errorInput' : ''"
      v-model="input.text"
      :disabled="disabled"
      @blur="checkDirty(vText$)"
    />
    <input v-else
      :type="type"
      @input="handleInput"
      :placeholder="placeholder"
      :class="vText$.text.$errors[0] && input.isDirty ? 'errorInput' : ''"
      required
      v-model="input.text"
      :disabled="disabled"
      @blur="checkDirty(vText$)"
      v-mask="maskInput"
    />
    <transition name="fade">
      <div class="error" v-if="vText$.text.$errors[0] && input.isDirty">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2226 5104 c-935 -120 -1741 -761 -2070 -1645 -71 -192 -124 -415 -145 -611 -14 -125 -14 -451 0 -576 75 -696 447 -1344 1014 -1767 364 -272 769 -434 1225 -491 152 -19 472 -18 630 1 692 86 1314 448 1735 1010 269 359 435 770 490 1215 20 162 20 477 0 640 -44 358 -168 713 -353 1014 -412 671 -1098 1115 -1872 1211 -155 19 -502 18 -654 -1z m584 -405 c367 -41 733 -187 1042 -416 128 -96 331 -298 424 -423 209 -281 340 -579 407 -929 19 -101 22 -149 22 -371 0 -222 -3 -270 -22 -371 -67 -348 -199 -650 -407 -929 -90 -122 -294 -326 -416 -416 -391 -291 -820 -435 -1300 -435 -583 0 -1107 218 -1520 631 -319 318 -516 694 -603 1149 -19 101 -22 149 -22 371 0 222 3 270 22 371 121 628 474 1143 1013 1474 394 242 881 348 1360 294z"/> <path d="M1732 3474 c-88 -44 -129 -141 -102 -241 12 -44 41 -76 324 -360 l310 -313 -310 -312 c-283 -285 -312 -317 -324 -361 -27 -100 14 -197 102 -241 57 -28 99 -32 162 -14 34 10 91 62 354 322 l312 310 313 -310 c284 -283 316 -312 360 -324 100 -27 197 14 241 102 28 57 32 99 14 162 -10 34 -62 91 -322 354 l-310 312 310 313 c260 262 312 319 322 353 18 63 14 105 -14 162 -44 88 -141 129 -241 102 -44 -12 -76 -41 -360 -324 l-313 -310 -312 310 c-263 260 -320 312 -354 322 -63 18 -105 14 -162 -14z"/> </g> </svg>
        <span v-for="error of vText$.text.$errors" :key="error.$uid">{{
          error.$message
        }}</span>
      </div>
    </transition>
  </div>
  <div v-else-if="type === 'textarea' && !required" class="block__">
    <textarea
      @input="$emit('update:modelValue', $event.target.value)"
      v-model="input.text"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
    ></textarea>
  </div>
  <div v-else-if="type === 'textarea'" class="block__">
    <textarea
      :type="type"
      @input="handleInput"
      :placeholder="placeholder"
      :class="vText$.text.$errors[0] && input.isDirty ? 'errorInput' : ''"
      :required="required"
      v-model="input.text"
      :disabled="disabled"
      @blur="checkDirty(vText$)"
    ></textarea>
    <transition name="fade">
      <div class="error" v-if="vText$.text.$errors[0] && input.isDirty">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2226 5104 c-935 -120 -1741 -761 -2070 -1645 -71 -192 -124 -415 -145 -611 -14 -125 -14 -451 0 -576 75 -696 447 -1344 1014 -1767 364 -272 769 -434 1225 -491 152 -19 472 -18 630 1 692 86 1314 448 1735 1010 269 359 435 770 490 1215 20 162 20 477 0 640 -44 358 -168 713 -353 1014 -412 671 -1098 1115 -1872 1211 -155 19 -502 18 -654 -1z m584 -405 c367 -41 733 -187 1042 -416 128 -96 331 -298 424 -423 209 -281 340 -579 407 -929 19 -101 22 -149 22 -371 0 -222 -3 -270 -22 -371 -67 -348 -199 -650 -407 -929 -90 -122 -294 -326 -416 -416 -391 -291 -820 -435 -1300 -435 -583 0 -1107 218 -1520 631 -319 318 -516 694 -603 1149 -19 101 -22 149 -22 371 0 222 3 270 22 371 121 628 474 1143 1013 1474 394 242 881 348 1360 294z"/> <path d="M1732 3474 c-88 -44 -129 -141 -102 -241 12 -44 41 -76 324 -360 l310 -313 -310 -312 c-283 -285 -312 -317 -324 -361 -27 -100 14 -197 102 -241 57 -28 99 -32 162 -14 34 10 91 62 354 322 l312 310 313 -310 c284 -283 316 -312 360 -324 100 -27 197 14 241 102 28 57 32 99 14 162 -10 34 -62 91 -322 354 l-310 312 310 313 c260 262 312 319 322 353 18 63 14 105 -14 162 -44 88 -141 129 -241 102 -44 -12 -76 -41 -360 -324 l-313 -310 -312 310 c-263 260 -320 312 -354 322 -63 18 -105 14 -162 -14z"/> </g> </svg>
        <span v-for="error of vText$.text.$errors" :key="error.$uid">{{
          error.$message
        }}</span>
      </div>
    </transition>
  </div>
  <div v-else-if="type === 'tel'" class="block__">
    <div class="input__phone">
      <Select
        id="city"
        v-model="input.phoneCountry"
        :options="input.phoneCountries"
        :disabled="disabled"
      />
      <input
        type="tel"
        @input="handleInput"
        :placeholder="placeholder"
        :class="vPhone$.text.$errors[0] && input.isDirty ? 'errorInput' : ''"
        :required="required"
        v-model="input.text"
        @blur="checkDirty(vPhone$)"
        v-mask="'(###) ### ####'"
        :disabled="disabled"
      />
    </div>
    <transition name="fade">
      <div class="error" v-if="vPhone$.text.$errors[0] && input.isDirty">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2226 5104 c-935 -120 -1741 -761 -2070 -1645 -71 -192 -124 -415 -145 -611 -14 -125 -14 -451 0 -576 75 -696 447 -1344 1014 -1767 364 -272 769 -434 1225 -491 152 -19 472 -18 630 1 692 86 1314 448 1735 1010 269 359 435 770 490 1215 20 162 20 477 0 640 -44 358 -168 713 -353 1014 -412 671 -1098 1115 -1872 1211 -155 19 -502 18 -654 -1z m584 -405 c367 -41 733 -187 1042 -416 128 -96 331 -298 424 -423 209 -281 340 -579 407 -929 19 -101 22 -149 22 -371 0 -222 -3 -270 -22 -371 -67 -348 -199 -650 -407 -929 -90 -122 -294 -326 -416 -416 -391 -291 -820 -435 -1300 -435 -583 0 -1107 218 -1520 631 -319 318 -516 694 -603 1149 -19 101 -22 149 -22 371 0 222 3 270 22 371 121 628 474 1143 1013 1474 394 242 881 348 1360 294z"/> <path d="M1732 3474 c-88 -44 -129 -141 -102 -241 12 -44 41 -76 324 -360 l310 -313 -310 -312 c-283 -285 -312 -317 -324 -361 -27 -100 14 -197 102 -241 57 -28 99 -32 162 -14 34 10 91 62 354 322 l312 310 313 -310 c284 -283 316 -312 360 -324 100 -27 197 14 241 102 28 57 32 99 14 162 -10 34 -62 91 -322 354 l-310 312 310 313 c260 262 312 319 322 353 18 63 14 105 -14 162 -44 88 -141 129 -241 102 -44 -12 -76 -41 -360 -324 l-313 -310 -312 310 c-263 260 -320 312 -354 322 -63 18 -105 14 -162 -14z"/> </g> </svg>
        <span v-for="error of vPhone$.text.$errors" :key="error.$uid">{{
          error.$message
        }}</span>
      </div>
    </transition>
  </div>
  <div v-else-if="type === 'email'" class="block__">
    <input
      :type="type"
      @input="handleInput"
      :placeholder="placeholder"
      :class="vEmail$.text.$errors[0] && input.isDirty ? 'errorInput' : ''"
      required
      v-model="input.text"
      :disabled="disabled"
      @blur="checkDirty(vEmail$)"
      autocomplete="off"
    />
    <transition name="fade">
      <div class="error" v-if="vEmail$.text.$errors[0] && input.isDirty">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2226 5104 c-935 -120 -1741 -761 -2070 -1645 -71 -192 -124 -415 -145 -611 -14 -125 -14 -451 0 -576 75 -696 447 -1344 1014 -1767 364 -272 769 -434 1225 -491 152 -19 472 -18 630 1 692 86 1314 448 1735 1010 269 359 435 770 490 1215 20 162 20 477 0 640 -44 358 -168 713 -353 1014 -412 671 -1098 1115 -1872 1211 -155 19 -502 18 -654 -1z m584 -405 c367 -41 733 -187 1042 -416 128 -96 331 -298 424 -423 209 -281 340 -579 407 -929 19 -101 22 -149 22 -371 0 -222 -3 -270 -22 -371 -67 -348 -199 -650 -407 -929 -90 -122 -294 -326 -416 -416 -391 -291 -820 -435 -1300 -435 -583 0 -1107 218 -1520 631 -319 318 -516 694 -603 1149 -19 101 -22 149 -22 371 0 222 3 270 22 371 121 628 474 1143 1013 1474 394 242 881 348 1360 294z"/> <path d="M1732 3474 c-88 -44 -129 -141 -102 -241 12 -44 41 -76 324 -360 l310 -313 -310 -312 c-283 -285 -312 -317 -324 -361 -27 -100 14 -197 102 -241 57 -28 99 -32 162 -14 34 10 91 62 354 322 l312 310 313 -310 c284 -283 316 -312 360 -324 100 -27 197 14 241 102 28 57 32 99 14 162 -10 34 -62 91 -322 354 l-310 312 310 313 c260 262 312 319 322 353 18 63 14 105 -14 162 -44 88 -141 129 -241 102 -44 -12 -76 -41 -360 -324 l-313 -310 -312 310 c-263 260 -320 312 -354 322 -63 18 -105 14 -162 -14z"/> </g> </svg>
        <span v-for="error of vEmail$.text.$errors" :key="error.$uid">{{
          error.$message
        }}</span>
      </div>
    </transition>
  </div>
  <div v-else-if="type === 'password'" class="block__">
    <div class="password">
      <input
        :type="input.showPassword ? 'text' : type"
        @input="handleInput"
        :placeholder="placeholder"
        :class="vPassword$.text.$errors[0] && input.isDirty ? 'errorInput' : ''"
        required
        v-model="input.text"
        :disabled="disabled"
        @blur="checkDirty(vPassword$)"
        autocomplete="off"
      />
      <div class="passwordShowHide" @click="input.showPassword = !input.showPassword">
        <transition name="toggleShow">
          <svg v-if="input.showPassword" version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2310 4334 c-19 -3 -90 -14 -158 -25 -818 -135 -1581 -707 -2088 -1564 -59 -100 -59 -100 -59 -180 0 -80 0 -80 59 -180 264 -448 635 -855 1016 -1117 350 -241 709 -388 1105 -455 88 -14 164 -18 380 -18 302 0 405 13 651 80 722 198 1387 744 1840 1510 59 100 59 100 59 180 0 80 0 80 -59 180 -453 766 -1118 1312 -1840 1509 -232 64 -342 78 -621 81 -137 2 -266 1 -285 -1z m525 -420 c379 -52 746 -214 1095 -482 110 -85 394 -370 493 -493 88 -111 257 -357 257 -374 0 -6 -41 -71 -90 -144 -307 -451 -689 -798 -1105 -1001 -313 -154 -602 -222 -936 -221 -476 1 -928 167 -1359 499 -110 85 -394 369 -493 493 -88 111 -257 357 -257 374 0 17 171 265 258 374 102 127 303 333 427 438 507 427 1118 618 1710 537z"/> <path d="M2441 3549 c-216 -25 -406 -117 -570 -273 -93 -90 -150 -169 -206 -287 -72 -154 -90 -236 -90 -424 0 -189 18 -272 91 -424 57 -120 113 -198 205 -287 260 -249 614 -339 960 -243 155 43 293 123 418 243 93 90 150 169 206 287 72 154 90 236 90 424 0 188 -18 270 -90 424 -56 118 -113 197 -206 287 -168 161 -353 248 -584 274 -101 11 -119 11 -224 -1z m299 -435 c149 -47 270 -154 340 -299 59 -123 75 -242 49 -367 -16 -80 -81 -206 -139 -270 -274 -306 -772 -235 -950 137 -82 171 -82 329 0 500 127 265 417 389 700 299z"/> </g> </svg>
        </transition>
        <transition name="toggleHide">
          <svg v-if="!input.showPassword" version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M372 4627 c-78 -78 -142 -147 -142 -152 0 -6 141 -151 314 -324 l314 -314 -92 -77 c-208 -175 -427 -435 -575 -683 -62 -104 -179 -343 -187 -384 -9 -48 110 -299 234 -492 514 -798 1375 -1271 2313 -1271 338 0 609 44 909 149 l115 40 385 -385 c212 -211 390 -384 395 -384 6 0 77 66 158 148 l147 147 -2063 2063 c-1134 1134 -2067 2062 -2072 2062 -6 0 -74 -64 -153 -143z m1490 -1916 c-4 -97 -1 -131 17 -201 79 -316 349 -531 670 -533 53 0 110 3 126 7 28 6 39 -3 205 -169 140 -140 172 -177 160 -185 -8 -5 -53 -23 -100 -40 -295 -108 -612 -90 -893 51 -206 103 -393 282 -501 480 -170 310 -188 684 -50 998 l25 55 173 -173 173 -173 -5 -117z"/> <path d="M2380 4419 c-128 -7 -339 -38 -445 -65 -92 -23 -305 -88 -305 -94 0 -3 114 -119 253 -258 l252 -252 70 24 c127 44 234 59 395 53 157 -5 251 -24 388 -77 250 -96 485 -314 610 -565 130 -263 152 -576 59 -868 l-21 -68 339 -339 c187 -187 343 -340 348 -340 16 0 211 186 292 279 206 236 356 469 466 723 29 67 39 101 35 122 -9 46 -148 321 -217 431 -412 655 -1068 1107 -1814 1249 -227 43 -479 59 -705 45z"/> <path d="M2520 3374 c0 -5 167 -176 371 -379 l371 -370 -5 95 c-9 156 -61 290 -159 407 -89 106 -222 192 -354 227 -70 19 -224 33 -224 20z"/> </g> </svg>
        </transition>
      </div>
    </div>
    <transition name="fade">
      <div class="error" v-if="vPassword$.text.$errors[0] && input.isDirty">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2226 5104 c-935 -120 -1741 -761 -2070 -1645 -71 -192 -124 -415 -145 -611 -14 -125 -14 -451 0 -576 75 -696 447 -1344 1014 -1767 364 -272 769 -434 1225 -491 152 -19 472 -18 630 1 692 86 1314 448 1735 1010 269 359 435 770 490 1215 20 162 20 477 0 640 -44 358 -168 713 -353 1014 -412 671 -1098 1115 -1872 1211 -155 19 -502 18 -654 -1z m584 -405 c367 -41 733 -187 1042 -416 128 -96 331 -298 424 -423 209 -281 340 -579 407 -929 19 -101 22 -149 22 -371 0 -222 -3 -270 -22 -371 -67 -348 -199 -650 -407 -929 -90 -122 -294 -326 -416 -416 -391 -291 -820 -435 -1300 -435 -583 0 -1107 218 -1520 631 -319 318 -516 694 -603 1149 -19 101 -22 149 -22 371 0 222 3 270 22 371 121 628 474 1143 1013 1474 394 242 881 348 1360 294z"/> <path d="M1732 3474 c-88 -44 -129 -141 -102 -241 12 -44 41 -76 324 -360 l310 -313 -310 -312 c-283 -285 -312 -317 -324 -361 -27 -100 14 -197 102 -241 57 -28 99 -32 162 -14 34 10 91 62 354 322 l312 310 313 -310 c284 -283 316 -312 360 -324 100 -27 197 14 241 102 28 57 32 99 14 162 -10 34 -62 91 -322 354 l-310 312 310 313 c260 262 312 319 322 353 18 63 14 105 -14 162 -44 88 -141 129 -241 102 -44 -12 -76 -41 -360 -324 l-313 -310 -312 310 c-263 260 -320 312 -354 322 -63 18 -105 14 -162 -14z"/> </g> </svg>
        <span v-for="error of vPassword$.text.$errors" :key="error.$uid">{{
          error.$message
        }}</span>
      </div>
    </transition>
  </div>
  <div v-else-if="type === 'new-password'" class="block__">
    <div class="password">
      <input
        :type="input.showPassword ? 'text' : 'password'"
        @input="handleInput"
        :placeholder="placeholder"
        :class="vNewPassword$.text.$errors[0] && input.isDirty ? 'errorInput' : ''"
        :required="required"
        v-model="input.text"
        :disabled="disabled"
        @blur="checkDirty(vNewPassword$)"
        autocomplete="on"
      />
      <div class="passwordShowHide" @click="input.showPassword = !input.showPassword">
        <transition name="toggleShow">
          <svg v-if="input.showPassword" version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2310 4334 c-19 -3 -90 -14 -158 -25 -818 -135 -1581 -707 -2088 -1564 -59 -100 -59 -100 -59 -180 0 -80 0 -80 59 -180 264 -448 635 -855 1016 -1117 350 -241 709 -388 1105 -455 88 -14 164 -18 380 -18 302 0 405 13 651 80 722 198 1387 744 1840 1510 59 100 59 100 59 180 0 80 0 80 -59 180 -453 766 -1118 1312 -1840 1509 -232 64 -342 78 -621 81 -137 2 -266 1 -285 -1z m525 -420 c379 -52 746 -214 1095 -482 110 -85 394 -370 493 -493 88 -111 257 -357 257 -374 0 -6 -41 -71 -90 -144 -307 -451 -689 -798 -1105 -1001 -313 -154 -602 -222 -936 -221 -476 1 -928 167 -1359 499 -110 85 -394 369 -493 493 -88 111 -257 357 -257 374 0 17 171 265 258 374 102 127 303 333 427 438 507 427 1118 618 1710 537z"/> <path d="M2441 3549 c-216 -25 -406 -117 -570 -273 -93 -90 -150 -169 -206 -287 -72 -154 -90 -236 -90 -424 0 -189 18 -272 91 -424 57 -120 113 -198 205 -287 260 -249 614 -339 960 -243 155 43 293 123 418 243 93 90 150 169 206 287 72 154 90 236 90 424 0 188 -18 270 -90 424 -56 118 -113 197 -206 287 -168 161 -353 248 -584 274 -101 11 -119 11 -224 -1z m299 -435 c149 -47 270 -154 340 -299 59 -123 75 -242 49 -367 -16 -80 -81 -206 -139 -270 -274 -306 -772 -235 -950 137 -82 171 -82 329 0 500 127 265 417 389 700 299z"/> </g> </svg>
        </transition>
        <transition name="toggleHide">
          <svg v-if="!input.showPassword" version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M372 4627 c-78 -78 -142 -147 -142 -152 0 -6 141 -151 314 -324 l314 -314 -92 -77 c-208 -175 -427 -435 -575 -683 -62 -104 -179 -343 -187 -384 -9 -48 110 -299 234 -492 514 -798 1375 -1271 2313 -1271 338 0 609 44 909 149 l115 40 385 -385 c212 -211 390 -384 395 -384 6 0 77 66 158 148 l147 147 -2063 2063 c-1134 1134 -2067 2062 -2072 2062 -6 0 -74 -64 -153 -143z m1490 -1916 c-4 -97 -1 -131 17 -201 79 -316 349 -531 670 -533 53 0 110 3 126 7 28 6 39 -3 205 -169 140 -140 172 -177 160 -185 -8 -5 -53 -23 -100 -40 -295 -108 -612 -90 -893 51 -206 103 -393 282 -501 480 -170 310 -188 684 -50 998 l25 55 173 -173 173 -173 -5 -117z"/> <path d="M2380 4419 c-128 -7 -339 -38 -445 -65 -92 -23 -305 -88 -305 -94 0 -3 114 -119 253 -258 l252 -252 70 24 c127 44 234 59 395 53 157 -5 251 -24 388 -77 250 -96 485 -314 610 -565 130 -263 152 -576 59 -868 l-21 -68 339 -339 c187 -187 343 -340 348 -340 16 0 211 186 292 279 206 236 356 469 466 723 29 67 39 101 35 122 -9 46 -148 321 -217 431 -412 655 -1068 1107 -1814 1249 -227 43 -479 59 -705 45z"/> <path d="M2520 3374 c0 -5 167 -176 371 -379 l371 -370 -5 95 c-9 156 -61 290 -159 407 -89 106 -222 192 -354 227 -70 19 -224 33 -224 20z"/> </g> </svg>
        </transition>
      </div>
    </div>
    <transition name="fade">
      <div class="error" v-if="vNewPassword$.text.$errors[0] && input.isDirty">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2226 5104 c-935 -120 -1741 -761 -2070 -1645 -71 -192 -124 -415 -145 -611 -14 -125 -14 -451 0 -576 75 -696 447 -1344 1014 -1767 364 -272 769 -434 1225 -491 152 -19 472 -18 630 1 692 86 1314 448 1735 1010 269 359 435 770 490 1215 20 162 20 477 0 640 -44 358 -168 713 -353 1014 -412 671 -1098 1115 -1872 1211 -155 19 -502 18 -654 -1z m584 -405 c367 -41 733 -187 1042 -416 128 -96 331 -298 424 -423 209 -281 340 -579 407 -929 19 -101 22 -149 22 -371 0 -222 -3 -270 -22 -371 -67 -348 -199 -650 -407 -929 -90 -122 -294 -326 -416 -416 -391 -291 -820 -435 -1300 -435 -583 0 -1107 218 -1520 631 -319 318 -516 694 -603 1149 -19 101 -22 149 -22 371 0 222 3 270 22 371 121 628 474 1143 1013 1474 394 242 881 348 1360 294z"/> <path d="M1732 3474 c-88 -44 -129 -141 -102 -241 12 -44 41 -76 324 -360 l310 -313 -310 -312 c-283 -285 -312 -317 -324 -361 -27 -100 14 -197 102 -241 57 -28 99 -32 162 -14 34 10 91 62 354 322 l312 310 313 -310 c284 -283 316 -312 360 -324 100 -27 197 14 241 102 28 57 32 99 14 162 -10 34 -62 91 -322 354 l-310 312 310 313 c260 262 312 319 322 353 18 63 14 105 -14 162 -44 88 -141 129 -241 102 -44 -12 -76 -41 -360 -324 l-313 -310 -312 310 c-263 260 -320 312 -354 322 -63 18 -105 14 -162 -14z"/> </g> </svg>
        <span v-for="error of vNewPassword$.text.$errors" :key="error.$uid">{{
          error.$message
        }}</span>
      </div>
    </transition>
  </div>
  <div v-else-if="type === 'confirm-password'" class="block__">
    <div class="password">
      <input
        :type="input.showPassword ? 'text' : 'password'"
        @input="handleInput"
        :placeholder="placeholder"
        :class="vConfirmPassword$.text.$errors[0] && input.isDirty ? 'errorInput' : ''"
        v-model="input.text"
        :required="required"
        :disabled="disabled"
        @blur="checkDirty(vConfirmPassword$)"
        autocomplete="on"
      />
      <div class="passwordShowHide" @click="input.showPassword = !input.showPassword">
        <transition name="toggleShow">
          <svg v-if="input.showPassword" version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2310 4334 c-19 -3 -90 -14 -158 -25 -818 -135 -1581 -707 -2088 -1564 -59 -100 -59 -100 -59 -180 0 -80 0 -80 59 -180 264 -448 635 -855 1016 -1117 350 -241 709 -388 1105 -455 88 -14 164 -18 380 -18 302 0 405 13 651 80 722 198 1387 744 1840 1510 59 100 59 100 59 180 0 80 0 80 -59 180 -453 766 -1118 1312 -1840 1509 -232 64 -342 78 -621 81 -137 2 -266 1 -285 -1z m525 -420 c379 -52 746 -214 1095 -482 110 -85 394 -370 493 -493 88 -111 257 -357 257 -374 0 -6 -41 -71 -90 -144 -307 -451 -689 -798 -1105 -1001 -313 -154 -602 -222 -936 -221 -476 1 -928 167 -1359 499 -110 85 -394 369 -493 493 -88 111 -257 357 -257 374 0 17 171 265 258 374 102 127 303 333 427 438 507 427 1118 618 1710 537z"/> <path d="M2441 3549 c-216 -25 -406 -117 -570 -273 -93 -90 -150 -169 -206 -287 -72 -154 -90 -236 -90 -424 0 -189 18 -272 91 -424 57 -120 113 -198 205 -287 260 -249 614 -339 960 -243 155 43 293 123 418 243 93 90 150 169 206 287 72 154 90 236 90 424 0 188 -18 270 -90 424 -56 118 -113 197 -206 287 -168 161 -353 248 -584 274 -101 11 -119 11 -224 -1z m299 -435 c149 -47 270 -154 340 -299 59 -123 75 -242 49 -367 -16 -80 -81 -206 -139 -270 -274 -306 -772 -235 -950 137 -82 171 -82 329 0 500 127 265 417 389 700 299z"/> </g> </svg>
        </transition>
        <transition name="toggleHide">
          <svg v-if="!input.showPassword" version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M372 4627 c-78 -78 -142 -147 -142 -152 0 -6 141 -151 314 -324 l314 -314 -92 -77 c-208 -175 -427 -435 -575 -683 -62 -104 -179 -343 -187 -384 -9 -48 110 -299 234 -492 514 -798 1375 -1271 2313 -1271 338 0 609 44 909 149 l115 40 385 -385 c212 -211 390 -384 395 -384 6 0 77 66 158 148 l147 147 -2063 2063 c-1134 1134 -2067 2062 -2072 2062 -6 0 -74 -64 -153 -143z m1490 -1916 c-4 -97 -1 -131 17 -201 79 -316 349 -531 670 -533 53 0 110 3 126 7 28 6 39 -3 205 -169 140 -140 172 -177 160 -185 -8 -5 -53 -23 -100 -40 -295 -108 -612 -90 -893 51 -206 103 -393 282 -501 480 -170 310 -188 684 -50 998 l25 55 173 -173 173 -173 -5 -117z"/> <path d="M2380 4419 c-128 -7 -339 -38 -445 -65 -92 -23 -305 -88 -305 -94 0 -3 114 -119 253 -258 l252 -252 70 24 c127 44 234 59 395 53 157 -5 251 -24 388 -77 250 -96 485 -314 610 -565 130 -263 152 -576 59 -868 l-21 -68 339 -339 c187 -187 343 -340 348 -340 16 0 211 186 292 279 206 236 356 469 466 723 29 67 39 101 35 122 -9 46 -148 321 -217 431 -412 655 -1068 1107 -1814 1249 -227 43 -479 59 -705 45z"/> <path d="M2520 3374 c0 -5 167 -176 371 -379 l371 -370 -5 95 c-9 156 -61 290 -159 407 -89 106 -222 192 -354 227 -70 19 -224 33 -224 20z"/> </g> </svg>
        </transition>
      </div>
    </div>
    <transition name="fade">
      <div class="error" v-if="vConfirmPassword$.text.$errors[0] && input.isDirty">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2226 5104 c-935 -120 -1741 -761 -2070 -1645 -71 -192 -124 -415 -145 -611 -14 -125 -14 -451 0 -576 75 -696 447 -1344 1014 -1767 364 -272 769 -434 1225 -491 152 -19 472 -18 630 1 692 86 1314 448 1735 1010 269 359 435 770 490 1215 20 162 20 477 0 640 -44 358 -168 713 -353 1014 -412 671 -1098 1115 -1872 1211 -155 19 -502 18 -654 -1z m584 -405 c367 -41 733 -187 1042 -416 128 -96 331 -298 424 -423 209 -281 340 -579 407 -929 19 -101 22 -149 22 -371 0 -222 -3 -270 -22 -371 -67 -348 -199 -650 -407 -929 -90 -122 -294 -326 -416 -416 -391 -291 -820 -435 -1300 -435 -583 0 -1107 218 -1520 631 -319 318 -516 694 -603 1149 -19 101 -22 149 -22 371 0 222 3 270 22 371 121 628 474 1143 1013 1474 394 242 881 348 1360 294z"/> <path d="M1732 3474 c-88 -44 -129 -141 -102 -241 12 -44 41 -76 324 -360 l310 -313 -310 -312 c-283 -285 -312 -317 -324 -361 -27 -100 14 -197 102 -241 57 -28 99 -32 162 -14 34 10 91 62 354 322 l312 310 313 -310 c284 -283 316 -312 360 -324 100 -27 197 14 241 102 28 57 32 99 14 162 -10 34 -62 91 -322 354 l-310 312 310 313 c260 262 312 319 322 353 18 63 14 105 -14 162 -44 88 -141 129 -241 102 -44 -12 -76 -41 -360 -324 l-313 -310 -312 310 c-263 260 -320 312 -354 322 -63 18 -105 14 -162 -14z"/> </g> </svg>
        <span>{{
          vConfirmPassword$.text.$errors[0].$message
        }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { reactive } from '@vue/reactivity'
import { watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { countriesPhoneNumber, deleteFormatToPhone } from '@/helpers/formatPhone'
import { required, email, helpers, minLength } from '@vuelidate/validators'

export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    validation: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    newPassword: {
      type: String,
      default: null
    },
    maskInput: {
      type: String,
      default: null
    },
    trySalary: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:modelValue',
    'update:validation'
  ],
  setup (props, { emit }) {
    const input = reactive({
      text: props.modelValue ? props.modelValue : '',
      showPassword: false,
      isDirty: false,
      widthErrors: 0,
      phoneCountry: '+7',
      phoneCountries: []
    })
    watch(
      () => props.modelValue,
      () => {
        if (props.type === 'textarea') {
          input.text = props.modelValue
        }
      }
    )
    input.phoneCountries = countriesPhoneNumber

    const i18n = useI18n()

    if (props.modelValue) {
      if (props.type === 'tel' && props.modelValue.length >= 12) {
        if (props.modelValue[0] === '+') input.phoneCountry = props.modelValue.substring(0, props.modelValue.length - 10)
        input.text = props.modelValue.substring(props.modelValue.length - 10, props.modelValue.length)
      }
    }

    // ====================  VALIDATE DATA  ====================
    const passwordValidate = helpers.regex(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
    )
    watch(
      () => props.newPassword,
      () => {
        sameAs.value = props.newPassword
        checkErrors(vConfirmPassword$)
      }
    )
    watch(
      () => input.phoneCountry,
      () => emit('update:modelValue', input.phoneCountry + '' + deleteFormatToPhone(input.text))
    )
    const sameAs = value => value === props.newPassword
    const rulesPhone = {
      text: {
        required: helpers.withMessage(i18n.t('errors.this_fieldIs_required'), required),
        minLength: helpers.withMessage(
          i18n.t('errors.enter_the_correct_number'),
          minLength(14)
        ),
        $lazy: true
      }
    }
    const rulesEmail = {
      text: {
        required: helpers.withMessage(i18n.t('errors.this_fieldIs_required'), required),
        email: helpers.withMessage(i18n.t('errors.enter_the_correct_email'), email),
        $lazy: true
      }
    }
    const rulesTextRequired = {
      text: {
        required: helpers.withMessage(i18n.t('errors.this_fieldIs_required'), required),
        $lazy: true
      }
    }
    const rulesPassword = {
      text: {
        required: helpers.withMessage(i18n.t('errors.this_fieldIs_required'), required),
        $lazy: true
      }
    }
    const rulesNewPassword = {
      text: {
        passwordValidate: helpers.withMessage(
          i18n.t('errors.enter_the_correct_password'),
          passwordValidate
        ),
        $lazy: true
      }
    }
    const rulesConfirmPassword = {
      text: {
        sameAs: helpers.withMessage(i18n.t('errors.passwords_dont_match'), sameAs),
        $lazy: true
      }
    }
    const vText$ = useVuelidate(rulesTextRequired, input)
    const vEmail$ = useVuelidate(rulesEmail, input)
    const vPassword$ = useVuelidate(rulesPassword, input)
    const vNewPassword$ = useVuelidate(rulesNewPassword, input)
    const vConfirmPassword$ = useVuelidate(rulesConfirmPassword, input)
    const vPhone$ = useVuelidate(rulesPhone, input)
    const checkErrors = (bool) => {
      if (!bool.value.$error && input.text.length > 0) {
        emit('update:validation', true)
        input.isDirty = false
      } else {
        emit('update:validation', false)
        if (input.widthErrors > 0) input.isDirty = true
      }
    }

    switch (props.type) {
      case 'email' :
        checkErrors(vEmail$)
        break
      case 'password' :
        checkErrors(vPassword$)
        break
      case 'tel' :
        checkErrors(vPhone$)
        break
      case 'text' :
        checkErrors(vText$)
        break
      case 'textarea' :
        checkErrors(vText$)
        break
    }

    const handleInput = event => {
      switch (props.type) {
        case 'email' :
          checkErrors(vEmail$)
          break
        case 'password' :
          checkErrors(vPassword$)
          break
        case 'new-password' :
          checkErrors(vNewPassword$)
          break
        case 'confirm-password' :
          checkErrors(vConfirmPassword$)
          break
        case 'tel' :
          checkErrors(vPhone$)
          break
        case 'text' :
          checkErrors(vText$)
          break
        case 'textarea' :
          checkErrors(vText$)
          break
      }
      if (props.type === 'tel') emit('update:modelValue', input.phoneCountry + '' + deleteFormatToPhone(event.target.value))
      else emit('update:modelValue', event.target.value)
    }
    const checkDirty = (bool) => {
      bool.text.$touch()
      ++input.widthErrors
      input.isDirty = bool.text.$error
    }
    return {
      vText$,
      vEmail$,
      vPassword$,
      vNewPassword$,
      vConfirmPassword$,
      vPhone$,
      input,
      checkErrors,
      handleInput,
      checkDirty,
      sameAs
    }
  }
}
</script>

<style lang="scss" scoped>
.block__{
  position: relative;
}
.error{
  display: grid;
  grid-template-columns: 13px 1fr;
  grid-gap: 8px;
  padding: 5px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;

  & svg{
    display: block;
    height: 13px;
    width: 13px;
    fill: #FF5656;
    left: 0;
    margin-top: 1px;
  }
  & span{
    font-size: 12px;
    line-height: 1.2;
    color: #FF5352;
  }
}
input,
textarea{
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  padding: 10px 20px 11px;
  border: 0.5px solid rgba(33, 37, 41, 0.7);
  position: relative;
  z-index: 1;

  &::placeholder{
    color: rgba(#212529, .5);
  }
  &.errorInput{
    border-color: #FF5352 !important;
    animation: bounce  .15s 1 ease-in-out;
  }
  &:focus,
  &:hover{
    border-color: #0077FF;
  }
  &[disabled]{
    opacity: .7;
    cursor: default;
    border: 0.5px solid rgba(33, 37, 41, 0.7);
  }
}
.password{
  position: relative;
  & input{
    padding-right: 30px;
  }
  & .passwordShowHide{
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    overflow: hidden;
    cursor: pointer;
    z-index: 6;
  }
  & svg{
    position: absolute;
    left: 0;
    display: block;
    height: 20px;
    width: 20px;
    opacity: .7;
    fill: #212529;
  }
}
textarea{
  resize: vertical;
  width: 100%;
  min-height: 230px;
}
.select{
    position: relative;
    width: 100%;

    &.showOptions{
      & .arrow svg{
        transform: rotate(270deg);
        fill: #0077FF;
      }
    }
}
.input{
  position: relative;
  & input{
    width: 100%;
    padding-right: 40px;
    cursor: pointer;
  }
  &__phone{
    display: grid;
    grid-template-columns: 112px 1fr;
    grid-gap: 10px;
  }
}
.arrow{
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  & svg{
    display: block;
    height: 16px;
    width: 8px;
    transform: rotate(90deg);
    transition: transform .2s linear;
  }
}
.options{
    width: 100%;
    max-height: 200px;
    position: absolute;
    top: 105%;
    left: 0;
    z-index: 10;
    overflow-y: auto;

    border: 1px solid rgba(#212529, .7);
    background-color: #fff;
    box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.1);
    transition: all .2s linear;
}

.option{
  cursor: pointer;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  transition: background-color .2s linear;

  &:hover{
    background-color: rgba(#0077FF, 0.15) !important;
  }
}

// Adaptive
@media (max-width: 992px){
  input,
  textarea{
    font-size: 14px;
    padding: 9px 18px 10px;
  }
  .error{
    display: grid;
    grid-template-columns: 12px 1fr;
    grid-gap: 7px;
    padding: 5px 0;
    & svg{
      height: 12px;
      width: 12px;
    }
    & span{
      font-size: 11px;
    }
  }
}

@media (max-width: 768px){
  input,
  textarea{
    font-size: 13px;
    padding: 8px 16px 9px;
  }
  .error{
    display: grid;
    grid-template-columns: 10px 1fr;
    grid-gap: 6px;
    padding: 5px 0;
    & svg{
      height: 10px;
      width: 10px;
    }
    & span{
      font-size: 10px;
    }
  }
}

@media (max-width: 576px){
  input,
  textarea{
    font-size: 12px;
    padding: 7px 14px 8px;
  }
  .error{
    display: grid;
    grid-template-columns: 9px 1fr;
    grid-gap: 5px;
    padding: 3px 0;
    & svg{
      height: 9px;
      width: 9px;
    }
    & span{
      font-size: 9px;
    }
  }
}

// Helpers
@keyframes bounce {
  from {
    transform: translateX(10px);
    animation-timing-function: out-in;
  }
  25% {
    transform: translateX(0);
    animation-timing-function: ease-in;
  }
  50% {
    transform: translateX(-10px);
    animation-timing-function: out-in;
  }
  75% {
    transform: translateX(0);
    animation-timing-function: ease-in;
  }
  to {
    transform: translateX(10px);
  }
}
.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s linear;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(-30px);
  padding: 0;
  height: 0;
}

.toggleShow-leave-active,
.toggleShow-enter-active,
.toggleHide-leave-active,
.toggleHide-enter-active {
  transition: all 0.2s linear;
}

.toggleShow-enter-from,
.toggleShow-leave-to {
  transform: translateY(-30px);
}
.toggleHide-enter-from,
.toggleHide-leave-to {
  transform: translateY(30px);
}
.drop-enter-active,
.drop-leave-active {
   transition: all.2s linear;
}
.drop-enter-from,
.drop-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
</style>
