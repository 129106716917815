<template>
  <Header/>
  <section class="searchVacancies searchMyVacancies">
    <div class="container">
      <HeaderFilter
        v-model="filter"
        @search="fetchData()"
        forVacancy
      />
      <NothingHere v-if="vacancies && vacancies.length === 0"/>
      <ListVacancies
        v-else
        :vacancies="vacancies"
        @update="() => {fetchData()}"
      />
      <Pagination
        v-if="vacancies"
        v-model="filter.page"
        :perPage="filter.per_page"
        :total="total"
        @newPage="() => {fetchData(filter.page)}"
      />
    </div>
  </section>
</template>

<script setup>
import HeaderFilter from '@/components/uiLanding/Filter/Header/HeaderFilter.vue'
import ListVacancies from '@/components/uiCompany/Vacancies/Search/ListVacancies.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getVacancies } from '@/actions/company'

const route = useRoute()
// Filter
const filter = reactive(
  {
    name: typeof route.query.search !== 'undefined' ? route.query.search : '',
    sort_type: route.query.type === 'date_asc' ? 'date_asc' : null,
    period: route.query.period,
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20
  }
)
const total = ref()

const vacancies = ref(null)

const fetchData = async (page = 1) => {
  filter.page = page
  vacancies.value = null
  const response = (await getVacancies(filter)).data
  vacancies.value = response.data
  total.value = response.total
  router.push({ path: '/company/search-vacancy/result', query: { search: filter.name, type: filter.sort_type, period: filter.period, page: page } })
}

onMounted(
  () => fetchData(filter.page)
)

watch(
  () => route.query,
  () => {
    if (route.name === 'CompanyVacancies') {
      const sort = route.query.type === 'date_asc' ? 'date_asc' : null
      const name = typeof route.query.search !== 'undefined' ? route.query.search : ''

      if (filter.period !== route.query.period || filter.name !== name || filter.sort_type !== sort) {
        filter.period = route.query.period
        filter.name = name
        filter.sort_type = sort
        filter.page = 1
      } else filter.page = route.query.page ?? 1
      fetchData(filter.page)
    }
  }
)
</script>

<style lang="scss">
.searchMyVacancies{
  & .filterToggleButtton{
    display: none !important;
  }
}
</style>
