<template>
  <div class="editLetter">
    <button class="title" type="text" @click="show = !show">{{info.title}}</button>
    <form v-if="show" @submit.prevent="editLetter()" method="POST">
      <Input
        v-model="formData.title"
        v-model:validation="success.title"
        :placeholder="$t('name_template')"
        required/>
      <Input
        type="textarea"
        v-model="formData.text"
        :placeholder="$t('description')"
        v-model:validation="success.text"
        required/>
      <div class="btns">
        <Button
          :name="$t('save')"
          :hasError="success"
          htmlType="submit"/>
        <Button
          :name="$t('delete')"
          noButton
          type="gray"
          @click="quastion = true"/>
      </div>
    </form>
  </div>
  <Modal
    :hide="()=>{quastion = false}"
    :show="quastion"
    quastion
    :agreement="() => {deleteLetters()}"
    :loading="loading"
  />
</template>
<script setup>
import { reactive, ref } from '@vue/reactivity'
import Modal from '@/components/common/Modal.vue'
import { putLetter, deleteLetter } from '@/actions/company'
import { defineProps, watch } from 'vue'
const props = defineProps({
  hide: {
    type: Function,
    default: null
  },
  info: {
    type: Object,
    default: () => {}
  },
  finally: {
    type: Function,
    default: () => null
  }
})

const show = ref(false)
const formData = reactive({
  title: props.info.title,
  text: props.info.text
})

const quastion = ref(false)
const loading = ref(false)

const success = reactive({
  title: false,
  text: false
})

const editLetter = async () => {
  loading.value = true
  try {
    await putLetter(props.info.id, formData)
  } finally {
    loading.value = false
    props.finally()
  }
}

const deleteLetters = async () => {
  loading.value = true
  try {
    await deleteLetter(props.info.id)
  } finally {
    loading.value = false
    props.finally()
  }
}

watch(
  () => [success.title, success.text],
  () => {
    if (formData.title.length !== 0 && formData.text.length !== 0) {
      success.title = true
      success.text = true
    }
  }
)
</script>

<style lang="scss">
.editLetter{
  max-width: 500px;
  & .title{
    display: inline-block;
    font-size: 16px;
    color: #4D533C;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 15px;
  }
  & form{
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    padding-left: 20px;
    margin-bottom: 40px;
    max-width: 620px;
  }
  & .btns{
    display: flex;
    grid-gap: 20px;
  }
}
</style>
