<template>
<div class="passingTest__question">
  <p class="passingTest__subtitle">{{`№${num} ${question.title}`}}</p>
  <div class="passingTest__block">
    <div
      v-for="variant of question.variations"
      :key="variant"
    >
      <RowVariant
        :variant="variant"
        :result="result"
        v-model:selectVariant="formData"
      />
    </div>
  </div>
</div>
</template>

<script setup>
import RowVariant from '@/components/uiEmployee/Test/RowVariant.vue'

import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  question: {
    type: Object,
    default: null
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  result: {
    type: Boolean,
    default: false
  },
  num: {
    type: Number,
    default: null
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
    emit('update:validation', true)
  }
)
</script>

<style lang="scss">
.passingTest{
  &__subtitle{
    font-size: 18px;
    font-weight: 500;
    color: #000;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  &__question{
    margin-bottom: 30px;
  }
  &__block{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
  }

  @media (max-width: 992px){
    &__subtitle{
      font-size: 16px;
      margin-bottom: 9px;
    }
  }
  @media (max-width: 768px){
    &__subtitle{
      font-size: 14px;
      margin-bottom: 8px;
    }
    &__question{
      margin-bottom: 25px;
    }
    &__block{
      grid-gap: 8px;
    }
  }
  @media (max-width: 576px){
    &__subtitle{
      font-size: 12px;
      margin-bottom: 6px;
    }
    &__block{
      grid-gap: 6px;
    }
  }

  @media (max-width: 400px){
    &__subtitle{
      font-size: 12px;
      margin-bottom: 6px;
    }
    &__question{
      margin-bottom: 20px;
    }
    &__block{
      grid-gap: 6px;
    }
  }
}
</style>
