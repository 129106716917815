<template>
<div class="stat">
  <div class="stat__item">
    <div class="stat__count">{{total.resume ? total.resume : '0'}}</div>
    <div class="stat__text">{{$t('resumes')}}</div>
  </div>
  <div class="stat__item">
    <div class="stat__count">{{total.vacancy ? total.vacancy : '0'}}</div>
    <div class="stat__text">{{$t('vacancies')}}</div>
  </div>
  <div class="stat__item">
    <div class="stat__count">{{total.company ? total.company : '0'}}</div>
    <div class="stat__text">{{$t('companies')}}</div>
  </div>
</div>
</template>

<script>
import { getStatistics } from '@/actions/home'
import { onMounted, ref } from '@vue/runtime-core'
export default {
  name: 'Home',
  setup () {
    const total = ref({})
    onMounted(
      async () => {
        const response = await getStatistics()
        total.value = response.data
      }
    )
    return {
      total
    }
  }
}
</script>

<style lang="scss" scoped>
.stat{
  position: relative;
  z-index: 10;
  &__item{
      margin-bottom: 30px;
  }
  &__count{
      font-size: 60px;
      font-weight: 800;
      color: #fff;
      line-height: 1.2;
  }
  &__text{
      font-size: 16px;
      font-weight: 300;
      color: #fff;
      line-height: 1.2;
  }
}
</style>
