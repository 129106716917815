<template>
<LayoutRow
  :title="$t('ownership_pc')"
  v-model="formData.ownershipPC_on"
>
  <div class="createResume__itemInner">
    <label for="prof">{{$t('main_programs')}}: <br><span>{{$t('list_separated_by_commas')}}</span> </label>
    <Input
      id="title"
      type="textarea"
      v-model="formData.computer_skills"/>
  </div>

  <div class="createResume__itemInner">
    <label for="prof">{{$t('additionally')}}: <br><span>{{$t('list_separated_by_commas')}}</span> </label>
    <Input
      id="title"
      type="textarea"
      v-model="formData.computer_skills_info"/>
  </div>
</LayoutRow>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits('update:modelValue')

const formData = ref(props.modelValue)

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)

if (formData.value.computer_skills || formData.value.computer_skills_info) formData.value.ownershipPC_on = true
else formData.value.ownershipPC_on = false
</script>
