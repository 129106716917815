<template>
  <NothingHere v-if="managers && managers.length === 0"/>
  <div class="companyManagers__block" v-if="managers">
    <RowManager
      v-for="manager of managers"
      :key="manager"
      :manager="manager"
      :deleted="() => {fetchData()}"/>
  </div>
  <div class="companyManagers__block" v-else>
      <div class="companyManagers__item skeleton__block" v-for="item of 3" :key="item">
          <div class="name"><p><span class="skeleton middle-text" style="margin-bottom:10px;width:100px"></span><span class="skeleton middle-text"></span></p></div>
          <div class="contact">
              <div class="title skeleton small-text" style="margin-bottom: 10px"></div>
              <div class="link skeleton middle-text"></div>
          </div>
          <div class="contact">
              <div class="title skeleton small-text" style="margin-bottom: 10px"></div>
              <div class="link skeleton middle-text"></div>
          </div>
          <div class="btns">
            <div class="skeleton small-text"></div>
            <div class="skeleton small-text"></div>
          </div>
      </div>
  </div>
  <Pagination
    v-if="managers"
    v-model="filter.page"
    :perPage="filter.per_page"
    :total="total"
    @newPage="fetchData"
  />
</template>
<script setup>
import RowManager from '@/components/uiCompany/Managers/RowManager.vue'

import { defineProps } from 'vue'
import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getManagers } from '@/actions/company'

const props = defineProps({
  commonDataPage: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()

// Filter
const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: props.commonDataPage ? 5 : 20
  }
)
const total = ref()

const managers = ref(null)

const fetchData = async () => {
  managers.value = null
  const response = (await getManagers(filter)).data.managers
  managers.value = response.data
  total.value = response.total
  if (!props.commonDataPage) router.push({ path: '/company/working-with-the-site/company-managers/result', query: { page: filter.page } })
}

onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'CompanyManagers') {
      filter.page = route.query.page ?? 1
      fetchData()
    }
  }
)
</script>

<style lang="scss" scoped>
.companyManagers__block{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  @media (max-width: 768px){
    grid-gap: 15px;
  }
  @media (max-width: 576px){
    grid-gap: 10px;
  }

}
.skeleton__block{
  & .skeleton{
    min-height: 1em;
  }
}
</style>
