<template>
<div :class="'answer ' + (variant.right_variation === 1 ? 'true' : 'false') + (newVariant ? ' new' : '')">
  <div class="variant">
    <button type="button" class="radio" @click="$emit('onTrue')"></button>
    <span>{{title}}</span>
  </div>

  <Input
    class="answer__input"
    :placeholder="$t('enter_text')"
    v-model="variant.title"
    v-model:validation="success"
    :required="!newVariant"
    :disabled="newVariant"/>

  <button
    class="verity"
    type="button"
    @click="$emit('onClick')"
  >
    <svg>
      <use xlink:href="/img/tmp/sprite.svg#plus"></use>
    </svg>
  </button>
</div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  option: {
    type: Object,
    default: () => {}
  },
  title: {
    type: String,
    default: 'A'
  },
  newVariant: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'onTrue', 'onClick'])

const variant = ref(props.option)
const success = ref(false)
if (props.newVariant) {
  variant.value = {}
  success.value = true
}
watch(
  () => variant.value.title,
  () => {
    emit('update:modelValue', variant.value.title)
  }
)
</script>

<style lang="scss" scoped>
.answer{
  position: relative;
  max-width: 520px;
  &.true{
    & .radio{
      border: 1px solid #201E9F;
      &::before{
        background: #201E9F;
      }
    }
  }
  &.new{
    & .variant,
    & .block {
      opacity: .4;
    }
    & .verity {
      background-color: rgba(#0082FA, .4);
      & svg{
        fill: #fff;
        transform: rotate(0);
      }
      &:hover{
        background-color: rgba(#0082FA, 1);
      }
  }
  }
}
.variant{
  display: flex;
  align-items: center;
  grid-gap: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 12px);
  & .radio{
    width: 15px;
    height: 15px;
    border-radius: 100px;
    border: 1px solid #212529;
    position: relative;
    &::before{
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: transparent;
    }
  }
  & span{
    font-size: 20px;
    color: rgba(33, 37, 41, 0.7);
  }
}
.verity{
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 5px);
  background: rgba(151, 151, 151, 0.5);
  transition: background-color .2s linear;

  & svg{
    width: 14px;
    height: 14px;
    fill: #fff;
    transform: rotate(-45deg);
  }
}

@media (max-width: 992px){
  .answer{
    max-width: 490px;
  }
}
@media (max-width: 768px){
  .answer{
    max-width: 590px;
    display: grid;
    grid-template-columns: 40px 1fr 25px;
    grid-gap: 10px 15px;
    align-items: center;
  }
  .variant,
  .verity{
    position: static;
    transform: none;
  }
}
@media (max-width: 576px){
  .answer{
    max-width: 100%;
    grid-gap: 6px 8px;
    grid-template-columns: 32px 1fr 20px;
  }

  .variant{
    grid-gap: 6px;
    & .radio{
      width: 10px;
      height: 10px;
      &::before{
        width: 5px;
        height: 5px;
      }
    }
    & span{
      font-size: 16px;
    }
  }

  .verity{
    height: 20px;
    width: 20px;
    & svg{
      width: 10px;
      height: 10px;
    }
  }
}

@media (max-width: 320px){
  .answer{
    &__input{
      grid-column: 2/4;
    }
  }
  .variant{
    grid-row: 2;
  }
  .verity{
    grid-row: 1;
    grid-column: 3/4;
  }
}
</style>
