<template>
  <div>
    <Header />
    <div class="bread_crumbs_bl">
        <div class="container">
            <ul class="bread_crumbs_bl__inner">
                <li><router-link :to="'/'">{{$t('home')}}</router-link></li>
                <li>{{$t('history')}}</li>
            </ul>
        </div>
    </div>
    <div class="container">
      <h3 class="section__title">{{$t('history')}}</h3>
    </div>
    <div
      class="aboutUs">
      <History :history="datas.history" />

      <YearsHistory />

      <Team :teams="datas.teams" />

      <Partners :partners="datas.partners" />

      <Principles :principles="datas.principles" />

      <Certificates :certificates="datas.certificates" />
    </div>
  </div>
</template>

<script setup>
import History from '@/components/uiLanding/AboutUs/History.vue'
import YearsHistory from '@/components/uiLanding/AboutUs/YearsHistory.vue'
import Team from '@/components/uiLanding/AboutUs/Team.vue'
import Partners from '@/components/uiLanding/AboutUs/Partners.vue'
import Principles from '@/components/uiLanding/AboutUs/Principles.vue'
import Certificates from '@/components/uiLanding/AboutUs/Certificates.vue'

import { onMounted, reactive } from 'vue-demi'
import { getHistory, getTeam, getPartners, getPrinciples, getCertificates } from '@/actions/aboutUs'

const datas = reactive(
  {
    history: null,
    historyYears: null,
    teams: null,
    partners: null,
    certificates: null
  }
)

onMounted(
  async () => {
    const [history, teams, partners, principles, certificates] = await Promise.all([
      getHistory(),
      getTeam(),
      getPartners(),
      getPrinciples(),
      getCertificates()
    ])
    history.data.forEach((element, i) => {
      if (Object.keys(element)[0] === 'ИСТОРИЯ') {
        datas.history = history.data[i]
        history.data.splice(i, 1)
      }
    })
    // 'ИСТОРИЯ'

    datas.historyYears = history.data
    datas.teams = teams.data.members.data
    datas.partners = partners.data.partners
    datas.certificates = certificates.data.certificates
    datas.principles = principles.data.advantages
  }
)
</script>

<style lang="scss">
@import '@/assets/css/AwardDetail.scss';

.aboutUs{
  & .team .slider__items{
    padding-bottom: 0 !important;
  }

  & .slider__inner{
    border-bottom: 1px solid rgba(0, 0, 0, 0.0955529);
  }

  & .historySlider__content,
  & .team .slider__inner,
  & .principles__items{
    padding-bottom: 110px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0955529);

    @media (max-width: 992px){
      padding-bottom: 80px;
    }
    @media (max-width: 768px){
      padding-bottom: 70px;
    }
    @media (max-width: 576px){
      padding-bottom: 50px;
    }
  }
}
</style>
