<template>
<Modal :hide="hide" :loading="loading">
  <div class="modal__title">{{$t('enter_your_password_recovery_email')}}</div>
  <form @submit.prevent="sendCode()" method="POST">
    <Input
      class="form__input"
      type="email"
      placeholder="Email"
      v-model="formData"
      v-model:validation="success.email"/>
    <Button
      :name="$t('send')"
      htmlType="submit"
      :hasError="success"/>
  </form>
</Modal>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import Modal from '@/components/common/Modal.vue'
import { postForgotPassword } from '@/actions/auth'
import { defineProps } from 'vue'
const props = defineProps({
  hide: {
    type: Function,
    default: null
  }
})

const success = reactive({
  email: false
})
const loading = ref(false)
const formData = ref('')
async function sendCode () {
  loading.value = true
  try {
    await postForgotPassword(formData.value)
    props.hide()
  } finally {
    loading.value = false
  }
}
</script>
<style lang="scss" scoped>
form{
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  @media (max-width: 768px){
    grid-gap: 25px;
  }
  @media (max-width: 576px){
    grid-gap: 10px;
  }
}
</style>
