<template>
<Header />
<section class="sendRequestForPayment">
  <div class="container">
    <h3 class="section__title">{{$t('send_a_request_for_payment')}}</h3>
    <form
      class="sendRequestForPayment__form"
      method="post"
      @submit.prevent="connectService()"
      v-if="
        commonData
        && helpers.countries
        && helpers.typeOwnerShip
        && helpers.industries
        && helpers.countries
      "
    >
      <div>
        <label for="companyName">{{$t('name_company')}}:</label>
        <Input
          id="companyName"
          v-model="formData.company_name"
          disabled
        />
      </div>
      <div>
        <label for="type_of_ownership">{{$t('type_of_ownership')}}</label>
        <Select
          id="type_of_ownership"
          :options="helpers.typeOwnerShip"
          v-model="formData.type_ownership_id"
          v-model:validation="success.typeOwnerShip"
          v-model:option="typeOwnership"
        />
      </div>
      <div>
        <label for="bin">{{$t('bin')}}</label>
        <Input
          id="bin"
          v-model="formData.BIN"
          v-model:validation="success.BIN"
          required
        />
      </div>
      <div>
        <label for="KBE">{{$t('KBE')}}</label>
        <Input
          id="KBE"
          v-model="formData.KBE"
          v-model:validation="success.KBE"
          required
        />
      </div>
      <div>
        <label for="IKK">{{$t('IKK')}}</label>
        <Input
          id="IKK"
          v-model="formData.IKK"
          v-model:validation="success.IKK"
          required
        />
      </div>
      <div>
        <label for="bank">{{$t('bank')}}</label>
        <Input
          id="bank"
          v-model="formData.bank_name"
          v-model:validation="success.bank_name"
          required
        />
      </div>
      <div>
        <label for="BIK">{{$t('BIK')}}</label>
        <Input
          id="BIK"
          v-model="formData.BIK"
          v-model:validation="success.BIK"
          required
        />
      </div>
      <div>
        <label for="KNP">{{$t('KNP')}}</label>
        <Input
          id="KNP"
          v-model="formData.KNP"
          v-model:validation="success.KNP"
          required
        />
      </div>

      <div>
        <label for="phone">{{$t('phone')}}:</label>
        <Input
          id="phone"
          type="tel"
          v-model="formData.phone"
          v-model:validation="success.phone"
          required
        />
      </div>
      <div>
        <label for="email">Email</label>
        <Input
          id="email"
          type="email"
          v-model="formData.email"
          v-model:validation="success.email"
          required
        />
      </div>
      <div>
        <label for="country">{{$t('country')}}</label>
        <Select
          id="country"
          :options="helpers.countries"
          v-model="formData.country_id"
          v-model:validation="success.country"
          v-model:option="country"
        />
      </div>
      <div>
        <label for="city">{{$t('city')}}</label>
        <Select
          id="city"
          type="city"
          :options="helpers.cities"
          v-model="formData.city_id"
          v-model:validation="success.city"
          v-model:option="city"
        />
      </div>
      <div>
        <label for="address">{{$t('legal_address')}}</label>
        <Input
          id="address" type="text"
          v-model="formData.address"
          v-model:validation="success.address"
          required
        />
      </div>

      <div>
          <div class="btns">
            <Button
              :name="$t('send')"
              htmlType="submit"
              :hasError="success"/>
            <Button
              class="btn"
              :name="$t('cancel')"
              type="gray"
              go="/company/services"/>
          </div>
      </div>
    </form>
    <div class="sendRequestForPayment__form" v-else>
      <div v-for="item of 9" :key="item">
        <label class="skeleton middle-text"></label>
        <div class="skeleton">
          <Input disabled/>
        </div>
      </div>
    </div>
  </div>
  <Loading class="fixed" :show="loading"/>
</section>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getCommonData, postRequestForPayment } from '@/actions/company'
import { getCountries, getCities, getTypeOwnerShip, getIndustries } from '@/actions/help'

const route = useRoute()

const commonData = ref(null)
const formData = reactive(
  {
    package_id: route.params.service_id,
    company_name: '',
    type_ownership_id: null,
    BIN: '',
    KBE: '',
    IKK: '',
    bank_name: '',
    BIK: '',
    KNP: '',
    phone: '',
    email: '',
    city_id: null,
    country_id: null,
    address: ''
  }
)
const loading = ref(false)
const typeOwnership = ref(null)
const city = ref(null)
const country = ref(null)

const success = reactive(
  {
    typeOwnerShip: false,
    BIN: false,
    KBE: false,
    IKK: false,
    bank_name: false,
    BIK: false,
    KNP: false,
    phone: false,
    email: false,
    city: false,
    country: false,
    address: false
  }
)

const helpers = reactive(
  {
    typeOwnerShip: null,
    industries: null,
    countries: null,
    cities: null
  }
)

onMounted(
  async () => {
    const [company, industries, typeOwnerShip, countries] = await Promise.all(
      [
        getCommonData(),
        getIndustries(),
        getTypeOwnerShip(),
        getCountries()
      ]
    )
    commonData.value = company.company
    formData.company_name = commonData.value.name
    formData.type_ownership_id = commonData.value.type_ownership_id
    formData.phone = commonData.value.phone
    formData.email = commonData.value.email
    formData.city_id = commonData.value.city_id
    formData.country_id = commonData.value.country_id
    formData.address = commonData.value.address

    helpers.typeOwnerShip = typeOwnerShip.data
    helpers.industries = industries
    helpers.countries = countries.data
  }
)

watch(
  () => formData.country_id,
  async () => {
    if (formData.country_id) helpers.cities = (await getCities(formData.country_id)).data.cities
  }
)

const connectService = async () => {
  try {
    loading.value = true
    await postRequestForPayment(formData, typeOwnership.value, city.value, country.value)
    router.push('/company/services')
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.sendRequestForPayment{
  & label{
    font-size: 16px;
    line-height: 25px;
    color: rgba(33, 37, 41, 0.7);
  }

  &__form{
    & > div{
      display: grid;
      grid-template-columns: 208px max(440px);
      grid-gap: 20px;
      align-items: center;
      margin-bottom: 20px;
    }

    & .btns{
      grid-column: 2/3;
      display: flex;
      grid-gap: 20px;
    }
  }

  @media (max-width: 768px){
    &__form{
      max-width: 500px;
      & > div{
        grid-template-columns: 1fr !important;
        grid-gap: 6px;
        margin-bottom: 18px;
      }
    }
    & label{
      font-size: 14px;
      line-height: 1.2;
    }
  }
  @media (max-width: 576px){
    & label{
      font-size: 12px;
    }

    &__block{
      grid-gap: 40px;
    }
    &__form > div {
      grid-gap: 2px;
      margin-bottom: 10px;
    }
    & .btns{
      grid-column: 1/2;
    }
  }
  @media (max-width: 395px){
    & .btns{
      flex-direction: column;
      grid-gap: 10px !important;
    }
  }
}
</style>
