<template>
  <button
    :class="'button ' + type + ' ' + (loading? 'loading' : '')"
    :type="htmlType"
    :disabled="hasError ? checkError(hasError) : false"
    @click="go? goPath(go) : null">
    <slot />
    <span>{{ name }}</span>
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    <slot name="right" />
  </button>
</template>

<script setup>
import { defineProps } from 'vue'
import router from '@/router/index.js'
defineProps({
  name: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'default'
  },
  go: {
    type: String,
    default: null
  },
  htmlType: {
    type: String,
    default: 'button'
  },
  loading: {
    type: Boolean,
    default: false
  },
  hasError: {
    type: Object,
    default: () => null
  },
  noButton: {
    type: Boolean,
    default: false
  }
})

const checkError = fields => {
  for (const field in fields) {
    if (!fields[field]) return true
  }
  return false
}

const goPath = link => {
  router.push(link)
}
</script>

<style lang="scss" scoped>
.button{
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
  padding: 10px 21px;
  border: 2px solid transparent;
  transition: all .2s linear;

  & *{
    cursor: pointer;
  }

  @media (max-width: 992px){
    padding: 8px 16px !important;
  }
  @media (max-width: 768px){
    padding: 8px 16px !important;
  }
  @media (max-width: 576px){
    padding: 7px 14px !important;
  }
  @media (max-width: 400px){
    padding: 4px 12px !important;
  }
}
.button.loading{
  pointer-events: none;
  & span{
    transform: scale(.7);
    opacity: 0;
  }
  & .lds-ellipsis{
    opacity: 1;
  }
}
span {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 500;
  text-transform: uppercase;
  transition: transform .2s linear;
  transform: color .2s linear;

  @media (max-width: 992px){
    font-size: 14px !important;
  }
  @media (max-width: 768px){
    font-size: 12px !important;
  }
  @media (max-width: 576px){
    font-size: 11px !important;
  }
  @media (max-width: 400px){
    font-size: 10px !important;
  }
}
.button.default {
  border: 2px solid #A32D2B;
  background-color: #A32D2B;
  box-shadow: 0 0 0 rgba(#A32D2B, .3);
  &:hover,
  &:focus{
    box-shadow: 0 2px 25px rgba(#A32D2B, .3);
  }
}
.button.default:active {
  transform: scale(0.9);
  background-color: #90211f;
  box-shadow: 0 0 0 rgba(#A32D2B, .3);
}
.button[disabled]{
  opacity: .7;
  pointer-events: none;
}
.button.white {
  width: 100%;
  text-align: center;
  border: 1px solid #212529;
  background-color: #fff;
  & span{
    color: #212529;
    line-height: 1.5;
  }
  &:hover,
  &:focus{
    background-color: #212529;
    & span{
      color: #ffffff;
    }
  }
}
.button.authorization{
  background-color: rgba(#fff, .2);
  border: 2px solid transparent;
  &:hover,
  &:focus{
      border-color: #fff;
  }
}
.button.darkGreen{
  padding: 10px 13px;
  background-color: #4F553D;
  transition: opacity .2s linear;

  & span{
    line-height: 1.5;
    text-transform: none;
  }
  &:hover,
  &:focus{
      opacity: .8;
  }
}
.button.gray{
  background: rgba(79, 85, 61, 0.2);
  transition: background-color .2s linear;

  & span{
    color: rgba(33, 37, 41, 0.7);
  }

  & .lds-ellipsis div {
    background: #a8a8a8;
  }

  &:hover{
    background-color: rgba(79, 85, 61, 0.4);
  }
}
.button.RedLight{
  border: 1px solid #AD1717;
  background-color: transparent;
  & span{
    color: #AD1717;
  }
}
.button.NoTextTransform{
  & span{
    text-transform: none !important;
  }
}
</style>
