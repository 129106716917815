<template>
<form @submit.prevent="sendFeedback()" method="post">
  <div>
      <Input
        class="form__input"
        :placeholder="$t('fullName')"
        v-model="formData.name"
        v-model:validation="success.name"
        required
      />
  </div>
  <div>
      <Input
        class="form__input"
        :placeholder="$t('phone')"
        v-model="formData.phone"
        v-model:validation="success.phone"
        type="tel"
        required
      />
  </div>
  <div>
      <Input
        class="form__input"
        placeholder="e-mail"
        v-model="formData.email"
        v-model:validation="success.email"
        required
      />
  </div>
  <div>
      <Input
        class="form__input"
        type="textarea"
        :placeholder="$t('message_text')"
        v-model="formData.text"
        v-model:validation="success.text"
        required
      />
  </div>
  <Button
    htmlType="submit"
    :name="$t('send_message')"
    :hasError="success"
  />
</form>
<Loading :show="loading" fixed />
</template>

<script setup>
import { ref, reactive } from 'vue'
import { postSendFeedback } from '@/actions/help'

const formData = reactive(
  {
    name: '',
    email: '',
    phone: '',
    text: ''
  }
)

const success = reactive(
  {
    name: false,
    email: false,
    phone: false,
    text: false
  }
)
const loading = ref(false)

// ====================  Send Feedback  ====================
async function sendFeedback () {
  loading.value = true
  try {
    await postSendFeedback(formData)
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>

</style>
