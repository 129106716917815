<template>
<h2 class="editVacancy__title">{{$t('basic_information')}}</h2>
<div class="commonData__block">
  <div class="editVacancy__item">
    <div>
      <label for="position">{{$t('name_vacancy')}}:</label>
      <Input
        id="position"
        v-model="formData.position"
        v-model:validation="success.position"
        required/>
    </div>

    <div>
      <label for="professional_area_id">{{$t('specialization')}}</label>
      <Select
        id="professional_area_id"
        v-model="formData.professional_area_id"
        v-model:validation="success.professional_area_id"
        :options="helpers.professionalsAreas"/>
    </div>

    <div>
      <label for="details">{{$t('tell_about_the_vacancy')}}</label>
      <Input
        id="details"
        type="textarea"
        v-model="formData.details"/>
    </div>

    <div>
      <label for="key_skills">{{$t('key_skills')}}</label>
      <Select
        id="key_skills"
        :placeholder="$t('select_skills')"
        v-model="formData.key_skills"
        :options="helpers.keySkills"
        multiSelect/>
    </div>

    <div>
      <label for="salary">{{$t('estimated_income_level_per_month_or_per_amount_of_work')}}</label>
      <div>
        <div class="salary__block">
          <div class="salary__inputs">
            <Input
              id="salary"
              :placeholder="$t('from')"
              type="number"
              v-model="formData.min_salary"
            />
            <Input
              :placeholder="$t('to')"
              type="number"
              v-model="formData.max_salary"
            />
          </div>
          <Select
            v-model="formData.currency_id"
            :options="helpers.currencies"
          />
        </div>
        <div class="checkbox_block">
          <div><Checkbox v-model="formData.before_taxes" dark /> <p>{{$t('before_taxes').toLowerCase()}}</p></div>
          <div><Checkbox v-model="formData.before_taxes" dark minus/> <p>{{$t('on_hand').toLowerCase()}}</p></div>
        </div>
      </div>
    </div>
  </div>
  <p class="warningMessage">
    {{$t('vacancy_codex')}}
  </p>
</div>
</template>

<script setup>
import Checkbox from '@/components/common/Checkbox.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  validation: {
    type: Object,
    default: null
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
const success = ref(props.validation)
watch(
  () => [formData.value, success.value],
  () => {
    emit('update:modelValue', formData.value)
    emit('update:validation', success.value)
  }
)
</script>

<style scoped lang="scss">
.commonData__block{
  display: grid;
  grid-template-columns: 1fr 29%;
  grid-gap: 40px;

  @media (max-width: 1200px){
    grid-template-columns: 1fr;
    grid-gap: 40px;

    & .warningMessage{
      grid-row: 1/2;
    }
  }

  @media (max-width: 992px){
    grid-gap: 30px;
    & .warningMessage{
      font-size: 12px;
    }
  }
  @media (max-width: 768px){
    grid-gap: 25px;
    & .warningMessage{
      font-size: 10px;
    }
  }

  @media (max-width: 576px){
    grid-gap: 22px;
  }
}
</style>
