<template>
<div class="btns" v-if="userData !== '3'">
  <Button
    :name="resumeInFavorite ? $t('remove_from_favorites') : $t('add_to_favorites')"
    type="gray NoTextTransform"
    @click="() => {resumeToFavorite()}"
    :loading="loading"
  />
  <Button
    :name="$t('reach')"
    type="gray NoTextTransform"
    @click="() => {$emit('showContact')}"
  />
</div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import { postFavoriteResume, deleteFavoriteResume } from '@/actions/company'

const props = defineProps({
  resume: {
    type: Object,
    default: null
  }
})
defineEmits(['showContact'])

const userData = localStorage.getItem('role_id')
const loading = ref(false)

// Resume in Favorite Or No
const resumeInFavorite = ref(false)
if (userData !== '3') resumeInFavorite.value = props.resume.favorite_resumes.length !== 0

// Click Events
async function resumeToFavorite () {
  loading.value = true
  try {
    if (resumeInFavorite.value) await deleteFavoriteResume(props.resume.id)
    else await postFavoriteResume(props.resume.id)
    resumeInFavorite.value = !resumeInFavorite.value
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.btns{
  display: flex;
  grid-gap: 20px;

  @media (max-width: 768px){
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 460px){
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
</style>
