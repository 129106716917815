<template>
<div class="block resultTest" v-if="test">
  <div class="title">{{$t('response_to_the_test')}}:</div>
  <div class="resultTest__title">{{test.title}}</div>
  <RowQuestion
    v-for="(question, i) of test.questions"
    :key="question"
    :question="question"
    v-model="responseToTest[i].variation_id"
    :result="true"
    :num="i + 1"
  />
</div>
</template>

<script setup>
import RowQuestion from '@/components/uiEmployee/Test/RowQuestion.vue'

import { defineProps, ref } from 'vue'

const props = defineProps({
  test: {
    type: Object,
    default: () => {}
  },
  response: {
    type: Array,
    default: () => []
  }
})

const responseToTest = ref(props.response)
</script>

<style lang="scss">
.resultTest{
  &__title{
    color: #4D533C;
    font-size: 24px;
    margin-bottom: 18px;
  }
  @media (max-width: 768px){
    &__title{
      font-size: 22px;
      margin-bottom: 16px;
    }
  }
  @media (max-width: 576px){
    &__title{
      font-size: 20px;
      margin-bottom: 14px;
    }
  }

  @media (max-width: 400px){
    &__title{
      font-size: 18px;
    }
  }
}
</style>
