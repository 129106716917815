<template>
<div class="vacancies__item">
  <!-- ====================  TOP  ==================== -->
  <div class="top">
    <router-link :to="'/company/detail-vacancy/' + vacancy.id" class="prof">{{vacancy.position}}</router-link >
  </div>

  <!-- ====================  CENTER  ==================== -->
  <div class="center">
    <div
      class="status"
      :class="{active: vacancy.active}"
    >
      {{vacancy.active ? $t('published') : $t('not_published')}}
    </div>
  </div>

  <!-- ====================  BOTTOM  ==================== -->
  <div class="bottom">
    <div class="date">
      {{vacancyUpdateDate}}
    </div>
    <div class="right">
      <Button
        type="gray"
        :name="$t('redact')"
        :go="'/company/edit-vacancy/' + vacancy.id"
      >
      <svg>
        <use xlink:href="/img/tmp/sprite2.svg#edit"></use>
      </svg>
      </Button>
      <button
        class="btn delete"
        @click="() => {quastion = true}"
      >
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#delete"></use>
        </svg>
        <span>{{$t('delete')}}</span>
      </button>
    </div>
  </div>
</div>
<Modal
  :hide="()=>{quastion = false}"
  :show="quastion"
  quastion
  :agreement="() => {deleteVacancies()}"
  :loading="loading"
/>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'

import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { defineProps, defineEmits, ref } from 'vue'

import { deleteVacancy } from '@/actions/company'

const props = defineProps({
  vacancy: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update'])

const i18n = useI18n()
const loading = ref(false)
const quastion = ref(false)

// Example Results => 19 Апрель 2022
const vacancyUpdateDate = `${moment(props.vacancy.created_at).format('DD')} ${i18n.t('months.' + moment(props.vacancy.created_at).format('M'))} ${moment(props.vacancy.created_at).format('YYYY')}`

// Delete Vacancy
const deleteVacancies = async () => {
  loading.value = true
  try {
    await deleteVacancy(props.vacancy.id)
    quastion.value = false
    emit('update')
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.vacancies__item{
  display: block;
  padding: 20px 30px;
  border: 0.5px solid rgba(33, 37, 41, 0.7);
  &.skeleton__block{
    border-color: rgba(0, 0, 0, 0.0955529) !important;
  }
  & .top,
  & .bottom{
    display: flex;
    justify-content: space-between;
  }
  & .bottom{
    align-items: flex-end;
  }
  & .top{
    margin-bottom: 10px;
  }
  & .prof{
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #212529;
    margin-right: 30px;
    position: relative;
    transition: all .2s ease;

    &::after{
      content: '';
      display: block;
      height: 0;
      width: 0;

      position: absolute;
      left: 0;
      top: 95%;

      border-bottom: 3px solid #212529;
      transition: all .2s ease;
    }

    &:hover{
      color: #AD1717;
      &::after{
        width: 100%;
        border-color: #AD1717;
      }
    }

    &.skeleton{
      width: 100%;
      max-width: 220px;
      &::before{
        border-bottom: none;
      }
    }
  }
  & .center{
    margin-bottom: 10px;
  }
  & .status{
    font-size: 16px;
    color: #FFFFFF;

    display: inline-block;
    padding: 3px 10px 4px;
    background: rgba(151, 151, 151, 0.7);

    &.active{
      background: rgba(44, 166, 34, 0.5);
    }
  }
  & .state{
    font-size: 16px;
    color: #2CA622;
  }
  & .bottom{
    & .right{
      display: flex;
      align-items: center;
      grid-gap: 20px;
      & .button{
        display: flex;
        align-items: center;
        & svg{
          display: block;
          width: 18px;
          height: 18px;
          fill: rgba(33, 37, 41, 0.7);
          margin-right: 10px;
        }
      }
    }
    & .btn{
      display: flex;
      align-items: center;
      grid-gap: 4px;

      color: rgba(33, 37, 41, 0.7);
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;

      padding: 0;
      position: relative;
      transition: all .2s linear;

      & svg{
        display: block;
        width: 18px;
        height: 18px;
        fill: rgba(33, 37, 41, 0.7);
        transition: all .2s linear;
      }

      &::before{
        content: "";
        display: block;
        height: 2px;
        width: 0;
        background-color: #AD1717;
        position: absolute;
        left: 0;
        top: 98%;
        transition: all .2s linear;
      }
      &:hover{
        color: #AD1717;
        & svg{
          fill: #AD1717;
        }
        &::before{
          width: 100%;
          background-color: #AD1717;
        }
      }
    }
  }
  & .date{
    font-size: 16px;
    color: #000000;
    white-space: nowrap;
    margin-right: 28px;
  }
  & button{
    &.skeleton{
      width: 220px;
      &::before{
        border-bottom: none;
      }
    }
  }
  @media (max-width: 992px){
    padding: 15px 25px;
    & .top{
      margin-bottom: 8px;
    }
    & .prof{
      font-size: 18px;
      margin-right: 25px;
    }
    & .center{
      margin-bottom: 8px;
    }
    & .status{
      font-size: 16px;
      padding: 2.5px 8px 3px;
    }
    & .state{
      font-size: 14px;
    }
    & .bottom{
      & .right{
        grid-gap: 15px;
        & .button{
          & svg{
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
      }
      & .btn{
        font-size: 14px;
        & svg{
          width: 16px;
          height: 16px;
        }
      }
    }
    & .date{
      font-size: 14px;
      margin-right: 26px;
    }
  }
  @media (max-width: 768px){
    padding: 10px 20px;
    & .top{
      margin-bottom: 6px;
    }
    & .prof{
      font-size: 16px;
      margin-right: 20px;
    }
    & .center{
      margin-bottom: 6px;
    }
    & .status{
      font-size: 14px;
      padding: 2px 7px 2px;
    }
    & .state{
      font-size: 12px;
    }
    & .bottom{
      & .right{
        grid-gap: 10px;
        justify-content: flex-end;
        & .button{
          & svg{
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }
        }
      }
      & .btn{
        font-size: 12px;
        & svg{
          width: 14px;
          height: 14px;
        }
      }
    }
    & .date{
      font-size: 12px;
      margin-right: 24px;
    }
  }
  @media (max-width: 576px){
    padding: 8px 18px;
    & .prof{
      font-size: 14px;
      margin-right: 18px;
    }
    & .status{
      font-size: 12px;
      padding: 2px 6px;
    }
    & .state{
      font-size: 10px;
    }
    & .bottom{
      & .right{
        grid-gap: 8px;
        & .button{
          & svg{
            width: 12px;
            height: 12px;
            margin-right: 4px;
          }
        }
      }
      & .btn{
        & svg{
          width: 12px;
          height: 12px;
        }
      }
    }
    & .date{
      margin-right: 20px;
    }
  }
  @media (max-width: 400px){
    padding: 8px 12px;
    & .bottom{
      display: block;
    }
  }
}
</style>
<style scoped lang="scss">
.vacancies__item{
  & .prof{
    margin: 0 !important;
  }
  @media (max-width: 576px){
    & .bottom {
      & .right{
        display: flex !important;
      }
    }
  }
  @media (max-width: 400px){
    & .bottom {
      & .right{
        display: flex !important;
        flex-direction: column;
        align-items: flex-end;
        grid-gap: 10px;
        & .button{
          width: 100%;
        }
      }
    }
    & .date{
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
