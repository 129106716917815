<template>
<LayoutRow
  :title="$t('language_skills')"
  v-model="formData.languages_on"
>
  <div class="createResume__itemInner">
    <label for="languages">{{$t('main_languages')}}:</label>
    <div class="languages__items">
      <div class="item"
        v-for="(lang, i) of formData.languages"
        :key="lang"
      >
        <DeleteButton @click="() => {deleteLang(i)}" />
        <Select
          id="languages"
          :placeholder="$t('lang')"
          :options="helpers.languages"
          v-model="lang.language_id"
          v-model:validation="success"
          :checkedOptions="formData.languages"
        />
        <Select
          :options="languagesLevels"
          v-model="lang.level"
        />
      </div>
      <button type="button" @click="addLang()">{{$t('specify_another_language')}}</button>
    </div>
  </div>

  <div class="createResume__itemInner">
    <label for="languages_info">{{$t('additionally')}}: <br><span>{{$t('list_separated_by_commas')}}</span> </label>
    <Input
      id="languages_info"
      type="textarea"
      v-model="formData.languages_info"/>
  </div>
</LayoutRow>
</template>

<script setup>
import DeleteButton from '@/components/common/Buttons/DeleteButton.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
formData.value.deleteLanguages = []

const success = ref(false)
const languagesLevels = [
  {
    title: 'A1 - Начальный',
    id: 'A1'
  },
  {
    title: 'A2 - Элементарный',
    id: 'A2'
  },
  {
    title: 'B1 - Средний',
    id: 'B1'
  },
  {
    title: 'B2 - Средне-продвинутый',
    id: 'B2'
  },
  {
    title: 'C1 - Продвинутый',
    id: 'C1'
  },
  {
    title: 'C2 - В совершенстве',
    id: 'C2'
  }
]

// ===================     Checking   ===================
if (formData.value.languages.length !== 0) formData.value.languages_on = true
else formData.value.languages_on = false

watch(
  () => [formData.value, formData.value.languages_on],
  () => {
    if (formData.value.languages_on && formData.value.languages.length === 0) addLang()
    emit('update:modelValue', formData.value)
    emit('update:validation', success)
  }
)

// Click Events
const addLang = () => {
  formData.value.languages.push({
    language_id: 0,
    level: 'A1'
  })
}

const deleteLang = i => {
  if (formData.value.languages[i].id) formData.value.deleteLanguages.push(formData.value.languages[i].id)
  formData.value.languages.splice(i, 1)
  if (formData.value.languages.length === 0) formData.value.languages_on = false
}
</script>
<style lang="scss" scoped>
.languages__items{
  & .item{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    position: relative;
  }

  & button{
    font-weight: 400;
    font-size: 16px;
    text-decoration-line: underline;
    color: #1D7FF2;
    margin-bottom: 15px;
  }
}
</style>
