<template>
<section class="partners" id="partners">
  <Slider
    v-if="partners"
    :title="$t('partners')"
  >
    <!-- Teams -->
    <div class="partners__item" v-for="partner of partners" :key="partner">
      <div class="partners__preview">
        <img v-if="partner.image" :src="`${apiUrl}/${partner.image}`" alt="">
        <div class="partners__fakeLogo" v-else>
          <div class="partners__logo">{{generateLogo(partner.name)}}</div>
        </div>
      </div>
      <div class="partners__content">
        <div class="partners__title">{{partner.name}}</div>
        <p v-html="partner.info"></p>
      </div>
    </div>
  </Slider>
  <Slider
    v-else
    :title="$t('partners')"
  >
    <!-- Teams -->
    <div class="partners__item" v-for="item of 5" :key="item">
      <div class="partners__preview">
        <div class="img skeleton skeleton-img"></div>
      </div>
      <div class="partners__content">
        <div class="partners__title skeleton middle-text" style="margin-bottom:20px"></div>
        <p class="skeleton middle-text" style="margin-bottom:10px;width:80%;"></p>
        <p class="skeleton middle-text" style="width:60%;"></p>
      </div>
    </div>

  </Slider>
</section>
</template>

<script setup>
import Slider from '@/components/common/Sliders/Slider.vue'

import { apiUrl } from '@/api'
import { defineProps } from 'vue'

defineProps({
  partners: {
    type: Array,
    default: null
  }
})

function generateLogo (str) {
  const splits = str.split(' ')
  let stringItog = ''

  for (let i = 0; i < splits.length; i++) {
    const name = splits[i]
    const аirst = name.substring(0, 1).toUpperCase()
    if (i < 2) stringItog += аirst
  }

  return stringItog
}
</script>

<style lang="scss">
.partners{
  & .slider__items{
    display: grid;
    grid-template-columns: repeat(6, 50%);
    grid-gap: 32px;
  }

  &__item{
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 20px;
    align-items: center;
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 25px 25px 75px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

  &__preview{
    height: 160px;
    width: 160px;

    display: flex;
    align-items: center;

    & img{
        width: 100%;
    }
  }

  &__fakeLogo{
    width: 100%;
    padding-bottom: 60%;

    position: relative;
    background-color: #212529;
  }

  &__logo{
    color: #fff !important;
    font-size: 3rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__content{
    font-size: 16px;
    line-height: 20px;
    color: rgba(33, 37, 41, 0.7);
    & p{
      line-height: 1.4;
    }
  }
  &__title{
    font-weight: bold;
    font-size: 24px;
    color: #4D533C;
    margin-bottom: 12px;
    transition: color .2s linear;
  }
  @media (max-width: 1200px){
    & .slider__items{
      grid-template-columns: repeat(6, 49%);
      grid-gap: 25px;
    }

    &__item{
      grid-template-columns: 120px 1fr;
    }

    &__preview{
      height: 120px;
      width: 120px;
    }

    &__content{
      font-size: 14px;
      line-height: 1.2;
    }
    &__title{
      font-size: 22px;
      margin-bottom: 11px;
    }
  }
  @media (max-width: 992px){
    &__item{
      display: flex;
      flex-direction: column;
      grid-gap: 16px;
      text-align: center;
      justify-content: center;
    }
    &__preview{
      height: 80px;
    }
    &__content{
      font-size: 12px;
    }
    &__title{
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 576px){
    & .slider__items{
      display: flex;
    }
    &__item{
      min-width: 285px;
    }
    &__preview{
      margin: 0 auto;
    }
  }
  @media (max-width: 415px){
    &__item{
      display: block;
    }
    &__num{
      margin-bottom: 10px;
    }
    &__title{
      font-size: 22px;
    }
  }
}

.partners__content p{
  line-height: 1.4;
}
</style>
