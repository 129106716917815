<template>
  <Header />
  <section class="companySearch">
    <div class="container">
      <h3 class="section__title">{{$t('services.title')}}</h3>
      <NothingHere v-if="services && services.length === 0"/>
      <ListServices v-else :services="services" />
    </div>
  </section>
</template>

<script setup>
import ListServices from '@/components/uiConsultant/Services/ListServices.vue'

import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'

import { getListServices } from '@/actions/consultant'

const services = ref(null)

const fetchData = async () => {
  services.value = null
  services.value = await getListServices()
}

onMounted(
  () => fetchData()
)
</script>

<style lang="scss" scoped>
.companySearch{
  &__block{
    display: grid;
    grid-template-columns: 208px 1fr;
    grid-gap: 40px;
  }
  &__sidebar{
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.0955529);

    & > div:first-child {
      margin-bottom: 45px;
    }
  }

  &__item:first-child{
    border-top: 0.5px solid #919599;
  }
}
.region{
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;

  & span{
    font-style: italic;
    font-weight: 300;
  }
}
.showMore{
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
@media (max-width: 860px){
  .companySearch{
    &__block{
      grid-template-columns: 1fr;
    }
    &__sidebar{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      & > div:first-child{
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 620px){
  .companySearch{
    &__item{
      & .top,
      & .center{
        flex-direction: column;
      }
      & .name{
        margin-bottom: 10px;
      }
      & .description{
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}
@media (max-width: 420px){
  .companySearch{
    &__header{
      flex-direction: column;
      grid-gap: 12px;
    }
    &__sidebar{
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
    &__item{
      & .bottom{
        flex-direction: column;
        grid-gap: 10px;
        align-items: initial;
      }
    }
  }
}
</style>
