<template>
<section class="sale" id="stockAll">
  <div class="container">
    <div class="sale__inner" v-if="stockAll">
      <div v-for="(stock, i) of stockAll.data" :key="stock" :class="'sale__item ' + (i + 1 === saleLength? 'show' : '')">
        <div class="sale__num"><span>{{ i + 1 }}</span></div>
        <div class="sale__block">
          <div class="sale__content">
            <router-link :to="'/stock/' + stock.id" class="sale__title">{{ stock.title }}</router-link>
             <p class="sale__text" v-html="stock.description"></p>
          </div>
          <div class="sale__preview">
            <div class="sale__photo">
              <img :src="`${apiUrl}/${stock.image}`" alt="">
            </div>
            <SliderBtns
              :next="() => {saleSliderNext()}"
              :prev="() => {saleSliderPrev()}"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="sale__inner" v-else>
      <div v-for="i of 2" :key="i" :class="'sale__item ' + (i === saleLength? 'show' : '')">
        <div class="sale__num"><span>{{ i }}</span></div>
        <div class="sale__block">
          <div class="sale__content">
            <div class="sale__title">
              <div class="personalData__name skeleton middle-text" style="width:300px"></div>
            </div>
             <div class="sale__text">
               <span class="skeleton middle-text" style="width: 100%;margin-bottom:10px" v-for="s of 5" :key="s"></span>
               <span class="skeleton middle-text" style="width: 60%;margin-bottom:10px"></span>
             </div>
          </div>
          <div class="sale__preview">
            <div class="sale__photo" style="padding-bottom:0">
              <div class="img skeleton skeleton-img"></div>
            </div>
            <SliderBtns
              :next="() => {saleSliderNext()}"
              :prev="() => {saleSliderPrev()}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script setup>
import SliderBtns from '@/components/common/Sliders/SliderBtns.vue'

import { onMounted, ref } from '@vue/runtime-core'

import { apiUrl } from '@/api'
import { getStock } from '@/actions/home'

let myTimer
const stockAll = ref(null)
const saleLength = ref(1)
const saleMaxLength = ref(2)

const setSlider = () => {
  clearInterval(myTimer)
  myTimer = setInterval(() => {
    saleSliderNext()
  }, 10000)
}
const saleSliderNext = () => {
  if (saleLength.value === saleMaxLength.value) {
    saleLength.value = 1
  } else {
    saleLength.value++
  }
  setSlider()
}
const saleSliderPrev = () => {
  if (saleLength.value === 1) {
    saleLength.value = saleMaxLength.value
  } else {
    saleLength.value--
  }
  setSlider()
}

onMounted(
  async () => {
    stockAll.value = await getStock()
    saleMaxLength.value = stockAll.value.data.length
    setSlider()
  }
)
</script>

<style lang="scss">
.sale{
  & .container{
    padding-top: 0 !important;
  }

  &__inner{
      position: relative;
  }

  &__item{
      width: 100%;
      display: grid;
      grid-template-columns: 100px 1fr;
      position: absolute;
      top: 0;

      &.show{
          position: relative;

          & .sale__num{
              height: 80% !important;
              z-index: 2 !important;
          }

          & .sale__block:before{
            opacity: 1 !important;
          }
          & .sale__num,
          & .sale__photo{
              opacity: 1 !important;
              z-index: 1;
          }

          & .sale__title,
          & .sale__text{
              transform: translateY(0) !important;
              opacity: 1 !important;
              z-index: 1;
          }
          & .sale__title{
            cursor: pointer;
          }
      }
  }

  &__num{
      height: 60%;
      text-align: center;
      padding-top: 50px;
      background-color: #AD1717;

      position: relative;
      z-index: -1;
      transition: opacity .2s linear, height .8s linear;

      & span{
          color: #fff;
          font-size: 60px;
          font-weight: 300;
          line-height: 26px;
      }
  }

  &__block{
      display: flex;
      background-color: #fff;
      position: relative;
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.1);
      }
  }

  &__content{
      width: 52%;
      padding: 36px 0 57px 32px;
      margin-right: 34px;
  }

  &__title{
      display: inline-block;
      color: #4D533C;
      font-size: 36px;
      font-weight: 700;
      line-height: 1.1;
      margin-bottom: 35px;

      position: relative;
      z-index: -1;
      opacity: 0;
      transform: translateY(70px);
      cursor: default;

      transition: opacity .2s linear, transform .6s linear;
      &:hover{
        text-decoration: underline;
      }
  }

  &__text{
      color: rgba(#212529, .7);
      font-size: 16px;
      line-height: 1.6;

      position: relative;
      z-index: -1;
      opacity: 0;
      transform: translateY(70px);

      transition: opacity .2s linear, transform .9s linear;
  }

  &__preview{
      width: 46%;
  }

  &__photo{
      width: 100%;
      height: 84%;
      padding-bottom: 69%;
      overflow: hidden;

      position: relative;
      z-index: -1;
      opacity: 0;
      transition: opacity 1.1s linear;
      background-color: #ededed;

      & img{
          display: block;
          min-height: 100%;
          min-width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          z-index: 1;
          transform: translate3d(0, -50%, 0);
      }
  }

  & .slider__headBtns{
    justify-content: center;
  }
}
@media (max-width: 992px){
  .sale{
    display: flex;
    justify-content: space-between;
    &__block{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__inner{
      display: flex;
      justify-content: center;
    }
    &__num {
      & span{
        font-size: 50px;
      }
    }
    &__content{
      width: auto;
      padding: 32px 0 30px 28px;
    }
    &__preview{
      width: 100%;
    }
    &__photo{
      display: none;
    }
    &__item{
      width: 700px;
      max-width: 100%;
      height: 340px;
      grid-template-columns: 75px 1fr;
    }
    &__item.show  .sale__num{
      height: 75% !important;
    }
    &__title{
      font-size: 30px;
      margin-bottom: 26px;
    }
  }
}
@media (max-width: 576px){
  .sale{
    &__item{
      height: 300px;
      grid-template-columns: 50px 1fr;
      &.show{
        & .sale__num{
          height: 45% !important;
        }
      }
      & .slider__btns{
        display: flex !important;
      }
    }
    &__title{
      font-size: 18px;
      margin-bottom: 14px;
    }
    &__num{
      height: 30%;
      padding-top: 30px;
      & span{
        font-size: 38px;
      }
    }
    &__content{
      padding: 24px 0 15px 22px;
    }
    &__text,
    &__text p{
      font-size: 12px;
    }
  }
}
@media (max-width: 410px){
  .sale{
    &__item{
      height: 245px;
      grid-template-columns: 35px 1fr;
    }
    &__title{
      font-size: 16px;
      margin-bottom: 12px;
    }
    &__num{
      padding-top: 26px;
      & span{
        font-size: 28px;
      }
    }
    &__content{
      padding: 22px 0 13px 20px;
    }
    &__text,
    &__text p{
      font-size: 10px;
    }
  }
}
</style>
