import api from '../modules/api'

export const getAutoLocal = () =>
  api.asyncGet('/v1/auto-location', {}, false, true, false)

export const getCountries = () =>
  api.asyncGet('/v1/countries', {}, false, true, false)

export const getCities = (id) =>
  api.asyncGet('/v1/countries/' + id, {}, true, true, false)

export const getAutoLocation = () =>
  api.asyncGet('/v1/auto-location', null, true, false, false)

export const getTypeOwnerShip = () =>
  api.asyncGet('/v1/type-ownership', null, true, false, false)

export const getIndustries = () =>
  api.asyncGet('/v1/industries', null, true, false, false)

export const getCurrencies = () =>
  api.asyncGet('/v1/currencies', null, true, false, false)

export const getKids = () =>
  api.asyncGet('/v1/kids', null, true, false, false)

export const getMaritalStatus = () =>
  api.asyncGet('/v1/marital-status', null, true, false, false)

export const getEducationType = () =>
  api.asyncGet('/v1/education-type', null, true, false, false)

export const getDiplomType = () =>
  api.asyncGet('/v1/diplom-type', null, true, false, false)

export const getLanguages = () =>
  api.asyncGet('/v1/languages', null, true, false, false)

export const getKeySkills = () =>
  api.asyncGet('/v1/key-skills', null, true, false, false)

export const getEmploymentTypes = () =>
  api.asyncGet('/v1/employment-types', null, true, false, false)

export const getDriverCategories = () =>
  api.asyncGet('/v1/driver-certificates-categories', {}, true, true, false)

export const getWorkExperience = () =>
  api.asyncGet('/v1/minimal-work-expirience-options', null, true, false, false)

export const getWorkSchedule = () =>
  api.asyncGet('/v1/work-schedule-options', null, true, false, false)

export const getProfessionalsAreas = () =>
  api.asyncGet('/v1/professionals-areas', {}, false, true, false)

// Feedbacks
// For Employee And Company
export const postFeedback = (data) =>
  api.asyncPost('/v1/feedbacks', data)

// For Contact Page
export const postSendFeedback = (data) =>
  api.asyncPost('/v1/contact-feedbacks', data)
