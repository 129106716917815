<template>
  <Header/>
  <section class="resumeDetail">
    <div class="container">
      <div class="resumeDetail__headerTitle">
        <h2 class="section__title">{{$t('common_data')}}</h2>
        <button class="print" @click="printPDF" v-if="resume && userData === '3'">
          <svg>
              <use xlink:href="/img/tmp/sprite2.svg#print"></use>
          </svg>
        </button>
      </div>
      <div class="resumeDetail__inner" v-if="resume">
        <HeaderResume
          :resume="resume"
          @showContact="() => {showContacts = true}"
        />

        <Experiences
          v-if="resume.experiences"
          :experiences="resume.experiences"
        />

        <Educations
          v-if="resume.educations"
          :educations="resume.educations"
        />

        <div class="block" v-if="resume.professional_skills">
          <div class="title">{{$t('common_data')}}:</div>
          <p>{{resume.professional_skills}}</p>
        </div>

        <Courses
          v-if="resume.courses.length !== 0"
          :courses="resume.courses"
        />

        <OwnershipPC
          v-if="resume.computer_skills"
          :ownershipPC="resume"
        />

        <Languages
          v-if="resume.languages.length !== 0"
          :languages="resume.languages"
        />

        <Awards
          v-if="resume.awards.length !== 0"
          :awards="resume.awards"
        />

        <div class="block" v-if="resume.interests">
          <div class="title">{{$t('Interests_and_hobbies')}}:</div>
          <p>{{resume.interests}}</p>
        </div>

        <AvailabilityCar
          v-if="resume.has_auto !== null"
          :availabilityCar="resume"
        />

        <div class="block" v-if="resume.description">
          <div class="title">{{$t('more_about_yourself')}}:</div>
          <p>{{resume.description}}</p>
        </div>

        <TestResults
          v-if="test"
          :test="test"
          :response="responseToTest"
        />

        <Button
          v-if="userData !== '3'"
          :name="$t('reach')"
          @click="() => {showContacts = true}"
        />
        <Modal :show="showContacts && userData !== '3'" :hide="() => {showContacts = false}">
          <UserContact :userEmployee="resume.user" detailResume />
        </Modal>
      </div>
      <div class="resumeDetail__inner" v-else>
        <div class="resumeDetail__header">
          <div class="preview">
            <div class="skeleton skeleton-img"></div>
          </div>
          <div class="content">
            <div>
              <div class="top">
                <div class="prof skeleton middle-text" style="width:230px;max-width:100%;margin-bottom:10px"></div>
                <div class="right">
                  <div class="payment skeleton middle-text" style="width:230px;max-width:100%;margin-bottom:10px"></div>
                  <div class="date skeleton middle-text" style="width:150px;max-width:100%;margin-bottom:10px"></div>
                </div>
              </div>
              <p class="skeleton middle-text"></p>
              <p class="skeleton middle-text"></p>
              <p class="skeleton middle-text"></p>
              <p class="skeleton middle-text"></p>
              <p class="skeleton middle-text"></p>
            </div>
          </div>
        </div>
        <div class="experience skeleton__bl">
          <div class="title skeleton middle-text" style="width:290px;max-width:100%"></div>
            <div class="item" v-for="el of 2" :key="el">
              <div class="right">
                <div class="timePeriod">
                  <div class="title skeleton middle-text" style="width:230px;max-width:100%"></div>
                  <p class="skeleton middle-text" style="width:130px;max-width:100%"></p>
                </div>
                <div class="company">
                  <div class="name skeleton middle-text" style="width:200px;max-width:100%"></div>
                  <div class="local skeleton middle-text" style="width:180px;max-width:100%"></div>
                </div>
                <div class="direction skeleton middle-text" style="width:200px;max-width:100%"></div>
                <div class="direction skeleton middle-text" style="width:160px;max-width:100%"></div>
              </div>
              <div class="left">
                <div class="position skeleton middle-text" style="width:230px;max-width:100%;margin-bottom:10px"></div>
                <div class="description">
                  <div>
                    <p class="local skeleton middle-text" style="width:180px;max-width:100%;margin-bottom:10px"></p>
                    <p class="text skeleton middle-text" style="width:100%;margin-bottom:10px" v-for="sk of 3" :key="sk"></p>
                    <p class="text skeleton middle-text" style="width:70%;margin-bottom:10px"></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import UserContact from '@/components/uiCompany/Resumes/UserContact.vue'

import HeaderResume from '@/components/uiEmployee/Resume/Detail/HeaderResume.vue'
import Experiences from '@/components/uiEmployee/Resume/Detail/Experiences.vue'
import Educations from '@/components/uiEmployee/Resume/Detail/Educations.vue'
import Courses from '@/components/uiEmployee/Resume/Detail/Courses.vue'
import Languages from '@/components/uiEmployee/Resume/Detail/Languages.vue'
import AvailabilityCar from '@/components/uiEmployee/Resume/Detail/AvailabilityCar.vue'
import Awards from '@/components/uiEmployee/Resume/Detail/Awards.vue'
import OwnershipPC from '@/components/uiEmployee/Resume/Detail/OwnershipPC.vue'
import TestResults from '@/components/uiCompany/Responses/TestResults.vue'

import { onMounted, ref } from 'vue-demi'
import { useRoute } from 'vue-router'

import { getResume } from '@/actions/employee'
import { getResumeDetail, getResponseDetail } from '@/actions/company'

const route = useRoute()

const resume = ref(null)
const showContacts = ref(false)

const test = ref(null)
const responseToTest = ref(null)

const userData = localStorage.getItem('role_id')

onMounted(
  async () => {
    if (userData === '3') {
      resume.value = (await getResume(route.params.resume_id)).data
      if (route.params.from === 'download-pdf') {
        setTimeout(printPDF, 100)
      }
    } else {
      if (route.params.resume_id) {
        resume.value = await getResumeDetail(route.params.resume_id)
      } else {
        const response = (await getResponseDetail(route.params.response_id)).data.data
        resume.value = response.resume
        test.value = response.test
        responseToTest.value = response.right_variant
      }
    }
  }
)

const printPDF = () => {
  window.print()
}
</script>

<style lang="scss">
.resumeDetail{
  @page
  {
      size: auto;   /* auto is the initial value */
      margin: 0mm;  /* this affects the margin in the printer settings */
  }
  &__headerTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    & .section__title{
      margin-bottom: 0;
    }
    & .print{
      margin-bottom: 7px;
      & svg{
        display: block;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        fill: rgba(#4F553D, .7);
      }
    }
  }
  & .modal__inner{
    max-width: 415px;
    padding: 25px 45px 25px;
    min-height: 210px;
    & .email{
      margin-bottom: 15px;
    }
  }
  & .experience,
  & .education,
  & .course,
  & .block,
  & .languages,
  & .quality,
  & .doHaveCar{
    padding: 40px 0;
    border-bottom: 1px solid rgba(#000, .1);

    & > .title{
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      color: #4D533C;
      margin-bottom: 30px;
      &.skeleton{
        height: 35px;
      }
    }

    & .description{
      & > div{
        margin-bottom: 20px;
      }
      & > div:last-child{
        margin-bottom: 0;
      }
      & .text{
        display: inline-block;
        padding-left: 20px;
      }
      & a.text{
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 280px;
      }
      & a.text:hover,
      & a.text:active{
        text-decoration: underline;
      }
    }

    &.skeleton__bl{
      padding: 0;
      border-bottom: none !important;
    }
  }
  & .experience .timePeriod .title,
  & .education .item .title,
  & .block .item .title,
  & .direction.skeleton{
    font-weight: bold;
    font-size: 16px;
    text-transform: none;
    color: #212529;
    margin-bottom: 4px;
  }

  & .experience .timePeriod .subtitle,
  & .experience .company .local,
  & .education .item .name,
  & .block .item .name,
  & .education .item .local{
    font-size: 14px;
    font-style: italic;
    line-height: 1.7;
    color: rgba(33, 37, 41, 0.7);
  }
  &__inner{
    & > div{
      padding-bottom: 0 !important;
      border-bottom: none !important;
      margin-bottom: 50px !important;
    }
  }
  @media (max-width: 576px){
    & .experience,
    & .education,
    & .block,
    & .languages,
    & .quality,
    & .doHaveCar{
      padding: 40px 0;
      border-bottom: 1px solid rgba(#000, .1);

      & > .title{
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
  }
}

// Print
@media print {
  .header,
  .footer,
  .buttonToTop{
    display: none !important;
  }
  .resumeDetail{
    & .print{
      display: none;
    }
    & p{
      font-size: 11px;
    }
    & .experience,
    & .education,
    & .course,
    & .block,
    & .languages,
    & .quality,
    & .doHaveCar{
      padding: 16px 0;

      & > .title{
        font-size: 16px;
        margin-bottom: 20px;
      }

      & .description{
        & > div{
          margin-bottom: 20px;
        }
        & > div:last-child{
          margin-bottom: 0;
        }
        & .text{
          padding-left: 20px;
        }
      }
    }

    & .experience .timePeriod .title,
    & .education .item .title,
    & .block .item .title,
    & .direction.skeleton{
      font-size: 13px;
      margin-bottom: 4px;
    }

    & .experience .timePeriod .subtitle,
    & .experience .company .local,
    & .education .item .name,
    & .block .item .name,
    & .education .item .local{
      font-size: 11px;
      line-height: 1.7;
    }
    &__inner{
      & > div{
        padding-bottom: 0 !important;
        border-bottom: none !important;
        margin-bottom: 30px !important;
      }
    }
  }
}
</style>
