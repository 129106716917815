<template>
<section class="hotVacancies" id="hotVacancies" :class="{solo: itemCount === 1}" v-if="itemCount !== 0">
  <!-- Hot vacanices -->
  <Slider
    v-if="professionalAreas"
    :title="$t('LastMinuteJobs')"
    :sliderStyle="sliderStyle"
  >
   <router-link
      class="hotVacancies__item"
      v-for="professionalArea of professionalAreas"
      :key="professionalArea"
      :to="'/burning-vacancies/' + professionalArea.id"
    >
        <div class="hotVacancies__content">
          <div class="hotVacancies__name">{{professionalArea.title}}</div>
        </div>
        <div class="hotVacancies__preview">
          <img v-if="professionalArea.image" :src="`${apiUrl}/${professionalArea.image}`" alt="">
          <img v-else class="img" src="https://i.pinimg.com/564x/14/32/f7/1432f7ce422963c1a2ecfc33888018a9.jpg" alt="">
        </div>
    </router-link>
  </Slider>

  <!-- Skeleton -->
  <Slider
    v-else
    :title="$t('LastMinuteJobs')"
    :sliderStyle="sliderStyle"
  >
   <div
    class="hotVacancies__item skeleton_block"
    v-for="vacanciesItems of 14"
    :key="vacanciesItems"
  >
      <div class="hotVacancies__preview">
        <div class="img skeleton skeleton-img"></div>
      </div>
    </div>
  </Slider>
</section>
</template>

<script setup>
import { apiUrl } from '@/api'
import Slider from '@/components/common/Sliders/Slider.vue'

import { reactive, ref } from '@vue/reactivity'

import { getHotVacancies } from '@/actions/home'
import { onMounted, watch } from 'vue-demi'

const professionalAreas = ref(null)
const fetchData = async () => {
  professionalAreas.value = null
  professionalAreas.value = (await getHotVacancies()).data
  itemCount.value = Math.ceil(professionalAreas.value.length / 2)
}

onMounted(
  () => fetchData()
)

// Slider Style & Adaptive
const itemWidth = ref(350)
const itemCount = ref(7)
const sliderStyle = reactive(
  {
    gridTemplateColumns: `repeat(${itemCount.value}, ${itemWidth.value}px)`
  }
)

if (document.body.clientWidth <= 1200) {
  itemWidth.value = 290
}
if (document.body.clientWidth <= 992) {
  itemWidth.value = 215
}
if (document.body.clientWidth <= 768) {
  itemWidth.value = 165
}
if (document.body.clientWidth <= 576) {
  itemWidth.value = 200
}

watch(
  () => [itemWidth.value, itemCount.value],
  () => {
    sliderStyle.gridTemplateColumns = `repeat(${itemCount.value}, ${itemWidth.value}px)`
  }
)

</script>

<style lang="scss">
.hotVacancies{
  & .slider__items{
    display: grid;
    grid-template-rows: 185px 185px;
    padding-bottom: 0 !important;
  }
  &.solo .slider__items{
    grid-template-rows: 185px;
  }
  &__item{
    position: relative;
    box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.1);
    transition: box-shadow .2s linear;
    &:hover{
      box-shadow: 30px 30px 100px rgba(0, 0, 0, 0);
      & .hotVacancies__content{
        background-color: rgba(#212529, .7);
      }
      & .hotVacancies__name{
        transform: scaleX(1.08);
      }
    }

    &.skeleton_block{
      box-shadow: none !important;
    }
  }
  &__content{
    height: 100%;
    max-width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(#212529, .5);
    transition: background-color .4s linear;
  }
  &__name{
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    transition: transform .4s linear;
  }
  &__preview{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    & .img{
      width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 1200px){
    & .slider__items{
      grid-template-rows: 155px 155px;
    }
    &.solo .slider__items{
      grid-template-rows: 155px;
    }
    &__name{
      font-size: 22px;
    }
  }

  @media (max-width: 992px){
    & .slider__items{
      grid-template-rows: 125px 125px;
      grid-gap: 20px;
    }
    &.solo .slider__items{
      grid-template-rows: 125px;
    }
    &__name{
      font-size: 16px;
    }
  }

  @media (max-width: 768px){
    & .slider__items{
      grid-template-rows: 95px 95px;
      grid-gap: 15px;
    }
    &.solo .slider__items{
      grid-template-rows: 95px;
    }
    &__name{
      font-size: 12px;
    }
  }

}
</style>
