<template>
  <div class="slider__btns">
    <button class="slider__btn" @click="prev">
      <svg class="arrow">
          <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
      </svg>
      <svg class="arrow">
          <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
      </svg>
    </button>
    <button class="slider__btn" @click="next">
      <svg class="arrow">
          <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
      </svg>
      <svg class="arrow">
          <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SliderBtns',
  props: {
    next: {
      type: Function,
      default: null
    },
    prev: {
      type: Function,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.slider{
  &__btns{
    display: flex;
    align-items: center;
    grid-gap: 50px;
    padding: 20px 0;
    justify-content: center;
    & .slider__btn:first-child{
      transform: rotate(180deg);
    }
  }

  &__btn{
    display: block;
    height: 24px;
    position: relative;
    cursor: pointer;

    & svg{
      display: block;
      width: 13.33px;
      height: 24px;

      fill: #212529;

      transition: all .2s linear;
    }

    & svg:last-child{
      width: 8.73px;
      height: 15.72px;
      position: absolute;
      top: 50%;
      opacity: 0;
      transform: translate3d(0, -50%, 0);

      transition: all .2s linear;
    }

    &:hover svg{
      fill: #AD1717;
    }

    &:hover svg:last-child{
      opacity: 1;
      transform: translate3d(12px, -50%, 0);
    }
  }
  @media (max-width: 576px){
    &__btns{
      display: none;
    }
  }

}
</style>
