<template>
  <Header />
  <section class="personalData">
      <div class="container">
          <h3 class="section__title">{{$t('common_data')}}</h3>
          <div class="personalData__block" v-if="commonData">
              <div class="personalData__preview">
                <img v-if="commonData.logo" :src="`${apiUrl}/${commonData.logo}`" alt="">
                <img v-else src="/img/tmp/defold.png" alt="">
              </div>
              <div class="personalData__content">
                  <div>
                      <div class="personalData__name">{{commonData.name}}</div>
                      <p v-if="commonData.country && commonData.city">{{commonData.country.title}}, {{commonData.city.title}}</p>
                      <p>{{$t('field_of_activity')}}: {{commonData.industry ? commonData.industry.title : $t('not_set')}}</p>
                      <p>{{$t('address_title')}}: {{commonData.address}}</p>
                      <p>{{$t('phone')}}: {{formatToPhone(commonData.phone)}}</p>
                      <p>Email: {{commonData.email}}</p>
                      <p>{{$t('description_company')}}: {{commonData.description ? commonData.description : $t('not_set')}}</p>
                  </div>

                  <div class="btns">
                    <Button :name="$t('edit_data')" go="/company/edit-common-data"/>
                    <Button v-if="userRoleId === '5' && managerCount > 0" :name="$t('add_meneger')" go="/company/create-manager"/>
                    <Button v-else-if="userRoleId === '5'" :name="$t('add_meneger')" @click="() => {question = true}"/>
                  </div>
              </div>
          </div>
          <div class="personalData__block" v-else>
              <div class="personalData__preview">
                <div class="img skeleton skeleton-img"></div>
              </div>
              <div class="personalData__content">
                  <div>
                      <div class="personalData__name skeleton middle-text" style="width:100%;max-width: 500px;"></div>
                      <div style="display:flex;margin-bottom:15px;grid-gap:10px" v-for="item of 7" :key="item"><span class="skeleton middle-text" style="width: 70px"></span><span class="skeleton middle-text"></span></div>
                  </div>

                  <div class="btns">
                    <Button class="skeleton" style="width:200px; pointer-events: none"/>
                    <Button class="skeleton" style="width:200px; pointer-events: none"/>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="companyManagers">
    <div class="container">
      <div class="section__title">{{$t('company_menegers')}}</div>
      <ListManagers commonDataPage/>
    </div>
  </section>
  <section class="map" v-if="commonData">
    <yandexMap :coords="[commonData.width, commonData.height]" :zoom="14">
      <ymapMarker :coords="[commonData.width, commonData.height]" marker-id="123" hint-content="some hint"/>
    </yandexMap>
  </section>
  <section class="map" v-else>
    <div class="skeleton skeleton-img"></div>
  </section>
  <Modal
    :title="$t('message.first_but_a_package_to_create_a_manager')"
    :btnTitle="$t('buy')"
    :hide="()=>{question = false}"
    :show="question"
    :agreement="() => {router.push('/company/services')}"
    quastion
  />
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import ListManagers from '@/components/uiCompany/Managers/ListManagers.vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

import { apiUrl } from '@/api'
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'
import router from '@/router/index.js'

import { countManagersAndVacancies } from '@/helpers/countManagersAndVacancies'
import { formatToPhone } from '@/helpers/formatPhone'
import { getCommonDataEdit } from '@/actions/company'

const userRoleId = localStorage.getItem('role_id')
const commonData = ref(null)
const managerCount = ref(0)
const question = ref(false)

onMounted(
  async () => {
    managerCount.value = (await countManagersAndVacancies()).availableManagers
    commonData.value = (await getCommonDataEdit()).data.company
  }
)
</script>

<style lang="scss" scoped>
.map{
  height: 320px;
  background-color: #ebebeb;
  @media (max-width: 992px){
    height: 280px;
  }
  @media (max-width: 768px){
    height: 240px;
  }
  @media (max-width: 576px){
    height: 220px;
  }
  @media (max-width: 480px){
    height: 200px;
  }
}
.personalData{
  & .btns{
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    & .btn:first-child{
      margin-right: 20px;
    }
  }
  @media (max-width: 992px){
    &__content > div:first-child{
      margin-bottom: 20px;
    }
    & .btns{
      grid-gap: 15px;
    }
  }
  @media (max-width: 768px){
    & .btns{
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      & .btn:first-child{
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 480px){
    & .btns{
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
}
</style>
