<template>
<button class="header__user" v-if="login.company || login.employee || login.consultant">
    <div class="inner">
      <div class="photo">
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#user"></use>
        </svg>
      </div>
    </div>
    <div class="drop">
      <DropCompany
        v-if="login.company"
        @search="$emit('search')"
        @createVacancy="$emit('createVacancy')"
      />
      <DropEmployee
        v-else-if="login.employee"
        @search="$emit('search')"
      />
      <DropConsultant v-else />

      <button class="drop__link" @click="logout(), login.employee = false, login.company = false, login.consultant = false">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"> <path d="M670 5101 c-190 -61 -336 -212 -384 -398 -14 -54 -16 -252 -16 -1811 0 -1232 3 -1767 11 -1804 20 -95 75 -194 148 -267 60 -59 128 -101 686 -423 445 -255 639 -362 689 -377 82 -25 224 -28 306 -6 180 48 326 183 388 359 23 63 26 91 30 254 l4 182 422 0 c256 0 446 4 482 11 32 6 97 28 143 51 148 71 253 200 296 363 12 48 15 128 15 432 0 347 -1 376 -19 411 -23 46 -46 67 -94 87 -85 35 -194 -1 -240 -80 -8 -14 -14 -136 -17 -390 -6 -415 -4 -404 -83 -469 l-39 -31 -434 -3 -433 -3 -3 1278 -3 1278 -22 66 c-29 83 -86 171 -149 230 -55 50 -92 73 -729 439 -242 139 -444 257 -450 262 -5 5 490 7 1101 6 l1110 -3 42 -28 c23 -15 52 -46 64 -70 23 -41 23 -50 28 -452 3 -285 9 -416 17 -430 6 -11 24 -32 40 -47 79 -75 211 -61 280 29 l28 36 3 423 c3 470 0 503 -60 618 -43 81 -156 195 -233 233 -132 67 -29 63 -1519 62 -1264 0 -1355 -1 -1406 -18z"/> <path d="M4062 3649 c-53 -27 -90 -79 -98 -141 -11 -78 8 -112 129 -235 l110 -112 -694 -3 c-767 -3 -725 0 -782 -67 -78 -93 -50 -239 55 -290 44 -21 52 -21 733 -21 l688 0 -110 -113 c-114 -116 -133 -149 -133 -222 0 -39 31 -104 63 -134 38 -35 113 -52 173 -39 47 10 63 24 332 292 156 154 292 298 303 318 25 48 25 120 -1 172 -27 57 -552 580 -602 601 -52 21 -115 19 -166 -6z"/> </g> </svg>
        <span>{{$t('logout')}}</span>
      </button>
    </div>
</button>
<Button
  :name="$t('authorization')"
  type="authorization"
  @click="$emit('showLogin')"
  v-else
></Button>
</template>

<script setup>
import DropCompany from '@/components/uiLanding/layouts/Header/Drops/DropCompany.vue'
import DropEmployee from '@/components/uiLanding/layouts/Header/Drops/DropEmployee.vue'
import DropConsultant from '@/components/uiLanding/layouts/Header/Drops/DropConsultant.vue'

import { useAuth } from '@/modules/auth.js'
import { onMounted, reactive, defineEmits } from 'vue'

defineEmits(['showLogin', 'search', 'createVacancy'])

const login = reactive(
  {
    company: false,
    employee: false,
    consultant: false
  }
)

onMounted(
  async () => {
    const userData = localStorage.getItem('role_id')
    if (userData === '3') login.employee = true
    else if (userData === '4' || userData === '5') login.company = true
    else if (userData === '2') login.consultant = true
  }
)

async function logout () {
  const { logout } = useAuth()
  logout()
}
</script>

<style lang="scss">
.header__user{
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: 2px solid transparent;
  position: relative;
  & .inner{
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: relative;
    transition: transform .2s ease-in-out;
    cursor: pointer;
  }

  & .photo{
    min-width: 32px;
    min-height: 32px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
  }

  & svg{
      display: block;
      width: 32px;
      height: 32px;
  }

  &:hover,
  &:focus{
    & .inner{
      transform: scale(0.8);
    }
    & .drop{
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 992px){
    width: 30px;
    height: 30px;

    & .photo{
      min-width: 30px;
      min-height: 30px;
    }

    & svg{
        display: block;
        width: 30px;
        height: 30px;
    }
  }

  @media (max-width: 768px){
    width: 28px;
    height: 28px;

    & .photo{
      min-width: 28px;
      min-height: 28px;
    }

    & svg{
        display: block;
        width: 28px;
        height: 28px;
    }
  }
}

.drop{
  width: 220px;
  display: inline-block;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  overflow: hidden;
  @media (max-width: 768px){
    width: 180px;
  }
  &__notPermanent{
    display: none !important;
  }
  @media (max-width: 1200px){
    &__notPermanent{
      display: flex !important;
    }
  }
}

.drop__link{
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  padding: 12px 15px;
  transition: background-color .2s linear;
  & span{
    display: inline;
    font-size: 16px;
    color: #000;
    position: relative;
    &:before{
      content: "";
      display: block;
      height: 2px;
      width: 0;
      position: absolute;
      top: 100%;
      left: 0;
      border-radius: 5px;
      background-color: #000;
      transition: width .2s linear;
    }
  }
  & svg{
    display: block;
    width: 20px;
    height: 20px;
    fill: #000;
  }
  &:hover{
    background-color: #f5f5f5;
    & span::before{
      width: 100%;
    }
  }

  @media (max-width: 992px){
    grid-gap: 8px;
    padding: 10px 13px;
    & span{
      font-size: 14px;
    }
    & svg{
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 768px){
    grid-gap: 7px;
    padding: 8px 11px;
    & span{
      font-size: 12px;
    }
    & svg{
      width: 16px;
      height: 16px;
    }
  }
}
</style>
<style scoped>
.drop{
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  transition: all .2s linear;
}
</style>
