<template>
<section class="news">
      <div class="container">
          <div class="news__header">
            <h3 class="section__title">{{$t('news')}}</h3>
            <router-link to="/news" v-if="homePage">{{$t('show_all')}}</router-link>
          </div>
          <NothingHere v-if="news && news.length === 0"/>
          <div v-if="news">
            <div class="news__items">
              <router-link v-for="newsItem in news" :key="newsItem" :to="'/detail-news/' + newsItem.id" class="news__item">
                <div class="news__preview">
                  <img class="img" :src="`${apiUrl}/${newsItem.image}`" alt="">
                </div>
                <div class="news__content">
                  <div class="news__title">{{newsItem.title}}</div>
                  <div class="news__date">{{newsItem.date}}</div>
                </div>
              </router-link>
            </div>
            <Pagination
              v-if="!homePage"
              v-model="filter.page"
              :perPage="filter.per_page"
              :total="total"
              @newPage="fetchData"
            />
          </div>

          <!-- Skeleton -->
          <div class="news__items" v-else>
            <div v-for="i in homePage ? 3 : 6" :key="i" class="news__item skeleton__block">
                <div class="news__preview">
                </div>
                <div class="news__content">
                    <div class="news__title skeleton middle-text"></div>
                    <div class="news__date skeleton small-text" style="width:70%;"></div>
                </div>
            </div>
          </div>
      </div>
  </section>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import { getNews } from '@/actions/home'
import { onMounted } from 'vue-demi'
import { defineProps, watch } from 'vue'
import { apiUrl } from '@/api'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

const props = defineProps({
  homePage: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()

// Filter
const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: props.homePage ? 3 : 9
  }
)
const total = ref()

const news = ref(null)
const fetchData = async () => {
  news.value = null
  const response = await getNews(filter.page, undefined, filter.per_page)
  news.value = response.data
  total.value = response.total
  if (!props.homePage) router.push({ path: '/news/result', query: { page: filter.page } })
}

onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'News') {
      filter.page = route.query.page ?? 1
      fetchData()
    }
  }
)
</script>

<style lang="scss" scoped>
.news{
  & .container{
    border-bottom: none;
  }
  &__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    & .section__title{
      margin-bottom: 0;
    }
    & a{
      color: rgba(#212529, .7);
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      text-decoration: underline;

      transition: color .2s linear;

      &:hover{
          color: #212529;
      }
    }
  }
  &__items{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }

  &__item{
    position: relative;
    cursor: pointer !important;

    &:hover{
      & .news__content{
        background-color: rgba(#212529, .7);
      }

      & .news__title{
        margin-bottom: 17px;
      }
      & .news__date{
        margin-bottom: 7px;
      }
    }
  }

  &__preview{
    position: relative;
    padding-bottom: 69.5%;
    overflow: hidden;

    & .img{
      display: block;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      transform: translate3d(0, -50%, 0);
    }
  }

  &__content{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: absolute;
    top: 0;
    z-index: 2;

    padding: 30px;
    background-color: rgba(#212529, .5);

    transition: background-color .2s linear;
  }

  &__title{
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 10px;

    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-height: 45px;
    transition: margin-bottom .2s linear;
  }

  &__date{
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    transition: margin-bottom .2s linear;
  }
}
.skeleton__block{
  border: .5px solid;
  & .news__content{
    background-color: transparent !important;
  }
}
@media (max-width: 992px){
  .news__header{
    margin-bottom: 45px;
  }
}
@media (max-width: 885px){
  .news{
    &__items{
      grid-template-columns: 1fr 1fr !important;
      grid-gap: 20px;
    }
  }
}
@media (max-width: 768px){
  .news{
    &__header{
      margin-bottom: 35px;
    }
    &__content{
      padding: 20px;
    }
  }
}
@media (max-width: 576px){
  .news{
    &__items{
      grid-template-columns: 1fr !important;
    }
    &__item{
      display: grid;
      grid-template-columns: 120px 1fr;
      grid-gap: 10px;
    }
    &__preview{
      background-color: #ededed;
    }
    &__content{
      display: block;
      position: static;
      padding: 0;
      background-color: #fff !important;
    }
    &__title,
    &__date{
      color: #212529;
    }
    &__date{
      font-weight: 400;
    }
  }
  .skeleton__block{
    border: 0 !important;
  }
}
@media (max-width: 380px){
  .news__header a{
    display: none;
  }
}
</style>
