<template>
  <Header />
  <section class="passingTest">
    <div class="container">
      <h3 class="section__title">{{$t('passing_the_test')}}</h3>
      <form @submit.prevent="vacancyInvite" method="POST" v-if="test">

        <h5 class="passingTest__title">{{test.title}}</h5>

        <div class="passingTest__questions">
          <RowQuestion
            v-for="(question, i) of test.questions"
            :key="question"
            :question="question"
            v-model="formData.questions[i].variation_id"
            v-model:validation="success['question' + i]"
            :num="i + 1"
          />
        </div>

        <div class="btns">
          <Button
            :name="$t('send')"
            htmlType="submit"
            :loading="false"
            :hasError="success"/>
          <Button
            class="btn"
            :name="$t('cancel')"
            type="gray"
            :go="'/user/detail-vacancy/' + route.params.vacancy_id"
          />
        </div>
      </form>
      <div class="skeleton__block" v-else>
        <h5 class="passingTest__title skeleton middle-text"></h5>

        <div class="passingTest__question" v-for="variant of 5" :key="variant">
          <p class="passingTest__subtitle">
            <span class="skeleton small-text"></span>
            <span class="skeleton small-text"></span>
          </p>
          <div class="passCheckbox__block">
            <p class="passCheckbox__item skeleton small-text" style="width:730px"></p>
            <p class="passCheckbox__item skeleton small-text" style="width:700px"></p>
            <p class="passCheckbox__item skeleton small-text" style="width:690px"></p>
            <p class="passCheckbox__item skeleton small-text" style="width:680px"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Loading class="fixed" :show="loading"/>
</template>

<script setup>
import RowQuestion from '@/components/uiEmployee/Test/RowQuestion.vue'

import { onMounted, reactive, ref } from 'vue-demi'
import { useRoute } from 'vue-router'
import { useToast } from '@/modules/toast'
import { useI18n } from 'vue-i18n'
import router from '@/router/index.js'

import { getTest, postResponseVacancy, getResumes } from '@/actions/employee'

const i18n = useI18n()
const route = useRoute()
const { toast } = useToast()

const formData = reactive(
  {
    questions: [],
    test_id: null,
    resume_id: null
  }
)
const test = ref(null)
const success = reactive({})
const loading = ref(false)

onMounted(
  async () => {
    test.value = (await getTest(route.params.vacancy_id)).data.vacancy.company_tests[0]
    test.value.questions.forEach((question, i) => {
      success['question' + i] = false
      formData.questions.push(
        {
          question_id: question.id,
          variation_id: null
        }
      )
    })
    formData.test_id = test.value.id
  }
)

// ====================  Send Feedback  ====================
const vacancyInvite = async () => {
  loading.value = true
  try {
    // Api Resumes
    const resumes = (await getResumes()).data.data
    if (resumes.length !== 0) {
      // Post Response Vacancy
      formData.resume_id = resumes[0].id
      await postResponseVacancy(route.params.vacancy_id, formData)
      router.push('/user/detail-vacancy/' + route.params.vacancy_id)
    } else {
      toast({ message: i18n.t('message.zero_resume') })
      router.push('/user/detail-vacancy/' + route.params.vacancy_id)
      return
    }
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.passingTest{
  &__title{
    color: #4D533C;
    font-size: 28px;
    margin-bottom: 35px;
  }
  &__questions{
    margin-bottom: 70px;
  }
  & .btns{
    display: flex;
    grid-gap: 20px;
  }

  @media (max-width: 1100px){
    &__title{
      font-size: 26px;
      margin-bottom: 30px;
    }
    &__questions{
      margin-bottom: 60px;
    }
  }
  @media (max-width: 992px){
    &__title{
      font-size: 24px;
      margin-bottom: 25px;
    }
    &__questions{
      margin-bottom: 70px;
    }
  }
  @media (max-width: 768px){
    &__title{
      font-size: 22px;
      margin-bottom: 20px;
    }
    &__questions{
      margin-bottom: 60px;
    }
  }
  @media (max-width: 576px){
    &__title{
      font-size: 20px;
      margin-bottom: 15px;
    }
    &__questions{
      margin-bottom: 50px;
    }
  }

  @media (max-width: 400px){
    &__title{
      font-size: 18px;
    }
    &__questions{
      margin-bottom: 40px;
    }
  }
}

.skeleton__block{
  & .passingTest{
    &__title{
      width: 350px;
      max-width: 100%;
    }
    &__subtitle{
      margin-bottom: 18px;
      & span{
        max-width: 100%;
        width: 80%;
        margin-bottom: 8px;
      }
      & span:first-child{
        width: 100%;
      }
    }
  }
  & .passCheckbox__item{
    max-width: 100%;
    margin-bottom: 10px;
  }
}
</style>
