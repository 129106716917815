<template>
  <Header />
  <section class="CompanyServices">
    <div class="container">
      <div class="section__header">
        <h3 class="section__title">{{$t('services.title')}}</h3>
        <router-link class="link" to="/company/working-with-the-site/connected-services/result?page=1">{{$t('services.connected')}}</router-link>
      </div>
      <div class="services__table" v-if="services">
        <div class="row description">
          <div class="tr">
            <div class="th">{{$t('denomination')}}</div>
            <div class="th">{{$t('access_to_the_database_of_resume_of_applicants')}}</div>
            <div class="th">{{$t('number_of_vacancies_to_post')}}</div>
            <div class="th">{{$t('number_of_company_managers')}}</div>
            <div class="th">{{$t('service_cost')}}</div>
            <div class="th"></div>
          </div>
        </div>

        <div
          class="row"
          v-for="service of services"
          :key="service"
        >
          <div class="tr">
            <div class="td title">{{service.title}}</div>
            <div class="td">{{service.access === 1 ? $t('full_access') : $t('no_access')}}</div>
            <div class="td">{{service.vacancies_count}}</div>
            <div class="td">{{service.managers_count}}</div>
            <div class="td salary">{{`${formatToSalary(service.price)} ${service.currency.title}`}}</div>
          </div>
          <Button :name="$t('pay')" :go="'/company/services/send-a-request-for-payment/' + service.id"/>
        </div>
      </div>

      <!-- Skeleton -->
      <div class="services__table" v-else>
        <div class="row description">
          <div class="tr">
            <div class="th skeleton middle-text" style="width:150px; max-width: 100%;"></div>
            <div class="th skeleton middle-text" style="width:110px; max-width: 100%;"></div>
            <div class="th skeleton middle-text" style="width:110px; max-width: 100%;"></div>
            <div class="th skeleton middle-text" style="width:110px; max-width: 100%;"></div>
            <div class="th skeleton middle-text" style="width:110px; max-width: 100%;"></div>
          </div>
        </div>

        <div
          class="row"
          v-for="service of 5"
          :key="service"
        >
          <div class="tr">
            <div class="td title skeleton">
            </div>
            <div class="td skeleton__block" v-for="el of 4" :key="el">
              <span class="th skeleton middle-text" style="width:110px; max-width: 100%;"></span>
            </div>
          </div>
          <Button class="skeleton" style="width:128px; max-width: 100%;"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'
import { useI18n } from 'vue-i18n'

import { getServices } from '@/actions/company'
import { formatToSalary } from '@/helpers/formatSalary'

const services = ref(null)
const filter = ref(
  {
    perPage: 10,
    sort_type: false
  }
)

const i18n = useI18n()

const fetchData = async () => {
  services.value = null
  const response = (await getServices(filter.value)).data
  services.value = response.data
  services.value.forEach(service => {
    switch (service.managers_count) {
      case null :
        service.managers_count = '-'
        break
      case 1 :
        service.managers_count = `${service.managers_count} ${i18n.t('manager').toLowerCase()}`
        break
      default:
        service.managers_count = `${i18n.t('before')} ${service.managers_count} ${i18n.t('managers').toLowerCase()}`
    }

    switch (service.vacancies_count) {
      case null :
        service.vacancies_count = '-'
        break
      case 1 :
        service.vacancies_count = `${service.vacancies_count} ${i18n.t('vacancy').toLowerCase()}`
        break
      default:
        service.vacancies_count = `${service.vacancies_count} ${i18n.t('vacancies').toLowerCase()}`
    }
  })
}

onMounted(
  fetchData()
)
</script>

<style lang="scss" scoped>
.services__table{
  border-collapse: collapse;
  & .row{
    display: grid;
    grid-template-columns: 1fr 128px;
    justify-content: space-between;
    align-items: center;
    grid-gap: 20px;
    margin-bottom: 10px;
  }
  & .th{
    font-size: 12px;
    font-weight: 400;
    line-height: 1.1;
    color: rgba(33, 37, 41, 0.7);
    padding-left: 0;
    padding-right: 20px;
    background-color: #fff;
    border: none;
  }

  & .td{
    font-size: 16px;
    line-height: 1.2;
    color: rgba(33, 37, 41, 0.7);
    padding: 20px;
    border: 0.5px solid rgba(173, 23, 23, 0.5);
    &.title{
      font-weight: 600;
      font-size: 20px;
      text-transform: uppercase;
      color: #212529;
      background-color: rgba(173, 23, 23, 0.05);
      &.skeleton__block{
        background-color: rgba(#000, .09);
      }
    }
    &.salary{
      font-size: 24px;
      color: #212529;
    }
  }

  & .tr{
    width: 100%;
    display: flex;
    background: #fff;

    display: grid;
    grid-template-columns: 30% 16.3% 14.8% 19.2% 19%;
  }

  @media (max-width: 992px){
    & .row{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      grid-gap: 10px;

      margin-bottom: 25px;
    }

    & .td.title{
      font-size: 18px;
    }

    & .description{
      margin-bottom: 10px !important;
    }
  }

  @media (max-width: 768px){
    & .description{
      display: none;
    }
    & .tr{
      display: block;
    }
    & .td{
      padding: 16px;
    }
  }
}
</style>
