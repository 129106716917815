import api from '../modules/api'

export const getStatistics = () =>
  api.asyncGet('/v1/', {}, false, true)

export const getStock = () =>
  api.asyncGet('/v1/second-banners')

export const getStockDetail = (id) =>
  api.asyncGet('/v1/second-banners/' + id, {}, true, true)

// Services
export const getServices = () =>
  api.asyncGet('/v1/services?perPage=15', {}, true, true)

export const getServiceDetail = (id) =>
  api.asyncGet('/v1/services/' + id)

export const getOutsourcingPersonnel = () =>
  api.asyncGet('/v1/personnel-outsourcings')

export const getOutsourcingDetail = (id) =>
  api.asyncGet('/v1/personnel-outsourcings/' + id)

// News
export const getNews = (page, filters, perPage) =>
  api.asyncGet('/v1/news', { page, ...filters, perPage })

export const getNewsDetail = (id) =>
  api.asyncGet('/v1/news/article/' + id)

// Hot Vacancy
export const getHotVacancies = () =>
  api.asyncGet('/v1/professional-areas', null, false, true)

export const getHotVacancyDetail = id =>
  api.asyncGet(`/v1/professional-areas/${id}/vacancies`, null, false, true)
