<template>
<div class="filter__header_inner">
  <div class="filter__header_btns">
    <button
      :class="{active: !formData.sort_type}"
      type="button"
      @click="() => {clickOption(false)}"
    >
      {{$t('filterCompanies.all_companies')}}
    </button>
    <button
      :class="{active: formData.sort_type}"
      type="button"
      @click="() => {clickOption(true)}"
    >
      {{$t('filterCompanies.only_with_open_vacancies')}}
    </button>
  </div>
  <div class="filter__header_total">
    {{total ?? 0}} {{$t('companies').toLowerCase()}}
  </div>
</div>
</template>
<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  hide: {
    type: Function,
    default: null
  },
  total: {
    type: [String, Number],
    default: 0
  },
  forVacancy: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'search'])

const formData = ref(props.modelValue)

const clickOption = val => {
  formData.value.sort_type = val
  emit('update:modelValue', formData.value)
  emit('search')
}

watch(
  () => props.modelValue,
  () => {
    formData.value = props.modelValue
  }
)
</script>

<style lang="scss" scoped>
.filter__header{
  &_inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_btns{
    display: flex;
    grid-gap: 40px;
  }

  & button{
    font-weight: 400;
    font-size: 16px;
    color: #979797;
    position: relative;
    transition: all .2s linear;

    &::before{
      content: '';
      display: block;
      height: 1.5px;
      width: 100%;
      opacity: 0;
      background-color: #979797;

      position: absolute;
      top: 130%;
      left: 0;
      z-index: 1;
      transition: all .2s linear;
    }

    &:hover{
      &::before{
        top: 98%;
        opacity: 1;
      }
    }

    &.active{
      color: #AD1717;
      &::before{
        display: none;
      }
    }
  }

  &_total{
    font-weight: 500;
    font-size: 20px;
    color: #212529;
  }

  @media (max-width: 992px){
    &_btns{
      grid-gap: 25px;
    }

    & button{
      font-size: 14px;
    }

    &_total{
      font-size: 18px;
    }
  }

  @media (max-width: 768px){
    &_btns{
      grid-gap: 20px;
    }

    & button{
      font-size: 12px;
    }
    &_total{
      display: none;
    }
  }

  @media (max-width: 540px){
    &_btns{
      align-items: flex-start;
      flex-direction: column;
      grid-gap: 7px;
    }
  }
}
</style>
