<template>
<div>
  <label for="address" v-if="title">{{title}}</label>

  <!-- ------------  List Options ------------------- -->
  <div v-if="!options" class="message">{{$t('select_a_region_first')}}</div>
  <div
    class="search__options"
    :class="{showOptions: showOptions}"
    v-else
  >
    <div
      class="options"
      :class="{showAll: showAll}"
    >
      <OptionFilter
        v-for="option of showOptions || showAll ? options : options.slice(0, showAmount)"
        :key="option"
        :title="option.title"
        :active="checkOption(option.id)"
        :type="type"
        :dark="dark"
        @check="() => {clickOption(option)}"
        @unCheck="() => {unClickOption(option.id)}"
      />
    </div>
  </div>

  <!-- ------------  Showing All Options ------------------- -->
  <button
    class="showMore"
    @click="showOptions = true"
    v-if="!showOptions && options && !showAll && options.length > showAmount"
  >
   {{$t('select_show_all')}}
    <span>{{options.length}}</span>
  </button>
</div>
</template>

<script setup>
import OptionFilter from '@/components/uiLanding/Filter/Sidebar/OptionFilter.vue'
import { defineProps, defineEmits, ref, onMounted } from 'vue'

const props = defineProps({
  modelValue: {
    type: [Number, Array, Boolean],
    default: null
  },
  options: {
    type: Array,
    default: null
  },
  title: {
    type: String,
    default: ''
  },
  showAmount: {
    type: Number,
    default: 3
  },
  showAll: {
    type: Boolean,
    default: false
  },
  multiFilter: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'default'
  },
  dark: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation', 'update:optionTitle'])

const formData = ref(props.modelValue)

if (props.multiFilter) {
  formData.value = []
  props.modelValue.forEach(id => {
    formData.value.push(Number(id))
  })
}

onMounted(
  () => {
    if (!props.multiFilter && formData.value) emit('update:validation', true)
    else if (props.multiFilter && formData.value.length === 0) emit('update:validation', false)
  }
)

// --------------- Input ----------------
const showOptions = ref(false)

// --------------- Click Events ----------------
const clickOption = (option) => {
  if (!props.multiFilter) {
    formData.value = option.id
    emit('update:optionTitle', option.title)
  } else if (props.multiFilter) formData.value.push(option.id)
  emit('update:modelValue', formData.value)
  emit('update:validation', true)
}

const checkOption = optionId => {
  if (!props.multiFilter && formData.value === optionId) return true
  else if (props.multiFilter && formData.value.includes(optionId)) return true
  else return false
}

const unClickOption = optionId => {
  if (!props.multiFilter) {
    formData.value = null
    emit('update:validation', false)
  } else {
    const i = formData.value.indexOf(optionId)
    formData.value.splice(i, 1)
    if (formData.value.length === 0) emit('update:validation', false)
  }
  emit('update:modelValue', formData.value)
}
</script>

<style lang="scss" scoped>
label{
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #212529;
  margin-bottom: 13px;
}
.search__options{
  position: relative;
  &::before,
  &::after{
    content: '';
    display: block;
    height: 20px;
    width: 100%;

    position: absolute;
    left: 0;
    z-index: 2;
  }
  &::before{
    top: 0;
    background: linear-gradient(#fff, rgba(#fff, .1));
  }
  &::after{
    bottom: 0;
    background: linear-gradient(rgba(#fff, .1), #fff);
  }
  & .options{
    max-height: 300px;
    overflow: auto;
    padding-top: 10px;
    &.showAll{
      max-height: none !important;
      overflow: none;
    }
  }
}
.message{
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.showMore{
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 992px){
  label{
    font-size: 1.8em;
    margin-bottom: 11px;
  }
}
</style>
