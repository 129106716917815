<template>
  <Header />
  <section class="lettersTemplates">
    <div class="container">
      <div class="lettersTemplates__header">
          <h3 class="section__title">{{$t('letter_templates')}}</h3>
          <Button :name="$t('create_latter')" type="gray" @click="showCreateLetter = true"/>
      </div>
      <CreateLetter
        :hide="() => {showCreateLetter = false}"
        v-if="showCreateLetter"
        :finally="() => {fetchData()}"
      />

      <NothingHere v-if="letters && letters.length === 0"/>
      <div v-if="letters">
        <RowLetter
          v-for="letter of letters"
          :key="letter"
          :info="letter"
          :finally="() => {fetchData()}"
        />
        <Pagination
          v-model="filter.page"
          :perPage="filter.per_page"
          :total="total"
          @newPage="() => {fetchData(filter.page)}"
        />
      </div>
      <div class="editLetter" v-else>
        <div class="title skeleton small-text" style="width:100%" v-for="el of 10" :key="el"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import RowLetter from '@/components/uiCompany/Letters/RowLetter.vue'
import CreateLetter from '@/components/uiCompany/Letters/CreateLetter.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getLetters } from '@/actions/company'

const route = useRoute()

const showCreateLetter = ref(false)
const letters = ref(null)

// Filter
const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20
  }
)
const total = ref()

const fetchData = async (page = 1) => {
  filter.page = page
  letters.value = null
  const response = (await getLetters(filter)).data.letters
  letters.value = response.data
  total.value = response.total
  router.push({ path: '/company/letters-templates/result', query: { page: page } })
}

onMounted(
  () => fetchData(filter.page)
)

watch(
  () => route.query,
  () => {
    if (route.name === 'LettersList') {
      filter.page = route.query.page ?? 1
      fetchData(filter.page)
    }
  }
)
</script>

<style lang="scss">
.lettersTemplates{
  &__header{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  & .section__title{
    margin-right: 40px;
    margin-bottom: 0;
  }
  & .link{
    display: block;
    font-size: 16px;
    margin-bottom: 15px;
    color: #4D533C;
    text-decoration: underline;
  }

  & .btns{
    display: flex;
    grid-gap: 20px;
  }

  @media (max-width: 480px){
    &__header{
      display: block;
    }
    & .section__title{
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}
</style>
