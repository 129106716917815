<template>
<NothingHere v-if="vacancies && vacancies.length === 0"/>
<!-- ================ Skeleton ================== -->
<div class="vacancySearch__items" v-else-if="!vacancies">
  <div
    class="vacancies__item skeleton__block"
    v-for="vacancy of 5"
    :key="vacancy"
  >
    <div class="top">
      <div class="prof skeleton middle-text"></div>
      <div class="price skeleton middle-text"></div>
    </div>
    <div class="center">
      <div class="company skeleton middle-text"></div>
    </div>
    <div class="bottom">
      <div class="state skeleton small-text"></div>
      <div class="right">
        <div class="date skeleton small-text"></div>
        <Button class="skeleton"/>
      </div>
    </div>
  </div>
</div>
<!-- ================ Vacancies ================== -->
<div class="vacancySearch__items" v-else>
  <div v-if="thisUser">
    <RowEmployeeVacancy
      v-for="vacancy of vacancies"
      :key="vacancy"
      :vacancy="vacancy"
      :deleted="() => {$emit('fetchData')}"
    />
  </div>
  <RowCompanyVacancy
    v-else
    v-for="vacancy of vacancies"
    :key="vacancy"
    :vacancy="vacancy"
    @update="() => {$emit('update')}"
  />
</div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import RowCompanyVacancy from '@/components/uiCompany/Vacancies/Search/RowVacancy.vue'
import RowEmployeeVacancy from '@/components/uiEmployee/Vacancies/RowVacancy.vue'

defineProps({
  vacancies: {
    type: [Array, Object],
    default: null
  }
})
defineEmits(['update'])

const userData = localStorage.getItem('role_id')
const thisUser = userData === '3'
</script>

<style lang="scss">
.searchResumes{
  &__items{
    border-collapse: collapse;
  }
}
.skeleton__block{
  & button{
    height: 35px !important;
  }
}
</style>
