<template>
<div class="responses__item">
  <div class="top">
    <div class="left">
      <div class="prof">
        <router-link :to="`/company/working-with-the-site/vacancy-response/${response.id}/resume-detail`" class="text">{{response.vacancy.position}}</router-link>
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#star"></use>
        </svg>
      </div>
      <div class="experience" >
        <div class="title">{{$t('experience')}}:</div>
        <div class="time">{{lastWorkPlace ? response.resume.work_places.totalPeriod : $t('not_set')}}</div>
      </div>
      <div class="previousWork" v-if="lastWorkPlace" >
        <div class="title">{{$t('last_job')}}:</div>
        <div class="name">{{lastWorkPlace.company_name}}</div>
        <div class="prof">{{lastWorkPlace.industry.title}}</div>
        <div class="time">{{lastWorkPlace.period}}</div>
      </div>
    </div>
    <div class="right">
      <div class="preview">
        <img src="/img/tmp/personalData.png" alt="">
      </div>
      <UserContact :userEmployee="response.resume.user_employee" />
    </div>
  </div>
  <div class="responses__bottom">
    <div class="time">
      <p>{{$t('updated')}} {{generateDate(response.resume.updated_at)}}</p>
      <p>{{$t('responded')}} {{generateDate(response.created_at)}}</p>
    </div>
    <div class="right">
      <div
        v-if="state"
        :class="'state ' + state"
      >
        {{state === 'Accepted' ? $t('invited') : $t('refusal')}}
      </div>
      <div class="btns" v-else>
        <Button
          :name="$t('to_invite')"
          class="invite"
          @click="() => {showInvite = true}"
        />
        <Button
          :name="$t('refuse')"
          class="refuse"
          :loading="loadingRefuse"
          @click="() => {refuseResume()}"
        />
      </div>

      <div class="status">
        {{response.status ? $t('viewed') : $t('not_viewed')}}
      </div>
    </div>
  </div>
</div>
<ModalAccept
  :show="showInvite"
  @hide="() => {showInvite = false}"
  :responseId="response.id"
  v-model:state="state"
/>
</template>

<script setup>
import ModalAccept from '@/components/uiCompany/Responses/AcceptModalResponse.vue'
import UserContact from '@/components/uiCompany/Resumes/UserContact.vue'

import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { defineProps, reactive, ref } from 'vue'

import { calcExperiencesPeriodTime } from '@/helpers/periodTime'
import { putRefuseResponse } from '@/actions/company'

const props = defineProps({
  response: {
    type: Object,
    default: () => {}
  }
})

const i18n = useI18n()
const lastWorkPlace = reactive(props.response.resume.work_places[0])
const loadingRefuse = ref(false)
const state = ref(props.response.status)
const showInvite = ref(false)

// Total Experience => 2 года 4 месяца
calcExperiencesPeriodTime(props.response.resume.work_places)

// Example Results => 29 сентября 15:10:25
const generateDate = time => {
  let date = ''
  date += `${moment(time).format('DD')} ${i18n.t('months.' + moment(time).format('M'))} `
  if (moment().format('YYYY') !== moment(time).format('YYYY')) {
    date += `${moment(time).format('YYYY')} `
  }
  date += `${moment(time).format('hh:mm:ss')}`
  return date
}
if (lastWorkPlace) {
  lastWorkPlace.period = `с ${moment(lastWorkPlace.period_from).format('DD-MM-YYYY')} `
  if (lastWorkPlace.period_to) lastWorkPlace.period += `${i18n.t('on')} ${moment(lastWorkPlace.period_to).format('DD-MM-YYYY')}`
  else lastWorkPlace.period += i18n.t('until_now')
}

// Refuse Resume
async function refuseResume () {
  loadingRefuse.value = true
  try {
    await putRefuseResponse(props.response.id)
    state.value = 'Rejected'
  } finally {
    loadingRefuse.value = false
  }
}
</script>

<style lang="scss">
.responses{
  &__item{
    display: block;
    padding: 20px;
    border: 0.5px solid #64676A;
    margin-bottom: -0.5px;
  }

  & .title{
    font-size: 14px;
    color: rgba(33, 37, 41, 0.7);
    line-height: 1.8;
  }

  & .top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    & .left{
      & .prof{
        display: flex;
        margin-bottom: 15px;
        & .text{
          font-weight: 500;
          font-size: 20px;
          text-transform: uppercase;
          color: #212529;
          margin-right: 20px;
          transition: all .2s linear;

          position: relative;

          &::after{
            content: '';
            display: block;
            height: 0;
            width: 0;

            position: absolute;
            top: 98%;
            left: 0;

            border-bottom: 3px solid #AD1717;
            transition: all .2s linear;
          }

          &:hover{
            color: #AD1717;
            &::after{
              width: 100%;
            }
          }
        }

        & svg{
          display: block;
          width: 20px;
          height: 18px;
          fill: #000;
        }
      }

      & .experience{
        margin-bottom: 15px;
        & .time{
          font-size: 16px;
          color: #212529;
        }
      }

      & .previousWork {
        & *{
          line-height: 1.6;
        }
        & .name,
        & .prof {
          font-size: 16px;
          color: #212529;
          margin-bottom: 0;
        }
        & .prof {
          color: #B5C096;
        }
        & .time {
          font-size: 14px;
          color: rgba(33, 37, 41, 0.7);
        }
      }
    }

    & .right{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & .preview{
        width: 117px;
        height: 117px;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;
        & img{
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      & .text{
        font-size: 16px;
        color: #4A98E7;
        text-align: end;
      }
    }

  }

  & .responses__bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .right{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 15px;
      & *{
        font-weight: 500;
        font-size: 14px;
        color: rgba(33, 37, 41, 0.7);
      }
      & > .btns{
        display: flex;
        grid-gap: 15px;
      }
      & button{
        padding: 10px 20px !important;
        border: none;
        transition: all .2s linear;
        & span{
          color: rgba(33, 37, 41, 0.7);
          text-transform: none;
        }
        &:hover,
        &:focus{
          box-shadow: none;
        }
      }
      & .invite {
        background-color: rgba(#2CA622, .2);
        &:hover,
        &:focus{
          background-color: rgba(#2CA622, .3);
        }
        &:active{
          background-color: rgba(#2CA622, .4);
        }
      }
      & .refuse {
        background-color: rgba(#AD1717, .2);
        &:hover,
        &:focus{
          box-shadow: none;
          background-color: rgba(#AD1717, .3);
        }
        &:active{
          background-color: rgba(#AD1717, .4);
        }
      }
      & .state,
      & .status {
        font-weight: 500;
        font-size: 14px;
        color: rgba(33, 37, 41, 0.7);
      }
      & .state{
        &.Rejected {
          color: #AD1717;
        }
        &.Accepted{
          color: #2CA622;
        }
      }
    }
  }
}
@media (max-width: 710px){
  .responses{
    & .responses__bottom{
      flex-direction: column;
      grid-gap: 10px;
      align-items: initial;
    }
  }
}
@media (max-width: 576px){
  .responses{
    & .top{
      display: grid;
      grid-template-columns: 1fr;
      & .right{
        grid-row: 1;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 20px;
        & .preview{
          margin-right: 10px;
          margin-bottom: 0;
        }
        & .text{
          text-align: start;
        }
      }
    }
  }
}
</style>
