<template>
  <Header />
  <section class="help">
    <div class="container">
      <h3 class="section__title">{{$t('Writeto_the_technical_department')}}</h3>
      <form @submit.prevent="onSendFeedback()" method="POST">
        <Input
          class="form__input"
          :placeholder="$t('topic')"
          v-model="formData.title"
          v-model:validation="success.title"
          required
        />
        <Input
          type="textarea"
          class="form__textarea"
          :placeholder="$t('your_message')"
          v-model="formData.description"
          v-model:validation="success.description"
          required
        />
        <div class="btns">
          <Button
            :name="$t('send')"
            htmlType="submit"
            :loading="false"
            :hasError="success"/>
          <Button
            class="btn"
            :name="$t('cancel')"
            type="gray"
            go="/"
          />
        </div>
      </form>
    </div>
  </section>
  <Loading class="fixed" :show="loading"/>
</template>

<script setup>
import { postFeedback } from '@/actions/help'

import { reactive, ref } from '@vue/reactivity'
import router from '@/router/index.js'
import { onMounted } from 'vue-demi'

const formData = reactive({
  description: '',
  title: ''
})
const success = reactive({
  description: false,
  title: false
})
const loading = ref(false)

onMounted(
  () => {
    formData.email = localStorage.getItem('email')
    if (!formData.email) router.push('/authorization/login')
  }
)

// ====================  Send Feedback  ====================
const onSendFeedback = async () => {
  try {
    loading.value = true
    await postFeedback(formData)
    router.push('/')
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.help{
  & form{
    display: flex;
    flex-direction: column;
    max-width: 730px;
  }
  & .form__input {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }
  & .form__textarea {
    margin-bottom: 40px;
  }
  & .btns{
    display: flex;
    grid-gap: 20px;
  }
  @media (max-width: 768px){
    .form__input {
      margin-bottom: 15px;
    }
    & .form__textarea {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 576px){
    .form__input {
      margin-bottom: 10px;
    }
    & .form__textarea {
      margin-bottom: 25px;
    }
    & .btns{
      display: flex;
      grid-gap: 10px;
    }
  }

}
</style>
