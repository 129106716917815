<template>
<div class="modal__sn">
  <div class="modal__snTitle">{{$t('login_with')}}</div>
  <div class="modal__snItems">
    <button class="modal__snItem" @click="() => {login('google')}">
      <img src="/img/tmp/google.png" alt="">
    </button>
    <button class="modal__snItem" @click="() => {login('facebook')}">
      <img src="/img/tmp/facebook.png" alt="">
    </button>
    <button class="modal__snItem" @click="() => {login('vkontakte')}">
      <img src="/img/tmp/vk.png" alt="">
    </button>
    <button class="modal__snItem" @click="() => {login('linkedin')}">
      <img src="/img/tmp/linkedIn.png" alt="">
    </button>
  </div>
</div>
</template>

<script setup>
import { getSocialNetwork } from '@/actions/auth.js'
import { defineEmits, ref } from 'vue'

const emit = defineEmits(['update:loading'])

const loading = ref(false)

const login = async socilaNetwork => {
  loading.value = true
  emit('update:loading', true)
  try {
    const token = await getSocialNetwork(socilaNetwork)
    window.location.href = token.data
  } finally {
    loading.value = false
    emit('update:loading', false)
  }
}
</script>

<style lang="scss" scoped>
.modal{
  &__sn{
    margin-bottom: 60px;
  }
  &__snTitle{
    color: #212529;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 27px;
  }
  &__snItems{
    display: grid;
    grid-template-columns: 115px 115px 115px 115px;
    grid-gap: 20px;
  }
  &__snItem{
    transition: transform .2s linear;

    &:hover{
      transform: translateY(-10px);
    }
  }

  @media (max-width: 992px){
    &__sn{
      margin-bottom: 40px;
    }
    &__snTitle{
      margin-bottom: 20px;
    }
    &__snItems{
      grid-template-columns: 110px 110px 110px 110px;
      grid-gap: 15px;
    }
  }
  @media (max-width: 768px){
    &__sn{
      margin-bottom: 30px;
    }
    &__snTitle{
      font-size: 12px;
      margin-bottom: 6px;
    }
    &__snItems{
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
    }
  }
  @media (max-width: 576px){
    &__sn{
      margin-bottom: 20px;
    }
    &__snTitle{
      font-size: 10px;
      margin-bottom: 6px;
    }
    &__snItems{
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 8px;
    }
  }
}
</style>
