<template>
<div class="filter__header">
  <Search
    v-model="formData.name"
    typeInput
    @search="onSearch"
  />
  <div class="bottom">
    <FilterResponse
      v-if="forResponse"
      v-model="formData"
      :options="vacancies"
      @search="onSearch"
    />
    <FilterCompany
      v-else-if="!forVacancy"
      v-model="formData"
      :total="total"
      @search="onSearch"
    />
    <FilterVacancy
      v-else
      v-model="formData"
      @search="onSearch"
    />

    <FilterButton
      v-if="withFilterBtn"
      @click="emit('showSideBar')"
    />
  </div>
</div>
</template>

<script setup>
import Search from '@/components/common/Search.vue'
import FilterCompany from '@/components/uiLanding/Filter/Header/FilterCompany.vue'
import FilterVacancy from '@/components/uiLanding/Filter/Header/FilterVacancy.vue'
import FilterResponse from '@/components/uiLanding/Filter/Header/FilterResponse.vue'
import FilterButton from '@/components/uiLanding/Filter/Header/FilterButton.vue'

import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  hide: {
    type: Function,
    default: null
  },
  total: {
    type: [String, Number],
    default: 0
  },
  vacancies: {
    type: Array,
    default: null
  },
  forVacancy: {
    type: Boolean,
    default: false
  },
  forResponse: {
    type: Boolean,
    default: false
  },
  withFilterBtn: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'search', 'showSideBar'])

const formData = ref(props.modelValue)

const onSearch = () => {
  emit('update:modelValue', formData.value)
  emit('search')
}

watch(
  () => props.modelValue,
  () => {
    formData.value = props.modelValue
  }
)
</script>

<style lang="scss" scoped>
.filter__header{
  margin-bottom: 37px;

  & .search{
    margin-bottom: 20px;
  }

  @media (max-width: 992px){
    margin-bottom: 30px;
    & .search{
      margin-bottom: 18px;
    }
  }

  @media (max-width: 768px){
    & .bottom{
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-gap: 20px;
    }
  }

  @media (max-width: 540px){
    & .bottom{
      align-items: flex-start;
    }
  }
}
</style>
