<template>
<LayoutRow
  :title="$t('availability_of_a_car')"
  v-model="formData.availabilityCar_on"
>
  <div class="createResume__itemInner">
    <label for="prof">{{$t('own_car')}}:</label>
    <div class="checkbox_block">
      <div><Checkbox v-model="formData.has_auto" dark /> <p>{{$t('there_is')}}</p></div>
      <div><Checkbox v-model="formData.has_auto" dark minus /> <p>{{$t('no').toLowerCase()}}</p></div>
    </div>
  </div>
  <div class="createResume__itemInner">
    <label for="prof">{{$t('driver_license')}}:</label>
    <div class="checkbox_block">
      <div><Checkbox v-model="formData.has_driver_certificate" dark /> <p>{{$t('there_is')}}</p></div>
      <div><Checkbox v-model="formData.has_driver_certificate" dark minus/> <p>{{$t('no').toLowerCase()}}</p></div>
    </div>
  </div>
  <div class="createResume__itemInner">
    <label for="prof">{{$t('category_of_rights')}}:</label>
    <Select
      id="industries"
      :placeholder="$t('select_categories')"
      v-model="formData.driver_certificate_categories"
      :options="helpers.driverCategories"
      multiSelect/>
  </div>
</LayoutRow>
</template>

<script setup>
import Checkbox from '@/components/common/Checkbox.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue'])

const formData = ref(props.modelValue)
if (formData.value.has_auto || formData.value.has_driver_certificate || formData.value.driver_certificate_categories.length !== 0) {
  formData.value.availabilityCar_on = true
} else formData.value.availabilityCar_on = false

watch(
  () => formData.value,
  () => {
    if (formData.value.availabilityCar_on) {
      if (!formData.value.has_auto) formData.value.has_auto = 0
      if (!formData.value.has_driver_certificate) formData.value.has_driver_certificate = 0
      if (!formData.value.driver_certificate_categories) formData.value.driver_certificate_categories = []
    }
    emit('update:modelValue', formData.value)
  }
)

</script>
