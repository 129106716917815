<template>
<Modal :hide="hide" :show="show" :loading="loading">
  <div class="modal-register">
    <div class="modal__title">{{$t('registration')}}</div>
    <div class="modal__radioBtns">
      <button class="active">{{$t('applicant')}}</button>
      <button @click="showCompany">{{$t('company')}}</button>
    </div>
    <form
      class="modal__form modal__registerForm"
      method="POST"
      @submit.prevent="registration()"
    >
        <div>
            <label for="surname">{{$t('surname')}}</label>
            <Input
              type="text"
              id="surname"
              v-model="formData.surname"
              v-model:validation="success.surname"
              required/>
        </div>
        <div>
            <label for="name">{{$t('name')}}</label>
            <Input
              id="name"
              v-model="formData.name"
              v-model:validation="success.name"
              required/>
        </div>
        <div>
            <label for="patronymic">{{$t('patronymic')}}</label>
            <Input
              id="patronymic"
              v-model="formData.patronym"/>
        </div>
        <div>
            <label for="country">{{$t('country')}}</label>
            <Select
              id="country"
              :options="country"
              :optionText="optionsText.country"
              v-model="formData.country_id"
              v-model:validation="success.country"/>
        </div>
        <div>
            <label for="city">{{$t('city')}}</label>
            <div>
              <Select
                id="city"
                :options="city"
                :optionText="optionsText.city"
                v-model="formData.city_id"
                v-model:validation="success.city"
              />

              <div class="possibleCity" v-if="autoLocal && !formData.city_id">
                <div class="text">{{`${$t('your_city')} ${langLocale === 'en' ? autoLocal.city.name_en : autoLocal.city.name_ru}`}}?</div>
                <div class="btns">
                  <Button
                    :name="$t('yes')"
                    type="gray"
                    @click="() => {clickAutoLocale(autoLocal)}"
                  />
                  <Button
                    :name="$t('no')"
                    type="gray"
                    @click="() => {autoLocal = null}"
                  />
                </div>
              </div>
            </div>
        </div>
        <div>
            <label for="date">{{$t('date_birth')}}</label>
              <DatePicker
                v-model="formData.birthday"
                v-model:validation="success.birthday"/>
        </div>
        <div>
            <label for="phone">{{$t('phone')}}</label>
            <Input
              id="phone"
              type="tel"
              v-model="formData.phone"
              v-model:validation="success.phone"/>
        </div>
        <div>
            <label for="email">Email</label>
            <Input
              type="email"
              id="email"
              v-model="formData.email"
              v-model:validation="success.email"/>
        </div>
        <div>
            <label for="password">{{$t('password')}}</label>
            <Input
              type="new-password"
              id="password" v-model="formData.password"
              v-model:validation="success.password"/>
        </div>
        <div>
            <label for="repeatPassword">{{$t('repeat_password')}}</label>
            <Input
              type="confirm-password"
              id="repeatPassword"
              v-model="formData.password_confirmation"
              v-model:validation="success.password_confirmation"
              :newPassword="formData.password"/>
        </div>
        <div class="checkboxBlock">
            <Checkbox
              v-model:validation="success.agreement"/>
            <div class="text">{{$t('i_have_read_and_agree_with')}} <a href="#">{{$t('terms_of_agreement')}}</a></div>
        </div>
        <Button
          :name="$t('register_button')"
          htmlType="submit"
          :hasError="success"/>
    </form>
  </div>
</Modal>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import Checkbox from '@/components/common/Checkbox.vue'
import DatePicker from '@/components/common/DatePickers/DatePicker.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'
import { defineProps, watch } from 'vue'

import { getCountries, getCities, getAutoLocal } from '@/actions/help'
import { postRegisterUser } from '@/actions/auth'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  showCompany: {
    type: Function,
    default: null
  },
  hide: {
    type: Function,
    default: null
  },
  showSendModal: {
    type: Function,
    default: null
  }
})
const formData = reactive({})
const success = reactive({
  name: false,
  surname: false,
  birthday: false,
  email: false,
  phone: false,
  password: false,
  password_confirmation: false,
  agreement: false
})
const loading = ref(false)
const optionsText = reactive(
  {
    country: '',
    city: ''
  }
)

// ====================  Api Country And City  ====================
const country = ref(null)
const city = ref(null)
const autoLocal = ref(null)

const fetchData = async () => {
  const response = (await getAutoLocal()).data.location
  if (response.country.id === 122) {
    autoLocal.value = response
  }
  country.value = (await getCountries()).data
  if (formData.country_id) city.value = (await getCities(formData.country_id)).data.cities
}
onMounted(
  async () => {
    if (props.show) {
      fetchData()
    }
  }
)
watch(
  () => formData.country_id,
  async () => {
    city.value = (await getCities(formData.country_id)).data.cities
  }
)

watch(
  () => props.show,
  () => {
    if (props.show) {
      fetchData()
    }
  }
)

// Click City And Country
const langLocale = localStorage.getItem('lang')
const clickAutoLocale = autoLocal => {
  formData.city_id = autoLocal.city.id
  formData.country_id = autoLocal.country.id
  if (langLocale === 'en') {
    optionsText.city = autoLocal.city.name_en
    optionsText.country = autoLocal.country.name_en
  } else {
    optionsText.city = autoLocal.city.name_ru
    optionsText.country = autoLocal.country.name_ru
  }
}

// ====================  REGISTER  ====================
async function registration () {
  loading.value = true
  try {
    await postRegisterUser(formData)
    props.hide()
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.modal{
  &__radioBtns{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;

    & button{
        height: auto;
        color: rgba(#212529, .7);
        font-size: 18px;
        line-height: 1.3;
        padding: 10px;
        cursor: pointer;
        border: .5px solid rgba(#212529, .7);
        transition: color .2s linear, background-color .2s linear;

        &.active{
            color: #fff;
            background-color: rgba(#212529, .7);
        }
    }
  }
  & label{
    color: rgba(#212529, .7);
    font-size: 16px;
    margin-top: 10px;
  }
  &__registerForm > div{
    display: grid;
    grid-template-columns: 140px 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }

  & .possibleCity{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 25px;
    margin-top: 13px;

    & .text{
      font-size: 16px;
      line-height: 1.2;
      color: rgba(33, 37, 41, 0.7);
    }

    & .btns{
      display: flex;
      grid-gap: 20px;
    }
  }

  & .checkboxBlock{
    display: flex !important;
    margin: 30px 0 40px;

    & .text{
      font-size: 16px;
      line-height: 1.2;
      color: rgba(33, 37, 41, 0.7);

      & a{
        color: #1D65F2;

        &:hover{
          text-decoration: underline;
        }
      }
    }

    & svg{
      display: block;
      width: 14px;
      height: 10px;

      opacity: 0;

      fill: none;
    }
  }
  @media (max-width: 768px){
    &__radioBtns{
      grid-template-columns: 1fr 1fr;
      margin-bottom: 25px;

      & button{
        font-size: 16px;
        padding: 8px;
      }
    }
    & label{
      font-size: 14px;
      margin-top: 8px;
    }
    &__registerForm > div{
      grid-template-columns: 120px 1fr;
      grid-gap: 15px;
      margin-bottom: 15px;
    }

    & .possibleCity{
        grid-gap: 12px;
        margin-top: 11px;
        & .text{
          font-size: 14px;
        }

        & .btns{
          grid-gap: 15px;
        }
    }

    & .checkboxBlock{
      margin: 25px 0 35px;

      & .text{
        font-size: 14px;
      }

      & svg{
        width: 12px;
        height: 9px;
      }
    }
  }
  @media (max-width: 576px){
    &__radioBtns{
      grid-template-columns: 1fr 1fr;
      margin-bottom: 20px;

      & button{
        font-size: 14px;
        padding: 7px;
      }
    }
    &__registerForm > div{
        grid-template-columns: 1fr;
        grid-gap: 8px;
        margin-bottom: 15px;
    }
    & label{
      font-size: 12px;
      margin-top: 0;
    }

    & .possibleCity{
        grid-gap: 10px;
        margin-top: 8px;
        & .text{
          font-size: 12px;
        }

        & .btns{
          grid-gap: 10px;
        }
    }

    & .checkboxBlock{
      margin: 15px 0 20px;

      & .text{
        font-size: 12px;
      }

      & svg{
        width: 10px;
        height: 6px;
      }
    }
  }
}
</style>
