<template>
  <Header />
  <section class="favorites searchVacanciesClient">
    <div class="container">
      <h3 class="section__title">{{$t('favourites')}}</h3>
      <div
        class="favorites__controller"
        :class="searchType"
      >
        <button
          class="favorites__btn"
          type="button"
          :class="{active: searchType === 'vacancies'}"
          @click="() => {searchType = 'vacancies', fetchData()}"
        >
          {{$t('vacancies')}}
        </button>
        <button
          class="favorites__btn"
          type="button"
          :class="{active: searchType === 'companies'}"
          @click="() => {searchType = 'companies', fetchData()}"
        >
          {{$t('companies')}}
        </button>
      </div>
      <!-- ===================== List Vacancies ================== -->
      <div v-if="searchType === 'vacancies'">
        <NothingHere v-if="data.vacancies && data.vacancies.length === 0"/>
        <ListVacancies
          v-else
          :vacancies="data.vacancies"
        />
        <Pagination
          v-if="data.vacancies"
          v-model="filter.vacancy.page"
          :perPage="filter.vacancy.per_page"
          :total="total"
          @newPage="() => {fetchData()}"
        />
      </div>
      <!-- ===================== List Companies ================== -->
      <div v-else>
        <NothingHere v-if="data.companies && data.companies.length === 0"/>
        <ListCompanies
          v-else
          :companies="data.companies"
        />
        <Pagination
          v-if="data.company"
          v-model="filter.company.page"
          :perPage="filter.company.per_page"
          :total="total"
          @newPage="() => {fetchData()}"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ListCompanies from '@/components/uiEmployee/Companies/Search/ListCompanies.vue'
import ListVacancies from '@/components/uiCompany/Vacancies/Search/ListVacancies.vue'

import { useRoute } from 'vue-router'
import router from '@/router/index.js'
import { onMounted, reactive, ref, watch } from 'vue-demi'
import { getFavoriteCompanies, getFavoriteVacancies } from '@/actions/employee'

const route = useRoute()
const searchType = ref('vacancies')
const data = reactive(
  {
    vacancies: null,
    companies: null
  }
)

const filter = reactive({
  vacancy: {
    per_page: 20,
    page: 1
  },
  company: {
    per_page: 20,
    page: 1
  }
})
const total = ref()

const fetchData = async () => {
  if (searchType.value === 'vacancies') {
    data.vacancies = null
    const response = (await getFavoriteVacancies(filter.vacancy)).data
    data.vacancies = response.vacancies.data
    total.value = response.vacancies.total
    router.push({ path: '/user/favorites/result', query: { type: searchType.value, page: filter.vacancy.page } })
  } else {
    data.companies = null
    const response = await getFavoriteCompanies(filter.company)
    data.companies = response.data
    total.value = response.total
    router.push({ path: '/user/favorites/result', query: { type: searchType.value, page: filter.company.page } })
  }
}
onMounted(
  () => {
    searchType.value = route.query.type ?? 'vacancies'
    if (searchType.value === 'vacancies') filter.vacancy.page = route.query.page ?? 1
    else filter.company.page = route.query.page ?? 1
    fetchData()
  }
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'Favorites') {
      filter.page = route.query.page ?? 1
      fetchData()
    }
  }
)
</script>

<style lang="scss">
.favorites{
  &__controller{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 60px;

    border-radius: 3px;
    position: relative;
    margin-bottom: 45px;
    & .favorites__btn:first-child{
      border-right: none;
    }
    & .favorites__btn:last-child{
      border-left: none;
    }
  }
  &__btn{
    font-size: 22px;
    color: #000;
    font-weight: 500;

    position: relative;
    z-index: 3;
    border: 3px solid #AD1717;
    transition: .2s linear;

    &.active{
      color: #fff;
      background-color: #AD1717;
    }
  }

  & .companySearch{
    &__items{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
    &__item{
      border: 0.5px solid #919599;
    }
  }
  & .vacancySearch__items .state span{
    display: none;
  }

  @media (max-width: 576px){
    &__controller{
      grid-template-rows: 50px;
      margin-bottom: 30px;
    }
    &__btn{
      font-size: 18px;
      border: 2px solid #AD1717;
    }
  }

  @media (max-width: 400px){
    &__controller{
      grid-template-columns: 1fr;
      grid-template-rows: 50px 50px;

      margin-bottom: 25px;
      & .favorites__btn:first-child{
        border: 2px solid #AD1717;
      }
      & .favorites__btn:last-child{
        border: 2px solid #AD1717;
      }
    }
  }

}
</style>
