<template>
<LayoutRow
  :title="$t('education')"
  v-model="formData.education_on"
>
  <RowEducation
    v-for="(education, i) of formData.educations"
    :key="education"
    :education="education"
    :helpers="helpers"
    @onDelete="() => {deleteEducation(i)}"
  />
  <Button
    :name="$t('add_more')"
    class="createResume__AddMore"
    type="darkGreen"
    @click="addEducation()"
  />
</LayoutRow>
</template>

<script setup>
import RowEducation from '@/components/uiEmployee/Resume/Edit/Education/RowEducation.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
formData.value.deleteEducations = []

// ===================     Checking   ===================
if (formData.value.educations.length !== 0) formData.value.education_on = true
else formData.value.education_on = false

watch(
  () => [formData.value, formData.value.education_on],
  () => {
    if (formData.value.education_on && formData.value.educations.length === 0) addEducation()
    emit('update:modelValue', formData.value)
  }
)

// ===================     Click Events    ===================
const addEducation = () => {
  formData.value.educations.push({
    education_type_id: null,
    graduated_with_honors: 0
  })
}

const deleteEducation = i => {
  if (formData.value.educations[i].id) formData.value.deleteEducations.push(formData.value.educations[i].id)
  formData.value.educations.splice(i, 1)
  if (formData.value.educations.length === 0) formData.value.education_on = false
}
</script>
