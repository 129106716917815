<template>
  <Header />
  <section class="detailVacancy">
    <div class="container" v-if="vacancy">

      <!-- ====================  Header Vacancy  ==================== -->
      <HeaderVacancy :vacancy="vacancy" />

      <!-- ====================  Position and Salary  ==================== -->
      <h2 class="section__title vacancy">{{vacancy.position}}</h2>
      <div
        class="price"
        v-if="vacancy.min_salary || vacancy.max_salary"
      >
        {{vacancy.min_salary ? formatToSalary(vacancy.min_salary) : ''}}
        {{vacancy.min_salary && vacancy.max_salary ? '-' : ''}}
        {{vacancy.max_salary ? formatToSalary(vacancy.max_salary) : ''}}
        {{` ${vacancy.currency?.title ?? ''}`}}
      </div>

      <!-- ====================  ОТКЛИКНУТЬСЯ / Добавить в избранные  ==================== -->
      <ButtonsVacancy
        v-if="userData === '3'"
        :vacancy="vacancy"
        v-model:favorite="vacancyState.favorite"
        v-model:invite="vacancyState.invite"
      />

      <!-- ====================  Description Vacancy  ==================== -->
      <DescriptionVacancy :vacancy="vacancy" :userData="userData === '3'" />

      <!-- ====================  Key Skills Vacancy  ==================== -->
      <KeySkills
        v-if="vacancy.key_skills.length !== 0"
        :keySkills="vacancy.key_skills"
      />

      <!-- ====================  Languages Vacancy  ==================== -->
      <Languages
        v-if="vacancy.languages.length !== 0"
        :languages="vacancy.languages"
      />

      <!-- ====================  Drive Certificates Vacancy  ==================== -->
      <DriveCertificates
        v-if="vacancy.driver_certificate_categories.length !== 0"
        :driveCertificates="vacancy.driver_certificate_categories"
      />

      <div class="vacancy__item">
        <p class="date">{{`${$t('published')} ${moment(vacancy.created_at).format('DD-MM-YYYY')}`}}</p>
      </div>

      <!-- ====================  ОТКЛИКНУТЬСЯ / Добавить в избранные  ==================== -->
      <ButtonsVacancy
        v-if="userData === '3'"
        :vacancy="vacancy"
        v-model:favorite="vacancyState.favorite"
        v-model:invite="vacancyState.invite"
      />
    </div>
    <div class="container" v-else>
      <div class="detailVacancy__header">
        <div class="detailVacancy__left" style="max-width:700px;">
          <div class="skeleton middle-text" style="width:150px;margin-bottom:10px"></div>
          <div class="skeleton middle-text" style="width:70%;margin-bottom:10px"></div>
          <div class="skeleton middle-text" style="width:50%;margin-bottom:10px"></div>
          <div class="skeleton middle-text" style="width:200px;margin-bottom:10px"></div>
        </div>
        <div class="detailVacancy__right">
          <div class="img skeleton skeleton-img"></div>
        </div>
      </div>
      <div class="section__title vacancy skeleton small-text" style="width:400px;max-width:100%"></div>
      <div class="price skeleton small-text" style="width:200px;max-width:100%"></div>
      <div v-for="el of 2" :key="el" style="margin-bottom:40px">
        <div class="skeleton middle-text" style="width:100px;max-width:100%;margin-bottom:10px"></div>
        <div class="skeleton middle-text" style="width:750px;max-width:100%;margin-bottom:10px" v-for="item of 5" :key="item"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import HeaderVacancy from '@/components/uiCompany/Vacancies/Detail/HeaderVacancy.vue'
import DescriptionVacancy from '@/components/uiCompany/Vacancies/Detail/DescriptionVacancy.vue'
import KeySkills from '@/components/uiCompany/Vacancies/Detail/KeySkills.vue'
import Languages from '@/components/uiCompany/Vacancies/Detail/Languages.vue'
import DriveCertificates from '@/components/uiCompany/Vacancies/Detail/DriveCertificates.vue'
import ButtonsVacancy from '@/components/uiEmployee/Vacancies/ButtonsVacancy.vue'

// import { apiUrl } from '@/api'
import { reactive, ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { onMounted } from 'vue-demi'
import { getSearchVacancyDetail } from '@/actions/employee'
import { getVacancyDetail } from '@/actions/company'
import { formatToSalary } from '@/helpers/formatSalary'
import moment from 'moment'

const vacancy = ref(null)
const route = useRoute()
const userData = localStorage.getItem('role_id')

const vacancyState = reactive(
  {
    favorite: false,
    invite: false
  }
)

onMounted(
  async () => {
    if (userData === '3') {
      vacancy.value = (await getSearchVacancyDetail(route.params.vacancy_id)).data
      vacancyState.favorite = vacancy.value.favorite_vacancies.length !== 0
      vacancyState.invite = vacancy.value.invite.length !== 0
    } else vacancy.value = (await getVacancyDetail(route.params.vacancy_id)).data
  }
)
</script>

<style lang="scss">
.detailVacancy{
  & .price{
    font-size: 24px;
    text-transform: uppercase;
    color: #212529;
    margin-bottom: 35px;
  }
  .vacancy{
    margin-bottom: 20px;

    &__item{
      margin-bottom: 40px;

      & .title{
        font-weight: bold;
        font-size: 16px;
        color: #212529;
      }
    }
  }
  @media (max-width: 576px){
    & .price{
      font-size: 22px;
      margin-bottom: 30px;
    }
    .vacancy{
      margin-bottom: 10px;
      font-size: 24px;
    }
  }
  @media (max-width: 480px){
    & .price{
      font-size: 18px;
      margin-bottom: 20px;
    }
    .vacancy{
      margin-bottom: 8px;
      font-size: 20px;
    }
  }
}
</style>
