<template>
<div
  class="companyVacancies"
>
  <div class="section__title">{{$t('vacancies_of_this_company')}}</div>
  <ListVacancies
    :vacancies="showAll ? vacancies : vacancies.slice(0, 3)"
  />

  <Button
    v-if="!showAll"
    class="companyVacancies__btn"
    :name="$t('select_show_all')"
    @click="() => {showAll = true}"
  />
</div>

</template>
<script setup>
import ListVacancies from '@/components/uiCompany/Vacancies/Search/ListVacancies.vue'

import { defineProps, ref } from 'vue'

defineProps({
  vacancies: {
    type: Array,
    default: () => []
  }
})

const showAll = ref(false)
</script>

<style lang="scss">
.companyVacancies{
  & .vacancySearch__items{
    margin-bottom: 40px;
  }
  &__btn{
    border: 1.5px solid #4D533C !important;
    background-color: transparent !important;
    box-shadow: none !important;
    margin: 0 auto;

    & span{
      color: #4D533C !important;
    }
    &:hover{
      background-color: #4D533C !important;
      & span{
        color: #fff !important;
      }
    }
  }
}
</style>
