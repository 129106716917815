<template>
  <footer class="footer">
    <div class="container">
        <div class="footer__inner">
            <div class="footer__block">
                <div class="footer__cols">
                    <div class="footer__col">
                        <div class="footer__title">{{$t('phone')}}</div>
                        <a href="tel:+77273110954" class="footer__text footer__link">+7 727 311-09-54</a>
                    </div>
                    <div class="footer__col">
                        <div class="footer__title">{{$t('address_title')}}</div>
                        <div class="footer__text">{{$t('address')}}</div>
                    </div>
                </div>
                <ul class="footer__nav">
                    <li><a :href="'/#stockAll'" class="footer__link">{{$t('sales')}}</a></li>
                    <li><a :href="'/#hotVacancies'" class="footer__link">{{$t('LastMinuteJobs')}}</a></li>
                    <li><a href="/history" class="footer__link">{{$t('aboutUs.title')}}</a></li>
                    <li><a :href="'/#services'" class="footer__link">{{$t('services.title')}}</a></li>
                    <li><router-link :to="'/news/result?page=1'" class="footer__link">{{$t('news')}}</router-link></li>
                    <li><router-link :to="'/contacts'" class="footer__link">{{$t('contacts')}}</router-link></li>
                </ul>
            </div>

            <div class="footer__copyright">
                <p>{{$t('copyright')}}</p>
            </div>
        </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  setup () {
    document.title = 'Altyn Adam Agency'
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.footer{
    background-color: #212529;

    &__inner{
        padding: 60px 0;
    }

    &__block{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #5D6544;
        padding-bottom: 60px;
    }

    &__cols{
        display: flex;
        justify-content: space-between;
    }

    &__col{
      max-width: 50%;
      margin-right: 80px;
    }

    &__link,
    &__title{
        color: #B6C197;
        font-size: 16px;
        line-height: 1.2;

        margin-bottom: 20px;
    }

    &__link,
    &__tel{
        display: inline-block;
        position: relative;

        &::before{
            content: "";
            display: block;
            width: 0;
            height: 2px;

            position: absolute;
            top: 100%;
            left: 0;

            background-color: #fff;

            transition: width .2s linear;
        }

        &:hover{
            &::before{
                width: 100%;
            }
        }
    }

    &__text{
        color: #fff;
        font-size: 14px;
        font-weight: 700;
    }

    &__nav{
      list-style-type: none;
      padding-left: 0;

      & li{
        white-space: nowrap;
      }

        & .footer__link{
            margin-bottom: 19px;

            &::before{
                background-color: #B6C197;
            }
        }
    }

    &__copyright{
        display: flex;
        justify-content: flex-end;

        padding: 25px 0 10px;

        & p{
            color: #B6C197;
            font-size: 14px;
            line-height: 1.2;
        }
    }
}
@media (max-width: 865px){
  .footer{
    &__cols{
        flex-wrap: wrap;
    }

    &__link,
    &__title{
      font-size: 14px;
      margin-bottom: 18px;
    }
    &__text{
      font-size: 12px;
    }
    &__copyright{
        padding: 22px 0 8px;
        & p{
            font-size: 12px;
        }
    }
  }
}
@media (max-width: 768px){
  .footer{
    &__inner{
      padding: 45px 0;
    }

    &__block{
      padding-bottom: 45px;
    }
  }
}
@media (max-width: 725px){
  .footer{
    &__cols{
      width: 70%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 45px 40px;
      margin-right: 50px;
    }

    &__col{
      max-width: none;
      margin-right: 0;
    }

    &__col:first-child{
      grid-row: 2;
    }

    &__col:nth-child(2){
      grid-column: 1/3;

      & p{
        max-width: 320px;
      }
    }
  }
}
@media (max-width: 530px){
  .footer{
    &__cols{
      width: auto;
      grid-template-columns: 1fr;
    }

    &__col{
      grid-column: 1;
    }
    &__link,
    &__title{
      font-size: 12px;
      margin-bottom: 16px;
    }
    &__text{
      font-size: 11px;
    }
    &__copyright{
        padding: 20px 0 6px;
        & p{
            font-size: 11px;
        }
    }
  }
}
@media (max-width: 445px){
  .footer{
    &__block{
      display: block;
    }

    &__cols{
      margin-right: 0;
      grid-gap: 35px 0;
      margin-bottom: 35px;
    }
    &__title{
      margin-bottom: 12px;
    }
  }
}
</style>
