<template>
  <div class="serviceDetail">
    <Header />
    <div class="bread_crumbs_bl">
        <div class="container">
            <ul class="bread_crumbs_bl__inner">
                <li><router-link :to="'/'">{{$t('home')}}</router-link></li>
                <li><a :href="'/#services'">{{$t('services.title')}}</a></li>
                <li>{{service.title ? service.title : ''}}</li>
            </ul>
        </div>
    </div>
    <section>
        <div class="container" v-if="service.title">
            <h3 class="section__title">{{service.title}}</h3>

            <div class="serviceDetail__block">
                <div class="serviceDetail__preview">
                    <div class="skeleton skeleton-img" v-if="!service"></div>
                    <img v-else :src="`${apiUrl}/${service.image}`" alt="">
                </div>
                <div class="serviceDetail__content">
                  <p>{{service.description}}</p>
                </div>
            </div>

            <div class="serviceDetail__table" v-html="service.text"></div>
        </div>

        <div class="container skeleton__block" v-else>
            <h3 class="section__title skeleton small-text" style="width:70%"></h3>
            <div class="serviceDetail__block">
                <div class="serviceDetail__preview skeleton">
                  <div class="img skeleton skeleton-img"></div>
                </div>
                <div class="serviceDetail__content">
                  <div>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:100%;height:" v-for="k of 7" :key="k"></p>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:80%;"></p>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:30%;"></p>
                  </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'
import { useRoute } from 'vue-router'
import { getServiceDetail } from '@/actions/home'
import { apiUrl } from '@/modules/api.js'

const route = useRoute()

const service = ref({})

onMounted(
  async () => {
    const response = await getServiceDetail(route.params.service_id)
    service.value = response
  }
)
</script>

<style lang="scss">
.serviceDetail{
  & ul{
      display: block;
      margin: 0;
  }

  & ul li {
      margin-bottom: 0 !important;
  }
  &__ulTitle,
  &__table > p:first-child,
  & ul li{
      color: rgba(#212529, .7);
      font-size: 16px;
      line-height: 1.6;
  }

  &__ulTitle,
  &__table > p:first-child {
      color: #212529;
      font-weight: 400;
      margin-bottom: 0;
  }

  &__table > p:first-child{
    margin-bottom: 20px;
  }

  &__block{
      display: grid;
      grid-template-columns: 420px 1fr;
      grid-gap: 30px;
      margin-bottom: 70px;
      align-items: start;

      & .serviceDetail__preview{
          position: relative;
          padding-bottom: 120%;
          overflow: hidden;

          & img{
            display: block;
            min-height: 100%;
            max-width: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #ededed;
          }
      }

      & .serviceDetail__content{
          padding: 0;

          & p{
              margin-bottom: 32px;
          }
      }
  }

  & table{
    width: 100% !important;
    display: block;
    border-collapse: collapse;
    margin-bottom: 70px;

    & p{
        margin-bottom: 20px;
    }

    & th,
    & td {
        color: rgba(#212529, .7);
        font-size: 16px;
        line-height: 1.5;
        padding: 20px;
        border: 1px solid #AD1717;
        vertical-align: top;
    }

    & th{
        font-weight: 600;
        color: #fff;
        padding: 12px 20px;
        background-color: #AD1717;
        border: 1px solid #AD1717;
        text-align: left;
    }

    & tr th:nth-child(1){
        width: 22.97%;
    }

    & tr th:nth-child(2){
        width: 28.37%;
    }

    & tr:nth-child(2n + 1){
        background-color: #FAF2F2;
    }

    & ul{
        margin: 0;
    }
  }
  @media (max-width: 992px){
    &__block{
      grid-template-columns: 220px 1fr;
      margin-bottom: 50px;
    }
    & ul{
      list-style-position: inside;
    }
  }
  @media (max-width: 768px){
    &__block{
      grid-template-columns: 1fr;
      grid-gap: 15px;
      margin-bottom: 30px;
    }
    &__preview{
      width: 245px;
      padding-bottom: 50% !important;
    }
    &__table > p:first-child {
      margin-bottom: 32px;
    }
    & table{
      & th{
        padding: 10px;
        font-size: 14px;
      }
      & td{
        padding: 12px;
      }
      & li,
      & td{
        font-size: 14px !important;
      }
    }
  }
  @media (max-width: 576px){
    &__block{
      margin-bottom: 20px;
    }
    &__table > p:first-child {
      margin-bottom: 20px;
    }
    & table{
      & th{
        padding: 6px;
        font-size: 12px;
      }
      & td{
        padding: 8px;
      }
      & li,
      & td{
        font-size: 12px !important;
      }
      & ul{
        padding-left: 16px;
      }
    }
  }
  @media (max-width: 480px){
    &__preview{
      width: 100%;
      padding-bottom: 50% !important;
    }
    & table{
      margin-bottom: 50px;
      & td{
        padding: 6px;
      }
      & li,
      & td{
        font-size: 8px !important;
      }
      & ul{
        padding-left: 10px;
      }
    }
  }
}
.recruiting__table{
    margin-bottom: 0;
}
</style>
<style scoped>
.skeleton__block .middle-text{
  height: 1.5em;
}
</style>
