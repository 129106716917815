<template>
<Header/>
<section class="editManagers">
  <div class="container">
      <h3 class="section__title">
        {{!route.params.manager_id ? $t('add_meneger') : $t('edit_meneger')}}
      </h3>

      <form
        class="editManagers__form"
        @submit.prevent="createOrEditManager(formData)"
        method="POST"
        v-if="country && (city || !route.params.manager_id)">
        <div>
            <label for="surname">{{$t('surname')}}:</label>
            <Input
              id="surname"
              v-model="formData.surname"
              v-model:validation="success.surename"
              required/>
        </div>
        <div>
            <label for="name">{{$t('name')}}:</label>
            <Input
              id="name"
              v-model="formData.name"
              v-model:validation="success.name"
              required/>
        </div>
        <div>
            <label for="patronymic">{{$t('patronymic')}}:</label>
            <Input
              id="patronymic"
              v-model="formData.patronym"/>
        </div>
        <div>
            <label for="phone">{{$t('phone')}}:</label>
            <Input
              id="phone"
              type="tel"
              v-model="formData.phone"
              :disabled="route.params.manager_id ? true : false"/>
        </div>
        <div>
            <label for="country">{{$t('country')}}:</label>
            <Select
              id="country"
              v-model="formData.country_id"
              v-model:validation="success.country"
              :options="country"/>
        </div>
        <div>
            <label for="city">{{$t('city')}}:</label>
            <Select
              id="city"
              v-model="formData.city_id"
              v-model:validation="success.city"
              :options="city"
              type="city"/>
        </div>
        <div>
            <label for="email">Email:</label>
            <Input
              type="email"
              v-model="formData.email"
              :disabled="route.params.manager_id ? true : false"/>
        </div>
        <div>
            <label for="password">{{$t('password')}}:</label>
            <Input
              v-if="route.params.manager_id"
              id="password"
              type="new-password"
              v-model="formData.password"
            />
            <Input
              v-else
              type="new-password"
              id="password"
              v-model="formData.password"
              v-model:validation="success.password"
              required
            />
        </div>

        <div>
            <label for="password_confirm">{{$t('repeat_password')}}:</label>
            <Input
              v-if="route.params.manager_id"
              type="confirm-password"
              id="repeatPassword"
              :newPassword="formData.password"
              v-model="formData.password_confirmation"
            />
            <Input
              v-else
              type="confirm-password"
              id="repeatPassword"
              :newPassword="formData.password"
              v-model="formData.password_confirmation"
              v-model:validation="success.password_confirmation"
              required
            />
        </div>

        <div>
            <div class="btns">
              <Button
                :name="!route.params.manager_id ? $t('create') : $t('edit')"
                htmlType="submit"
                :hasError="success" />
              <Button
                :name="$t('cancel')"
                type="gray"
                go="/company/common-data"/>
            </div>
        </div>
      </form>
      <div class="editManagers__form" v-else>
        <div v-for="item of 9" :key="item">
          <label class="skeleton middle-text"></label>
          <div class="skeleton">
            <Input disabled/>
          </div>
        </div>
      </div>
  </div>
  <Loading class="fixed" :show="loading"/>
</section>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'
import { onMounted, watch } from 'vue-demi'

import { getCountries, getCities } from '@/actions/help'
import { postManager, getManager, putManager } from '@/actions/company'

const route = useRoute()
const formData = ref({})
const success = ref({
  name: false,
  surename: false,
  country: false,
  city: false
})
const loading = ref(false)

// ====================  Api Country And City / Manager Data ====================
const country = ref(null)
const city = ref(null)
onMounted(
  async () => {
    country.value = (await getCountries()).data
    if (formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
    if (route.params.manager_id) formData.value = (await getManager(route.params.manager_id)).data.manager
    if (!route.params.manager_id && formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
    if (!route.params.manager_id) {
      success.value.password = false
      success.value.password_confirmation = false
    }
  }
)
watch(
  () => formData.value.country_id,
  async () => {
    city.value = (await getCities(formData.value.country_id)).data.cities
  }
)

// ====================  Api Create And Edit Manager  ====================
const createOrEditManager = async () => {
  try {
    loading.value = true
    if (!route.params.manager_id) await postManager(formData.value)
    else await putManager(route.params.manager_id, formData.value)
    router.push('/company/common-data')
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.editManagers{
  & label{
    font-size: 16px;
    line-height: 25px;
    color: rgba(33, 37, 41, 0.7);
  }

  &__form{
    & > div{
      max-width: 820px;
      display: grid;
      grid-template-columns: 310px 1fr;
      grid-gap: 20px;
      align-items: center;
      margin-bottom: 20px;
    }

    & .btns{
      grid-column: 2/3;
      display: flex;
      grid-gap: 20px;
    }
  }

  @media (max-width: 992px){
    &__form > div{
      grid-template-columns: 180px 1fr;
    }
  }
  @media (max-width: 768px){
    &__form > div{
      grid-template-columns: 150px 1fr;
    }
  }
  @media (max-width: 568px){
    &__form > div{
      grid-template-columns: 1fr;
      grid-gap: 6px;
    }
  }
}
</style>
