import { filterMultiSelect } from '@/helpers/filterMultiSelect'
import { deleteResumeAward, deleteResumeLanguage, deleteResumeCourse, deleteResumeEducation, deleteResumeExperience } from '@/actions/employee'

// ====================  FILTER RESUME  ====================

export const filterResume = resume => {
  const data = resume
  //  =======   Willing To Relocate - Гражданство
  if (data.willing_to_relocate.length !== 0) data.willing_to_relocate = filterMultiSelect(data.willing_to_relocate)

  //  =======   Experiences - Опыт работы
  if (!data.experience_on) {
    data.experiences.forEach(async (experience) => {
      data.deleteExperiences.push(experience.id)
    })
    data.experiences = []
  }
  data.deleteExperiences.forEach(async (experienceId) => {
    await deleteResumeExperience(data.id, experienceId)
  })

  //  =======   Educations - Образование
  if (!data.education_on) {
    data.educations.forEach(async (education) => {
      data.deleteEducations.push(education.id)
      if (education.graduated_with_honors === false) education.graduated_with_honors = 0
      else education.graduated_with_honors = 1
    })
    data.educations = []
  }
  data.deleteEducations.forEach(async (educationId) => {
    await deleteResumeEducation(data.id, educationId)
  })

  //  =======   Courses - Курсы, тренинги, семинары
  if (!data.courses_on) {
    data.courses.forEach(async (course) => {
      data.deleteCourses.push(course.id)
    })
    data.courses = []
  }
  data.deleteCourses.forEach(async (courseId) => {
    await deleteResumeCourse(data.id, courseId)
  })

  //  =======   Ownership PC - Владение ПК
  if (!data.ownershipPC_on) {
    data.computer_skills = null
    data.computer_skills_info = null
  }

  //  =======   Languages - Знание Языков
  data.languages.forEach(element => {
    if (element.language_id === 0) data.languages.splice(data.languages.indexOf(element), 1)
  })
  if (!data.languages_on) {
    data.languages.forEach(async (lang) => {
      data.deleteLanguages.push(lang.id)
    })
    data.languages = []
  }
  data.deleteLanguages.forEach(async (langId) => {
    await deleteResumeLanguage(data.id, langId)
  })

  //  =======   Awards - Награды И Сертификаты
  if (!data.award_on) {
    data.awards.forEach(async (award) => {
      data.deleteAward.push(award?.id)
    })
    data.awards = []
  }
  data.deleteAward.forEach(async (awardId) => {
    await deleteResumeAward(data.id, awardId)
  })

  //  =======   Hobbies - Награды, грамоты
  if (!data.hobbies_on) data.interests = null

  //  =======   Personal Skills - Личные качества
  if (!data.personalSkills_on) data.personal_skills = null

  //  =======   Availability Car - Наличие Авто
  if (!data.availabilityCar_on) {
    data.has_auto = null
    data.has_driver_certificate = null
    data.driver_certificate_categories = []
  } else if (data.driver_certificate_categories.length !== 0) {
    data.driver_certificate_categories = filterMultiSelect(data.driver_certificate_categories)
  }

  //  =======   Description_on - Дополнительно о себе
  if (!data.description_on) data.description = null
  return data
}
