<template>
  <header id="header" class="header" :class="{main: fromMain, login: userLogin}">
    <div class="header__top">
        <div class="container">
            <div class="left">
                <router-link to="/" class="header__logo">
                    <svg class="logo">
                        <use xlink:href="/img/tmp/sprite.svg#logo"></use>
                    </svg>
                    <span>Altyn Adam Agency</span>
                </router-link>

                <a href="https://www.facebook.com/profile.php?id=100006684453958" class="header__socialNetwork" target="_blank">
                    <svg>
                        <use xlink:href="/img/tmp/sprite.svg#facebook"></use>
                    </svg>
                </a>
                <a href="https://www.instagram.com/agencyaltynadam/" class="header__socialNetwork" target="_blank">
                    <svg>
                        <use xlink:href="/img/tmp/sprite.svg#instagram"></use>
                    </svg>
                </a>
                <a href="https://www.linkedin.com/company/altyn-adam-agency" class="header__socialNetwork" target="_blank">
                    <svg>
                        <use xlink:href="/img/tmp/sprite.svg#linkedIn"></use>
                    </svg>
                </a>
            </div>
            <div class="right">
                <SwitchLanguage />
                <DropTemplate
                  @showLogin="() => {showModal.login = true, showModal.bg = true}"
                  @search="onShowSearch"
                  @createVacancy="goCreateVacancy"
                />

                <button class="nav-burger" type="button" @click="navShow ? navShowFunc(false) : navShowFunc(true)">
                    <svg v-if="!navShow">
                        <use xlink:href="/img/tmp/sprite.svg#menu"></use>
                    </svg>
                    <svg class="close" v-if="navShow">
                        <use xlink:href="/img/tmp/sprite.svg#close"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>
    <NavTemplate
      v-model:showSearchInput="showSearchInput"
      :loading="loading"
      @search="onShowSearch"
      @createVacancy="goCreateVacancy"
    />
  </header>
  <Authorization :hideModals="() => {hideModals()}" v-model="showModal"/>
  <Modal
    :title="$t('message.first_but_a_package_to_create_a_vacancy')"
    :btnTitle="$t('buy')"
    :hide="()=>{question = false}"
    :show="question"
    :agreement="() => {router.push('/company/services')}"
    quastion
  />
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import NavTemplate from '@/components/uiLanding/layouts/Header/Navs/NavTemplate.vue'
import DropTemplate from '@/components/uiLanding/layouts/Header/Drops/DropTemplate.vue'
import SwitchLanguage from '@/components/common/Selects/SwitchLanguage.vue'
import Authorization from '@/components/Authorization/Authorization.vue'

import router from '@/router/index.js'
import { reactive, ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { defineProps } from 'vue'

import { countManagersAndVacancies } from '@/helpers/countManagersAndVacancies'

defineProps({
  fromMain: {
    type: Boolean,
    default: false
  }
})

const showModal = reactive({
  bg: false,
  emailVerified: false,
  registerCompany: false,
  registerClient: false,
  login: false,
  forgotPassword: false,
  sendMessage: false,
  passwordResetSuccesfully: false
})
const navShow = ref(false)
const route = useRoute()

const navShowFunc = (bool) => {
  const header = document.querySelector('header.header')
  if (!bool) {
    header.classList.remove('showNav')
    navShow.value = false
  } else {
    header.classList.add('showNav')
    navShow.value = true
  }
}

if (route.params.from === 'login') showModal.login = true
else if (route.params.from === 'registration-client') showModal.registerClient = true
else if (route.params.from === 'registration-company') showModal.registerCompany = true
else if (route.params.from === 'email-verified') showModal.emailVerified = true
else if (route.params.from === 'password-reset-succesfully') showModal.passwordResetSuccesfully = true

const hideModals = () => {
  Object.keys(showModal).forEach(function (key) {
    showModal[key] = false
  })
}

// User Login Or No
const userData = localStorage.getItem('role_id')
const userLogin = ref(false)
if (userData === '3' || userData === '4' || userData === '5' || userData === '2') {
  userLogin.value = true
}

// Create Vacancy
const question = ref(false)
const loading = ref(false)
const goCreateVacancy = async () => {
  loading.value = true
  try {
    const countVacancy = (await countManagersAndVacancies()).availableVacancies
    if (countVacancy > 0) router.push('/company/edit-vacancy')
    else question.value = true
  } finally {
    loading.value = false
  }
}

// Search Event
const showSearchInput = ref(false)
const onShowSearch = () => {
  showSearchInput.value = true
  setTimeout(x => {
    document.querySelector('input.search__input').focus()
  }, 50)
}
</script>

<style lang="scss">
@import '@/assets/css/Header/HeaderLogo.scss';
@import '@/assets/css/Header/HeaderSocialNetworks.scss';
@import '@/assets/css/Header/NavBurger.scss';

.header{
  position: relative;
  z-index: 15;
  margin-bottom: 35px;
  transition: margin-bottom .4s linear;

  &.showNav{
    margin-bottom: 70px;
    & .header__top{
      background-color: #4D533C !important;
    }
  }

  &__top{
    padding: 10px 0;
    background-color: #4F553D;
    position: relative;
    z-index: 10;
    transition: background-color .2s linear;
    & .container{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  & .right,
  & .left{
    display: flex;
    align-items: center;
  }
  & .right{
    grid-gap: 20px;
  }

  @media (max-width: 768px){
    &__top{
      padding: 8px 0;
    }
    & .right{
      grid-gap: 16px;
    }
    &.login .right{
      grid-gap: 20px;
    }
  }
}
</style>
