import api from '../modules/api'

export const getHistory = () =>
  api.asyncGet('/v1/histories', {}, false, true)

export const getTeam = (page, filters, per_page = 20) =>
  api.asyncGet('/v1/team/leadership', { page, ...filters, per_page }, false, true)

export const getPartners = () =>
  api.asyncGet('/v1/partners', {}, false, true)

export const getPrinciples = () =>
  api.asyncGet('/v1/advantages', {}, false, true)

export const getCertificates = () =>
  api.asyncGet('/v1/certificates', {}, false, true)
