<template>
<transition name="fade" :class="fixed? 'fixed' : ''">
  <div class="loading" v-if="show">
    <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </div>
</transition>
</template>

<script setup>
import { defineProps, onMounted, onUnmounted } from 'vue'
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  fixed: {
    type: Boolean,
    default: false
  }
})
onMounted(() => {
  if (props.show && props.fixed) {
    document.querySelector('body').classList.add('no-scroll')
  }
})
onUnmounted(() => {
  document.querySelector('body').classList.remove('no-scroll')
})
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.loading{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(#fff, .8);
  backdrop-filter:blur(5px);

  &.fixed{
    position: fixed;
    height: 100vh;
    z-index: 10000;
  }
}

$offset: 167;
$duration: 1.8s;

.spinner {
  display: block;
  height: 150px;
  width: 150px;
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-width: 4px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash $duration ease-in-out infinite,
    colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
0% { stroke: #4285F4; }
25% { stroke: #DE3E35; }
50% { stroke: #F7C223; }
75% { stroke: #1B9A59; }
100% { stroke: #4285F4; }
}

@keyframes dash {
 0% { stroke-dashoffset: $offset; }
 50% {
   stroke-dashoffset: $offset/4;
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }
}
</style>
