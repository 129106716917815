<template>
  <div>
    <Header />
    <div class="bread_crumbs_bl">
        <div class="container">
            <ul class="bread_crumbs_bl__inner">
                <li><router-link to="/">{{$t('home')}}</router-link></li>
                <li><a :href="'/#services'">{{$t('services.title')}}</a></li>
                <li>{{$t('outsourcing_personnel')}}</li>
            </ul>
        </div>
    </div>

    <section class="outsourcingPersonnel">
        <div class="container">
            <h3 class="section__title">{{$t('outsourcing_personnel')}}</h3>
            <div class="links" v-if="outsourcingPersonnel">
                <router-link v-for="item of outsourcingPersonnel" :key="item" :to="'/services/outsourcing-personnel/' + item.id" class="link">
                    <div class="title">{{item.title}}</div>
                    <div class="btn">
                        <span>{{$t('services.button')}}</span>
                        <svg class="arrow">
                            <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
                        </svg>
                    </div>
                </router-link>
            </div>

            <div class="links" v-else>
                <div v-for="item of 4" :key="item" class="link skeleton__block">
                    <div class="title">
                      <div class="skeleton small-text" style="width: 100%; margin-bottom: 10px"></div>
                      <div class="skeleton small-text" style="width: 80%"></div>
                    </div>
                    <div class="btn skeleton small-text"></div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script setup>
import { getOutsourcingPersonnel } from '@/actions/home'
import { onMounted, ref } from 'vue-demi'

const outsourcingPersonnel = ref(null)

onMounted(
  async () => {
    outsourcingPersonnel.value = await getOutsourcingPersonnel()
  }
)
</script>

<style lang="scss" scoped>
.outsourcingPersonnel{
    & .links{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }

    & .link{
        min-height: 223px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 50px 20px 36px;
        text-align: center;
        background-color: #D27F7F;
        transition: background-color .2s linear;

        &:hover{
            background-color: #AD1717;

            & .btn{
                & svg{
                    transform: translateX(10px);
                }
            }
        }
    }

    & .title{
        color: #fff;
        font-size: 24px;
        line-height: 1.2;
    }

    & .btn{
        color: #fff;
        font-size: 16px;
        line-height: 1.6;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        & span{
            margin-right: 28px;
            cursor: pointer;
        }

        & svg{
            display: block;
            width: 10px;
            height: 18px;
            fill: #fff;

            transition: transform .2s linear;
        }
    }
}
@media (max-width: 992px){
    .outsourcingPersonnel{
        & .links{
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }
        & .link{
            min-height: 140px;
            padding: 33px 19px 29px;
        }
        & .title{
            margin-bottom: 34px;
        }
    }
}
@media (max-width: 768px){
    .outsourcingPersonnel{
        & .links{
            grid-gap: 15px;
        }
        & .link{
            padding: 20px;
        }
        & .title{
            font-size: 20px;
        }
    }
}
@media (max-width: 576px){
    .outsourcingPersonnel{
        & .link{
            padding: 15px;
        }
        & .title{
            font-size: 18px;
        }
    }
}

.skeleton__block{
    background-color: #fff !important;
    border: 0.5px solid;
  & .title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
