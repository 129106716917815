<template>
  <form
    :class="'search ' + typeInput"
    @submit.prevent="() => {typeInput ? $emit('search') : search()}"
  >
    <label class="search__icon" for="search" v-if="!typeInput">
        <svg>
            <use xlink:href="/img/tmp/sprite.svg#search"></use>
        </svg>
    </label>
    <input class="search__input" type="text" v-model="formData">

    <div class="search__select" v-if="!typeInput">
        <div class="search__selected" @click="showOptions = !showOptions">
            <span>{{ selectValue }}</span>
            <svg>
                <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
            </svg>
        </div>
        <transition name="drop">
            <div class="search__drop" v-if="showOptions">
              <div v-for="option of options" :key="option">
                <div class="search__option" v-if="selectValue !== option" @click="selectValue = option, showOptions = false">{{option}}</div>
              </div>
            </div>
        </transition>
    </div>

    <button
      class="search__btn"
      v-if="typeInput"
      htmlType="submit"
    >
      <svg>
          <use xlink:href="/img/tmp/sprite.svg#search"></use>
      </svg>
    </button>
    <Modal
      :title="$t('message.you_first_need_to_login')"
      :show="quastion"
      :hide="()=>{quastion = false}"
      :agreement="() => {router.push('/authorization/login')}"
      :btnTitle="$t('authorization_button')"
      quastion
    />
  </form>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'

import { useI18n } from 'vue-i18n'
import router from '@/router/index.js'
import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  typeInput: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'search'])
const i18n = useI18n()

const showOptions = ref(false)

const userData = localStorage.getItem('role_id')
const options = ref([i18n.t('resumes'), i18n.t('vacancies'), i18n.t('companies')])

if (userData === '4' || userData === '5') options.value = [i18n.t('resumes')]
else if (userData === '3') options.value = [i18n.t('vacancies'), i18n.t('companies')]

const selectValue = ref(options.value[0])
const formData = ref(props.modelValue)

const quastion = ref(false)

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)

const search = () => {
  if (selectValue.value === options.value[2] && userData === '3') router.push('/user/search-company/result?page=1&type=false&country&city&name=' + formData.value)
  else if (selectValue.value === options.value[1] && userData === '3') router.push(`/user/search-vacancy/result?search=${formData.value}&type&period=month&country=0&salary_type=0&page=1`)
  else if (selectValue.value === options.value[0] && (userData === '4' || userData === '5')) router.push(`/company/search-resumes/result?search=${formData.value}&type=date_asc&period=month&salary_type=0&gender&country&page=1`)
  else quastion.value = true
}
</script>

<style lang="scss" scoped>
.search{
  width: 100%;
  max-width: 600px;
  display: flex;
  background-color: #fff;
  position: relative;
  border: 0.5px solid rgba(33, 37, 41, 0.7);

  &.true{
    justify-content: space-between;
    & .search__input{
      width: 100%;
    }
  }

  &__btn,
  &__icon{
    padding: 13px 20px;
    & svg{
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  &__icon{
    padding-right: 0;
  }

  &__btn{
    cursor: pointer;
    background: #AD1717;
    & svg{
      fill: #fff;
    }
  }

  &__input{
    width: 68%;
    color: #000;
    font-size: 16px;
    font-weight: 500;

    padding: 13px 20px;
    min-width: auto;
  }

  &__select{
    display: flex;
    align-items: center;
    cursor: pointer;

    position: absolute;
    right: 0;
    top: 0;

    height: 100%;
    background-color: #AD1717;

    &:hover{
      & svg{
        transform: rotate(270deg);
      }
    }
  }

  &__drop{
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    & .search__option:last-child{
        margin-bottom: 0;
    }
  }

  &__option{
    padding: 12px 20px;
    cursor: pointer;
    background-color: #AD1717;

    transition: all .2s linear;

    &:hover{
      background-color: #fff;
      color: #AD1717;
    }
  }

  &__selected,
  &__option{
    color: #fff;
    font-size: 16px;
    line-height: 1.6;
    cursor: pointer !important;
  }

  &__selected{
    min-width: 155px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    & span{
      margin-right: 30px;
      cursor: pointer;
    }

    & svg{
      height: 16px;
      width: 8px;
      fill: #fff;

      transform: rotate(90deg) translateX(2px);
      transition: transform .2s linear;
    }
  }

  // Adaptive
  @media (max-width: 1200px){
    max-width: 580px;
    &__btn,
    &__icon{
      padding: 11px 16px;
      & svg{
        width: 18px;
        height: 18px;
      }
    }
    &__icon{
      padding-right: 0;
    }
    &__input{
      font-size: 14px;
      padding: 12px 18px;
    }

    &__option{
      padding: 11px 18px;
    }

    &__selected,
    &__option{
      font-size: 14px;
    }

    &__selected{
      min-width: 145px;
      padding: 0 18px;
      & span{
        margin-right: 24px;
      }

      & svg{
        height: 14px;
        width: 6px;
      }
    }
  }
  @media (max-width: 992px){
    max-width: 580px;
    &__btn,
    &__icon{
      padding: 11px 16px;
      & svg{
        width: 16px;
        height: 16px;
      }
    }
    &__icon{
      padding-right: 0;
    }
    &__input{
      font-size: 12px;
      padding: 11px 16px;
    }

    &__option{
      padding: 10px 16px;
    }

    &__selected,
    &__option{
      font-size: 12px;
    }

    &__selected{
      min-width: 130px;
      padding: 0 16px;
      & span{
        margin-right: 22px;
      }

      & svg{
        height: 14px;
        width: 6px;
      }
    }
  }
  @media (max-width: 576px){
    max-width: 100%;
    &__btn,
    &__icon{
      & svg{
        width: 14px;
        height: 14px;
      }
    }
    &__icon{
      padding-right: 0;
    }
    &__input{
      font-size: 11px;
      padding: 11px 14px;
    }

    &__option{
      padding: 10px 14px;
    }

    &__selected,
    &__option{
      font-size: 11px;
    }

    &__selected{
      min-width: 110px;
      padding: 0 14px;
      & span{
        margin-right: 20px;
      }

      & svg{
        height: 13px;
        width: 5px;
      }
    }
  }

  @media (max-width: 400px){
    &__btn,
    &__icon{
      & svg{
        width: 12px;
        height: 12px;
      }
    }
    &__input{
      padding: 9px 12px;
    }

    &__option{
      padding: 9px 10px;
    }

    &__selected,
    &__option{
      font-size: 10px;
    }

    &__selected{
      min-width: 100px;
      padding: 0 12px;
      & span{
        margin-right: 18px;
      }

      & svg{
        height: 10px;
        width: 4px;
      }
    }
  }
}

// Animate
.drop-enter-active,
.drop-leave-active {
   transition: all.2s linear;
}
.drop-enter-from,
.drop-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
</style>
