<template>
<div class="form__item">
  <div class="form__itemHeader">
    <div class="form__title">{{title}}</div>
    <Toggle v-model="showContent" typeToggle/>
  </div>
  <slot v-if="showContent" />
</div>
</template>

<script setup>
import Toggle from '@/components/common/Toggle.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Example Title'
  }
})
const emit = defineEmits('update:modelValue')

const showContent = ref(props.modelValue)
watch(
  () => showContent.value,
  () => {
    emit('update:modelValue', showContent.value)
  }
)
watch(
  () => props.modelValue,
  () => {
    showContent.value = props.modelValue
  }
)
</script>
<style scoped>
.form__itemHeader{
  display: flex;
  align-items: center;
  grid-gap: 20px;
  margin-bottom: 20px;
}
</style>
