<template>
<section class="slider">
  <div class="container" v-if="years">
    <div class="slider__header">
      <h3 class="section__title" v-html="$t('company_development_stages')"></h3>
      <div class="slider__dates">
        <div class="slider__years">
          <div  v-for="(year, i) of years" :key="i" class="slider__year">
            <button :class="'dot ' + (i <= saleLength? 'active' : '')" @click="sliderYear(i)"><span>{{ deleteYear(getKey(year)) }}</span></button>
          </div>
          <div class="slider__progressBar"><span></span></div>
        </div>
      </div>
    </div>
    <div class="slider__content">
      <div class="slider__slides">
        <div v-for="(year, i) of years" :key="deleteYear(getKey(year))"  :class="'slider__slide slide_id-'+ i + ' ' + (i == saleLength? 'show' : '')">
          <div>
            <p>{{getKey(year)}}</p>
            <p v-html="year[getKey(year)]"></p>
          </div>
          <div class="slider__preview">
            <img v-if="i % 2 === 1" src="/img/tmp/sale/01.jpg" alt="">
            <img v-else src="https://i.pinimg.com/564x/9e/e4/f9/9ee4f983bab311820388c9e8a3d1272b.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="slider__btns">
        <button class="slider__btn" @click="historySliderPrev()">
          <svg class="arrow">
              <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
          </svg>
          <svg class="arrow">
            <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
          </svg>
        </button>
        <button class="slider__btn" @click="historySliderNext()">
          <svg class="arrow">
            <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
          </svg>
          <svg class="arrow">
            <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <div class="container sceleton__block" v-else>
    <div class="slider__header">
      <h3 class="section__title" v-html="$t('company_development_stages')"></h3>
      <div class="slider__dates">
        <div class="slider__years">
          <div  v-for="item of 15" :key="item" class="slider__year">
            <div class="dot"></div>
          </div>
          <div class="slider__progressBar"><span></span></div>
        </div>
      </div>
    </div>
    <div class="slider__content">
      <div class="slider__slides">
        <div class="slider__slide show">
          <div>
            <p class="skeleton middle-text" style="margin-bottom:20px;width:100px;"></p>
            <p class="skeleton middle-text" style="margin-bottom:10px;width:100%;" v-for="k of 7" :key="k"></p>
            <p class="skeleton middle-text" style="margin-bottom:10px;width:80%;"></p>
            <p class="skeleton middle-text" style="margin-bottom:10px;width:30%;"></p>
          </div>
          <div class="slider__preview skeleton skeleton-img">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script setup>
import { getHistory } from '@/actions/aboutUs'
import { onMounted, ref } from '@vue/runtime-core'
const years = ref(null)
onMounted(
  async () => {
    const response = await getHistory()
    years.value = response.data
    years.value.splice(15, 15)
    saleMaxLength.value = years.value.length - 1
    setHistorySlider()
  }
)
const getKey = obj => Object.keys(obj)[0]
const deleteYear = String => String.replace(' год', '')

const saleLength = ref(0)
const saleMaxLength = ref(1)
let historyTimer
const setHistorySlider = () => {
  clearInterval(historyTimer)
  historyTimer = setInterval(() => {
    historySliderNext()
  }, 20000)
}
const historySliderNext = () => {
  if (saleLength.value === saleMaxLength.value) {
    saleLength.value = 0
  } else {
    saleLength.value++
  }
  setHistorySlider()
}
const historySliderPrev = () => {
  if (saleLength.value === 0) {
    saleLength.value = saleMaxLength.value
  } else {
    saleLength.value--
  }
  setHistorySlider()
}
const sliderYear = (year) => {
  saleLength.value = year
  setHistorySlider()
}
</script>
<style lang="scss" scoped>
.dot{
  width: 14px;
  height: 14px;

  display: flex;
  justify-content: center;

  background-color:#D3D3D4;
  border-radius: 100px;
  position: relative;
  transition: border-color .2s linear;

  &::after{
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 100px;
      border: 1px solid #D3D3D4;

      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      transition: background-color .2s linear;
  }

  &.active{
    background-color: #AD1717;
    &::after{
        border-color: #AD1717;
        background-color:#fff;
    }
  }
}
.slider{
    &__header{
        position: relative;
        overflow: hidden;
    }

    &__dates{
        padding: 35px 0 25px;
        margin-bottom: 40px;
    }

    &__years{
        display: flex;
        position: relative;
        justify-content: space-around;
    }

    &__year{
        & button{
          &::before{
            content: "";
            display: block;
            height: 1px;
            width: 100px;

            opacity: 0;

            position: absolute;
            top: 50%;
            right: 100px;
            z-index: -2;
            background-color: #AD1717;
            transition: all .2s linear;
          }
          & span{
              font-size: 16px;
              line-height: 46px;
              color: rgba(33, 37, 41, 0.7);

              position: relative;
              transform: translateY(-45px);
          }
          &.active{
            &::before{
              right: 0;
              opacity: 1;
            }
          }
        }
    }

    &__progressBar{
        width: 100%;
        height: 1px;

        position: absolute;
        top: 50%;
        z-index: -5;
        transform: translateY(-50%);
        background-color: #D3D3D4;
    }

    &__content{
        padding: 0 65px;
        position: relative;
    }

    &__slides{
        position: relative;
    }

    &__slide{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;

        position: absolute;
        top: 0;

        & p{
            position: relative;
            z-index: 100;
            opacity: 0;
            transform: translateY(70px);

            transition: all, 0.9s;
        }

        &.show{
            position: relative;
            z-index: 10;

            & .slider__preview{
                opacity: 1 !important;
            }

            & p{
                transform: translateY(0) !important;
                opacity: 1 !important;
            }
        }
    }

    &__preview{
        width: 100%;
        padding-bottom: 69.5%;
        overflow: hidden;

        position: relative;
        z-index: 100;
        opacity: 0;
        transition: opacity 1.1s linear;

        & img{
            display: block;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            transform: translate3d(0, -50%, 0);
        }
    }

    &__btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 20px 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);

        & .slider__btn:first-child{
            transform: rotate(180deg);
        }
    }

    &__btn{
        display: block;
        height: 24px;
        position: relative;
        cursor: pointer;

        & svg{
            display: block;
            width: 13.33px;
            height: 24px;

            fill: #212529;

            transition: all .2s linear;
        }

        & svg:last-child{
            width: 8.73px;
            height: 15.72px;
            position: absolute;
            top: 50%;
            opacity: 0;
            transform: translate3d(0, -50%, 0);

            transition: all .2s linear;
        }

        &:hover svg{
            fill: #AD1717;
        }

        &:hover svg:last-child{
            opacity: 1;
            transform: translate3d(12px, -50%, 0);
        }
    }
    &__head{
      display: flex;
      justify-content: space-between;
    }
    &__headBtns{
      display: flex;
      align-items: center;
      grid-gap: 50px;
      & .slider__btn:first-child{
        transform: rotate(180deg);
      }
    }
}

.sceleton__block{
  pointer-events: none;
}
@media (max-width: 992px){
  .slider{
    &__dates{
      width: 100%;
      overflow-x: auto;
      margin-bottom: 35px;
    }
    &__years{
      width: 900px;
    }
    &__content{
      padding: 0 15px;
    }
    &__slide{
      grid-gap: 25px;
    }
    &__btn{
      position: relative;
    }
    &__btns{
      & .slider__btn:first-child{
        left: -15px;
      }
      & .slider__btn:last-child{
        right: -15px;
      }
    }
  }
}
@media (max-width: 768px){
  .slider{
    &__slide{
      grid-template-columns: 1fr;
    }
    &__content{
      padding: 0;
    }
    &__btns{
      margin: 0 auto;
      width: 60px;
      position: static;
      transform: none;
    }
  }
}
</style>
