<template>
<div class="connectedServices__item">
  <div class="top">
    <div class="left">
      <div class="name">{{service.package.title}}</div>
      <div class="info" v-if="service.available_vacancies_count !== null">{{`${$t('number_of_available_vacancies')}(${service.available_vacancies_count})`}}</div>
      <div class="info" v-if="service.available_managers_count !== null">{{`${$t('number_of_available_managers')}(${service.available_managers_count})`}}</div>
    </div>
    <div class="right">
      <div class="price">{{`${formatToSalary(service.package.price)} ${service.package.currency.title}`}}</div>

      <div class="state" :class="{disabled: daysLeft <= 0}">
        <div class="icon" v-if="daysLeft > 0">
          <svg>
            <use xlink:href="/img/tmp/sprite.svg#checkbox"></use>
          </svg>
        </div>
        <div class="text">{{daysLeft > 0 ? $t('connected') : $t('disabled')}}</div>
      </div>

    </div>
  </div>
  <div class="bottom">
    <div class="deadline" v-if="daysLeft > 0">{{`${$t('left_days')}: ${daysLeft}`}}</div>
    <div class="date">{{serviceUpdateDate}}</div>
  </div>
</div>
</template>

<script setup>
import { formatToSalary } from '@/helpers/formatSalary.js'

import moment from 'moment'
import { defineProps } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  service: {
    type: Object,
    default: () => {}
  }
})
const i18n = useI18n()

// Example Results => 19 Апрель 2022
const serviceUpdateDate = `${moment(props.service.created_at).format('DD')} ${i18n.t('months.' + moment(props.service.created_at).format('M'))} ${moment(props.service.created_at).format('YYYY')}`
const connectedDate = moment(props.service.created_at)
const endDate = moment([moment(connectedDate).format('YYYY'), Number(moment(connectedDate).format('M')) - 1, moment(connectedDate).format('DD')]).add(props.service.package.date, 'days')
const today = moment([moment().format('YYYY'), Number(moment().format('M')) - 1, moment().format('DD')])

const daysLeft = endDate.diff(today, 'days')
</script>

<style lang="scss">
.connectedServices__item{
  border: .5px solid rgba(#212529, .7);
  padding: 20px 30px;
  & .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;

    & .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  & .name {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    color: #212529;
    margin-bottom: 15px;
  }
  & .info {
    font-size: 16px;
    color: rgba(33, 37, 41, 0.7);
    margin-bottom: 4px;
  }

  & .price {
    font-size: 24px;
    text-transform: uppercase;
    color: #212529;
    margin-bottom: 15px;
  }
  & .state {
    display: flex;
    align-items: center;
    &.disabled .text{
      color: #AD1717;
    }
    & .icon {
      width: 16px;
      height: 16px;
      overflow: hidden;
      border-radius: 100px;
      background-color: #2CA622;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg{
        display: block;
        width: 8px;
        height: 6px;
        fill: transparent;
        stroke: #fff;
      }
    }

    & .text {
      font-size: 16px;
      color: #2CA622;
    }
  }

  & .bottom {
    display: flex;
    justify-content: space-between;
  }
  & .deadline {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
  }
  & .date {
    font-size: 16px;
    color: #000000;
  }
  & .delete {
    font-size: 16px;
    text-decoration-line: underline;
    color: #979797;
  }
  @media (max-width: 768px){
    padding: 15px 20px;
  }

  @media (max-width: 540px){
    & .top{
      margin-bottom: 15px;
      flex-direction: column;
      & .right{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      & .left{
        margin-bottom: 15px;
      }
    }
    & .price{
      margin-bottom: 0;
    }
    & .name,
    & .deadline{
      margin-bottom: 8px;
    }
  }

  @media (max-width: 370px){
    & .top{
      margin-bottom: 12px;
      flex-direction: column;
      & .right{
        flex-direction: column;
        align-items: flex-start;
      }
      & .price{
        margin-bottom: 8px;
      }
      & .left{
        margin-bottom: 12px;
      }
    }
  }
}
</style>
