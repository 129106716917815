import { getConnectedServices } from '@/actions/company'

// ====================  CALCULATOR Count Managers And Vacanices ====================
export const countManagersAndVacancies = async () => {
  // Connected Services
  const responseConnectedServices = (await getConnectedServices()).data

  const data = {
    managersAll: 0,
    vacanciesAll: 0,

    availableManagers: 0,
    availableVacancies: 0
  }

  responseConnectedServices.forEach(service => {
    if (service.id !== 4) {
      if (service.package.managers_count) data.managersAll += service.package.managers_count
      if (service.package.vacancies_count) data.vacanciesAll += service.package.vacancies_count

      if (service.available_managers_count) data.availableManagers += service.available_managers_count
      if (service.available_vacancies_count) data.availableVacancies += service.available_vacancies_count
    }
  })

  return data
}
