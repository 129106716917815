<template>
    <div :class="'lang ' + (showDrop ? 'active' : '')">
      <button class="inner" @click="showDrop = !showDrop">
        <span>{{$t('lang_title')}}</span>
        <div class="arrow-icon">
          <div class="left-bar"></div>
          <div class="right-bar"></div>
        </div>
      </button>
      <transition name="drop">
        <div class="drop" v-if="showDrop">
          <div v-for="local in locals" :key="local">
            <button class="drop__link" v-if="selected != local" @click="switchLang(local)">
              <span style="text-transform: uppercase">{{local === 'kk' ? 'kz' : local}}</span>
            </button>
          </div>
        </div>
      </transition>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useToast } from '@/modules/toast'
import { useI18n } from 'vue-i18n'
import router from '@/router/index.js'

const i18n = useI18n()
const { toast } = useToast()
const showDrop = ref(false)
const selected = ref('')
const locals = ['ru', 'kk', 'en']
const switchLang = (local, message = true) => {
  i18n.locale.value = local
  localStorage.setItem('lang', local)
  showDrop.value = false
  selected.value = local
  if (message) {
    toast({ message: i18n.t('message.changed_lang') })
    router.go(0)
  }
}
if (localStorage.getItem('lang')) switchLang(localStorage.getItem('lang'), false)
</script>

<style lang="scss" closed>
.drop-enter-active,
.drop-leave-active {
   transition: all.2s linear;
}
.drop-enter-from,
.drop-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
.lang{
  position: relative;
  &.active{
    & .left-bar:after {
      transform-origin: center center;
      transform: rotate(-70deg);
    }
    & .right-bar:after {
      transform-origin: center center;
      transform: rotate(70deg);
    }
  }
  & .inner{
    display: flex;
    align-items: center;
  }
  & span{
      font-size: 16px;
      line-height: 1.2;
      text-decoration-line: none;
      color: #FFFFFF;
      margin-right: 5px;

  }
  & .arrow-icon {
    background-color: transparent;
    height: 8px;
    width: 15px;
    display:block;
    padding: 0.5em;
    position: relative;
    transform: translateY(12%);
    cursor: pointer;
    border-radius: 4px;
  }

  & .left-bar {
    position: absolute;
    background-color: transparent;
    top: 30%;
    left:0;
    width: 8px;
    height: 1.5px;
    display: block;
    transform: rotate(35deg);
    float: right;
    border-radius: 1.5px;
    &:after {
      content:"";
      background-color: white;
      width: 8px;
      height: 1.5px;
      display: block;
      float: right;
      border-radius: 6px 10px 10px 6px;
      transition: all 0.5s cubic-bezier(.25,1.7,.35,.8);
      z-index: -1;
    }
  }

  & .right-bar {
    position: absolute;
    background-color: transparent;
    top: 30%;
    left:5.5px;
    width: 8px;
    height: 1.5px;
    display: block;
    transform: rotate(-35deg);
    float: right;
    border-radius: 1.5px;
    &:after {
      content:"";
      background-color: white;
      width: 8px;
      height: 1.5px;
      display: block;
      float: right;
      border-radius: 10px 6px 6px 10px;
      transition: all 0.5s cubic-bezier(.25,1.7,.35,.8);
      z-index: -1;
    }
  }
  & .drop{
    width: auto;
    & span{
      color: #000;
    }
  }
}
</style>
