import { createI18n } from 'vue-i18n'
import { ru } from './ru'
import { kk } from './kk'
import { en } from './en'

const dataMessages = {
  ru,
  en,
  kk
}

export const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: dataMessages
})
