<template>
  <div class="serviceDetail">
    <Header />
    <div class="bread_crumbs_bl">
        <div class="container">
            <ul class="bread_crumbs_bl__inner">
                <li><router-link :to="'/'">{{$t('home')}}</router-link></li>
                <li><a :href="'/#serviceDetail'">{{$t('services.title')}}</a></li>
                <li><router-link to="/services/outsourcing-personnel">{{$t('outsourcing_personnel')}}</router-link></li>
                <li>{{service.title ? service.title : ''}}</li>
            </ul>
        </div>
    </div>
    <section>
        <div class="container" v-if="service.title">
            <h3 class="section__title">{{service.title}}</h3>

            <div class="serviceDetail__block">
                <div class="serviceDetail__preview">
                    <div class="skeleton skeleton-img" v-if="!service"></div>
                    <img v-else :src="`${apiUrl}/${service.image}`" alt="">
                </div>
                <div class="serviceDetail__content">
                  <p>{{service.description}}</p>
                </div>
            </div>

            <div class="serviceDetail__table" v-html="service.text"></div>
        </div>

        <div class="container skeleton__block" v-else>
            <h3 class="section__title skeleton small-text" style="width:70%"></h3>
            <div class="serviceDetail__block">
                <div class="serviceDetail__preview skeleton">
                  <div class="img skeleton skeleton-img"></div>
                </div>
                <div class="serviceDetail__content">
                  <div>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:100%;height:" v-for="k of 7" :key="k"></p>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:80%;"></p>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:30%;"></p>
                  </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'
import { useRoute } from 'vue-router'
import { getOutsourcingDetail } from '@/actions/home'
import { apiUrl } from '@/modules/api.js'

const service = ref({})
const route = useRoute()
onMounted(async () => {
  const response = await getOutsourcingDetail(route.params.outsourcing_id)
  service.value = response
})
</script>
