<template>
<div class="searchResumes__item" :class="{topResume: resume.topResume}">
  <div class="top">
    <router-link
      v-if="checkAccess"
      :to="'/company/resume-detail/' + resume.id"
      class="nameLink"
    >
      <span class="topIcon">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 232.211 232.211" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 232.211 232.211">
          <g>
            <path d="M218.006,180.449l14.205-134.226L166.438,68.03L115.598,3.56L64.79,67.989L0,46.174l13.074,134.275H218.006z    M75.255,103.169l40.343-51.16l40.311,51.119l41.572-13.783l-6.467,61.104H40.295L34.35,89.395L75.255,103.169z"/>
            <rect width="207.825" x="11.853" y="198.651" height="30"/>
          </g>
        </svg>
      </span>
        {{resume.title}}
    </router-link>

    <button
      v-else
      class="nameLink"
      @click="() => {quastion = true}"
    >
      {{resume.title}}
    </button>

    <div
      v-if="resume.try_salary"
      class="wades__value"
    >
      {{`${formatToSalary(resume.try_salary)} ${resume.currency.title}`}}
    </div>
  </div>

  <div class="center">
    <div v-if="resume.work_places.length !== 0">
      <div class="title">
        <span>{{`${$t('last_job')}: `}} </span>
        <div class="companyName" v-if="resume.work_places[0].company_name"> {{resume.work_places[0].company_name}}</div>
      </div>
      <div class="position">{{resume.work_places[0].position}}</div>
      <div class="date" v-if="resume.work_places[0].period_from">
        {{resume.work_places[0].period}}
      </div>
    </div>
    <div v-else>
      <div class="title">
        <span>{{`${$t('last_job')}: `}} </span>
        <div class="companyName"> {{$t('not_set')}}</div>
      </div>
    </div>
    <div class="centerBottom">
      <div class="LastUpdateDate">
        {{`${$t('updated')} ${moment(resume.updated_at).format('DD-MM-YYYY')}`}}
      </div>
      <UserContact v-if="checkAccess" :userEmployee="resume.user_employee" />
    </div>
  </div>
  <div class="bottom">
    <UserContact v-if="checkAccess" :userEmployee="resume.user_employee" />
    <Button
      :name="resumeInFavorite ? $t('remove_from_favorites') : $t('add_to_favorites')"
      type="gray NoTextTransform"
      @click="() => {resumeToFavorite()}"
      :loading="loading"
    />
  </div>
  <Modal
    :title="$t('message.pay_the_tariff_for_viewing_contacts')"
    :btnTitle="$t('pay')"
    :hide="()=>{quastion = false}"
    :show="quastion"
    :agreement="() => {router.push('/company/services')}"
    quastion
  />
</div>
</template>

<script setup>
import UserContact from '@/components/uiCompany/Resumes/UserContact.vue'
import Modal from '@/components/common/Modal.vue'

import moment from 'moment'
import router from '@/router/index.js'
import { defineProps, inject, reactive, ref } from 'vue'

import { formatToSalary } from '@/helpers/formatSalary'
import { postFavoriteResume, deleteFavoriteResume } from '@/actions/company'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  resume: {
    type: Object,
    default: () => {}
  }
})

const i18n = useI18n()

const resume = reactive(props.resume)
const checkAccess = inject('check_access')
const resumeInFavorite = ref(props.resume.favorite_resumes.length !== 0)

const loading = ref(false)
const quastion = ref(false)

// Period Generator
if (resume.work_places.length !== 0) {
  resume.work_places[0].period = `с ${moment(resume.work_places[0].period_from).format('DD-MM-YYYY')} `
  if (resume.work_places[0].period_to) resume.work_places[0].period += `${i18n.t('on')} ${moment(resume.work_places[0].period_to).format('DD-MM-YYYY')}`
  else resume.work_places[0].period += i18n.t('until_now')
}

// Click Evetn Resume Add To Favorite Or Delete
async function resumeToFavorite () {
  loading.value = true
  try {
    if (resumeInFavorite.value) await deleteFavoriteResume(props.resume.id)
    else await postFavoriteResume(props.resume.id)
    resumeInFavorite.value = !resumeInFavorite.value
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.searchResumes{
  &__item{
    display: block;
    padding: 20px;
    border: 0.5px solid #919599;
    & .top,
    & .bottom,
    & .center{
      display: flex;
      justify-content: space-between;
      grid-gap: 15px;
    }

    // ---------- Top ----------
    & .top{
      margin-bottom: 10px;
    }
    & .nameLink{
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      color: #212529;
      position: relative;
      transition: all .2s linear;

      &::after{
        content: '';
        display: block;
        height: 0;
        width: 0;

        position: absolute;
        top: 90%;
        left: 0;

        border-bottom: 3px solid #AD1717;
        transition: all .2s linear;
      }

      &:hover{
        color: #AD1717;
        &::after{
          width: 100%;
        }
        & svg{
          fill: #AD1717;
        }
      }
    }
    & .wades__value{
      font-size: 24px;
      text-transform: uppercase;
      color: #212529;
    }

    // ---------- Center ----------
    & .center{
      margin-bottom: 10px;

      & span{
        display: inline-block;
        margin-bottom: 5px;
      }
    }
    & .title{
      font-size: 14px;
      color: rgba(33, 37, 41, 0.7);
    }
    & .companyName,
    & .position{
      font-size: 16px;
      color: #212529;
      margin-bottom: 5px;
    }
    & .position{
      color: #B5C096;
    }

    & .centerBottom{
      display: flex;
      justify-content: space-between;

      & .contacts{
        display: none !important;
      }
    }
    & .LastUpdateDate{
      font-size: 16px;
      color: rgba(33, 37, 41, 0.7);
    }

    // ---------- Bottom ----------
    & .bottom{
      align-items: center;
    }

    & .contacts .text,
    & .date{
      font-size: 14px;
      color: rgba(33, 37, 41, 0.7);
    }

    &.topResume{
      background-color: aliceblue;
      & .topIcon{
        display: inline-block !important;
      }
    }

    & .topIcon{
      display: none;
      height: 20px;
      width: 20px;
      & svg{
        fill: #ff2400;
        transition: fill .2s linear;
      }
    }
  }

  @media (max-width: 768px){
    &__item{
      padding: 15px;

      // ---------- Top ----------
      & .top{
        margin-bottom: 8px;
      }
      & .nameLink{
        font-size: 18px;
      }
      & .wades__value{
        font-size: 22px;
      }

      // ---------- Center ----------
      & .center{
        margin-bottom: 8px;
      }
      & .title{
        font-size: 12px;
      }
      & .companyName,
      & .position{
        font-size: 14px;
      }

      & .LastUpdateDate{
        font-size: 14px;
      }

      // ---------- Bottom ----------
      & .contacts .text,
      & .date{
        font-size: 12px;
      }

      & .topIcon{
        height: 18px;
        width: 18px;
      }
    }
  }

  @media (max-width: 576px){
    &__item{
      & .top,
      & .center{
        flex-direction: column;
        grid-gap: 10px;
      }
      // ---------- Top ----------
      & .top{
        grid-gap: 3px;
        margin-bottom: 6px;
      }
      & .nameLink{
        font-size: 14px;
      }
      & .wades__value{
        font-size: 15px;
      }

      // ---------- Center ----------
      & .center{
        margin-bottom: 6px;
      }
      & .title{
        font-size: 10px;

        & span{
          margin-right: 5px;
        }
      }
      & .companyName,
      & .position{
        font-size: 12px;
      }
      & .companyName{
        display: inline;
      }
      & .LastUpdateDate{
        font-size: 12px;
      }

      // ---------- Bottom ----------
      & .date{
        font-size: 10px;
      }

      & .topIcon{
        height: 14px;
        width: 14px;
      }
    }
  }
  @media (max-width: 400px){
    &__item{
      padding: 10px;
      // ---------- Top ----------
      & .top{
        grid-gap: 2px;
        margin-bottom: 5px;
      }
      & .nameLink{
        font-size: 12px;
      }
      & .wades__value{
        font-size: 13px;
      }

      // ---------- Center ----------
      & .center{
        margin-bottom: 5px;
      }
      & .title{
        font-size: 9px;

        & span{
          margin-right: 4px;
        }
      }
      & .companyName,
      & .position{
        font-size: 11px;
      }

      & .centerBottom{
        margin-bottom: 10px;
        & .contacts{
          display: block !important;
        }
      }

      // ---------- Bottom ----------
      & .bottom{
        & .contacts{
          display: none !important;
        }
        & .button{
          width: 100% !important;
        }
      }
      & .date{
        font-size: 9px;
      }
      & .LastUpdateDate{
        font-size: 11px;
      }

      & .topIcon{
        height: 12px;
        width: 12px;
      }

      & .user__contacts .infoDrop{
        left: auto;
        right: 0;
      }
    }
  }
}
</style>
