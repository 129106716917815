<template>
  <ModalLogin
    :show="showModal.login"
    :hide="hideModals"
    :showForgotPassword="() => {showModal.login = false, showModal.forgotPassword = true}"
    :showRegister="() => {showModal.login = false, showModal.registerClient = true}"/>

  <ModalRegisterClient
    :show="showModal.registerClient"
    :showCompany="() => {showModal.registerClient = false, showModal.registerCompany = true}"
    :showSendModal="() => {showModal.registerClient = false, showModal.sendMessage = true}"
    :hide="hideModals"/>

  <ModalRegisterCompany
    :show="showModal.registerCompany"
    :showClient="() => {showModal.registerClient = true, showModal.registerCompany = false}"
    :showSendModal="() => {showModal.registerCompany = false, showModal.sendMessage = true}"
    :hide="hideModals"/>

  <Modal
    contentCenter="true"
    :hide="hideModals"
    :show="showModal.passwordResetSuccesfully || showModal.emailVerified"
    :title="showModal.emailVerified ? $t('message.your_email_has_been_successfully_verified') : $t('message.your_password_has_been_successfully_changed')"
    :agreement="() => {showModal.passwordResetSuccesfully = false, showModal.emailVerified = false, showModal.login = true}"
    class="showBoxShadow white"
    successfully/>

  <ModalForgotPassword
    :show="showModal.forgotPassword"
    :hide="hideModals"/>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import Modal from '@/components/common/Modal.vue'
import ModalForgotPassword from '@/components/Authorization/ModalForgotPassword.vue'
import ModalLogin from '@/components/Authorization/ModalLogin.vue'
import ModalRegisterClient from '@/components/Authorization/ModalRegisterClient.vue'
import ModalRegisterCompany from '@/components/Authorization/ModalRegisterCompany.vue'
import { defineProps } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  hideModals: {
    type: Function,
    default: null
  }
})

const showModal = ref(props.modelValue)
</script>
