<template>
<div
  class="checkbox__item"
  @click="clickOption()"
>
  <Checkbox v-model="activeOption" :class="(dark ? 'dark ' : 'red ') + type"/>
  <span class="checkbox__text">{{title}}</span>
</div>
</template>

<script setup>
import Checkbox from '@/components/common/Checkbox.vue'

import { defineProps, defineEmits, ref, watch, computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: 'Exaple option'
  },
  active: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'default'
  },
  dark: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'check', 'unCheck'])

const activeOption = computed(() => props.active)
const showFlyBtn = ref(false)

watch(
  () => props.active,
  () => {
    activeOption.value = props.active
  }
)

// Click Option
const clickOption = () => {
  if (!props.active) {
    emit('check')
    showFlyBtn.value = true
  } else emit('unCheck')
}
</script>

<style lang="scss" scoped>
.checkbox{
  &__item{
    display: flex;
    align-items: center;
    grid-gap: 15px;
    margin-bottom: 14px;
    cursor: pointer;
    position: relative;
    &:hover .checkbox__text{
      color: #AD1717;
    }
    & *{
      cursor: pointer;
    }
  }
  &__text{
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    transition: all .2s linear;
  }

  @media (max-width: 992px){
    &__item{
      grid-gap: 13px;
      margin-bottom: 12px;
    }
    &__text{
      font-size: 14px;
    }
  }
  @media (max-width: 768px){
    &__item{
      grid-gap: 12px;
      margin-bottom: 11px;
    }
    &__text{
      font-size: 12px;
    }
  }
  @media (max-width: 576px){
    &__item{
      grid-gap: 10px;
      margin-bottom: 8px;
    }
    &__text{
      font-size: 10px;
    }
  }
}
</style>
