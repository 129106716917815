<template>
  <div class="buttonToTop">
    <button @click="scrollToTop()">
      <svg>
        <use xlink:href="/img/tmp/sprite.svg#arrowUp"></use>
      </svg>
    </button>
    <div class="animationBlock">
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonToTop',
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (event) {
      if (window.scrollY > 700) {
        document.querySelector('div.buttonToTop').classList.add('show')
      } else {
        document.querySelector('div.buttonToTop').classList.remove('show')
      }
    }
  },
  setup () {
    const scrollToTop = () => {
      let positionY = window.scrollY
      const myTimer = setInterval(() => {
        window.scrollTo(0, positionY)
        positionY -= 40
        if (window.scrollY === 0) {
          clearInterval(myTimer)
        }
      }, 0.1)
    }
    return {
      scrollToTop
    }
  }
}
</script>
<style lang="scss" scoped>
  .buttonToTop{
    display: block;
    width: 60px;
    height: 60px;

    position: fixed;
    bottom: -100px;
    right: 80px;
    z-index: 10;
    transition: all .2s linear;

    &:hover{
      transform: translateY(-10px);
      & svg{
        stroke: #AD1717;
      }
    }

    &.show{
      bottom: 80px;
    }

    & button{
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      border-radius: 100px;
      background-color: #fff;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    }

    & svg{
      display: block;
      width: 47%;
      height: 47%;
      fill: transparent;
      stroke: #C1C1C1;
      stroke-width: 1px;
      transition: stroke .4s linear;
    }

    & .animationBlock{
      & span{
        display: block;
        background-color: #AD1717;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        border-radius: 100px;
        opacity: .2;
        z-index: -2;
        animation: scaleAnimate 6s infinite;
        animation-delay: 3s;
        pointer-events: none;
      }
    }

    @keyframes scaleAnimate {
      0% {
        transform: scale(0.95);
        opacity: .7;
      }
      50% {
        transform: scale(0.95);
        opacity: .7;
      }

      70% {
        transform: scale(1.6);
        opacity: 0;
      }

      100% {
        transform: scale(0.95);
        opacity: 0;
      }
    }
  }
  @media (max-width: 1200px){
    .buttonToTop{
      width: 55px;
      height: 55px;
    }
  }
  @media (max-width: 885px){
    .buttonToTop{
      width: 48px;
      height: 48px;
      bottom: -70px;
      right: 50px;
    }
  }
  @media (max-width: 885px){
    .buttonToTop{
      width: 48px;
      height: 48px;
      bottom: -70px;
      right: 50px;
    }
  }
  @media (max-width: 568px){
    .buttonToTop{
      width: 40px;
      height: 40px;
      bottom: -60px;
      right: 40px;
    }
  }
</style>
