<template>
  <Header />
  <section class="createResume">
      <div class="container">
          <h3 class="section__title">{{route.params.resume_id ? 'Редактирование резюме' : 'Создать резюме'}}</h3>
          <form
            class="createResume__form"
            @submit.prevent="CreateOrEditResume()"
            method="post"
            v-if="helpers.educationType"
          >
            <!-- Common Data //////////////////////////////////////////-->
            <CommonData
              v-model="formData"
              v-model:validation="success"
              :helpers="helpers"
            />

            <!-- Work Experience //////////////////////////////////////////-->
            <Experience
              v-model="formData"
              v-model:validation="success"
              :helpers="helpers"
            />

            <!-- Education //////////////////////////////////////////-->
            <Education
              v-model="formData"
              :helpers="helpers"
            />

            <!-- Courses //////////////////////////////////////////-->
            <Courses v-model="formData" />

            <!-- Ownership PC //////////////////////////////////////////-->
            <OwnershipPC v-model="formData" />

            <!-- Languages  //////////////////////////////////////////-->
            <Languages v-model="formData" :helpers="helpers"/>

            <!-- Awards  //////////////////////////////////////////-->
            <Awards v-model="formData" />

            <!-- Hobbies //////////////////////////////////////////-->
            <Hobbies v-model="formData" />

            <!-- Personal qualities //////////////////////////////////////////-->
            <PersonalSkills v-model="formData" />

            <!-- Availability of a car //////////////////////////////////////////-->
            <AvailabilityCar v-model="formData" :helpers="helpers"/>

            <!-- More about yourself //////////////////////////////////////////-->
            <MoreAboutYourself v-model="formData"/>

            <div>
                <div></div>
                <div class="btns">
                  <Button
                    :name="$t('save_edited')"
                    @click="CreateResume()"
                    htmlType="submit"
                  />
                  <Button
                    :name="$t('cancel')"
                    type="gray"
                    go="/user/my-resume"
                  />
                </div>
            </div>
        </form>

        <div class="createResume__form" v-else>
          <div class="form__item">
            <div class="createResume__itemInner" v-for="item of 9" :key="item">
              <label class="skeleton middle-text"></label>
              <div class="skeleton">
                <Input disabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading :show="loading" fixed />
  </section>
</template>

<script setup>
import CommonData from '@/components/uiEmployee/Resume/Edit/CommonData.vue'
import Experience from '@/components/uiEmployee/Resume/Edit/Experience/ListExperience.vue'
import Education from '@/components/uiEmployee/Resume/Edit/Education/ListEducation.vue'
import Courses from '@/components/uiEmployee/Resume/Edit/Courses/ListCourses.vue'
import OwnershipPC from '@/components/uiEmployee/Resume/Edit/OwnershipPC.vue'
import Languages from '@/components/uiEmployee/Resume/Edit/Languages.vue'
import Awards from '@/components/uiEmployee/Resume/Edit/Awards/ListAwards.vue'
import Hobbies from '@/components/uiEmployee/Resume/Edit/Hobbies.vue'
import PersonalSkills from '@/components/uiEmployee/Resume/Edit/PersonalSkills.vue'
import AvailabilityCar from '@/components/uiEmployee/Resume/Edit/AvailabilityCar.vue'
import MoreAboutYourself from '@/components/uiEmployee/Resume/Edit/MoreAboutYourself.vue'

import { reactive, ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { getCountries, getIndustries, getCurrencies, getKids, getMaritalStatus, getDriverCategories, getLanguages, getDiplomType, getEducationType } from '@/actions/help'
import { getResume, putResume, postResume } from '@/actions/employee'
import { onMounted } from 'vue-demi'
import router from '@/router/index.js'

const route = useRoute()

const formData = ref(
  route.params.resume_id ? null : {
    experience_on: false,
    education_on: false,
    courses_on: false,
    ownershipPC_on: false,
    languages_on: false,
    award_on: false,
    hobbies_on: false,
    personalSkills_on: false,
    availabilityCar_on: false,
    description_on: false,
    experiences: [],
    educations: [],
    courses: [],
    languages: [],
    awards: [],
    willing_to_relocate: [],
    driver_certificate_categories: []
  }
)
const helpers = reactive({
  formData: null,
  country: null,
  industries: null,
  currencies: null,
  kids: null,
  maritalStatus: null,
  driverCategories: null,
  languages: null
})
const success = reactive({
  title: false,
  email: false,
  phone: false,
  industries: false,
  professional_skills: false
})
const loading = ref(false)

onMounted(
  async () => {
    if (route.params.resume_id) {
      formData.value = (await getResume(route.params.resume_id)).data
      formData.value.industry_id = formData.value.industry.id
      formData.value.currency_id = formData.value.currency.id
      formData.value.country_id = formData.value.country.id
      delete formData.value.industry
      delete formData.value.currency
      delete formData.value.country
    }
    const [country, industries, currencies, kids, maritalStatus, driverCategories, languages, diplomType, educationType] = await Promise.all([
      getCountries(),
      getIndustries(),
      getCurrencies(),
      getKids(),
      getMaritalStatus(),
      getDriverCategories(),
      getLanguages(),
      getDiplomType(),
      getEducationType()
    ])
    helpers.country = country.data
    helpers.industries = industries
    helpers.currencies = currencies
    helpers.kids = kids
    helpers.maritalStatus = maritalStatus
    helpers.driverCategories = driverCategories.data
    helpers.languages = languages
    helpers.diplomType = diplomType
    helpers.educationType = educationType
  }
)

const CreateOrEditResume = async () => {
  loading.value = true
  try {
    if (route.params.resume_id) await putResume(route.params.resume_id, formData.value)
    else await postResume(formData.value)
    router.push('/user/my-resumes')
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.createResume{
  & textarea{
    min-height: 100px !important;
  }
  & .form__title{
    display: block !important;
    font-weight: bold;
    font-size: 24px;
    line-height: 48px;
    text-transform: uppercase;
    color: #4D533C;
  }
  & label{
    font-size: 16px;
    line-height: 25px;
    color: rgba(33, 37, 41, 0.7);
    & span{
      color: rgba(33, 37, 41, 0.4);
    }
  }

  &__form{
    & .form__item{
      max-width: 812px;
      display: grid;
      grid-gap: 20px;
      padding: 40px 0 50px;
      border-top: 1px solid rgba(0, 0, 0, 0.0955529);
      & .createResume__itemRow:last-of-type {
        border-bottom: none;
      }
    }

    & .form__itemHeader{
      display: flex;
    }

    & .form__item:first-child{
      padding-top: 0;
      border-top: none;
    }

    & .btns{
      display: flex;
      grid-gap: 20px;
    }

  }
  &__itemRow{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0955529);
    position: relative;
  }
  &__itemRow:last-child {
    border-bottom: none;
  }

  &__itemInner{
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 20px;
  }
  &__AddMore{
    max-width: 150px;
  }
  & .wages{
    display: grid;
    grid-template-columns: 1fr 115px;
    grid-gap: 20px;
  }
  & .typeDiploma{
    & input{
      width: 100%;
      margin-bottom: 8px;
    }
  }
  & .checkboxBlock{
    display: flex;
    align-items: center;
    grid-gap: 15px;
    & .text{
      font-size: 16px;
      color: rgba(33, 37, 41, 0.7);
    }
  }

  & .checkbox_block{
    display: flex;
    grid-gap: 30px;
    & > div{
      width: 100%;
      max-width: 250px;
      display: flex;
      align-items: center;
      grid-gap: 15px;
    }
  }
@media (max-width: 992px){
  & textarea{
    min-height: 90px !important;
  }
  & .form__title{
    font-size: 20px;
    line-height: 1.3;
  }
  & label{
    font-size: 14px;
    line-height: 1.2;
  }

  &__form{
    & .form__item{
      grid-gap: 18px;
      padding: 30px 0 40px;
    }

    & .btns{
      grid-gap: 18px;
    }

  }
  &__itemRow{
    grid-gap: 18px;
    padding-bottom: 23px;
  }
  &__itemInner{
    grid-template-columns: 280px 1fr;
    grid-gap: 18px;
  }
  & .wages{
    grid-template-columns: 1fr 110px;
    grid-gap: 18px;
  }
  & .typeDiploma{
    & input{
      margin-bottom: 6px;
    }
  }
  & .checkboxBlock{
    grid-gap: 12px;
    & .text{
      font-size: 14px;
    }
  }

  & .checkbox_block{
    grid-gap: 25px;
    & > div{
      max-width: 230px;
      grid-gap: 13px;
    }
  }
  &__itemInner{
    grid-template-columns: 220px 1fr;
    grid-gap: 18px;
  }
}
@media (max-width: 768px){
  & .form__title{
    font-size: 18px;
  }
  & label{
    font-size: 12px;
  }

  &__form{
    & .form__item{
      grid-gap: 16px;
      padding: 25px 0 35px;
    }

    & .btns{
      grid-gap: 16px;
    }

  }
  &__itemRow{
    grid-gap: 16px;
    padding-bottom: 20px;
  }
  &__itemInner{
    grid-template-columns: 250px 1fr;
    grid-gap: 16px;
  }
  & .wages{
    grid-template-columns: 1fr 100px;
    grid-gap: 16px;
  }
  & .typeDiploma{
    & input{
      margin-bottom: 4px;
    }
  }
  & .checkboxBlock{
    grid-gap: 10px;
    & .text{
      font-size: 12px;
    }
  }

  & .checkbox_block{
    grid-gap: 18px;
    & > div{
      max-width: 180px;
      grid-gap: 10px;
    }
  }
  &__itemInner{
    grid-template-columns: 1fr !important;
    grid-gap: 6px !important;
  }
}

@media (max-width: 576px){
  & .form__title{
    font-size: 16px;
  }
  & label{
    font-size: 10px;
  }

  &__form{
    & .form__item{
      grid-gap: 14px;
      padding: 22px 0 32px;
    }

    & .btns{
      grid-gap: 14px;
    }

  }
  &__itemRow{
    grid-gap: 14px;
    padding-bottom: 18px;
  }
  & .wages{
    grid-template-columns: 1fr 70px;
    grid-gap: 14px;
  }
  & .checkboxBlock{
    & .text{
      font-size: 10px;
    }
  }

  & .checkbox_block{
    grid-gap: 16px;
    & > div{
      max-width: 160px;
      grid-gap: 8px;
    }
  }
  &__itemInner{
    grid-template-columns: 1fr !important;
    grid-gap: 6px !important;
  }
}
@media (max-width: 400px){
  & .btns{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
}
</style>
