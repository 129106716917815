<template>
<div class="container slider__about">
  <div class="slider__inner">
    <div class="slider__head">
      <h3 class="section__title">{{title}}</h3>
      <SliderBtns
        :next="() => {toNext()}"
        :prev="() => {toPrev()}"
      />
    </div>
    <div
      class="slider__items"
      ref="slider"
      :style="[{width: sliderWidth + 'px', paddingRight: paddingWidth + 'px'}, sliderStyle]"
    >
      <slot />
    </div>
  </div>
</div>
</template>

<script setup>
import SliderBtns from '@/components/common/Sliders/SliderBtns.vue'

import { defineProps, defineEmits, ref, watch } from 'vue'

defineProps({
  modelValue: {
    type: Number,
    default: 0
  },
  paddingWidth: {
    type: Number,
    default: 0
  },
  title: {
    type: String,
    default: 'Example'
  },
  sliderStyle: {
    type: Object,
    default: null
  }
})
defineEmits(['update:modelValue', 'update:paddingWidth'])

const slider = ref(null)

const paddingWidth = ref((document.body.clientWidth - document.querySelectorAll('.footer__inner')[0].clientWidth) / 2)
const sliderWidth = paddingWidth.value + document.querySelectorAll('.footer__inner')[0].clientWidth

const toNext = () => {
  slider.value.scrollBy(document.querySelectorAll('.footer__inner')[0].clientWidth, 0)
}

const toPrev = () => {
  slider.value.scrollBy(0 - document.querySelectorAll('.footer__inner')[0].clientWidth, 0)
}

watch(
  () => document.body.clientWidth,
  () => {
    paddingWidth.value = (document.body.clientWidth - document.querySelectorAll('.footer__inner')[0].clientWidth) / 2
    sliderWidth.value = paddingWidth.value + document.querySelectorAll('.footer__inner')[0].clientWidth
  }
)
</script>

<style lang="scss">
.slider__about {
  & .container{
    padding-bottom: 0;
  }
  & .slider__head{
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .section__title{
      margin-bottom: 0;
    }
  }
  & .slider__items{
    display: flex;
    grid-gap: 30px;
    padding: 30px 0 110px 0;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    position: relative;
    transition: left .4s linear;

    &::-webkit-scrollbar {
      display: none;
    }
    & > *{
      scroll-snap-align: start;
    }
  }

  @media (max-width: 992px){
    & .slider__items{
      grid-gap: 25px;
      padding: 25px 0 80px 0;
    }
  }
  @media (max-width: 768px){
    & .slider__items{
      grid-gap: 20px;
      padding: 22px 0 70px 0;
    }
  }
  @media (max-width: 576px){
    & .slider__items{
      grid-gap: 15px;
      padding: 20px 0 50px 0;
    }
  }
}
</style>
