<template>
<div class="bottom">
  <SelectFilter
    v-model="formData.vacancy_id"
    :options="options"
  />

  <SelectFilter
    v-model="formData.sort_type"
    :options="dateFilters"
  />
</div>
</template>

<script setup>
import SelectFilter from '@/components/uiLanding/Filter/Header/SelectFilter.vue'
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  options: {
    type: Array,
    default: null
  }
})
const emit = defineEmits(['update:modelValue', 'search'])
const i18n = useI18n()

const formData = ref(props.modelValue)

const dateFilters = [
  {
    title: i18n.t('dateFilters.date_descending'),
    id: null
  },
  {
    title: i18n.t('dateFilters.date_asc'),
    id: 'date_asc'
  }
]

watch(
  () => [formData.value.sort_type, formData.value.vacancy_id],
  () => {
    emit('update:modelValue', formData.value)
    emit('search')
  }
)
</script>

<style lang="scss" scoped>
.bottom{
  display: flex;
  align-items: center;
  grid-gap: 60px;
}
</style>
