<template>
<transition name="fade">
  <div class="modal" @keyup.esc="hide" v-if="show">
    <div class="modal__block">
        <div class="container">
            <div class="modal__inner" :class="{quastion: quastion}">
              <div class="check" v-if="successfully">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
              </div>

              <div class="modal__title" v-if="title || quastion">{{title ?? $t('are_you_sure')}}</div>

              <div v-if="quastion">
                <div class="btns">
                  <Button :name="btnTitle ?? $t('delete')" @click="agreement"/>
                  <Button type="gray" :name="$t('cancel')" @click="hide"/>
                </div>
              </div>

              <button class="modal__closeBtn" @click="hide" type="button">
                <svg>
                    <use xlink:href="/img/tmp/sprite.svg#close"></use>
                </svg>
              </button>
              <slot />
              <Button v-if="footer" class="closeBtnFooter" :name="$t('close')" @click="hide"/>
              <Button v-if="successfully" class="closeBtnFooter" :name="btnTitle ?? $t('authorization')" @click="agreement"/>

              <Loading :show="loading" />
            </div>
        </div>
    </div>
    <div v-if="successfully">
      <div class="conf-cont">
        <div class="confetti" v-for="item of 70" :key="item"></div>
      </div>
      <div class="conf-cont conf-cont-2">
        <div class="confetti" v-for="item of 70" :key="item"></div>
      </div>
    </div>
  </div>
</transition>
</template>

<script setup>
import { onMounted, onUnmounted, watch } from '@vue/runtime-core'
import { defineProps } from 'vue'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  hide: {
    type: Function,
    default: null
  },
  title: {
    type: String,
    default: null
  },
  footer: {
    type: Boolean,
    default: false
  },
  quastion: {
    type: Boolean,
    default: false
  },
  btnTitle: {
    type: String,
    default: null
  },
  agreement: {
    type: Function,
    default: null
  },
  loading: {
    type: Boolean,
    default: false
  },
  successfully: {
    type: Boolean,
    default: false
  }
})
const hideModalOnEsc = (e) => {
  if (e.keyCode === 27) {
    props.hide()
  }
}
onMounted(() => {
  document.addEventListener('keydown', hideModalOnEsc)
  if (props.show) {
    document.querySelector('body').classList.add('no-scroll')
  }
})
onUnmounted(() => {
  document.removeEventListener('keydown', hideModalOnEsc)
  document.querySelector('body').classList.remove('no-scroll')
})
watch(
  () => props.show,
  () => {
    if (props.show) {
      document.querySelector('body').classList.add('no-scroll')
    } else {
      document.querySelector('body').classList.remove('no-scroll')
    }
  }
)
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transition: opacity .5s ease;
}
.modal{
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(#000, .7);
  backdrop-filter:blur(5px);
  transition: all .4s ease;
  &.white{
    background-color: rgba(#fff, .7);
  }
  &.disabled{
    & button{
      opacity: .5;
      pointer-events: none;
    }
  }
  &.showBoxShadow{
    & .modal__inner{
      box-shadow: 10px 10px 80px rgba(#000, .1);
    }
  }
  &__block{
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__inner{
    width: 100%;
    max-width: 790px;
    padding: 75px 120px 95px;
    margin: 50px auto;
    background-color: #fff;
    position: relative;
    z-index: 100;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.center{
      align-items: center;
      text-align: center;
    }
    &.quastion{
      max-width: 500px;
      min-height: 270px;
      padding: 35px 35px 40px;

      & .modal__title{
        text-transform: inherit;
      }
    }
  }

  &__title{
    color: #4D533C;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 35px;
  }

  &__subtitle{
    text-align: center;
    margin-bottom: 35px;
  }

  &__closeBtn{
    height: 24px;
    width: 24px;

    position: absolute;
    top: 17px;
    right: 17px;
    z-index: 2;
    background-color: #fff;

    transition: transform .2s linear;

    & svg{
      height: 24px;
      width: 24px;
      stroke: #212529;
      stroke-width: 1px;
      fill: #212529;
    }

    &:hover{
      transform: rotate(90deg);
    }
  }

  @media (max-width: 992px){
    &__inner{
      max-width: 760px;
      padding: 70px 100px 90px;
      margin: 50px auto;

      &.quastion{
        max-width: 400px;
        min-height: 220px;
      }
    }
    &__title{
      font-size: 22px;
      margin-bottom: 30px;
    }

    &__subtitle{
      margin-bottom: 32px;
    }

    &__closeBtn{
      height: 22px;
      width: 22px;

      top: 15px;
      right: 15px;

      & svg{
        height: 22px;
        width: 22px;
      }
    }
  }
  @media (max-width: 768px){
    &__inner{
      padding: 40px 50px 70px;
      margin: 50px auto;

      &.quastion{
        max-width: 320px;
        min-height: 180px;
        padding: 25px 25px 30px;
      }
    }
    &__title{
      font-size: 20px;
      margin-bottom: 25px;
    }

    &__subtitle{
      margin-bottom: 30px;
    }

    &__closeBtn{
      height: 20px;
      width: 20px;

      top: 13px;
      right: 13px;

      & svg{
        height: 20px;
        width: 20px;
      }
    }
  }
  @media (max-width: 576px){
    &__inner{
      min-height: auto;
      padding: 35px 40px 60px;
      margin: 40px auto;

      &.quastion{
        max-width: 280px;
        min-height: 160px;
        padding: 20px 20px 25px;
      }
    }
    &__title{
      font-size: 18px;
      margin-bottom: 20px;
    }

    &__subtitle{
      margin-bottom: 25px;
    }

    &__closeBtn{
      height: 18px;
      width: 18px;

      & svg{
        height: 18px;
        width: 18px;
      }
    }
  }

  @media (max-width: 400px){
    &__inner{
      min-height: auto;
      padding: 30px 35px 50px;
      margin: 35px auto;

      &.quastion{
        max-width: 280px;
        min-height: 140px;
        padding: 20px 20px 25px;
      }
    }
    &__title{
      font-size: 16px;
      margin-bottom: 18px;
    }

    &__subtitle{
      margin-bottom: 20px;
    }

    &__closeBtn{
      height: 16px;
      width: 16px;

      & svg{
        height: 16px;
        width: 16px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.btns{
  display: flex;
  justify-content: center;
  grid-gap: 20px;
}
</style>
