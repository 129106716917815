import api from '../modules/api'
import { filterPhoto } from '@/helpers/filterPhoto'
import { filterMultiSelect } from '@/helpers/filterMultiSelect'

export const getCommonData = () =>
  api.asyncGet('/v1/companies')

export const getCommonDataEdit = () =>
  api.asyncGet('/v1/companies/edit', {}, false, true)

export const putCommonData = (data) => {
  delete data.phone
  return api.asyncPut('/v1/companies/update', data)
}

// Image - Avatar
export const postImage = async (id, data) => {
  data = await filterPhoto(data)
  return api.asyncPost('/v1/companies/image/' + id, data)
}

export const deleteImage = async (id) => {
  return api.asyncDelete('/v1/companies/image/' + id)
}

// Managers
export const postManager = (data) => {
  return api.asyncPost('/v1/companies/managers', data)
}

export const getManagers = (data) =>
  api.asyncGet('/v1/companies/managers', data, false, true)

export const getManager = (id) =>
  api.asyncGet('/v1/companies/managers/' + id, {}, false, true)

export const putManager = (id, data) => {
  return api.asyncPut('/v1/companies/managers/' + id, data)
}

export const deleteManager = id =>
  api.asyncDelete('/v1/companies/managers/' + id)

// Tests
export const getTests = (data) =>
  api.asyncGet('/v1/companies/tests', data, false, true)

export const postTest = (data) =>
  api.asyncPost('/v1/companies/tests', data, false)

export const getTest = (id) =>
  api.asyncGet('/v1/companies/tests/' + id, {}, false, true)

export const putTest = async (id, data, deleteQuastions = [], deleteVariants = []) => {
  deleteQuastions.forEach(async (id) => {
    await deleteQuastion(id)
  })
  deleteVariants.forEach(async (id) => {
    await deleteVariant(id)
  })
  return api.asyncPut('/v1/companies/tests/' + id, data, false)
}

export const deleteTest = id =>
  api.asyncDelete('/v1/companies/tests/' + id)

export const deleteQuastion = id =>
  api.asyncDelete('/v1/companies/questions/' + id)

export const deleteVariant = id =>
  api.asyncDelete('/v1/companies/variations/' + id)

// Tests Without Pagination
export const getTestsWithoutPaginations = () =>
  api.asyncGet('/v1/companies/show/tests', {}, false, true)

// Letters
export const getLetters = (data) =>
  api.asyncGet('/v1/companies/letters', data, false, true)

export const postLetter = (data) =>
  api.asyncPost('/v1/companies/letters', data)

export const putLetter = (id, data) =>
  api.asyncPut('/v1/companies/letters/' + id, data)

export const deleteLetter = id =>
  api.asyncDelete('/v1/companies/letters/' + id)

export const getLettersShow = () =>
  api.asyncGet('/v1/companies/show/letters', null, false, true)

// Vacancies
export const getVacancies = (formData) => {
  const data = formData
  data.position = formData.name
  return api.asyncGet('/v1/companies/vacancies', data, false, true)
}

export const postVacancy = (formData) => {
  const data = Object.assign({}, formData)
  if (data.key_skills.length !== 0) data.key_skills = filterMultiSelect(formData.key_skills)
  data.languages.forEach(element => {
    if (element.language_id === 0) data.languages.splice(data.languages.indexOf(element), 1)
  })
  data.hot = +data.hot
  delete formData.country
  return api.asyncPost('/v1/companies/vacancies', data, false, false, false)
}

export const getVacancyDetail = (id) =>
  api.asyncGet('/v1/companies/vacancies/' + id, null, false, true)

export const putVacancy = async (id, formData) => {
  const data = Object.assign({}, formData)
  data.key_skills = await filterMultiSelect(data.key_skills)
  data.languages.forEach(element => {
    if (element.language_id === 0) data.languages.splice(data.languages.indexOf(element), 1)
  })
  delete data.country
  return api.asyncPut('/v1/companies/vacancies/' + id, data)
}

export const deleteVacancy = id =>
  api.asyncDelete('/v1/companies/vacancies/' + id)

export const getVacanciesShow = () =>
  api.asyncGet('/v1/companies/vacancies/show', null, false, true)

// Resumes
export const getResumes = (formData, experiences) => {
  const data = Object.assign({}, formData)
  data.title = data.name
  data.countries_id = []
  delete data.salary_type
  if (data.country_id !== null && data.country_id !== 0) data.countries_id.push(data.country_id)
  delete data.country_id
  for (const [key, value] of Object.entries(experiences)) {
    data[key] = value
  }
  return api.asyncGet('/v1/companies/search/resumes', data, false, true)
}

export const getResumeDetail = (id) =>
  api.asyncGet('/v1/companies/search/resumes/' + id)

// Favorites Resumes
export const getFavoriteResumes = data =>
  api.asyncGet('/v1/companies/favorite-resumes', data, false, true)

export const postFavoriteResume = (id) =>
  api.asyncPost('/v1/companies/favorite-resumes/' + id)

export const deleteFavoriteResume = id =>
  api.asyncDelete('/v1/companies/favorite-resumes/' + id)

// Services
export const getServices = () =>
  api.asyncGet('/v1/companies/packages', null, false, true)

// ========== Working With The Website =======

// Contract
export const getContract = () =>
  api.asyncGet('/v1/companies/working-with-the-site/contract', null, false, true)

// Responses Vacancies
export const getResponses = (formData) => {
  const data = formData
  data.title = formData.name
  if (data.vacancy_id === 0) data.vacancy_id = null
  return api.asyncGet('/v1/companies/vacancies/responses', data, false, true)
}

export const getResponseDetail = (id) =>
  api.asyncGet(`/v1/companies/vacancies/responses/${id}/resume`, null, false, true)

export const putInviteResponse = (id, data) =>
  api.asyncPut('/v1/companies/vacancies/responses/' + id + '/accept', data)

export const putRefuseResponse = (id) =>
  api.asyncPut('/v1/companies/vacancies/responses/' + id + '/reject')

// Connected Services
export const getConnectedServices = () =>
  api.asyncGet('/v1/companies/working-with-the-site/services', null, false, true)

export const getMyServices = (data) =>
  api.asyncGet('/v1/companies/my-packages', data, false, true)

// Send Request For Payment
export const postRequestForPayment = (formData, typeOwnership, city, country) => {
  const data = formData
  data.type_ownership = typeOwnership.title
  data.city = city.title
  data.country = country.title

  delete data.type_ownership_id
  delete data.country_id
  delete data.city_id
  return api.asyncPost('/v1/companies/send-request-for-payment', data)
}
