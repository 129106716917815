<template>
<div class="vacancy__item">
  <div class="btns">
    <Button
      :name="invite ? $t('cancel_response') : $t('response')"
      :loading="loading.invite"
      @click="vacancyInvite"
    />
    <Button
      :name="favorite ? $t('remove_from_favorites') : $t('add_to_favorites')"
      :loading="loading.favorite"
      @click="vacancyToFavorite"
      type="gray"
    />
  </div>
</div>

<Modal
  :title="$t('message.to_respond_you_need_to_pass_a_test')"
  :btnTitle="$t('lets_go')"
  :hide="()=>{question = false}"
  :show="question"
  :agreement="() => {router.push(`/user/detail-vacancy/${vacancy.id}/passing-the-test`)}"
  quastion
/>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'

import { defineProps, defineEmits, reactive, ref } from 'vue'
import { useToast } from '@/modules/toast'
import { useI18n } from 'vue-i18n'
import router from '@/router/index.js'

import { postFavoriteVacancy, deleteFavoriteVacancy, postResponseVacancy, deleteResponseVacancy, getResumes, getTest } from '@/actions/employee'

const props = defineProps({
  vacancy: {
    type: Object,
    default: null
  },
  favorite: {
    type: Boolean,
    default: false
  },
  invite: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:favorite', 'update:invite'])

const { toast } = useToast()
const i18n = useI18n()

// ====================  Values  ==================== -->
const loading = reactive({
  favorite: false,
  invite: false
})

const question = ref(false)

const inviteId = ref(props.vacancy.invite.length !== 0 ? props.vacancy.invite[0].id : null)

// ==================== Click Events  ==================== -->
const vacancyToFavorite = async () => {
  loading.favorite = true
  try {
    if (!props.favorite) {
      await postFavoriteVacancy({ vacancy_id: props.vacancy.id })
      emit('update:favorite', true)
    } else {
      await deleteFavoriteVacancy(props.vacancy.id)
      emit('update:favorite', false)
    }
  } finally {
    loading.favorite = false
  }
}

const vacancyInvite = async () => {
  loading.invite = true
  try {
    if (!props.invite) {
      // Api Resumes
      const resumes = (await getResumes()).data.data
      if (resumes.length !== 0) {
        // Api Tests
        const tests = (await getTest(props.vacancy.id)).data.vacancy.company_tests
        if (tests.length !== 0) {
          question.value = true
        } else {
          const responseVacancy = (await postResponseVacancy(props.vacancy.id, { resume_id: resumes[0].id, test_id: null })).respond.id
          inviteId.value = responseVacancy
          emit('update:invite', true)
        }
      } else {
        toast({ message: i18n.t('message.zero_resume') })
        return
      }
    } else {
      await deleteResponseVacancy(inviteId.value)
      emit('update:invite', false)
    }
  } finally {
    loading.invite = false
  }
}

</script>

<style lang="scss" scoped>
.vacancy__item{
  & .btns{
    display: flex;
    grid-gap: 20px;
  }
}
@media (max-width: 480px){
  .vacancy__item{
    & .btns{
      flex-direction: column;
      grid-gap: 10px;
    }
  }
}
</style>
