import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueTheMask from 'vue-the-mask'
import { i18n } from './locales'
import Header from '@/components/uiLanding/layouts/Header.vue'
import Select from '@/components/common/Selects/Select.vue'
import Input from '@/components/common/Input.vue'
import Button from '@/components/common/Buttons/Button.vue'
import Loading from '@/components/common/Loading.vue'
import YmapPlugin from 'vue-yandex-maps'
import vClickOutside from 'click-outside-vue3'
import LayoutRow from '@/components/uiEmployee/Resume/Edit/LayoutRow.vue'
import NothingHere from '@/components/common/NothingHere.vue'
import Pagination from '@/components/common/Pagination.vue'

const settings = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  enterprise: false,
  version: '2.1'
}
createApp(App)
  .use(i18n)
  .use(vClickOutside)
  .component('Header', Header)
  .component('Select', Select)
  .component('Input', Input)
  .component('Button', Button)
  .component('Loading', Loading)
  .component('LayoutRow', LayoutRow)
  .component('NothingHere', NothingHere)
  .component('Pagination', Pagination)
  .use(VueTheMask)
  .use(YmapPlugin, settings)
  .use(router).mount('#app')
