<template>
  <Header />
  <section class="employeeServices">
    <div class="container">
      <div class="section__header">
        <h3 class="section__title">{{$t('services.title')}}</h3>
        <router-link class="link" to="/user/services/connected-services/result?page=1">{{$t('services.connected')}}</router-link>
      </div>
      <Services
        :services="services"
      />

    </div>
  </section>
</template>

<script setup>
import Services from '@/components/uiEmployee/Services/ListServices.vue'

import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'

import { getServices } from '@/actions/employee'

const services = ref(null)
const filter = ref(
  {
    perPage: 100,
    sort_type: false
  }
)

const fetchData = async () => {
  services.value = null
  const response = (await getServices(filter.value)).data.services
  services.value = response.data
}

onMounted(
  () => fetchData()
)
</script>
