<template>
<div class="driverCategories__list">
  <button
    class="driverCategories__item"
    :class="{active: formData.includes(category.id)}"
    type="button"
    v-for="category of options"
    :key="category"
    @click="() => {clickOption(category.id)}"
  >
    <span>{{category.title}}</span>
  </button>
</div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  modelValue: {
    type: Array,
    default: null
  },
  options: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['update:modelValue'])

const formData = ref(props.modelValue)

const clickOption = (categoryId, i) => {
  if (formData.value.indexOf(categoryId) !== -1) {
    const i = formData.value.indexOf(categoryId)
    formData.value.splice(i, 1)
  } else formData.value.push(categoryId)
  emit('update:modelValue', formData.value)
}
</script>

<style scoped lang="scss">
.driverCategories{
  &__list{
    display: flex;
    flex-wrap: wrap;
  }
  &__item{
    width: 46px;
    height: 46px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 0.5px solid rgba(33, 37, 41, 0.7);
    margin-top: -0.5px;
    margin-left: -1px;
    transition: all .2s linear;

    & span{
      font-size: 16px;
      color: rgba(33, 37, 41, 0.7);
      transition: all .2s linear;
    }

    &:hover{
      background-color: rgba(#4F553D, .3);
    }

    &.active{
      background-color:#4F553D;
      & span{
        color: #fff;
      }
    }

    @media (max-width: 992px){
      width: 44px;
      height: 44px;
      & span{
        font-size: 14px;
      }
    }
    @media (max-width: 768px){
      width: 40px;
      height: 40px;
      & span{
        font-size: 12px;
      }
    }
    @media (max-width: 576px){
      width: 38px;
      height: 38px;
      & span{
        font-size: 11px;
      }
    }

    @media (max-width: 400px){

    }
  }
}
</style>
