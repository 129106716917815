<template>
  <button
    class="toggle"
    type="button"
    :class="{active: agreement}"
    @click="clickToggle()"
  >
    <div class="check"></div>
  </button>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean],
    default: null
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation', 'checkbox'])

const agreement = ref(props.modelValue ?? false)

watch(
  () => props.modelValue,
  () => {
    agreement.value = props.modelValue
  }
)

const clickToggle = () => {
  agreement.value = !agreement.value
  if (agreement.value) emit('update:modelValue', true)
  else emit('update:modelValue', false)
}
</script>

<style lang="scss" scoped>
.toggle{
  height: 24px;
  width: 40px;
  background-color: #929292;
  border: 2px solid #929292;
  border-radius: 100px;
  position: relative;
  transition: all .2s linear;
  cursor: pointer;
  & .check{
    height: 100%;
    width: 20px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;

    position: absolute;
    top: 0;
    left: 0;
    transition: all .2s linear;
  }

  &.active{
    justify-content: flex-start;
    background-color: #4F553D;
    border: 2px solid #4F553D;

    & .check{
      left: calc(100% - 20px);
    }
  }
  &:active{
    & .check{
      width: 22px;
      left: calc(100% - 22px);
    }
  }

  @media (max-width: 992px){
    height: 22px;
    width: 38px;
    cursor: pointer;
    & .check{
      width: 18px;
    }

    &.active{
      & .check{
        left: calc(100% - 19px);
      }
    }

    &:active{
      & .check{
        width: 20px;
        left: calc(100% - 19px);
      }
    }
  }

  @media (max-width: 768px){
    height: 20px;
    width: 32px;
    cursor: pointer;
    & .check{
      width: 16px;
    }

    &.active{
      & .check{
        left: calc(100% - 16px);
      }
    }

    &:active{
      & .check{
        width: 18px;
        left: calc(100% - 16px);
      }
    }
  }

  @media (max-width: 576px){
    height: 18px;
    width: 30px;
    cursor: pointer;
    & .check{
      width: 14px;
    }

    &.active{
      & .check{
        left: calc(100% - 14px);
      }
    }

    &:active{
      & .check{
        width: 16px;
        left: calc(100% - 14px);
      }
    }
  }
}

</style>
