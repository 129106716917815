<template>
<div class="sendMessage__block">
  <h3 class="section__title">{{$t('write_a_letter')}}</h3>
  <form @submit.prevent="sendMessageToCompany()" method="POST">
      <Select
        :placeholder="$t('select_resume')"
        :options="resumes"
        v-model="formData.resume_id"
        v-model:validation="success.resume"
      />

      <Input
        class="sendMessage__textarea"
        id="text"
        type="textarea"
        :placeholder="$t('enter_your_cover_letter')"
        v-model="formData.description"
        v-model:validation="success.description"
        required
      />

      <div class="btns">
        <Button
          :name="$t('send')"
          :hasError="success"
          htmlType="submit"
        />
        <Button
          :name="$t('cancel')"
          type="gray"
          @click="() => {$emit('hide')}"
        />
      </div>
  </form>
  <Loading :show="loading" fixed />
</div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, onMounted, reactive } from 'vue'
import { getResumes, postSendMessageToCompany } from '@/actions/employee'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'hide'])

const formData = ref(props.modelValue)
const success = reactive({
  resume: false,
  description: false
})
const loading = ref(false)

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)

// ====================  Get Resumes ====================
const resumes = ref(null)
onMounted(
  async () => {
    resumes.value = (await getResumes()).data.data
  }
)

// ====================  Send Feedback this Company  ====================
const sendMessageToCompany = async () => {
  loading.value = true
  try {
    await postSendMessageToCompany(formData.value)
    props.hide()
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.sendMessage__block{
  padding: 20px 40px 40px;
  background: rgba(79, 85, 61, 0.2);
  margin-bottom: 40px;

  & .section__title{
    margin-bottom: 40px;
  }

  & .select{
    max-width: 332px;
    margin-bottom: 20px;
  }

  & textarea{
    max-width: 665px;
    margin-bottom: 40px;
  }

  & .btns{
      display: flex;
      grid-gap: 20px;
  }

  @media (max-width: 576px){
    padding: 15px 20px 30px;

    & .section__title{
      margin-bottom: 20px;
    }
    & .select {
      max-width: 100%;
      margin-bottom: 20px;
    }
    & textarea{
      margin-bottom: 25px;
    }
    & .btns{
      grid-gap: 15px;
    }
  }
  @media (max-width: 430px){
    padding: 10px 12px 20px;

    & .section__title,
    & .select{
      margin-bottom: 15px;
    }
    & textarea{
      margin-bottom: 15px;
    }
    & .btns{
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }
}
</style>
