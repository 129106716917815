<template>
  <Header />
  <section class="personalData">
      <div class="container">
        <h3 class="section__title">{{$t('personal_data')}}</h3>
        <div class="personalData__block" v-if="personalData">
          <div class="personalData__preview">
            <img v-if="personalData.photo" :src="`${apiUrl}/${personalData.photo}`" alt="">
            <img v-else src="/img/tmp/defold.png" alt="">
          </div>
          <div class="personalData__content">
            <div class="personalData__datas">
              <div class="personalData__name">{{personalData.name}} {{personalData.surname}} {{personalData.patronym ?? ''}}</div>
              <p>{{$t('date_birth')}}: {{personalData.show_birth === 1 ? moment(personalData.birthday).format('DD-MM-YYYY') : $t('hidden')}}</p>
              <p>{{$t('gender')}}: {{personalData.sex === 1 ? $t('man') : $t('woman')}}</p>
              <p>{{$t('country')}}: {{personalData.country}}</p>
              <p>{{$t('city')}}: {{personalData.city}}</p>
              <p>{{$t('address_origin')}}: {{personalData.show_address === 1 ? personalData.address : $t('hidden')}}</p>
              <p>E-mail: {{personalData.email}}</p>
              <p>{{$t('phone')}}: {{formatToPhone(personalData.phone)}}</p>
              <p v-if="personalData.second_phone">{{$t('second_phone')}}: {{formatToPhone(personalData.second_phone)}}</p>
              <p v-if="personalData.additional_contacts">{{$t('additional_contacts')}}: {{personalData.additional_contacts}}</p>
            </div>

            <Button class="btn" :name="$t('edit_personal_data')" go="/user/edit-personal-data"/>
          </div>
        </div>
        <div class="personalData__block" v-else>
          <div class="personalData__preview">
            <div class="img skeleton skeleton-img"></div>
          </div>
          <div class="personalData__content">
            <div style="width:100%;">
              <div class="personalData__name skeleton middle-text" style="width:100%;max-width: 500px;"></div>
              <div style="display:flex;margin-bottom:15px;grid-gap:10px" v-for="item of 7" :key="item"><span class="skeleton middle-text" style="width: 70px"></span><span class="skeleton middle-text"></span></div>
            </div>

            <Button class="skeleton" style="width:200px; pointer-events: none"/>
          </div>
        </div>
      </div>
  </section>
</template>

<script setup>

import { apiUrl } from '@/api'
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'

import { formatToPhone } from '@/helpers/formatPhone'
import { getPersonalData } from '@/actions/employee'
import moment from 'moment'

const personalData = ref(null)
onMounted(
  async () => {
    personalData.value = await getPersonalData()
  }
)
</script>

<style lang="scss">
.personalData{
    &__block{
      display: grid;
      grid-template-columns: 320px 1fr;
      grid-gap: 60px;
    }

    &__preview{
      width: 320px;
      height: 320px;
      position: relative;
      overflow: hidden;
      background-color: #ededed;

      & img{
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: 500px;
        height: 500px;
        transform: translate3D(-50%, -50%, 0);
      }
    }

    &__content{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__datas{
      margin-bottom: 30px;
    }

    &__name{
        font-size: 36px;
        color: #212529;
        margin-bottom: 30px;
    }
}
@media (max-width: 1100px){
  .personalData{
    &__block{
      grid-template-columns: 280px 1fr;
      grid-gap: 40px;
    }
    &__preview{
      width: 280px;
      height: 280px;
    }
    &__datas{
      margin-bottom: 20px;
    }
    &__name{
      font-size: 32px;
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 992px){
  .personalData{
    &__block{
      grid-template-columns: 260px 1fr;
      grid-gap: 30px;
    }
    &__preview{
      width: 260px;
      height: 260px;
    }
    &__name{
      font-size: 26px;
      margin-bottom: 11px;
    }
  }
}
@media (max-width: 768px){
  .personalData{
     &__block{
      grid-template-columns: 1fr;
      grid-gap: 40px;
    }
    &__preview{
      width: 240px;
      height: 240px;
    }
    &__name{
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 576px){
  .personalData{
     &__block{
      grid-gap: 30px;
    }
    &__preview{
      width: 220px;
      height: 220px;
    }
    &__name{
      font-size: 22px;
    }
  }
}

@media (max-width: 400px){
  .personalData{
    &__preview{
      margin: 0 auto;
    }
    &__name{
      font-size: 20px;
    }
  }
}
</style>
