<template>
  <Header />
  <section class="editPersonalData">
      <div class="container">
          <h3 class="section__title">{{ $t('edit_data') }}</h3>
          <div class="editPersonalData__block" v-if="formData && country && typeOwnerShip && industries">
              <UploadImage
                v-model="formData.logo"
                :company_id="formData.id"
                @onUpdated="fetchData"
              />
              <form class="editPersonalData__form" @submit.prevent="editCommonData(formData)" method="POST">
                <div>
                    <label for="companyName">{{$t('name_company')}}:</label>
                    <Input
                      id="companyName"
                      v-model="formData.name"
                      disabled/>
                </div>
                <div>
                    <label for="type_of_ownership">{{$t('type_of_ownership')}}</label>
                    <Select
                      id="type_of_ownership"
                      :options="typeOwnerShip"
                      v-model="formData.type_ownership_id"
                      v-model:validation="success.typeOwnerShip"/>
                </div>
                <div>
                  <label>{{$t('field_of_activity')}}</label>
                  <Select
                    id="field_of_activity"
                    :options="industries"
                    v-model="formData.industry_id"
                    v-model:validation="success.industry"/>
                </div>
                <div>
                    <label for="phone">{{$t('phone')}}:</label>
                    <Input
                      id="phone"
                      type="tel"
                      v-model="formData.phone"
                      required
                      disabled/>
                </div>
                <div>
                    <label for="email">Email:</label>
                    <Input
                      id="email"
                      type="email"
                      v-model="formData.email"
                      disabled/>
                </div>
                <div>
                    <label for="country">{{$t('country')}}:</label>
                    <Select
                      id="country"
                      :options="country"
                      v-model="formData.country_id"
                      v-model:validation="success.country"/>
                </div>
                <div>
                    <label for="city">{{$t('city')}}:</label>
                    <Select
                      id="city"
                      type="city"
                      :options="city"
                      v-model="formData.city_id"
                      v-model:validation="success.city"/>
                </div>
                <div>
                    <label for="address">{{$t('address_title')}}:</label>
                    <Input
                      id="text" type="text"
                      v-model="formData.address"
                      v-model:validation="success.address"
                      required/>
                </div>
                <div>
                    <label for="description">{{$t('description_company')}}:</label>
                    <Input
                      id="description" type="textarea"
                      v-model="formData.description"/>
                </div>
                <div class="map">
                    <label>{{$t('location_on_map')}}: <span>{{$t('show_location_of_company_on_map')}}</span></label>
                    <div>
                      <yandexMap :coords="coords" :zoom="14" @click="mapPosition">
                        <ymapMarker :coords="coords" marker-id="123" hint-content="some hint"/>
                      </yandexMap>
                    </div>
                </div>
                <div>
                    <div></div>
                    <div class="btns">
                      <Button
                        :name="$t('save_edited')"
                        htmlType="submit"
                        :hasError="success"/>
                      <Button
                        class="btn"
                        :name="$t('cancel')"
                        type="gray"
                        go="/company/common-data"/>
                    </div>
                </div>
              </form>
          </div>
          <div class="editPersonalData__block" v-else>
              <div class="editPersonalData__preview">
                <div class="img skeleton skeleton-img"></div>
              </div>
              <div class="editPersonalData__form">
                <div v-for="item of 9" :key="item">
                  <label class="skeleton middle-text"></label>
                  <div class="skeleton">
                    <Input disabled/>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <Loading class="fixed" :show="loading"/>
  </section>
</template>

<script setup>
import UploadImage from '@/components/common/Upload/Image/UploadImage.vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'

import { getCommonData, putCommonData } from '@/actions/company'
import { getCountries, getCities, getTypeOwnerShip, getIndustries } from '@/actions/help'
import router from '@/router/index.js'

const formData = ref({})
const success = reactive({
  address: false,
  industry: false,
  typeOwnerShip: false,
  country: false,
  city: false
})
const coords = ref([43.237219, 76.915007])
const loading = ref(false)

// ====================  Api Common Data  ====================
const fetchData = async () => {
  formData.value = {}
  formData.value = (await getCommonData()).company
}
// ====================  Api Country And City  ====================
const typeOwnerShip = ref(null)
const industries = ref([])
const country = ref(null)
const city = ref(null)

onMounted(
  async () => {
    fetchData()
    typeOwnerShip.value = (await getTypeOwnerShip()).data
    industries.value = await getIndustries()
    country.value = (await getCountries()).data
    coords.value = [formData.value.width, formData.value.height]
  }
)
watch(
  () => formData.value.country_id,
  async () => {
    if (formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
  }
)

// ====================  Edit Common Data  ====================
const editCommonData = async () => {
  try {
    loading.value = true
    formData.value.width = String(coords.value[0])
    formData.value.height = String(coords.value[1])
    await putCommonData(formData.value)
    router.push('/company/common-data')
  } finally {
    loading.value = false
  }
}

// ====================  Position Map  ====================
const mapPosition = (e) => {
  if (e.get) {
    coords.value = e.get('coords')
  }
}
</script>

<style lang="scss">
.editPersonalData__form{
  & > div{
    grid-template-columns: 217px max(440px);
    align-items: flex-start;
  }

  & label{
    margin-top: 10px;
  }
  & .map{
    & > div{
      height: 200px;
      background-color: #ededed;
    }
  }
}
@media (max-width: 1200px){
  .editPersonalData{
    &__form{
      & > div{
        grid-template-columns: 217px 1fr;
      }
    }
  }
}
</style>
