<template>
<LayoutRow
  :title="$t('awards')"
  v-model="formData.award_on"
>
  <RowAward
    v-for="(award, i) of formData.awards"
    :key="award"
    :award="award"
    @onDelete="deleteAward(i)"
  />
  <Button
    :name="$t('add_more')"
    class="createResume__AddMore"
    type="darkGreen"
    @click="onAddAward()"
  />
</LayoutRow>
</template>

<script setup>
import RowAward from '@/components/uiEmployee/Resume/Edit/Awards/RowAward.vue'

import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
formData.value.deleteAward = []

watch(
  () => [formData.value, formData.value.award_on],
  () => {
    if (formData.value.award_on && formData.value.awards.length === 0) onAddAward()
    emit('update:modelValue', formData.value)
  }
)

if (formData.value.awards.length !== 0) formData.value.award_on = true
else formData.value.award_on = false

const onAddAward = () => {
  formData.value.awards.push({
    name: '',
    description: null,
    file: null
  })
}
const deleteAward = i => {
  if (formData.value.awards[i].id) formData.value.deleteAward.push(formData.value.awards[i].id)
  formData.value.awards.splice(i, 1)
  if (formData.value.awards.length === 0) formData.value.award_on = false
}
</script>
