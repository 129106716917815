<template>
<div class="myResume__item">
  <div class="top">
    <router-link :to="'/user/detail-resume/' + resume.id" class="prof">{{resume.title}}</router-link>
    <div class="top-left">
      <div class="price">{{formatToSalary(resume.try_salary ? resume.try_salary : '')}} {{resume.currency ? resume.currency.title : ''}}</div>
      <button class="btn delete" @click="quastion = true">
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#delete"></use>
        </svg>
        <span>{{$t('delete')}}</span>
      </button>
    </div>
  </div>
  <div class="center">
    <div class="text" v-if="resume.country">{{$t('citizenship')}}: {{resume.country.title}}</div>
    <div class="text" v-if="resume.willing_to_relocate && resume.willing_to_relocate.length !== 0">{{$t('work_permit')}}: {{resume.willing_to_relocate[0].title}}</div>
  </div>
  <div class="bottom">
    <div class="left">
      <div class="date">{{moment(resume.updated_at).format('DD-MM-YYYY')}}</div>
      <a class="print" :href="`/user/detail-resume/${resume.id}/download-pdf`">
        <svg>
            <use xlink:href="/img/tmp/sprite2.svg#print"></use>
        </svg>
      </a>
    </div>
    <div class="right">
      <Button
        class="look"
        :name="$t('look')"
        type="gray"
        :go="'/user/detail-resume/' + resume.id"
      >
        <svg>
            <use xlink:href="/img/tmp/sprite2.svg#look"></use>
        </svg>
      </Button>
      <Button
        class="edit"
        :name="$t('redact')"
        type="gray"
        :go="'/user/edit-resume/' + resume.id"
      >
        <svg>
            <use xlink:href="/img/tmp/sprite2.svg#edit"></use>
        </svg>
      </Button>
    </div>
  </div>
</div>
  <Modal
    :hide="()=>{quastion = false}"
    :show="quastion"
    quastion
    :agreement="deleteResumes"
    :loading="loading"
  />
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'

import { defineProps, ref } from 'vue'
import moment from 'moment'

import { formatToSalary } from '@/helpers/formatSalary'
import { deleteResume } from '@/actions/employee'

const props = defineProps({
  resume: {
    type: Object,
    default: () => {}
  },
  deleted: {
    type: Function,
    default: () => null
  }
})
const quastion = ref(false)
const loading = ref(false)

const deleteResumes = async () => {
  loading.value = true
  try {
    await deleteResume(props.resume.id)
    quastion.value = false
    props.deleted()
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.myResume{
  &__item{
    display: block;
    padding: 20px 30px;
    border: 0.5px solid rgba(33, 37, 41, 0.7);
    margin-bottom: 15px;
  }
  & .top,
  & .bottom{
    display: flex;
    justify-content: space-between;
  }
  & .top{
    margin-bottom: 10px;
  }
  & .prof{
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: #212529;
    transition: all .2s linear;

    position: relative;

    &::after{
      content: '';
      display: block;
      height: 0;
      width: 0;

      position: absolute;
      top: 89%;
      left: 0;

      border-bottom: 3px solid #AD1717;
      transition: all .2s linear;
    }

    &:hover{
      color: #AD1717;
      &::after{
        width: 100%;
      }
    }
  }
  & .top-left{
    display: flex;
    grid-gap: 20px;
  }
  & .price{
    font-size: 24px;
    text-align: end;
    text-transform: uppercase;
    color: #212529;
  }
  & .center{
    margin-bottom: 10px;
  }
  & .text,
  & .date{
    font-size: 16px;
    line-height: 1.2;
    color: rgba(33, 37, 41, 0.7);
  }
  & .state{
    font-size: 16px;
    color: #2CA622;
  }
  & .bottom{
    align-items: flex-end;
    & .right{
      display: flex;
      grid-gap: 10px;
    }
  }
  & .left{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-right: 10px;
  }
  & .print{
    margin-bottom: 7px;

    &.skeleton{
      width: 24px;
      height: 24px;
      margin-bottom: 0;
    }

    & svg{
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      fill: rgba(#4F553D, .7);
    }
  }
  & .look,
  & .edit{
    display: flex !important;
    align-items: center;

    &.skeleton{
      min-width: 120px;
    }

    & svg{
      display: block;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      fill: rgba(#4F553D, .7);
    }
  }
  & .delete{
    display: flex;
    grid-gap: 4px;
    align-items: center;
    & span{
      color: #AD1717;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 500;
    }
    & svg{
      display: block;
      width: 18px;
      height: 18px;
      fill: #AD1717;
    }
    &::before{
      background-color: #AD1717;
    }
  }
  @media (max-width: 992px){
    &__item{
      padding: 15px 25px;
      margin-bottom: 15px;
    }
    & .top{
      margin-bottom: 8px;
    }
    & .prof{
      font-size: 20px;
    }
    & .top-left{
      grid-gap: 18px;
    }
    & .price{
      font-size: 22px;
    }
    & .center{
      margin-bottom: 8px;
    }
    & .text,
    & .date,
    & .state{
      font-size: 14px;
    }
    & .left{
      margin-right: 8px;
    }
    & .print{
      margin-bottom: 8px;

      &.skeleton{
        width: 22px;
        height: 22px;
      }

      & svg{
        width: 22px;
        height: 22px;
        margin-right: 3px;
      }
    }
    & .look,
    & .edit{
      & svg{
        width: 12px;
        height: 12px;
        margin-right: 8px;
      }
    }
    & .delete{
      & span{
        font-size: 14px;
      }
      & svg{
        width: 16px;
        height: 16px;
      }
    }
  }
  @media (max-width: 768px){
    &__item{
      padding: 10px 18px;
      margin-bottom: 10px;
    }
    & .top{
      margin-bottom: 8px;
    }
    & .prof{
      font-size: 18px;
    }
    & .top-left{
      grid-gap: 16px;
    }
    & .price{
      font-size: 20px;
    }
    & .center{
      margin-bottom: 8px;
    }
    & .text,
    & .date,
    & .state{
      font-size: 12px;
    }
    & .left{
      margin-right: 6px;
    }
    & .print{
      margin-bottom: 6px;

      &.skeleton,
      & svg{
        width: 20px;
        height: 20px;
      }

      & svg{
        margin-right: 3px;
      }
    }
    & .delete{
      & span{
        font-size: 12px;
      }
      & svg{
        width: 14px;
        height: 14px;
      }
    }
    & .look,
    & .edit{
      width: 100%;
      &.skeleton{
        width: 100% !important;
      }
    }
  }
  @media (max-width: 576px){
    &__item{
      padding: 8px 14px;
    }
    & .prof{
      font-size: 14px;
    }
    & .top-left{
      align-items: flex-end;
      flex-direction: column;
      grid-gap: 6px;
    }
    & .price{
      font-size: 16px;
    }
    & .text,
    & .date,
    & .state{
      font-size: 10px;
    }
    & .left{
      margin-right: 6px;
    }
    & .print{
      margin-bottom: 6px;

      &.skeleton,
      & svg{
        width: 18px;
        height: 18px;
      }
    }
    & .delete{
      & span{
        font-size: 12px;
      }
      & svg{
        width: 14px;
        height: 14px;
      }
    }
    & .look,
    & .edit{
      width: 100%;
      &.skeleton{
        width: 100% !important;
      }
    }

    & .bottom{
      & .left{
        margin: 0;
      }
      & .right{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      flex-direction: column;
      grid-gap: 8px;
    }
  }
  @media (max-width: 400px){
    &__item{
      padding: 8px 10px;
    }
    & .prof{
      font-size: 12px;
    }
    & .top-left{
      grid-gap: 4px;
    }
    & .price{
      font-size: 14px;
    }
    & .left{
      margin-right: 4px;
    }
    & .print{
      margin-bottom: 4px;

      &.skeleton,
      & svg{
        width: 16px;
        height: 16px;
      }
    }
    & .delete{
      & span{
        font-size: 10px;
      }
      & svg{
        width: 12px;
        height: 12px;
      }
    }

    & .bottom{
      & .right{
        grid-gap: 6px;

        & svg{
          display: none !important;
        }
      }
      grid-gap: 6px;
    }
  }
}
</style>
