<template>
  <div>
    <Header />
    <div class="bread_crumbs_bl">
        <div class="container">
            <ul class="bread_crumbs_bl__inner">
                <li><router-link :to="'/'">{{$t('home')}}</router-link></li>
                <li><router-link :to="'/news/result?page=1'">{{$t('news')}}</router-link></li>
                <li>{{newsDetail? newsDetail.title : ''}}</li>
            </ul>
        </div>
    </div>
    <section class="newsDetail">
        <div class="container" v-if="newsDetail">
            <h3 class="section__title">{{newsDetail.title}}</h3>
            <div class="section__block">
                <div class="section__preview">
                    <div>
                        <img :src="`${apiUrl}/${newsDetail.image}`" alt="">
                    </div>
                </div>
                <div class="section__content">
                    <div class="newsDetail__date">{{newsDetail.date}}</div>
                    <div class="newsDetail__text" v-html="newsDetail.text">
                    </div>
                </div>
            </div>
        </div>

        <div class="container skeleton__block" v-else>
            <h3 class="section__title skeleton small-text" style="width:70%"></h3>
            <div class="section__block">
                <div class="section__preview">
                    <div>
                        <div class="img skeleton skeleton-img"></div>
                    </div>
                </div>
                <div class="section__content">
                  <div>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:100%;height:" v-for="k of 7" :key="k"></p>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:80%;"></p>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:30%;"></p>
                  </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script setup>
import { apiUrl } from '@/api'
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { getNewsDetail } from '@/actions/home'
import { onMounted } from 'vue-demi'

const newsDetail = ref(null)
const route = useRoute()
onMounted(
  async () => {
    newsDetail.value = await getNewsDetail(route.params.news_id)
  }
)
</script>

<style lang="scss" scoped>
.newsDetail{
  &__date,
  &__text p{
      color: #A32D2B;
      font-size: 16px;
      margin-bottom: 28px;
  }

  &__text p{
      color: rgba(#212529, .7);
  }

  & img{
    background-color: #ededed;
  }

  @media (max-width: 992px){
    &__date,
    &__text p{
        margin-bottom: 15px;
    }
  }

}

.skeleton__block{
  & .middle-text{
    height: 1.5em;
  }
}
</style>
