<template>
<div>
  <div class="personalData__preview">
    <img v-if="modelValue" :src="`${apiUrl}/${modelValue}`" alt="">
    <img v-else src="/img/tmp/defold.png" alt="">
    <button
      class="btn"
      @click="() => {modelValue ? showModal.profilePhoto = true : showModal.editPhoto = true}"
    >
      {{$t('select_photo')}}
    </button>
  </div>
  <Modal
    class="modal__profilePhoto"
    :class="showModal.editPhoto ? 'edit' : '' + showModal.editPhoto ? 'crop' : ''"
    :hide="() => {hideModal()}"
    :show="showModal.profilePhoto || showModal.editPhoto || showModal.cropPhoto"
    :loading="loading"
  >
    <ProfilePhoto
      v-if="showModal.profilePhoto"
      :photo="formData"
      @onDelete="() => {daleteImage()}"
      @onEdit="() => {showModal.editPhoto = true, showModal.profilePhoto = false}"
    />
    <EditProfilePhoto
      v-if="showModal.editPhoto"
      v-model="formData"
      @goCrop="() => {showModal.editPhoto = false, showModal.cropPhoto = true}"
    />
    <CropProfilePhoto
      v-if="showModal.cropPhoto"
      v-model="formData"
      @onSave="() => {updateImage()}"
    />
  </Modal>
</div>

</template>

<script setup>
import ProfilePhoto from '@/components/common/Upload/Image/ProfilePhoto.vue'
import EditProfilePhoto from '@/components/common/Upload/Image/EditProfilePhoto.vue'
import CropProfilePhoto from '@/components/common/Upload/Image/CropProfilePhoto.vue'
import Modal from '@/components/common/Modal.vue'

import { apiUrl } from '@/api'
import { ref, defineProps, defineEmits } from 'vue'
import { postEmployeeUploadImage } from '@/actions/employee'
import { postImage, deleteImage } from '@/actions/company'

const props = defineProps({
  modelValue: [File, Object, String],
  company_id: {
    type: [String, Number],
    default: null
  }
})
const emit = defineEmits('onUpdated')

const formData = ref(props.modelValue)
const loading = ref(false)

const showModal = ref({
  profilePhoto: false
})

const hideModal = () => {
  showModal.value = {}
}

// ====================  Update Image  ====================
async function updateImage () {
  loading.value = true
  try {
    if (!props.company_id) await postEmployeeUploadImage(formData.value)
    else await postImage(props.company_id, formData.value)
    hideModal()
    emit('onUpdated')
  } finally {
    loading.value = false
  }
}
// ====================  Delete Image  ====================
async function daleteImage () {
  loading.value = true
  try {
    await deleteImage(props.company_id)
    hideModal()
    emit('onUpdated')
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.personalData__preview{
  position: relative;
  display: flex;
  align-items: flex-end;

  & .btn{
    padding: 10px 40px;
    font-size: 18px;
    color: rgba(#000, 0.6);
    text-transform: none;
    border: .5px solid rgba(#000, 0.6);
    background: rgba(#fff, .6);
    margin: 0 auto 40px;
    position: relative;
    z-index: 10;
    cursor: pointer;
    transition: all .2s linear;

    &:hover{
      background-color: #fff;
    }
  }
}

@media (max-width: 768px){
  .personalData__preview .btn{
    font-size: 16px;
    margin-bottom: 20px;
    padding: 8px 20px;
  }
}
</style>

<style lang="scss">
.modal__profilePhoto {
  & .modal__inner{
    max-width: 600px;
    padding: 45px 58px 60px;
  }

  &.edit,
  &.crop {
    & .modal__inner{
      max-width: 730px;
    }
  }

  & .modal__title{
    text-transform: none;
    margin-bottom: 20px;
  }
}
</style>
