<template>
<div class="contacts user__contacts">
  <button
    v-if="!detailResume"
    @click="() => {showContact = true}"
    class="text"
  >
    {{$t('show_contacts')}}
  </button>

  <Transition name="drop">
    <div class="infoDrop" v-if="showContact || detailResume" v-click-outside="() => { showContact = false }">
      <div class="name">{{`${user.name} ${user.surname}`}}</div>
      <a class="phone" :href="'tel:' + user.phone">{{ formatToPhone(user.phone) }}</a>
      <a class="email" :href="'mailto:' + user.email">{{ user.email }}</a>
      <div class="address">{{user.placeOfResidence}}</div>
      <button class="closeBtn" @click.stop @click="() => {showContact = false}">
        <svg class="closeBtn__svg">
            <use xlink:href="/img/tmp/sprite.svg#close"></use>
        </svg>
      </button>
    </div>
  </Transition>
</div>
</template>

<script setup>
import { defineProps, reactive, ref } from 'vue'
import { formatToPhone } from '@/helpers/formatPhone'

const props = defineProps({
  userEmployee: {
    type: Object,
    default: () => {}
  },
  detailResume: {
    type: Boolean,
    default: false
  }
})

const user = reactive(props.userEmployee)
const showContact = ref(false)

// User Place of Residence
user.placeOfResidence = ''
if (user.city) user.placeOfResidence += user.city.title
if (user.city && user.address) user.placeOfResidence += ', '
if (user.address) user.placeOfResidence += user.address
</script>

<style lang="scss">
.user__contacts{
  position: relative;
  & .text:hover{
    text-decoration: underline;
  }
  & .infoDrop{
    text-align: start;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    padding: 20px;
    width: 320px;
    max-width: 320px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    transition: all .2s linear;
  }
  & .closeBtn{
    position: absolute;
    top: 17px;
    right: 17px;
    transition: transform .2s linear;

    &:hover{
      transform: rotate(-90deg);
      & svg{
        fill: #212529;
      }
    }
  }
  & .closeBtn__svg{
    display: block;
    width: 16px;
    height: 16px;
    fill: #a7a7a7;
    transition: fill .2s linear;
  }
  & .name,
  & .phone,
  & .email,
  & .address{
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: #212529;
    margin-bottom: 5px;
  }
  & .email,
  & .address{
    color: #227BD5;
  }
  & .email,
  & .phone{
    display: block;
    margin-bottom: 10px;
    &:hover{
      text-decoration: underline;
    }
  }
  & .email{
    margin-bottom: 20px;
  }
  .drop-enter-active,
  .drop-leave-active {
    transition: all .2s linear;
  }
  .drop-enter-from,
  .drop-leave-to {
    transform: translateY(30px);
    opacity: 0;
  }
}

.resumeDetail{
  & .infoDrop{
    position: static;
    padding: 0;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  & .closeBtn{
    display: none;
  }

  & .name,
  & .phone,
  & .email,
  & .address{
    font-size: 18px;
  }
}

@media (max-width: 992px){
  .user__contacts{
    & .infoDrop{
      padding: 18px;
      width: 300px;
      max-width: 300px;
    }
    & .closeBtn{
      top: 16px;
      right: 16px;
    }
    & .closeBtn__svg{
      width: 14px;
      height: 14px;
    }
    & .name,
    & .phone,
    & .email,
    & .address{
      font-size: 14px;
      margin-bottom: 4px;
    }
    & .email,
    & .phone{
      margin-bottom: 8px;
    }
    & .email{
      margin-bottom: 18px;
    }
  }

  .resumeDetail{
    & .name,
    & .phone,
    & .email,
    & .address{
      font-size: 16px;
    }
  }
}
@media (max-width: 768px){
  .user__contacts{
    & .infoDrop{
      padding: 16px;
      width: 260px;
      max-width: 260px;
    }
    & .closeBtn{
      top: 14px;
      right: 14px;
    }
    & .closeBtn__svg{
      width: 12px;
      height: 12px;
    }
    & .name,
    & .phone,
    & .email,
    & .address{
      font-size: 12px;
      margin-bottom: 4px;
    }
    & .email,
    & .phone{
      margin-bottom: 6px;
    }
    & .email{
      margin-bottom: 16px;
    }
  }

  .resumeDetail{
    & .name,
    & .phone,
    & .email,
    & .address{
      font-size: 14px;
    }
  }
}
@media (max-width: 576px){
  .user__contacts{
    & .infoDrop{
      padding: 14px;
      width: 220px;
      max-width: 220px;
    }
    & .closeBtn{
      top: 12px;
      right: 12px;
    }
    & .name,
    & .phone,
    & .email,
    & .address{
      font-size: 11px;
    }
    & .email,
    & .phone{
      margin-bottom: 4px;
    }
    & .email{
      margin-bottom: 14px;
    }
  }

  .resumeDetail{
    & .name,
    & .phone,
    & .email,
    & .address{
      font-size: 12px;
    }
  }
}

@media (max-width: 400px){

}
</style>
