<template>
  <div>
    <Header />
    <div class="bread_crumbs_bl">
        <div class="container">
            <ul class="bread_crumbs_bl__inner">
                <li><router-link :to="'/'">{{$t('home')}}</router-link></li>
                <li><a :href="'/#hotVacancies'">{{$t('LastMinuteJobs')}}</a></li>
                <li>{{pageTitle}}</li>
            </ul>
        </div>
    </div>

    <section class="burningVacancies">
        <div class="container">
            <h3 class="section__title">{{pageTitle}}</h3>

            <NothingHere v-if="vacancies && vacancies.length === 0"/>

            <div class="burningVacancies__items" v-if="vacancies">
              <button
                v-for="vacancy of vacancies"
                :key="vacancy"
                class="burningVacancies__item"
                @click="() => {checkRoleId(vacancy.id)}"
              >
                  <div>
                    <div class="burningVacancies__name">{{vacancy.position}}</div>
                    <div class="burningVacancies__price">
                      {{vacancy.min_salary ? formatToSalary(vacancy.min_salary) : ''}}
                      {{vacancy.min_salary && vacancy.max_salary ? '-' : ''}}
                      {{vacancy.max_salary ? formatToSalary(vacancy.max_salary) : ''}}
                      {{` ${vacancy.currency?.title ?? ''}`}}
                    </div>
                  </div>
              </button>
          </div>

          <div class="burningVacancies__items" v-else>
              <div
                v-for="vacancy of 9"
                :key="vacancy"
                class="burningVacancies__item skeleton__block "
              >
                  <div>
                    <div class="burningVacancies__name skeleton middle-text"></div>
                    <div class="burningVacancies__price skeleton"></div>
                  </div>
              </div>
          </div>
        </div>
    </section>
  </div>
  <Modal
    :title="$t('message.you_need_to_log_in_as_a_user')"
    :btnTitle="$t('authorization_button')"
    :hide="()=>{quastion = false}"
    :show="quastion"
    :agreement="() => {router.push('/authorization/login')}"
    quastion
  />
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'

import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { onMounted } from 'vue-demi'
import router from '@/router/index.js'

import { formatToSalary } from '@/helpers/formatSalary'
import { getHotVacancyDetail } from '@/actions/home'

const vacancies = ref(null)
const pageTitle = ref(null)
const quastion = ref(false)

const route = useRoute()

onMounted(
  async () => {
    const response = (await getHotVacancyDetail(route.params.burning_vacancie_id)).data
    vacancies.value = response.data
    pageTitle.value = response.professional_area
  }
)

const checkRoleId = vacancyId => {
  if (localStorage.getItem('role_id') === '3') {
    router.push('/user/detail-vacancy/' + vacancyId)
  } else {
    quastion.value = true
  }
}
</script>

<style lang="scss" scoped>
.burningVacancies{
  &__items{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }

  &__item{
    display: flex;
    min-height: 182px;
    text-align: start;

    padding: 20px 28px;

    border: 1px solid transparent;
    border-left: 5px solid #4F553D;
    background-color: #fff;
    box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.1);

    transition: transform .4s linear, border-color .2s linear;

    &:hover{
      border-color: #AD1717;
      transform: translate(5px, -10px);

      & .burningVacancies__price{
        background-color: rgba(173, 23, 23, 0.2);
      }
    }
  }

  &__name{
    color: #4D533C;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
    text-transform: uppercase;

    margin-bottom: 5px;
  }

  &__price{
    color: rgba(#212529, .7);
    font-size: 16px;
    line-height: 1.6;

    display: inline-block;
    padding: 2px 10px;
    background-color: rgba(79, 85, 61, 0.1);
    transition: background-color .2s linear;
  }

  // Sceleton
  .skeleton__block{
    box-shadow: none !important;
    & .burningVacancies{
      &__name{
        margin-bottom: 10px;
      }
      &__price{
        width: 170px;
        max-width: 100%;
        height: 30px;
      }
    }
  }

  // Adaptive
  @media (max-width: 1200px){
    &__items{
      grid-gap: 25px;
    }

    &__item{
      min-height: 170px;
      padding: 18px 26px;
    }

    &__name{
      font-size: 20px;
      margin-bottom: 4px;
    }

    &__price{
      font-size: 14px;
    }
  }
  @media (max-width: 992px){
    &__items{
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    &__item{
      min-height: 150px;
      padding: 16px 24px;
    }

    &__name{
      font-size: 18px;
    }

    &__price{
      font-size: 12px;
    }
  }
  @media (max-width: 768px){
    &__items{
      grid-gap: 15px;
    }

    &__item{
      min-height: 130px;
      padding: 14px 22px;
    }

    &__name{
      font-size: 16px;
    }

    &__price{
      font-size: 11px;
    }
  }

  @media (max-width: 576px){
    &__items{
      grid-template-columns: 1fr;
    }
    &__item{
      min-height: 100px;
      padding: 12px 20px;
    }
  }
  @media (max-width: 400px){
    &__items{
      grid-gap: 12px;
    }

    &__item{
      padding: 12px 20px;
    }

    &__name{
      font-size: 14px;
    }

    &__price{
      font-size: 10px;
    }
  }
}
</style>
