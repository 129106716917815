<template>
  <Header />
  <section class="responses">
    <div class="container">
      <h3 class="section__title">{{$t('responses_to_a_job')}}</h3>
      <HeaderFilter
        v-if="vacancies.length !== 0"
        v-model="filter"
        :vacancies="vacancies"
        @search="fetchData()"
        forResponse
      />
      <NothingHere v-if="responses && responses.length === 0"/>
      <ListResponses
        v-else
        :responses="responses"
      />
      <Pagination
        v-if="responses"
        v-model="filter.page"
        :perPage="filter.per_page"
        :total="total"
        @newPage="() => {fetchData(filter.page)}"
      />
    </div>
  </section>
</template>

<script setup>
import HeaderFilter from '@/components/uiLanding/Filter/Header/HeaderFilter.vue'
import ListResponses from '@/components/uiCompany/Responses/ListResponses.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'
import { useI18n } from 'vue-i18n'

import { getResponses, getVacanciesShow } from '@/actions/company'

const i18n = useI18n()
const route = useRoute()

const responses = ref(null)
const vacancies = ref([])
// Filter
const filter = reactive(
  {
    name: typeof route.query.search !== 'undefined' ? route.query.search : '',
    sort_type: route.query.type === 'date_asc' ? 'date_asc' : null,
    vacancy_id: typeof route.query.vacancy !== 'undefined' ? Number(route.query.vacancy) : null,
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20
  }
)
const total = ref(null)

// FetchData
const fetchData = async (page = 1) => {
  filter.page = page
  responses.value = null
  const response = (await getResponses(filter)).data.responds
  responses.value = response.data
  total.value = response.total
  router.push({ path: '/company/working-with-the-site/vacancy-responses/result', query: { search: filter.name, type: filter.sort_type, vacancy: filter.vacancy_id, page: page } })
}

onMounted(
  async () => {
    const response = (await getVacanciesShow()).data
    vacancies.value.push(
      {
        title: i18n.t('all_responses'),
        id: null
      }
    )
    response.forEach(vacancy => {
      vacancies.value.push(
        {
          title: vacancy.position,
          id: vacancy.id
        }
      )
    })
    fetchData(filter.page)
  }
)

watch(
  () => route.query,
  () => {
    if (route.name === 'ResponsesForVacancy') {
      const sort = route.query.type === 'date_asc' ? 'date_asc' : null
      const name = typeof route.query.search !== 'undefined' ? route.query.search : ''

      if (filter.period !== route.query.period || filter.name !== name || filter.sort_type !== sort) {
        filter.vacancy_id = Number(route.query.vacancy)
        filter.name = name
        filter.sort_type = sort
        filter.page = 1
      } else filter.page = route.query.page ?? 1
      fetchData(filter.page)
    }
  }
)
</script>
