<template>
  <div>
    <div class="modal__title">{{$t('change_your_profile_photo')}}</div>
    <div
      @dragover="onDragOver"
      @dragleave="drag = false"
      @drop="onDrop"
      @click="input.click()"
      class="dropzone"
      :class="{ 'active-dropzone': drag }"
    >
      <div class="preview">
        <div class="img">
          <img src="/img/tmp/uploadImage.svg" alt="">
        </div>
        <div class="img">
          <img src="/img/tmp/uploadImage.svg" alt="">
        </div>
        <div class="img">
          <img src="/img/tmp/uploadImage.svg" alt="">
        </div>
      </div>

      <div class="content">
        <span class="editProfilePhoto__subtitle">{{$t('drag_photo_here')}}</span>
        <span class="or">{{$t('or')}}</span>
        <label for="dropzoneFile" @click.stop>{{$t('download_from_device')}}</label>
      </div>
      <input
        type="file"
        id="dropzoneFile"
        class="dropzoneFile"
        :accept="type"
        @change="onUpload"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import { apiUrl } from '@/modules/api.js'

const props = defineProps({
  modelValue: [File, Object, String],
  type: {
    type: String,
    default: 'image/png, image/gif, image/jpeg, image/svg'
  }
})
const emit = defineEmits([
  'update:modelValue',
  'goCrop'
])

const drag = ref(false)
const image = ref(props.modelValue?.link)

const onDragOver = event => {
  event.stopPropagation()
  event.preventDefault()
  drag.value = true
}

const onDrop = event => {
  event.stopPropagation()
  event.preventDefault()
  const fileList = event.dataTransfer.files
  if (fileList.length) upload(fileList)
  drag.value = false
}

const onUpload = event => {
  const fileList = event.target.files
  if (fileList.length) upload(fileList)
}

const upload = files => {
  emit('update:modelValue', files[0])
  const reader = new FileReader()
  reader.readAsDataURL(files[0])
  reader.onload = () => {
    image.value = reader.result
    emit('goCrop')
  }
}

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) image.value = props.modelValue?.link ? `${apiUrl}/storage/${props.modelValue?.link}` : props.modelValue
  }
)
</script>

<style scoped lang="scss">
.dropzone {
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed #b6b6b6;
  background-color: #fff;
  transition: 0.2s linear all;

  label {
    cursor: pointer;
    display: block;
    padding: 4px 10px;
    color: #278AF5;
    font-size: 14px;
    background-color: transparent;
    border: 2px solid #278AF5;
    border-radius: 3px;
    transition: 0.2s linear all;

    &:hover,
    &:active{
      background-color: #d4e6f8;
    }
  }

  input {
    display: none;
  }
}

.active-dropzone {
  background-color: #d4e6f8;
  border-color: #278AF5;
  & .preview{
    & .img:nth-child(2){
      transform: translateY(-15px);
      box-shadow: 0 2px 10px rgba(#000, .12);
      & img{
        transform: scale(1.2);
      }
    }

    & .img:first-child{
      transform: translateX(-54px);
      & img{
        transform: rotate(-20deg);
      }
    }
    & .img:last-child{
      transform: translateX(58px);
      & img{
        transform: rotate(20deg);
      }
    }
  }
}

.preview{
  position: relative;
  margin-bottom: 15px;
  & .img{
    position: relative;
    z-index: 2;
    transition: 0.2s linear all;
    & img{
      height: 80px;
      transition: 0.2s linear all;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  & .img:nth-child(2){
    z-index: 3;
  }

  & .img:first-child,
  & .img:last-child{
    position: absolute;
    z-index: 1;
    top: 0;
    filter: grayscale(100%);
    & img{
      height: 75px;
    }
  }
}

.content{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editProfilePhoto__subtitle{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 7px;
}
.or{
  font-size: 14px;
  font-weight: 400;
  color: #5f6368;
  margin-bottom: 12px;
}
</style>
