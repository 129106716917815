<template>
  <div>
    <Header/>
    <div class="wrapper">
      <div class="title">404</div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 220px;
  font-weight: 700;
}
@media (max-width: 576px){
  .title {
    font-size: 160px;
  }
}
@media (max-width: 420px){
  .title {
    font-size: 120px;
  }
}
</style>
