<template>
  <Header />
  <section class="searchResumes">
    <div class="container">
      <div class="section__header">
        <h3 class="section__title">{{$t('resume')}}</h3>
        <router-link class="link" to="/company/favorites-resumes/result?page=1">{{$t('favorites_resumes')}}</router-link>
      </div>
      <HeaderFilter
        v-model="filter"
        @search="fetchData()"
        @showSideBar="() => {showSideBar = true}"
        forVacancy
        withFilterBtn
      />
      <div class="searchFilters__block">
        <SidebarFilter
          v-model="filter"
          @search="fetchData()"
          v-model:showSideBar="showSideBar"
          forResumes
        />

        <div>
          <ListResumes :resumes="resumes"/>
          <Pagination
            v-if="resumes"
            v-model="filter.page"
            :perPage="filter.perPage"
            :total="total"
            @newPage="fetchData(filter.page)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import ListResumes from '@/components/uiCompany/Resumes/ListResumes.vue'
import HeaderFilter from '@/components/uiLanding/Filter/Header/HeaderFilter.vue'
import SidebarFilter from '@/components/uiLanding/Filter/Sidebar/ListSidebarFilter.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, provide, watch } from 'vue-demi'
import { getResumes } from '@/actions/company'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

const route = useRoute()

const resumes = ref(null)
const showSideBar = ref(false)

const checkArray = input => {
  if (typeof input === 'object') return input
  else if (!input) return []
  return [input]
}

const filter = reactive(
  {
    // Header
    name: route.query.search,
    period: route.query.period ?? 'month',
    sort_type: route.query.type === 'date_asc' ? 'date_asc' : null,

    // Sidebar
    country_id: Number(route.query.country),

    salary_type: Number(route.query.salary_type),
    salary_min: null,
    salary_max: null,

    industries_id: checkArray(route.query.industries),
    languages_id: checkArray(route.query.languages),
    education_types_id: checkArray(route.query.education_types),
    min_experiences_id: checkArray(route.query.min_experiences),
    sex: route.query.gender,

    // Pagination
    perPage: 20,
    page: route.query.page ? Number(route.query.page) : 1
  }
)
const total = ref(0)
const experiences = ref({})
const checkExperience = () => {
  if (filter.min_experiences_id) {
    experiences.value = {}
    filter.min_experiences_id.forEach(id => {
      let from = 0
      let to = 0
      switch (Number(id)) {
        // Без опыта
        case 1:
          from = 0
          to = 0
          break

        // Менее 1 года
        case 2:
          from = 0
          to = 1
          break

        // От 1 до 3 лет
        case 3:
          from = 1
          to = 3
          break

        // От 3 до 5 лет
        case 4:
          from = 3
          to = 5
          break

        // Свыше 5 лет
        case 5:
          from = 5
          to = 100
          break
        default:
          from = null
          to = null
      }
      experiences.value[`experience[${id}][experience_duration_from]`] = from
      experiences.value[`experience[${id}][experience_duration_to]`] = to
    })
    if (filter.min_experiences_id.length === 0) {
      experiences.value['experience[0][experience_duration_from]'] = null
      experiences.value['experience[0][experience_duration_to]'] = null
    }
  }
}
checkExperience()

// Check Access
const checkAccess = ref(false)
provide('check_access', checkAccess)

// Fetch Data
const fetchData = async (page = 1) => {
  resumes.value = null
  checkExperience()
  const response = (await getResumes(filter, experiences.value)).data
  resumes.value = response.resumes.data
  const topResumes = response.top_resumes.data

  topResumes.forEach(resume => {
    resume.topResume = true
    resumes.value.unshift(resume)
  })
  total.value = response.resumes.total
  checkAccess.value = response.check_access

  router.push(
    {
      path: '/company/search-resumes/result',
      query: {
        // ========== Header =============
        search: filter.name,
        type: filter.sort_type,
        period: filter.period,

        // Salary min - max
        salary_type: filter.salary_type,
        gender: filter.sex,

        // ========== Side Bar =============
        country: filter.country_id,
        industries: filter.industries_id,
        languages: filter.languages_id,
        education_types: filter.education_types_id,
        min_experiences: filter.min_experiences_id,

        page: page
      }
    }
  )
}

onMounted(
  () => fetchData(filter.page)
)

watch(
  () => route.query,
  () => {
    if (route.name === 'SearchResumes') {
      filter.page = route.query.page ? Number(route.query.page) : 1
      filter.period = route.query.period ?? 'month'
      filter.name = route.query.search

      filter.country_id = Number(route.query.country)
      filter.salary_type = Number(route.query.salary_type)

      filter.industries_id = checkArray(route.query.industries)
      filter.languages_id = checkArray(route.query.languages)
      filter.education_types_id = checkArray(route.query.education_types)
      filter.min_experiences_id = checkArray(route.query.min_experiences)
      filter.sex = route.query.gender
      fetchData(filter.page)
    }
  }
)
</script>

<style lang="scss">
.searchResumes{
  &__items{
    border-collapse: collapse;
  }
}
</style>
