<template>
<div class="detailVacancy__header">
  <div class="detailVacancy__left">
    <h2 class="section__title">{{vacancy.manager.company.name ?? $t('common_data')}}</h2>

    <div class="detailVacancy__right" v-if="documentWidth <= 400">
      <img v-if="vacancy.manager.company.logo" :src="`${apiUrl}/${vacancy.manager.company.logo}`" alt="">
      <img v-else src="/img/tmp/defold.png" alt="">
    </div>

    <p>{{`${vacancy.city.countries.title}, ${vacancy.city.title}`}}</p>
    <p>{{`${$t('field_of_activity')}: ${vacancy.professional_area.title}`}}</p>
    <p>{{`${$t('phone')}: ${formatToPhone(vacancy.manager.phone)}`}}</p>
    <p>{{`E-mail: ${vacancy.manager.email}`}}</p>
  </div>

  <div class="detailVacancy__right" v-if="documentWidth > 400">
    <img v-if="vacancy.manager.company.logo" :src="`${apiUrl}/${vacancy.manager.company.logo}`" alt="">
    <img v-else src="/img/tmp/defold.png" alt="">
  </div>
</div>
</template>

<script setup>
import { apiUrl } from '@/api'
import { defineProps, ref } from 'vue'
import { formatToPhone } from '@/helpers/formatPhone'

defineProps({
  vacancy: {
    type: Object,
    default: null
  }
})

const documentWidth = ref(document.body.clientWidth)
</script>

<style lang="scss">
.detailVacancy{
  &__header{
    display: grid;
    grid-template-columns: 1fr 230px;
    grid-gap: 60px;
    margin-bottom: 60px;

    & .section__title{
      margin-bottom: 22px;
    }
  }

  &__right{
    position: relative;
    height: 230px;
    width: 230px;
  }
}
@media (max-width: 768px){
.detailVacancy{
  &__header{
    grid-template-columns: 1fr 200px;
    grid-gap: 40px;
    margin-bottom: 40px;
  }
  &__right{
    height: 200px;
    width: 200px;
  }
}
}
@media (max-width: 576px){
.detailVacancy{
  &__header{
    grid-template-columns: 1fr 160px;
    grid-gap: 40px;
    margin-bottom: 40px;
  }
  &__right{
    height: 160px;
    width: 160px;
  }
}
}

@media (max-width: 400px){
.detailVacancy{
  &__header{
    grid-template-columns: 1fr;
    grid-gap: 0;
    margin-bottom: 30px;
  }
  &__right{
    margin-bottom: 20px;
  }
}
}
</style>
