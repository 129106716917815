<template>
<div class="container">
  <div class="nav">
    <a
      :href="'/history'"
      class="nav__link"
    >
      {{$t('history')}}
    </a>

    <a
      :href="'/history/#team'"
      class="nav__link"
    >
      {{$t('team')}}
    </a>

    <a
      :href="'/history/#partners'"
      class="nav__link"
    >
      {{$t('partners')}}
    </a>

    <a
      :href="'/history/#principles'"
      class="nav__link"
    >
      {{$t('principles')}}
    </a>

    <a
      :href="'/history/#certificates'"
      class="nav__link"
    >
      {{$t('certificates')}}
    </a>
  </div>
</div>
</template>
