export const kk = {
  lang_title: 'KZ',
  message: {
    logged: 'Сіз жүйеге кірдіңіз!',
    logout: 'Сіз жүйеден шықтыңыз!',
    password_reset_link_sent_to_email: 'Парольді қалпына келтіру сілтемесі поштаға жіберілді',
    changed_lang: 'Тілі орыс тіліне ауыстырылды',
    minTestLength: 'Жауаптардың ең аз саны екі',
    zero_resume: 'Жауап беру үшін түйіндеме жасау керек',
    your_email_has_been_successfully_verified: 'Сіздің email сәтті расталды!',
    your_password_has_been_successfully_changed: 'Құпия сөзіңіз сәтті өзгертілді!',
    request_failed: 'Сұрау қатесі! Интернетке қосылғаныңызды тексеріңіз.',
    server_error: 'Сервер қатесі!',
    you_first_need_to_login: 'Алдымен кіру керек!',
    we_do_not_have_such_a_page: 'Бізде ондай бет жоқ!',
    you_do_not_have_access_to_this_page: 'Сіз бұл параққа кіре алмайсыз!',
    an_error_occurred_while_requesting: 'Сұрау кезінде қате пайда болды! Кейінірек қайталап көріңіз.',
    looks_like_it_s_empty: 'Бұл бос сияқты!',
    you_need_to_log_in_as_a_user: 'Пайдаланушы ретінде кіру керек!',
    pay_the_tariff_for_viewing_contacts: 'Контактілерді көру үшін тарифті төлеңіз!',
    first_but_a_package_to_create_a_manager: 'Менеджер құру үшін алдымен пакет сатып алыңыз!',
    first_but_a_package_to_create_a_vacancy: 'Вакансия құру үшін алдымен пакет сатып алыңыз!',
    to_respond_you_need_to_pass_a_test: 'Жауап беру үшін тест тапсыру керек.'
  },
  us_address_title: 'Біздің мекен-жайымыз',
  skype: 'Скайп',
  search: 'Іздеу',
  logout: 'Шығу',
  filter: 'Фильтр',

  // Address
  address: 'Қазақстан Республикасы, Алматы қаласы, Достық даңғылы 210, "Көктем Гранд" БО, 2-блок, 2-қабат, 28-кеңсе',
  we_are_in_social_networks: 'Біз әлеуметтік желілерде',
  copyright: '© 2022 Altyn Adam. Барлық құқықтар қорғалған',
  your_city: 'Сіздің қалаңыз',

  // Upload Image
  photo_cropping: 'Суреттi Кадрлеу',
  select_photo: 'Cуретті Таңдау',
  save_as_profile_photo: 'Профиль суреті ретінде сақтау',
  profile_photo: 'Профиль суреті',
  change_your_profile_photo: 'Профиль суретін өзгерту',
  drag_photo_here: 'Суретті осында сүйреңіз',
  download_from_device: 'Құрылғыдан жүктеу',
  or: 'немесе',
  by_profile_photo: 'Профиль фотосуреті арқылы басқа адамдар сізді тани алады және сіз қай есептік жазбаға кіргеніңізді анықтау оңайырақ болады.',

  // ============================= Home Pages ==============================
  // Intro
  intro: {
    title: {
      top: 'HR консалтингтік және рекрутингтік ',
      bottom: 'агенттігi'
    },
    subtitle: 'Қазақстанның барлық өңірлерінде, ТМД елдерінде және шетелдерде жұмыс істеу'
  },

  // Salse
  sales: 'Акциялар',
  LastMinuteJobs: 'Жанып тұрған бос орындар',

  // About
  aboutUs: {
    title: 'Компания туралы',
    text: {
      text1: '2005 жылдан бастап Altyn Adam Agency қызметкерлерді басқару саласында: қызметкерлерді іздеу және іріктеу, оқыту және дамыту, аутсорсинг және аутстаффинг қызметтерінің толық спектрін жүзеге асырады. Осы уақыт аралығында біздің компания кеңейіп, қызмет сапасы, жоғары нәтижелілігі және өз саласын білуі үшін Қазақстан нарығында және шетелде жақсы беделге ие болды.',
      text2: 'Altyn Adam Agency ізденушілерінің деректер базасына қызметтің барлық салаларының ізденушілерінің түйіндемелері кіреді: Мұнай және газ, Банк ісі, жылу энергетикасы, уран өндіру өнеркәсібі, қаржы секторы, өнеркәсіп, инжиниринг, FMCG, Құқықтану, Құрылыс және жылжымайтын мүлік, өндірістік және жұмыс персоналы, ауыл шаруашылығы, көлік және логистика және т. б.',
      text3: 'түйіндеме ізденушілердің базасында құрылады Арнайы схема, ол ескереді барлық деректер құрастыру үшін сауатты, егжей-тегжейлі, дұрыс түйіндеме, қоса алғанда, толық ақпаратты жұмыс берушіге қажетті шешім қабылдау үшін кандидат.',
      text4: 'әрбір Компания, біздің агенттіктің клиенті бола отырып, қызметкерлерді іздеу және іріктеу, аутсорсинг және аутстаффинг, қызметкерлерді оқыту және дамыту саласында тиімді ынтымақтастыққа ие.',
      text5: 'біздің мақсатымыз-білікті қызметкерлерді іздеу және таңдау және ең жақсы жұмыс орнын табу кезінде клиенттеріміз бен жұмыс іздеушілеріміз үшін жақсы жағдай жасау.',
      text6: 'құрметпен, "Altyn Adam Agency"ЖШС командасы'
    }
  },

  // Services
  services: {
    title: 'Қызметтер',
    button: 'Толығырақ',
    connected: 'Қосылған қызметтер'
  },
  services_tipes: 'Қызметкерлерді жалдау бойынша қызмет түрлері:',
  pay: 'Төлеу',
  by_SMS: 'SMS-пен',

  // About us - История
  home: 'Басты бет',
  contacts: 'Байланыстар',
  history: 'Тарих',
  company_development_stages: 'Компания дамуының маңызды кезеңдері',
  team: 'Команда',
  partners: 'Біздің серіктестерiмiз',
  principles: 'Негізгі принциптерімiз',
  certificates: 'Марапаттар, сертификаттар, ұсыныстар',
  outsourcing_personnel: 'Қызметкерлер Аутсорсингi',

  // News
  news: 'Жаңалықтар',
  show_all: 'Барлығын көру',

  // Favorites
  favourites: 'Таңдаулылар',
  be_in_favorites: 'Таңдаулыларда',
  remove_from_favorites: 'Таңдаулыларды алып тастау',
  add_to_favorites: 'Таңдаулыларға қосу',

  // Responses and Invites
  responses_and_invitations: 'Жауаптар мен шақырулар',
  you_were_invited: 'Сізді шақырды!',
  you_were_denied: 'Сізге бас тартылды',
  viewed: 'Қаралды',
  not_viewed: 'Қаралмаған',
  response: 'Жауап',
  cancel_response: 'Жауапты жою',
  responded: 'Жауап берді',
  invited: 'Шақырылды',
  refusal: 'Қабылданбаған',
  to_invite: 'Шақыру',
  refuse: 'Бас тартуға',

  // Helpers
  topic: 'Өтініш тақырыбы',
  help: 'Көмек',
  message_text: 'Хабарлама',
  your_message: 'Сіздің үндеуіңіз',
  Writeto_the_technical_department: 'Техникалық бөлімге жазу',

  // Quastion Modal
  are_you_sure: 'Сіз сенімдісіз',

  // Login Modal
  login: 'Кіру',
  login_button: 'Кіру',
  authorization: 'Авторландыру',
  authorization_button: 'Авторлану',
  login_with: 'Кіру арқылы:',
  forgot_your_password: 'Құпия сөзді ұмыттыңыз ба?',
  register_on_site: 'САЙТТА ТІРКЕЛУ',

  // Register
  registration: 'Тіркеу',
  register_button: 'Тіркелу',
  company: 'Компания',
  applicant: 'Ізденуші',
  i_have_read_and_agree_with: 'Мен таныстым және келісемін',
  terms_of_agreement: 'Шарт талаптарымен',

  // Reset Password
  enter_your_password_recovery_email: 'Парольді қалпына келтіру үшін поштаны енгізіңіз',

  // Time
  from: 'бастап',
  to: 'дейін',
  before: 'дейін',
  after: 'кейін',

  // =================================== Data ======================================
  // Person Data
  fullName: 'Аты-жөні',
  name: 'Аты',
  surname: 'Тегі',
  patronymic: 'Әкесінің аты',
  gender: 'Жынысы',
  man: 'Ер адам',
  woman: 'Әйел',
  additionally: 'Қосымша',
  family_status: 'Отбасылық жағдайы',
  children: 'Балалар',

  // Common Data
  type_of_ownership: 'Меншік нысаны',
  name_company: 'Компанияның атауы',
  contact_person: 'Байланысушы тұлға',
  bin_and_iin: 'БСН / ЖСН',
  bin: 'БСН',
  KBE: 'Кбе',
  IKK: 'ЖСК',
  bank: 'Банк',
  BIK: 'БСК',
  KNP: 'ТТК',
  legal_address: 'Заңды мекен-жайы',
  description: 'Сипаттамасы',
  field_of_activity: 'Қызмет саласы',
  show_location_of_company_on_map: 'Картада компанияның орналасқан жерін көрсетіңіз',
  description_company: 'Компанияның Сипаттамасы',

  // Основная информация
  title: 'Атауы',
  basic_information: 'Негізгі ақпарат',
  specialization: 'Мамандандыру',

  // Email
  yourEmail: 'Сіздің поштаңыз',

  // Password
  password: 'Құпиясөз',
  repeat_password: 'Құпиясөзді қайталау',

  // Phone
  phone: 'Телефон',
  second_phone: 'Қосымша телефон',
  additional_contacts: 'Қосымша байланыс әдісі',

  // Address
  address_title: 'Мекен жайы',
  city: 'Қаласы',
  country: 'Ел',
  region: 'Аймақ',
  choose_region: 'Аймақты таңдаңыз',
  choose_country: 'Елді таңдаңыз',
  choose_city: 'Қаланы таңдаңыз',
  citizenship: 'Азаматтығы',
  country_origin: 'Тұратын елі',
  city_origin: 'Тұратын қаласы',
  address_origin: 'Тұратын мекен-жайы',
  show_address: 'Адресті көрсету',
  location_on_map: 'Картада орналасуы',
  work_permit: 'Разрешение на работу',
  place_of_workd: 'Жұмысқа рұқсат',
  where_to_look_for_an_employee: 'Қызметкерді қайдан іздеу керек',
  do_not_include_an_address: 'Мекен жайды көрсетпеу',
  specify_the_address: 'Мекен жайды көрсету',

  // Date and Birthday
  date_birth: 'Туған күні',
  show_date_birth: 'Туған күнін көрсету',
  pass_period: 'Өту кезеңі',
  until_now: 'қазіргі уақытта',
  on: 'бойынша',
  day: 'Күн',
  days: 'Күн',
  month: 'Ай',
  year: 'Жыл',

  // State
  connected: 'Қосылған',
  disabled: 'Ажыратылған',
  not_set: 'Берілмеген',
  hidden: 'Жасырын',
  updated: 'Жаңартылған',
  published: 'Жарияланды',
  not_published: 'Мұрағатта',
  all_changes_are_saved_in: 'Барлық өзгерістер сақталды',

  rofessional_skills: 'Кәсіби білім мен дағдылар',
  more_about_yourself: 'Өзіңіз туралы қосымша',

  // Hobbies
  Interests_and_hobbies: 'Қызығушылықтар мен хобби',

  // Avto - Car
  availability_of_a_car: 'Көліктің болуы',
  own_car: 'Меншікті автокөлік',
  driver_license: 'Жүргізуші куәлігі',
  categories: 'Санаттар',
  category_of_rights: 'Құқықтар санаты',
  select_categories: 'Категорияларды таңдаңыз',

  // Languages
  lang: 'Тіл',
  language_skills: 'Тілдерді білуі',
  main_languages: 'Негізгі тілдер',
  specify_another_language: 'Тағы бір тілді көрсетіңіз',

  // Issue Type
  issue_type: 'Беру түрі',
  before_taxes: 'Салықтарды шегергенге дейін',
  on_hand: 'Қолына',

  // Employment Type
  employment_type: 'Жұмыспен қамту түрі',
  industry: 'Саласы',

  // Work
  working_mode: 'Жұмыс тәртібі',
  temporary_clearance_possible: 'Мүмкін уақытша дизайн',
  it_is_possible_to_apply_for_gpc_or_part_time: 'АҚС (қызметтер, мердігерлік, ЖК, өзін-өзі жұмыспен қамтығандар) немесе қоса атқару бойынша рәсімдеуге болады',
  estimated_income_level_per_month_or_per_amount_of_work: 'Бір айдағы немесе жұмыс көлемі үшін табыстың болжамды деңгейі',
  work_period: 'Жұмыс кезеңі',
  functional_responsibilities: 'Функционалдық міндеттері',

  // Key skills
  key_skills: 'Негізгі дағдылар',
  select_skills: 'Дағдыларды таңдаңыз',

  // Position
  position: 'Лауазымы',
  career_objective: 'Қалаған лауазым',
  wage: 'Еңбек ақы',
  desired_salary: 'Қажетті жалақы',

  // Ownership PC
  ownership_pc: 'Компьютерді меңгеру',
  main_programs: 'Негізгі бағдарламалар',

  // Experiences
  required_work_experience: 'Қажетті жұмыс тәжірибесі',
  experience: 'Жұмыс тәжірибесі',
  responsibilities: 'Міндеттері',
  last_job: 'Соңғы жұмыс орны',

  // Education
  education: 'Білім',
  honors_degree: 'Үздік Диплом',
  type_of_education: 'Білім беру түрі',
  educational_institution: 'Оқу орны',
  faculty: 'Факультет',
  speciality: 'Мамандық',
  type_of_diploma: 'Диплом түрі',
  training_period: 'Оқу кезеңі',

  // Courses
  courses: 'Курстар, тренингтер, семинарлар',
  certificate: 'Сертификат',

  // Awards
  awards: 'МАРАПАТТАР, ГРАМОТАЛАР',
  file: 'Файл',

  // Personal Slills
  personal_qualities: 'Жеке қасиеттер',

  // Кто может откликаться
  who_can_respond: 'Кім жауап бере алады',
  applicants_with_disabilities: 'Мүгедектігі бар үміткерлер',
  applicants_with_an_incomplete_resume: 'Толық емес түйіндемесі бар ізденушілер',
  only_with_cover_letter: 'Тек ілеспе хатпен',

  // ================================= Placeholder =======================
  not_required_confidential: 'Толтыру үшін міндетті емес, құпия',
  list_separated_by_commas: 'Үтір арқылы тізімдеңіз',
  describe_in_free_form: 'Еркін түрде сипаттаңыз',

  // =================================== Button Texts ======================================
  // Hide and Not
  show: 'Көрсету',
  hide: 'Жасыру',
  look: 'Көру',
  show_contacts: 'Контактілерді көрсету',
  buy: 'Сатып алу',
  lets_go: 'Тапсыру',
  show_flyBtn: 'Көрсету',

  // Yes and No
  yes: 'Иә',
  no: 'Жоқ',
  there_is: 'бар',
  edit: 'Өзгерту',
  cancel: 'Болдырмау',
  close: 'Жабу',
  save_edited: 'ӨЗГЕРІСТЕРДІ САҚТАУ',
  send_message: 'Хабар жіберу',
  add_more: 'Тағы қосу',

  // Buttons
  // ///////////////////////
  send: 'Жіберу',
  save: 'Сақтау',
  create: 'Құру',
  delete: 'Өшіру',
  redact: 'Редакциялау',
  edit_data: 'Редакциялау деректер',
  reach: 'Байланысу',
  connect: 'Қосу',

  // Inputs
  // ///////////////////////
  enter_template: 'Атын енгізіңіз',
  name_template: 'Үлгі атауы',
  enter_text: 'Мәтінді енгізіңіз',

  // =================================== Select ==============================
  select_a_region_first: 'Алдымен аймақты таңдаңыз',
  select_show_all: 'Барлығын көрсету',
  not_found: 'табылды',

  // =================================== Company Pages ======================================
  // ///////////////////////
  common_data: 'Жалпы деректер',

  // Managers
  manager: 'Менеджер',
  managers: 'Менеджеров',
  add_meneger: 'Менеджерді қосу',
  company_menegers: 'Компания менеджерлері',
  edit_meneger: 'Менеджерді өзгерту',

  // Tests
  test: 'Тест',
  create_test: 'Тест жасау',
  edit_test: 'Тестті өңдеу',
  name_test: 'Тесттің атауы',
  test_templates: 'Тест үлгілері',
  add_quastion: 'Сұрақ қосу',
  applicant_test: 'Ізденушіге арналған Тест',
  select_tests: 'Тесттерді таңдаңыз',
  quastion: 'Сұрақ',
  response_to_the_test: 'Тестке жауап',
  passing_the_test: 'Тесттен өту',

  // Letters
  letter_template: 'Хат үлгісі',
  letter_templates: 'Хат үлгілері',
  create_latter: 'Хат жазу',

  // Vacancies
  vacancy: 'Вакансия',
  vacancies: 'Вакансиялар',
  edit_vacancy: 'Вакансияны өңдеу',
  create_vacancy: 'Вакансияны құру',
  about_the_position: 'Лауазым туралы',
  name_vacancy: 'Вакансия атауы',
  tell_about_the_vacancy: 'Вакансия туралы айтып беріңізші',
  vacancy_adderss: 'Вакансия мекенжайы',
  vacancy_codex: 'Бос жұмыс орнын жариялау кезінде еңбек саласындағы кемсітушілік сипаттағы талаптарды (жынысына, жасына, тұрғылықты жеріне және кандидаттың іскерлік қасиеттері мен еңбек функциясының ерекшелігіне байланысты емес өзге де мән-жайларға байланысты шектеулерді) қамтитын ақпаратты орналастыруға тыйым салынады. 6-баптың 2-тармағына сәйкес. ҚР Еңбек Кодексінің 2015 жылғы 23 қарашадағы № 414-V және 3-т."Бұқаралық ақпарат құралдары туралы"Қазақстан Республикасының 1999 жылғы 23 шілдедегі № 451-I Заңының 14-бабы 2-тармағы.',

  // Hot Vacancy
  hot_vacancy: {
    checkbox: 'Жанып тұрған вакансия',
    button: 'Жанған вакансия құру'
  },

  // Working With The Site
  working_with_site: 'Сайтпен жұмыс',
  about_the_contract: 'Шарт туралы',
  there_is_no_contract: 'Қызмет көрсету шарты жоқ',
  paid_bills: 'Төленген шоттар',
  connected_services: 'Қосылған қызметтер',
  responses_to_a_job: 'Вакансияның Пікірлерi',
  service_agreement: 'Қызмет көрсету шарты',
  signed: 'Қол қойылды',
  deadline_up_to: 'Дейінгі мерзім',
  vacancy_placement: 'Вакансия орналастыру',
  adding_a_manager: 'Менеджерді қосу',
  left: 'Қалды',
  all_responses: 'Барлық пікірлер',

  // Connected Services
  number_of_available_vacancies: 'Қол жетімді вакансиялар саны',
  number_of_available_managers: 'Қол жетімді менеджерлер саны',
  left_days: 'Күндер қалды',

  // Search Resumes
  resumes: 'Түйіндеме',
  applicants_resumes: 'Ізденушілердің түйіндемесі',

  // Favorites Resumes
  favorites_resumes: 'Таңдалған түйіндеме',

  // Services
  send_a_request_for_payment: 'Төлемге өтінім жіберу',
  denomination: 'Атауы',
  access_to_the_database_of_resume_of_applicants: 'Ізденушілер түйіндемесінің базасына қол жеткізу',
  number_of_vacancies_to_post: 'Орналастыру үшін бос орындар саны',
  number_of_company_managers: 'Компания менеджерлерінің саны',
  service_cost: 'Қызмет құны (KZT, ҚҚС қоса алғанда)',
  full_access: 'Толық қол жеткізу',
  no_access: 'Қатынау жоқ',

  // =================================== Client Pages ======================================
  // Person data
  personal_data: 'Жеке мәліметтер',
  edit_personal_data: 'Жеке деректерді өзгерту',

  // Resumes
  resume: 'Түйіндеме',
  my_resumes: 'Менің түйіндемем',
  create_resume: 'Түйіндеме жасау',

  // Companies
  companies: 'Компаниялар',
  companies_directory: 'Компания каталогы',

  // Feedback form
  write_a_letter: 'Хат жазу',
  i_want_to_work_here: 'Мұнда жұмыс істегім келеді',
  enter_your_cover_letter: 'Мұқабаның мәтінін енгізіңіз',
  select_resume: 'Түйіндемені таңдаңыз',

  // Vacancies
  vacancies_of_this_company: 'Осы компанияның вакансиялары',

  // =================================== HELPERS DATAS ======================================
  // Months
  months: {
    1: 'қаңтар',
    2: 'ақпан',
    3: 'наурыз',
    4: 'сәуір',
    5: 'мамыр',
    6: 'маусым',
    7: 'шілде',
    8: 'тамыз',
    9: 'қыркүйек',
    10: 'қазан',
    11: 'қараша',
    12: 'желтоқсан'
  },

  // Languages Levels
  languages_levels: {
    A1: 'A1-Бастапқы',
    A2: 'A2-Қарапайым',
    B1: 'B1-Орташа',
    B2: 'B2-орташа жетілдірілген',
    C1: 'C1-Жетілдірілген',
    C2: 'C2 - өте жақсы'
  },

  // Wages
  wages_option_1: '100 000 дейiн',
  wages_option_2: '100 000 - 200 000',
  wages_option_3: '200 000 - 300 000',
  wages_option_4: '300 000 - 400 000',
  wages_option_5: '400 000 - 500 000',
  wages_option_6: '500 000 бастап',

  // Time Filter
  timeFilters: {
    day: 'Бүгін',
    month: 'Осы айда',
    three_months: 'Үш айда',
    year: 'Жылда'
  },

  // Date Filter
  dateFilters: {
    date_asc: 'Күннің өсуі бойынша',
    date_descending: 'Күннің кемуі бойынша'
  },

  // Filter Company
  filterCompanies: {
    all_companies: 'Барлық компаниялар',
    only_with_open_vacancies: 'Тек ашық бос ваканциялармен'
  },

  // Errors
  errors: {
    this_fieldIs_required: 'Бұл өріс міндетті',
    enter_the_correct_number: 'Дұрыс нөмірді енгізіңіз',
    enter_the_correct_email: 'Дұрыс поштаны енгізіңіз',
    enter_the_correct_password: 'Пароль кемінде 8 таңба болуы керек. Онда бір Сан,бір үлкен әріп және бір кішкентай әріп болуы керек',
    passwords_dont_match: 'Парольдер сәйкес келмейді'
  }
}
