<template>
  <div class="pagination__block" v-if="recent > 1">
    <div class="pagination__inner">
      <!-- ====================  Button Arrow Left  ==================== -->
      <button
        v-if="currentPage !== 1"
        type="button"
        class="pagination__btn arrow"
        @click="currentPage -= 1"
      >
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
        </svg>
      </button>

      <!-- ====================  Button Pages  ==================== -->
      <button
        v-for="page of pagination"
        :key="page"
        type="button"
        class="pagination__btn"
        :class="{active: page === currentPage}"
        @click="() => {currentPage = page}"
      >
        <span>{{page}}</span>
      </button>

      <button
        class="pagination__btn"
        type="button"
        v-if="currentPage < recent - 4"
        disabled
      >
        <span>...</span>
      </button>
      <button
        class="pagination__btn"
        type="button"
        v-if="currentPage < recent - 4"
        @click="() => {currentPage = recent}"
      >
        <span>{{recent}}</span>
      </button>

      <!-- ====================  Button Arrow Right  ==================== -->
      <button
        v-if="currentPage < recent"
        type="button"
        class="pagination__btn arrow"
        @click="currentPage += 1"
      >
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: null
  },
  perPage: {
    type: Number,
    default: 10
  },
  total: {
    type: Number,
    default: 1
  }
})
const emit = defineEmits(['update:modelValue', 'newPage'])

const currentPage = ref(Number(props.modelValue))
const pagination = ref(props.total)
const recent = Math.ceil(props.total / props.perPage)

watch(
  () => currentPage.value,
  () => {
    emit('update:modelValue', currentPage.value)
    emit('newPage')
    maitPagination()
  }
)

const maitPagination = () => {
  let from = 1
  const page = currentPage.value
  if (page > recent - 3 && recent - 3 > 1) from = recent - 3
  else if (page > 1) from = page - 1

  let to = Math.ceil(recent)

  if (page === 1 && page + 3 < recent - 1) to = page + 3
  else if (page + 2 < recent - 1) to = page + 2

  pagination.value = []
  for (let j = from; j <= to; j++) {
    pagination.value.push(j)
  }
}
maitPagination()
</script>

<style lang="scss" scoped>
.pagination{
  &__block{
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
  }
  &__inner{
    display: inline-flex;
    grid-gap: 8px;
  }
  &__btn{
    height: 32px;
    width: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid #DFE3E8;
    border-radius: 4px;
    transition: all .2s ease-in-out;

    & span{
      font-size: 14px;
      color: #212529;
      transition: all .2s ease-in-out;
    }

    &.arrow{
      & svg{
        display: block;
        height: 12px;
        fill: #C4CDD5;
        transition: all .2s ease-in-out;
      }
    }

    &:hover,
    &.active{
      border-color: #AD1717;
      & span{
        color: #AD1717;
      }
    }

    &:hover{
      background-color: rgba(#AD1717, .05);
      & svg{
        fill: #AD1717;
      }
    }
    &[disabled]{
      pointer-events: none;
    }
  }
  &__btn.arrow:first-of-type{
    & svg{
      transform: rotate(180deg);
    }
  }

  @media (max-width: 992px){
    &__block{
      margin-top: 40px;
    }
    &__inner{
      grid-gap: 7px;
    }
    &__btn{
      height: 30px;
      width: 30px;
      border-radius: 4px;
      & span{
        font-size: 12px;
      }

      &.arrow svg{
        height: 10px;
      }
    }
  }
  @media (max-width: 768px){
    &__block{
      margin-top: 35px;
    }
    &__inner{
      grid-gap: 6px;
    }
    &__btn{
      height: 28px;
      width: 28px;
      border-radius: 3px;
      & span{
        font-size: 10px;
      }

      &.arrow svg{
        height: 10px;
      }
    }
  }
  @media (max-width: 576px){
    &__block{
      margin-top: 30px;
    }
    &__inner{
      grid-gap: 5px;
    }
    &__btn{
      height: 26px;
      width: 26px;
      border-radius: 3px;
      & span{
        font-size: 9px;
      }

      &.arrow svg{
        height: 8px;
      }
    }
  }
}
</style>
