<template>
<div class="languages__items">
  <div class="item"
    v-for="(lang, i) of formData"
    :key="lang"
  >
    <DeleteButton @click="() => {deleteLang(i)}" />
    <Select
      id="languages"
      :placeholder="$t('lang')"
      v-model="lang.language_id"
      :options="options"
      :checkedOptions="formData"
    />
    <Select
      v-model="lang.level"
      :options="languagesLevels"
    />
  </div>
  <button type="button" @click="addLang()">{{$t('specify_another_language')}}</button>
</div>
</template>

<script setup>
import DeleteButton from '@/components/common/Buttons/DeleteButton.vue'

import { ref } from '@vue/reactivity'
import { watch, defineProps, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
const i18n = useI18n()

const props = defineProps({
  modelValue: {
    type: Array,
    default: null
  },
  options: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)

const languagesLevels = [
  {
    title: i18n.t('languages_levels.A1'),
    id: 'A1'
  },
  {
    title: i18n.t('languages_levels.A2'),
    id: 'A2'
  },
  {
    title: i18n.t('languages_levels.B1'),
    id: 'B1'
  },
  {
    title: i18n.t('languages_levels.B2'),
    id: 'B2'
  },
  {
    title: i18n.t('languages_levels.C1'),
    id: 'C1'
  },
  {
    title: i18n.t('languages_levels.C2'),
    id: 'C2'
  }
]

// ===================     Checking   ===================
watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)

// Click Events
const addLang = () => {
  formData.value.push({
    language_id: 0,
    level: 'A1'
  })
  emit('update:validation', true)
}

if (formData.value.length === 0) addLang()

const deleteLang = i => {
  formData.value.splice(i, 1)
  if (formData.value.length === 0) emit('update:validation', false)
}
</script>

<style lang="scss">
.languages__items{
  & .item{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    position: relative;
  }

  & button{
    font-weight: 400;
    font-size: 16px;
    text-decoration-line: underline;
    color: #1D7FF2;
    margin-bottom: 15px;
  }
  @media (max-width: 992px){
    & .item{
      margin-bottom: 18px;
    }

    & button{
      font-size: 14px;
      margin-bottom: 13px;
    }
    & .createResume__deleteBtn{
      grid-column: 1/3;
    }
  }
  @media (max-width: 768px){
    & .item{
      margin-bottom: 16px;
    }

    & button{
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px){
    & .item{
      margin-bottom: 14px;
    }

    & button{
      margin-bottom: 8px;
    }
  }
}
</style>
