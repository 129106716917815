<template>
<div class="period">
  <div class="items">
    <DatePicker
      v-model="formData.period_from"
      v-model:validation="success.period_from"
      :maxDate="formData.period_to"
      :placeholder="$t('from')"
      period
    />

    <DatePicker
      v-model="formData.period_to"
      v-model:validation="success.period_to"
      :minDate="formData.period_from"
      :placeholder="$t('to')"
      :disabled="input.untilNow"
      period
    />
  </div>
  <div class="checkboxBlock">
    <Checkbox
      v-model="input.untilNow"
      dark
    />
    <div class="text">{{$t('until_now')}}</div>
  </div>
</div>
</template>

<script setup>
import { reactive } from 'vue-demi'
import { defineProps, defineEmits, watch, ref } from 'vue'
import Checkbox from '@/components/common/Checkbox.vue'
import DatePicker from '@/components/common/DatePickers/DatePicker.vue'

const props = defineProps({
  modelValue: {
    type: [String, Number, Object],
    default: null
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue ?? {})
const input = ref({
  period_to_interim: '',
  untilNow: false
})
const success = reactive({
  period_from: false,
  period_to: false
})

if (!formData.value.period_to && formData.value.period_from) input.value.untilNow = true

watch(
  () => [formData.value, input.value.untilNow],
  () => {
    if (input.value.untilNow) {
      input.value.period_to_interim = formData.value.period_to
      formData.value.period_to = null
    } else {
      formData.value.period_to = input.value.period_to_interim ?? null
    }
    emit('update:modelValue', formData.value)
  }
)

</script>

<style lang="scss" scoped>
.period{
  & .items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 5px;
  }
}
</style>
