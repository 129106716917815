<template>
  <div
    class="createResume__itemRow"
  >
    <DeleteButton @click="() => {$emit('onDelete')}" />
    <div class="createResume__itemInner">
      <label for="workPeriod">*{{$t('training_period')}}:</label>
      <DatePickerPeriod
        v-model="formData"
        v-model:validation="success"/>
    </div>
    <div class="createResume__itemInner">
      <label for="companyName">{{$t('type_of_education')}}:</label>
      <Select
        id="industries"
        v-model="formData.education_type_id"
        v-model:validation="success.education_type_id"
        :options="helpers.educationType"/>
    </div>
    <div class="createResume__itemInner">
      <label for="prof">{{$t('educational_institution')}}:</label>
      <Input
        id="name"
        v-model="formData.name"
        v-model:validation="success.name"
        required/>
    </div>
    <div class="createResume__itemInner">
      <label for="prof">{{$t('faculty')}}:</label>
      <Input
        id="faculty"
        v-model="formData.faculty"
        v-model:validation="success.faculty"
        required/>
    </div>
    <div class="createResume__itemInner">
      <label for="prof">{{$t('speciality')}}:</label>
      <Input
        id="speciality"
        v-model="formData.speciality"
        v-model:validation="success.speciality"
        required/>
    </div>
    <div class="createResume__itemInner">
      <label for="prof">{{$t('type_of_diploma')}}:</label>
      <div class="typeDiploma">
        <Select
          id="industries"
          v-model="formData.diplom_type_id"
          v-model:validation="success.diplom_type_id"
          :options="helpers.diplomType"/>
        <div class="checkboxBlock">
          <Checkbox
            v-model="formData.graduated_with_honors"
            dark
          />
          <div class="text">{{$t('honors_degree')}}</div>
        </div>
      </div>
    </div>
    <div class="createResume__itemInner">
      <label for="country">{{$t('country')}}:</label>
      <Select
        id="nationality"
        v-model="formData.country_id"
        v-model:validation="success.country"
        :options="helpers.country"/>
    </div>
    <div class="createResume__itemInner">
      <label for="city">{{$t('city')}}:</label>
      <Select
        id="nationality"
        v-model="formData.city_id"
        v-model:validation="success.city"
        :options="city"/>
    </div>

  </div>
</template>

<script setup>
import DeleteButton from '@/components/common/Buttons/DeleteButton.vue'
import DatePickerPeriod from '@/components/common/DatePickers/DatePickerPeriod.vue'
import Checkbox from '@/components/common/Checkbox.vue'

import { reactive, ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch, onMounted } from 'vue'
import { getCities } from '@/actions/help'

const props = defineProps({
  education: {
    type: Object,
    default: () => {}
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation', 'onDelete'])

const formData = ref(props.education)
const success = reactive({
  name: false,
  industry_id: false,
  country_id: false,
  city_id: false,
  employees_count: false,
  position: false
})

if (formData.value.graduated_with_honors === 0) formData.value.graduated_with_honors = false
else formData.value.graduated_with_honors = true

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)

const city = ref([])
onMounted(
  async () => {
    if (formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
  }
)
watch(
  () => formData.value.country_id,
  async () => {
    if (formData.value.country_id) city.value = (await getCities(formData.value.country_id)).data.cities
  }
)
</script>
