<template>
<div class="companySearch__item">
  <div class="top">
    <router-link :to="`/user/detail-company/${company.id}`" class="name">{{company.name}}</router-link>
    <div class="amountVacancy">{{amountVacancies}}</div>
  </div>
  <div class="center">
    <div class="description">
      <div v-if="company.industry">{{`${company.industry.title}`}}</div>
      {{`${company.country.title}, ${company.city?.title}`}}
    </div>
    <div class="date"> {{`${$t('updated')} ${moment(company.updated_at).format('DD-MM-YYYY')}`}}</div>
  </div>
  <div class="bottom">
    <div class="state" :style="{opacity: companyInFavorites ? '1' : '0'}">{{$t('be_in_favorites')}}</div>
    <Button
      type="gray"
      :name="companyInFavorites ? $t('remove_from_favorites') : $t('add_to_favorites')"
      @click="companyInFavorites ? deleteCompanyFromFavorite() : creatCompanyToFavorite()"
      :loading="loading"
    />
  </div>
</div>
</template>
<script setup>
import { defineProps, ref } from 'vue'
import moment from 'moment'
import { useI18n } from 'vue-i18n'

import { postFavoriteCompany, deleteFavoriteCompany } from '@/actions/employee.js'

const props = defineProps({
  company: {
    type: Object,
    default: () => {}
  }
})

const loading = ref(false)

const i18n = useI18n()
const amountVacancies = `${props.company.managers.sum_vacancy_count} ${props.company.managers.sum_vacancy_count === 1 ? i18n.t('vacancy') : i18n.t('vacancies')}`

// Company in Favorite Or No
const companyInFavorites = ref(true)
if (props.company.company_favorite) companyInFavorites.value = props.company.company_favorite.length !== 0

// Click Events
const creatCompanyToFavorite = async () => {
  loading.value = true
  try {
    await postFavoriteCompany({ company_id: props.company.id })
    companyInFavorites.value = true
  } finally {
    loading.value = false
  }
}

const deleteCompanyFromFavorite = async () => {
  loading.value = true
  try {
    await deleteFavoriteCompany(props.company.id)
    companyInFavorites.value = false
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.companySearch__item{
  display: block;
  padding: 20px;
  border: 0.5px solid #919599;
  border-top-width: 0;

  &.skeleton__block{
    border-color: rgba(0, 0, 0, 0.0955529) !important;
  }
  & .top,
  & .center,
  & .bottom{
    display: flex;
    justify-content: space-between;
    grid-gap: 15px;
  }
  & .top{
    margin-bottom: 10px;
  }
  & .name{
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #212529;
    position: relative;

    &::before{
      content: '';
      display: block;
      height: 0;
      width: 0;
      border-bottom: 3px solid #212529;

      position: absolute;
      left: 0;
      top: 92%;
      transition: all .2s ease;
    }

    &:hover::before{
      width: 100%;
    }
    &.skeleton{
      width: 250px;
      max-width: 100%;
      &::before{
        border-bottom: none;
      }
    }
  }
  & .amountVacancy{
    font-size: 24px;
    text-transform: uppercase;
    color: #212529;
  }
  & .center{
    margin-bottom: 20px;
  }
  & .description{
    max-width: 500px;
    margin-right: 30px;
    position: static;
    font-size: 16px;
    color: rgba(33, 37, 41, 0.7);

    & .skeleton{
      width: 190px;
      max-width: 100%;
    }

    & .skeleton:first-child{
      width: 320px;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
  & .state{
    font-size: 16px;
    color: #2CA622;
  }
  & .bottom{
    align-items: center;
  }
  & .date{
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
  }
  & button{
    &.skeleton{
      width: 100%;
      max-width: 220px;
      &::before{
        border-bottom: none;
      }
    }
  }
  @media (max-width: 992px){
    padding: 15px;
    & .top{
      margin-bottom: 8px;
    }
    & .name{
      font-size: 18px;
      &.skeleton{
        width: 230px;
      }
    }
    & .amountVacancy{
      font-size: 18px;
    }
    & .center{
      margin-bottom: 18px;
    }
    & .description{
      max-width: 490px;
      margin-right: 25px;
      font-size: 14px;
      & .skeleton{
        width: 180px;
      }

      & .skeleton:first-child{
        width: 300px;
        margin-bottom: 10px;
      }
    }
    & .state,
    & .date{
      font-size: 14px;
    }
    & button{
      &.skeleton{
        max-width: 200px;
      }
    }
  }
  @media (max-width: 768px){
    padding: 12px;
    & .top{
      margin-bottom: 6px;
    }
    & .name{
      font-size: 16px;
      &.skeleton{
        width: 200px;
      }
    }
    & .amountVacancy{
      font-size: 16px;
    }
    & .center{
      margin-bottom: 10px;
    }
    & .description{
      max-width: 470px;
      margin-right: 20px;
      font-size: 12px;
      & .skeleton{
        width: 160px;
      }

      & .skeleton:first-child{
        width: 280px;
        margin-bottom: 8px;
      }
    }
    & .state,
    & .date{
      font-size: 12px;
    }
    & button{
      &.skeleton{
        max-width: 180px;
      }
    }
  }
  @media (max-width: 576px){
    padding: 10px;
    & .top{
      margin-bottom: 4px;
    }
    & .name{
      font-size: 14px;
      &.skeleton{
        width: 180px;
      }
    }
    & .amountVacancy{
      font-size: 14px;
    }
    & .center{
      margin-bottom: 10px;
    }
    & .description{
      max-width: 100%;
      margin-right: 18px;
      font-size: 10px;
      & .skeleton{
        width: 140px;
      }

      & .skeleton:first-child{
        width: 260px;
        margin-bottom: 6px;
      }
    }
    & .state,
    & .date{
      font-size: 10px;
    }
    & button{
      &.skeleton{
        max-width: 160px;
      }
    }
  }
  @media (max-width: 400px){
    padding: 8px;
    & .name{
      font-size: 12px;
      &.skeleton{
        width: 140px;
      }
    }
    & .amountVacancy{
      font-size: 12px;
    }
    & .center{
      margin-bottom: 8px;
    }
    & .description{
      margin-right: 16px;
      font-size: 9px;
      & .skeleton{
        width: 100px;
      }

      & .skeleton:first-child{
        width: 200px;
        margin-bottom: 6px;
      }
    }
    & .state,
    & .date{
      font-size: 9px;
    }
    & button{
      &.skeleton{
        max-width: 120px;
      }
    }
  }
}

</style>
