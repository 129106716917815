<template>
<div class="question">
  <div class="question__title">
    <label>{{$t('quastion')}} {{num + 1}}</label>
    <Input
      class="question__text"
      v-model="formData.title"
      :placeholder="$t('enter_text')"
      required
    />
    <DeleteButton
      v-if="lengthQuastions > 1"
      @click="() => {$emit('deleteQuastion')}"
    />
  </div>
  <div class="variants">
    <VariantTest
      v-for="(variant, i) of formData.variations"
      :key="variant"
      :option="variant"
      :title="arrEn[i]"
      @onTrue="trueVariant(i)"
      @onClick="deleteVariant(i)"
    />
    <VariantTest
      v-if="formData.variations.length <= 9"
      :title="arrEn[formData.variations.length]"
      @onClick="createVariant()"
      newVariant
    />
  </div>
</div>
</template>

<script setup>
import DeleteButton from '@/components/common/Buttons/DeleteButton.vue'
import VariantTest from '@/components/uiCompany/Tests/Edit/VariantTest.vue'

import { useToast } from '@/modules/toast'
import { useI18n } from 'vue-i18n'
import { defineProps, defineEmits, ref, watch, reactive } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  num: {
    type: [String, Number],
    default: 1
  },
  edit: {
    type: String,
    default: null
  },
  lengthQuastions: {
    type: Number,
    default: null
  },
  deleteVariants: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['update:modelValue', 'update:deleteVariants', 'deleteQuastion', 'addQuastion'])

const i18n = useI18n()
const { toast } = useToast()

const formData = ref(props.modelValue)
const deleteVariants = reactive(props.deleteVariants)
const arrEn = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)

const trueVariant = (i) => {
  formData.value.variations.forEach((variant, a) => {
    if (i !== a) variant.right_variation = 0
    else variant.right_variation = 1
  })
}

const deleteVariant = (i) => {
  if (formData.value.variations.length <= 2) {
    toast({ message: i18n.t('message.minTestLength') })
  } else {
    deleteVariants.push(formData.value.variations[i].variation_id)
    emit('update:deleteVariants', deleteVariants)

    if (formData.value.variations[i].right_variation !== 1) {
      formData.value.variations.splice(i, 1)
    } else {
      formData.value.variations.splice(i, 1)
      formData.value.variations[0].right_variation = 1
    }
  }
}

const createVariant = () => {
  if (props.edit) {
    formData.value.variations.push({ id: formData.value.variations.length + 1, question_id: formData.value.id, right_variation: 0, title: '' })
  } else {
    formData.value.variations.push({ right_variation: 0, title: '' })
  }
}

</script>

<style lang="scss">
.createTest{
  & .question{
    position: relative;
    margin-bottom: 40px;

    &__title{
      grid-gap: 10px 20px !important;
      grid-column: 1/3;
    }

  }
  & .variants{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    grid-column: 2/3;
    margin-bottom: 15px;
  }

  @media (max-width: 992px){
    & .question{
      & label,
      &__text{
        grid-row: 2;
      }
      &__text,
      & .createResume__deleteBtn{
        grid-column: 2/3;
      }
    }
  }

  @media (max-width: 768px){
    & .question{
      &__text{
        grid-column: 1/3;
      }
      & label,
      & .createResume__deleteBtn{
        grid-row: 1;
      }
    }
    & .variants{
      grid-column: 1/2;
    }
  }
}
</style>
