<template>
  <div>
    <Header />
    <div class="bread_crumbs_bl">
        <div class="container">
            <ul class="bread_crumbs_bl__inner">
                <li><router-link :to="'/'">{{$t('home')}}</router-link></li>
                <li>{{$t('contacts')}}</li>
            </ul>
        </div>
    </div>

    <section class="contacts">
        <div class="container">
            <h2 class="section__title">{{$t('contacts')}}</h2>
            <div class="contacts__inner">
                <div class="contacts__col">
                    <FeedbackForm />
                </div>
                <div class="contacts__col">
                    <div class="contacts__item">
                        <div class="contacts__title">{{$t('us_address_title')}}:</div>
                        <p class="contacts__text">{{$t('address')}}</p>
                    </div>
                    <div class="contacts__item">
                        <div class="contacts__title">{{$t('phone')}}:</div>
                        <a class="contacts__text link" href="tel:+77273110954">+7 727 311-09-54</a>
                    </div>
                    <div class="contacts__item">
                        <div class="contacts__title">{{$t('we_are_in_social_networks')}}:</div>
                        <div class="contacts__socialNetworks">
                            <a href="https://www.facebook.com/profile.php?id=100006684453958" class="contacts__socialNetwork" target="_blank">
                                <svg>
                                    <use xlink:href="/img/tmp/sprite.svg#facebook"></use>
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/agencyaltynadam/" class="contacts__socialNetwork" target="_blank">
                                <svg>
                                    <use xlink:href="/img/tmp/sprite.svg#instagram"></use>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/company/altyn-adam-agency" class="contacts__socialNetwork" target="_blank">
                                <svg>
                                    <use xlink:href="/img/tmp/sprite.svg#linkedIn"></use>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="contacts__map">
                    <yandexMap :coords="[addressMap.width, addressMap.height]" :zoom="16">
`                    <ymapMarker :coords="[addressMap.width, addressMap.height]" marker-id="123" hint-content="some hint"/>
                    </yandexMap>`
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script setup>
import FeedbackForm from '@/components/uiLanding/FeedbackForm.vue'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

const addressMap = {
  width: 43.230043,
  height: 76.960943
}
</script>

<style lang="scss">
.contacts{
    &__inner{
        display: grid;
        grid-template-columns: 50% 1fr;
        grid-gap: 95px;
    }

    & .form__input,
    & .form__message{
        margin-bottom: 10px;
    }

    & .form__message{
        min-height: 112px;
    }

    &__item{
        margin-bottom: 30px;
    }

    &__item:last-child{
        margin-bottom: 0;
    }

    &__title,
    &__text{
        color: #212529;
        font-size: 16px;
        line-height: 1.5;
    }

    &__title{
        line-height: 1.2;
        margin-bottom: 5px;
    }

    &__text{
        color: rgba(#212529, .7);
        &.link:hover{
            text-decoration: underline;
        }
    }

    &__socialNetworks{
        display: flex;
    }

    &__socialNetwork{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;

        border: 2px solid #AD1717;
        border-radius: 100px;
        background-color: #AD1717;
        margin-right: 9px;

        transition: background-color .2s linear;

        & svg{
            height: 16px;
            fill: #fff;

            transition: fill .2s linear;
        }

        &:hover{
            background-color: transparent;

            & svg{
                fill: #AD1717;
            }
        }
    }

    &__map{
        grid-column: 1/3;
        height: 300px;
        background-color: #ebebeb;
    }
}
@media (max-width: 993px){
    .contacts{
        &__inner{
            grid-gap: 80px 45px;
        }
    }
}

@media (max-width: 666px){
    .contacts{
        &__inner{
            grid-template-columns: 1fr;
            grid-gap: 55px;
        }
    }
    .contacts__map {
        grid-column: auto;
    }
}
</style>
