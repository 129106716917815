<template>
<div class="editVacancy__item">
  <h2 class="editVacancy__title">{{$t('additionally')}}</h2>

  <!-- ---------------- Тип занятости --------------- -->
  <div>
    <label for="employmentType">{{$t('employment_type')}}</label>
    <div>
      <!--  Before Taxes - Временное оформление  -->
      <div class="checkbox_block">
        <div>
          <Checkbox v-model="formData.temporary_registration" dark />
          <div>
            <p class="title">{{$t('temporary_clearance_possible')}}</p>
            <p class="subtitle">{{$t('it_is_possible_to_apply_for_gpc_or_part_time')}}</p>
          </div>
        </div>
      </div>

      <!-- Employment Types - Тип занятости -->
      <RowCheckboxFilter
        v-model="formData.employment_type_id"
        v-model:validation="success.employment_type"
        :options="helpers.employmentTypes"
        type="circle"
        showAll
      />
    </div>
  </div>

  <!-- ---------------- Режим работы --------------- -->
  <div>
    <label for="workSchedule">{{$t('working_mode')}}</label>
    <RowCheckboxFilter
      v-model="formData.work_schedule_option_id"
      :options="helpers.workSchedule"
      showAll
      dark
    />
  </div>

  <!-- ---------------- Опыт работы --------------- -->
  <div>
    <label for="Experience">{{$t('experience')}}</label>
    <RowCheckboxFilter
      v-model="formData.min_experience_id"
      v-model:validation="success.min_experience"
      :options="helpers.workExperience"
      type="circle"
      showAll
    />
  </div>

  <!-- ---------------- Знание языков --------------- -->
  <div>
    <label for="languages">{{$t('language_skills')}}</label>
    <SelectLanguages
      v-model="formData.languages"
      :options="helpers.languages"
    />
  </div>

  <!-- ---------------- Категория прав --------------- -->
  <div class="driverCategories">
    <label for="driverCategories__list">{{$t('category_of_rights')}}</label>
    <DriverCategories
      v-model="formData.driver_certificate_categories"
      :options="helpers.driverCategories"
    />
  </div>

  <!-- ---------------- Кто может откликаться --------------- -->
  <div>
    <label for="position">{{$t('who_can_respond')}}</label>
    <div>
      <div class="checkbox_block">
        <!-- Соискатели с инвалидностью -->
        <div>
          <Checkbox v-model="formData.with_disabilities" dark />
          <p>{{$t('applicants_with_disabilities')}}</p>
        </div>

        <!-- Соискатели с неполным резюме  -->
        <div>
          <Checkbox v-model="formData.with_incomplete_resume" dark />
          <p>{{$t('applicants_with_an_incomplete_resume')}}</p>
        </div>

        <!-- Только с сопроводительным письмом -->
        <div>
          <Checkbox v-model="formData.cover_letter" dark />
          <p>{{$t('only_with_cover_letter')}}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- ---------------- Тест для соискателя --------------- -->
  <div>
    <label for="test_file">{{$t('applicant_test')}}</label>
    <Select
      id="test_file"
      :placeholder="$t('select_tests')"
      v-model="formData.tests[0]"
      :options="helpers.tests"
    />
  </div>

</div>
</template>

<script setup>
import DriverCategories from '@/components/uiCompany/Vacancies/Edit/DriverCategories.vue'
import RowCheckboxFilter from '@/components/uiLanding/Filter/Sidebar/RowCheckboxFilter.vue'
import Checkbox from '@/components/common/Checkbox.vue'
import SelectLanguages from '@/components/common/Selects/SelectLanguages.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  },
  validation: {
    type: Object,
    default: null
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
const success = ref(props.validation)

watch(
  () => [formData.value, success.value],
  () => {
    emit('update:modelValue', formData.value)
    emit('update:validation', success.value)
  },
  {
    deep: true
  }
)
</script>

<style scoped lang="scss">
.editVacancy__item{
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 50px;
}
.driverCategories{
  margin-bottom: 40px;
}
</style>
