<template>
<div class="passCheckbox__item" :class="{disabled: result}" @click="() => {formData = true}">
  <div class="passCheckbox__state" v-if="variant.id === selectVariant && result" :class="variant.right_variation === 1 ? 'true' : 'false'">
    <svg v-if="variant.right_variation === 1" viewBox="0 0 14 11" xmlns="http://www.w3.org/2000/svg">
      <g>
          <path d="M1.0708 5.00021L5.40017 9.32939L12.9292 0.670898" stroke="#88C879" stroke-width="2"/>
      </g>
    </svg>
    <svg v-else>
        <use xlink:href="/img/tmp/sprite.svg#close"></use>
    </svg>
  </div>
  <Checkbox
    v-else
    class="circle"
    v-model="formData"
    disabled
    dark
  />
  <p>{{variant.title}}</p>
</div>
</template>

<script setup>
import Checkbox from '@/components/common/Checkbox.vue'

import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  variant: {
    type: Object,
    default: () => {}
  },
  selectVariant: {
    type: Number,
    default: null
  },
  result: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:selectVariant'])

const formData = ref(props.selectVariant === props.variant.id)

watch(
  () => formData.value,
  () => {
    if (formData.value) emit('update:selectVariant', props.variant.id)
  }
)

watch(
  () => props.selectVariant,
  () => {
    if (props.selectVariant === props.variant.id) formData.value = true
    else formData.value = false
  }
)
</script>

<style lang="scss">
.passCheckbox{
  &__item{
    display: grid;
    grid-template-columns: 26px 1fr;
    grid-gap: 15px;
    cursor: pointer;

    & p{
      cursor: pointer;
    }

    &.disabled{
      pointer-events: none;
      & * {
        pointer-events: none;
      }
    }
  }
  &__state{
    width: 26px;
    height: 26px;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    border: 3px solid #F05F5F;
    background-color: #fff;
    & svg{
      max-width: 15px;
      max-height: 15px;
      fill: none;
      stroke: #F05F5F;
      overflow: hidden;
    }

    &.true{
      border-color:#88C879;
      & .svg{
        stroke: #88C879;
      }
    }

    &.false{
      & svg{
        max-width: 12px;
        stroke-width: 3px;
        max-height: 12px;
      }
    }
  }
  @media (max-width: 992px){
    &__item{
      grid-template-columns: 23px 1fr;
      grid-gap: 12px;
    }
    &__state{
      width: 23px;
      height: 23px;
      border: 2.5px solid #F05F5F;
      & svg{
        max-width: 13px;
        max-height: 13px;
      }

      &.true{
        border-color: #88C879;
      }

      &.false{
        & svg{
          max-width: 10px;
          stroke-width: 2.5px;
          max-height: 10px;
        }
      }
    }
  }
  @media (max-width: 768px){
    &__item{
      grid-template-columns: 20px 1fr;
      grid-gap: 10px;
    }
    &__state{
      width: 20px;
      height: 20px;
      border: 2px solid #F05F5F;
      & svg{
        max-width: 10px;
        max-height: 10px;
      }

      &.false{
        & svg{
          max-width: 8px;
          stroke-width: 2px;
          max-height: 8px;
        }
      }
    }
  }
  @media (max-width: 576px){
    &__item{
      grid-template-columns: 18px 1fr;
      grid-gap: 8px;
    }
    &__state{
      width: 18px;
      height: 18px;
      border: 1.5px solid #F05F5F;
      & svg{
        max-width: 8px;
        max-height: 8px;
      }

      &.false{
        & svg{
          max-width: 7px;
          stroke-width: 1.5px;
          max-height: 7px;
        }
      }
    }
  }

  @media (max-width: 400px){
    &__item{
      grid-template-columns: 16px 1fr;
      grid-gap: 6px;
    }
    &__state{
      width: 16px;
      height: 16px;
      border: 1px solid #F05F5F;
      & svg{
        max-width: 6px;
        max-height: 6px;
      }

      &.false{
        & svg{
          max-width: 6px;
          stroke-width: 1px;
          max-height: 6px;
        }
      }
    }
  }
}
</style>
