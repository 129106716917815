<template>
  <Header />
  <section class="userConnectedServices">
    <div class="container">
      <h3 class="section__title">{{$t('services.connected')}}</h3>
      <ListServices :services="services"/>
      <Pagination
        v-if="services"
        v-model="filter.page"
        :perPage="filter.per_page"
        :total="total"
        @newPage="fetchData"
      />
    </div>
  </section>

  <Modal
    contentCenter="true"
    :hide="() => {showModal = false}"
    :show="showModal"
    :title="'Услуга успешно подключена'"
    :agreement="() => {showModal = false}"
    :btnTitle="$t('close')"
    class="showBoxShadow white"
    successfully
  />

  <Loading :show="loading" fixed />
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import ListServices from '@/components/uiEmployee/ConectedServices/ListServices.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'
import { watch } from 'vue'

import { getConnectedServices, postConnectService } from '@/actions/employee'

const route = useRoute()

const showModal = ref(false)
const loading = ref(false)

const services = ref(null)
const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20,
    sort_type: false
  }
)
const total = ref(0)

const fetchData = async () => {
  services.value = null

  if (route.query.service_id) {
    loading.value = true
    await postConnectService(route.query.service_id)
    history.replaceState(history.state, '', window.location.origin + '/user/services/connected-services/result?page=1')
    loading.value = false
    showModal.value = true
  }

  const response = (await getConnectedServices(filter)).data
  services.value = response.data
  total.value = response.total
  router.push({ path: '/user/services/connected-services/result', query: { page: filter.page } })
}

onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'ClientServices') {
      filter.page = route.query.page ?? 1
      fetchData()
    }
  }
)
</script>
