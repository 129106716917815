<template>
  <Header />
  <section class="favorites searchVacanciesClient">
    <div class="container">
      <h3 class="section__title">{{$t('responses_and_invitations')}}</h3>
      <NothingHere v-if="responses && responses.length === 0"/>
      <div class="vacancySearch__items" v-else-if="!responses">
        <div
          class="vacancies__item skeleton__block"
          v-for="vacancy of 5"
          :key="vacancy"
        >
          <div class="top">
            <div class="prof skeleton middle-text"></div>
            <div class="price skeleton middle-text"></div>
          </div>
          <div class="center">
            <div class="company skeleton middle-text"></div>
            <div class="city skeleton middle-text"></div>
          </div>
          <div class="bottom">
            <div class="state skeleton small-text"></div>
            <div class="right">
              <div class="date skeleton small-text"></div>
              <Button class="skeleton"/>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        v-for="response of responses"
        :key="response"
      >
        <RowEmployeeVacancy
          :vacancy="response.vacancy"
          :status="response.status"
          :deleted="() => {fetchData()}"
          :responseId="response.id"
        />
      </div>
      <Pagination
        v-if="responses"
        v-model="filter.page"
        :perPage="filter.per_page"
        :total="total"
        @newPage="fetchData"
      />
    </div>
  </section>
</template>

<script setup>
import RowEmployeeVacancy from '@/components/uiEmployee/Vacancies/RowVacancy.vue'

import { onMounted, reactive, ref, watch } from 'vue-demi'
import { getResponsesVacancies } from '@/actions/employee'
import router from '@/router/index.js'
import { useRoute } from 'vue-router'

const route = useRoute()

// Filter
const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20
  }
)
const total = ref()

const responses = ref(null)
const fetchData = async () => {
  responses.value = null
  const response = (await getResponsesVacancies(filter.page, undefined, filter.per_page)).data.responds
  responses.value = response.data
  total.value = response.total
  router.push({ path: '/user/responses/result', query: { page: filter.page } })
}

onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'Responses') {
      filter.page = route.query.page ?? 1
      fetchData()
    }
  }
)
</script>

<style lang="scss">
.searchVacanciesClient.favorites .vacancies__item{
  & .state{
    display: inline-block;
  }
  @media (max-width: 768px){
    & .bottom {
      align-items: flex-end !important;
    }
  }
  @media (max-width: 576px){
    & .bottom .right {
      display: flex !important;
      grid-gap: 10px;
    }
  }
}
</style>
