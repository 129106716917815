<template>
  <div
    class="checkbox"
    :class="{active: agreement === !minus, dark}"
    @click="clickCheckbox()"
  >
    <svg>
      <use xlink:href="/img/tmp/sprite.svg#checkbox"></use>
    </svg>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean],
    default: null
  },
  validation: {
    type: Boolean,
    default: false
  },
  dark: {
    type: Boolean,
    default: false
  },
  minus: {
    type: Boolean,
    default: false
  },
  notDoubleClick: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation', 'checkbox'])
const agreement = ref(false)
if (props.modelValue || props.modelValue === 1) agreement.value = true

watch(
  () => props.modelValue,
  () => {
    agreement.value = props.modelValue
  }
)

const clickCheckbox = () => {
  if (!props.disabled) {
    if (!agreement.value) {
      agreement.value = true
      emit('update:modelValue', true)
      emit('update:validation', true)
    } else if (!props.notDoubleClick) {
      agreement.value = false
      emit('update:modelValue', false)
      emit('update:validation', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox{
  width: 24px;
  height: 24px;
  border: 0.5px solid rgba(33, 37, 41, 0.7);
  border-radius: 0;
  cursor: pointer;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  & svg{
    fill: none;
  }

  &.active{
    background-color: #0075FF;

    & svg{
      opacity: 1;
    }
  }

  &.active.dark{
    background-color: #4F553D;
  }
  &.active.red{
    background-color: #AD1717;
  }

  &.circle{
    width: 26px;
    height: 26px;

    border-radius: 50%;

    & svg{
      display: none;
    }

    &.active{
      border: 7px solid rgba(79, 85, 61, 0.7);
      box-sizing: border-box;
      background-color: #fff;
    }
  }

  @media (max-width: 992px){
    width: 22px;
    height: 22px;
    &.circle{
      width: 24px;
      height: 24px;

      &.active{
        border: 6px solid rgba(79, 85, 61, 0.7);
      }
    }
  }
  @media (max-width: 768px){
    width: 20px;
    height: 20px;
    &.circle{
      width: 22px;
      height: 22px;

      &.active{
        border: 5px solid rgba(79, 85, 61, 0.7);
      }
    }
  }
  @media (max-width: 576px){
    width: 18px;
    height: 18px;
    &.circle{
      width: 20px;
      height: 20px;

      &.active{
        border: 4px solid rgba(79, 85, 61, 0.7);
      }
    }
  }
  @media (max-width: 400px){
    width: 16px;
    height: 16px;
    &.circle{
      width: 18px;
      height: 18px;

      &.active{
        border: 3px solid rgba(79, 85, 61, 0.7);
      }
    }
  }
}
</style>
