<template>
  <div>
    <Header />
    <div class="bread_crumbs_bl">
        <div class="container">
            <ul class="bread_crumbs_bl__inner">
                <li><router-link :to="'/'">{{$t('home')}}</router-link></li>
                <li><a :href="'/#stockAll'">{{$t('sales')}}</a></li>
                <li v-if="stockDetail">{{stockDetail.title}}</li>
            </ul>
        </div>
    </div>
    <section class="saleDetail">
        <div class="container" v-if="stockDetail">
            <h3 class="section__title">{{stockDetail.title}}</h3>
            <div class="section__block saleDetail__block">
                <div class="section__preview saleDetail__preview">
                    <div>
                        <img :src="`${apiUrl}/${stockDetail.image}`" alt="">
                    </div>
                </div>
                <div class="section__content">
                    <div class="saleDetail__text" v-html="stockDetail.text">
                    </div>
                </div>
            </div>
        </div>

        <div class="container skeleton__block" v-else>
            <h3 class="section__title skeleton small-text" style="width:70%"></h3>
            <div class="section__block">
                <div class="section__preview">
                    <div>
                        <div class="img skeleton skeleton-img"></div>
                    </div>
                </div>
                <div class="section__content">
                  <div>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:100%;height:" v-for="el of 7" :key="el"></p>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:80%;"></p>
                    <p class="skeleton middle-text" style="margin-bottom:10px;width:30%;"></p>
                  </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script setup>
import { apiUrl } from '@/api'
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { getStockDetail } from '@/actions/home'
import { onMounted } from 'vue-demi'
const stockDetail = ref(null)
const route = useRoute()

onMounted(
  async () => {
    stockDetail.value = (await getStockDetail(route.params.stock_id)).data.data
  }
)
</script>
<style lang="scss" scoped>
.saleDetail{
  min-height: 100vh;
    &__preview div{
      background-color: #ededed;
    }
    &__text p{
        color: #A32D2B;
        font-size: 16px;
        margin-bottom: 28px;
    }

    &__text p{
        color: rgba(#212529, .7);
    }
}

.skeleton__block{
  & .middle-text{
    height: 1.5em;
  }
}
</style>
