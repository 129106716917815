<template>
<div class="searchFilter__sidebar"
  :class="{active: showSideBar}"
  @click="emit('update:showSideBar', false)"
>
  <div class="searchFilter__sidebarInner" @click.stop>
    <div class="searchFilter__sidebarTitle" @click="emit('update:showSideBar', false)">
      <svg>
        <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
      </svg>
      <span>{{$t('filter')}}</span>
    </div>
    <div v-if="forVacancy || forResumes" @click="clickMouse">
      <!-- --------  Search Button  ------- -->
      <Button
        class="searchFilter__sidebarBtn"
        :name="$t('search')"
        @click="() => {$emit('search'), $emit('search')}"
      />

      <!-- ---------------- Countries - Страны --------------- -->
      <!-- ---------------- Cities - Города --------------- -->
      <MultiCitiesFilter
        v-if="forVacancy"
        class="searchFilter__sidebarRow"
        v-model="formData.cities_id"
        v-model:countryId="formData.country_id"
        :optionsCountry="countries"
        :optionsCity="cities"
        :countryTitle="countryTitle"
      />
      <RowSidebarFilter
        v-else
        class="searchFilter__sidebarRow"
        v-model="formData.country_id"
        :title="$t('region')"
        :options="countries"
      />

      <!-- ---------------- Wages - Заработная плата --------------- -->
      <RowCheckboxFilter
        class="searchFilter__sidebarRow"
        v-model="formData.salary_type"
        :title="$t('wage')"
        :options="wages"
        showAll
      />

      <!-- ---------------- Professionals Areas - Сфера деятельности --------------- -->
      <RowCheckboxFilter
        v-if="forVacancy"
        class="searchFilter__sidebarRow"
        v-model="formData.professional_areas_id"
        :title="$t('field_of_activity')"
        :options="professionalsAreas"
        multiFilter
      />

      <!-- ----------------  Industry - Отрасль --------------- -->
      <RowCheckboxFilter
        v-else
        class="searchFilter__sidebarRow"
        v-model="formData.industries_id"
        :title="$t('industry')"
        :options="industries"
        :showAmount="5"
        multiFilter
      />

      <!-- ---------------- Work Experience - Опыт работы --------------- -->
      <RowCheckboxFilter
        class="searchFilter__sidebarRow"
        v-model="formData.min_experiences_id"
        :title="$t('experience')"
        :options="workExperience"
        multiFilter
        showAll
      />

      <!-- ---------------- Employment Types - Тип занятости --------------- -->
      <RowCheckboxFilter
        v-if="forVacancy"
        class="searchFilter__sidebarRow"
        v-model="formData.employment_types_id"
        :title="$t('employment_type')"
        :options="employmentTypes"
        multiFilter
        showAll
      />

      <!-- ---------------- Work Schedule - Режим работы --------------- -->
      <RowCheckboxFilter
        v-if="forVacancy"
        class="searchFilter__sidebarRow"
        v-model="formData.work_schedule_options_id"
        :title="$t('working_mode')"
        :options="workSchedule"
        multiFilter
        showAll
      />

      <!-- ---------------- Know Languages - Знание языков --------------- -->
      <RowCheckboxFilter
        class="searchFilter__sidebarRow"
        v-model="formData.languages_id"
        :title="$t('language_skills')"
        :options="languages"
        multiFilter
      />

      <RowCheckboxFilter
        v-if="forResumes"
        class="searchFilter__sidebarRow"
        v-model="formData.education_types_id"
        :title="$t('education')"
        :options="educationType"
        multiFilter
        showAll
      />

      <!-- ---------------- Gender Type - Пол --------------- -->
      <RowCheckboxFilter
        class="searchFilter__sidebarRow"
        v-model="formData.sex"
        :title="$t('gender')"
        :options="genders"
        showAll
      />

      <!-- --------  Search Button  ------- -->
      <Button
        class="searchFilter__sidebarBtn"
        :name="$t('search')"
        @click="() => {$emit('search'), $emit('search')}"
      />
    </div>

    <div v-else>
      <RowSidebarFilter
        class="searchFilter__sidebarRow"
        v-model="formData.country_id"
        :title="$t('region')"
        :options="countries"
      />
      <RowSidebarFilter
        class="searchFilter__sidebarRow"
        v-model="formData.city_id"
        :title="$t('city')"
        :options="cities"
      />
      <Button
        class="searchFilter__sidebarBtn"
        :name="$t('search')"
        @click="() => {$emit('search'), $emit('search')}"
      />
    </div>
  </div>
</div>
</template>

<script setup>
import RowSidebarFilter from '@/components/uiLanding/Filter/Sidebar/RowSidebarFilter.vue'
import RowCheckboxFilter from '@/components/uiLanding/Filter/Sidebar/RowCheckboxFilter.vue'
import MultiCitiesFilter from '@/components/uiLanding/Filter/Sidebar/MultiCitiesFilter.vue'

import { useI18n } from 'vue-i18n'
import { defineProps, defineEmits, ref, watch, onMounted } from 'vue'

import { getCountries, getCities, getLanguages, getEmploymentTypes, getWorkExperience, getProfessionalsAreas, getWorkSchedule, getEducationType, getIndustries } from '@/actions/help'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  forVacancy: {
    type: Boolean,
    default: false
  },
  forResumes: {
    type: Boolean,
    default: false
  },
  showSideBar: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'update:showSideBar', 'search'])

const i18n = useI18n()

const formData = ref(props.modelValue)
const countryId = ref(null)

const checkSalary = () => {
  switch (formData.value.salary_type) {
    // до 100 000
    case 1:
      formData.value.salary_min = 0
      formData.value.salary_max = 100000
      break

    // 100 000 - 200 000
    case 2:
      formData.value.salary_min = 100000
      formData.value.salary_max = 200000
      break

    // 200 000 - 300 000
    case 3:
      formData.value.salary_min = 200000
      formData.value.salary_max = 300000
      break

    // 300 000 - 400 000
    case 4:
      formData.value.salary_min = 300000
      formData.value.salary_max = 400000
      break

    // 400 000 - 500 000
    case 5:
      formData.value.salary_min = 400000
      formData.value.salary_max = 500000
      break

    // с 500 000
    case 6:
      formData.value.salary_min = 500000
      formData.value.salary_max = 10000000000000
      break

    // Show All - Показать все
    default:
      formData.value.salary_type = 0
      formData.value.salary_min = null
      formData.value.salary_max = null
  }
  emit('update:modelValue', formData.value)
}

watch(
  () => [formData.value, formData.value.salary_type],
  () => {
    checkSalary()
  }
)

if (formData.value.salary_type !== 0) checkSalary()

// ====================  Api Countries ans Cities ====================
const countries = ref(null)
const countryTitle = ref(null)
const cities = ref(null)

// ====================  Api Countries ans Cities  ====================
const languages = ref(null)
const employmentTypes = ref(null)
const workExperience = ref(null)
const professionalsAreas = ref(null)
const workSchedule = ref(null)
const educationType = ref(null)
const industries = ref(null)

const wages = [
  {
    title: i18n.t('wages_option_1'),
    id: 1
  },
  {
    title: i18n.t('wages_option_2'),
    id: 2
  },
  {
    title: i18n.t('wages_option_3'),
    id: 3
  },
  {
    title: i18n.t('wages_option_4'),
    id: 4
  },
  {
    title: i18n.t('wages_option_5'),
    id: 5
  },
  {
    title: i18n.t('wages_option_6'),
    id: 6
  }
]

const genders = [
  {
    title: i18n.t('woman'),
    id: 0
  },
  {
    title: i18n.t('man'),
    id: 1
  }
]

onMounted(
  async () => {
    countries.value = (await getCountries()).data
    let response = {}
    if (formData.value.country_id) response = (await getCities(formData.value.country_id)).data
    cities.value = response.cities
    countryTitle.value = response.title

    if (props.forVacancy) {
      employmentTypes.value = await getEmploymentTypes()
      workSchedule.value = await getWorkSchedule()
      professionalsAreas.value = (await getProfessionalsAreas()).data
    }
    if (props.forVacancy || props.forResumes) {
      languages.value = await getLanguages()
      workExperience.value = await getWorkExperience()
      educationType.value = await getEducationType()
      industries.value = await getIndustries()
    }
  }
)
watch(
  () => [formData.value.country_id, countryId.value],
  async () => {
    if (formData.value.country_id) cities.value = (await getCities(formData.value.country_id)).data.cities
    else if (countryId.value) cities.value = (await getCities(countryId.value)).data.cities
    else cities.value = null
  }
)
watch(
  () => [formData.value.country_id, formData.value.city_id],
  () => {
    if (!props.forVacancy) emit('search')
  }
)
</script>

<style lang="scss">
.searchFilter{
  &__sidebar{
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.0955529);
  }
  &__sidebarRow{
    margin-bottom: 45px;
  }
  &__sidebarBtn{
    width: 100%;
    margin-bottom: 20px;
  }
  &__sidebarTitle{
    display: none;
    align-items: center;
    grid-gap: 15px;
    margin-bottom: 25px;
    & svg{
      cursor: pointer;
      display: block;
      height: 18px;
      width: 10px;
      transform: rotate(180deg);
      transition: transform .2s linear;
    }

    & span{
      font-size: 22px;
      font-weight: 500;
    }
  }

  @media (max-width: 992px){
    &__sidebarRow{
      margin-bottom: 35px;
    }
  }

  @media (max-width: 768px){
    &__sidebar{
      height: 100vh;
      width: 100%;

      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1000;

      opacity: 0;
      visibility: hidden;

      overflow: hidden;
      padding: 0;

      border: none;

      background-color: rgba(#000, .7);
      backdrop-filter:blur(5px);
      transition: all .4s ease;

      &.active{
        opacity: 1;
        visibility: visible;
        & .searchFilter__sidebarInner{
          right: 0;
        }
      }
    }
    &__sidebarInner{
      height: 100vh;
      width: 300px;

      position: absolute;
      right: -300px;
      top: 0;

      overflow: auto;
      padding: 20px;
      background-color: #fff;

      transition: all .2s linear;
    }
    &__sidebarTitle{
      display: flex;
    }
    &__sidebarRow{
      margin-bottom: 30px;
    }
  }
}
</style>
