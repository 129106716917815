// ====================  FORMAT PHONE NUMBER  ====================
export const formatToSalary = (input) => {
  input = String(input)
  let total = ''
  let width = 0
  if (!input) return ''
  for (let i = 0; i < input.length;) {
    if (width !== 1 && input.length % 3 !== 0) {
      total += input.substring(0, input.length % 3)
      i += input.length % 3
    } else {
      total += input.substring(i, i + 3)
      i += 3
    }
    total += ' '
    width = 1
  }
  total = total.substring(0, total.length - 1)
  return total
}
