<template>
  <Header />
  <section class="companySearch">
    <div class="container">
      <h3 class="section__title">{{$t('companies')}}</h3>
      <HeaderFilter
        v-model="filter"
        :total="total"
        withFilterBtn
        @search="fetchData"
        @showSideBar="() => {showSideBar = true}"
      />
      <div class="searchFilters__block">
        <SidebarFilter
          v-model="filter"
          @search="fetchData"
          v-model:showSideBar="showSideBar"
        />
        <NothingHere v-if="companies && companies.length === 0"/>
        <div v-else>
          <ListCompanies :companies="companies" />
          <Pagination
            v-if="companies"
            v-model="filter.page"
            :perPage="filter.perPage"
            :total="total"
            @newPage="fetchData"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import ListCompanies from '@/components/uiEmployee/Companies/Search/ListCompanies.vue'
import HeaderFilter from '@/components/uiLanding/Filter/Header/HeaderFilter.vue'
import SidebarFilter from '@/components/uiLanding/Filter/Sidebar/ListSidebarFilter.vue'

import { reactive, ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { onMounted, watch } from 'vue-demi'
import router from '@/router/index.js'

import { getSearchCompany } from '@/actions/employee'

const route = useRoute()

const companies = ref(null)
const total = ref(0)
const showSideBar = ref(false)

const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    perPage: 20,
    city_id: Number(route.query.city),
    country_id: Number(route.query.country),
    sort_type: route.query.type === 'true' ? !false : false,
    name: route.query.name
  }
)

const fetchData = async () => {
  companies.value = null
  const response = (await getSearchCompany(filter)).data
  companies.value = response.data
  total.value = response.total
  router.push({ path: '/user/search-company/result', query: { page: filter.page, type: filter.sort_type, country: filter.country_id, city: filter.city_id, name: filter.name } })
}

onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (route.name === 'SearchCompany') {
      filter.page = route.query.page ?? 1
      filter.name = route.query.name
      filter.country_id = Number(route.query.country)

      filter.city_id = Number(route.query.city)

      if (route.query.type !== 'true') filter.sort_type = false
      fetchData()
    }
  }
)
</script>
