<template>
<LayoutRow
  :title="$t('more_about_yourself')"
  v-model="formData.description_on"
>
  <div class="createResume__itemInner">
      <label for="skills">{{$t('describe_in_free_form')}}: <br><span>{{$t('list_separated_by_commas')}}</span></label>
      <Input
        id="additionally"
        type="textarea"
        v-model="formData.description"
        required/>
  </div>
</LayoutRow>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue'])

const formData = ref(props.modelValue)
if (formData.value.description) formData.value.description_on = true
else formData.value.description_on = false

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)
</script>
