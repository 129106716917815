<template>
<div class="vacancies__item" v-if="vacancy">
  <!-- ====================  TOP  ==================== -->
  <div class="top">
    <router-link
      v-if="props.status === 'Accepted'"
      :to="'/user/invitations/' + responseId"
      class="prof"
    >
        {{vacancy.position}}
    </router-link >

    <router-link
      v-else
      :to="'/user/detail-vacancy/' + vacancy.id"
      class="prof"
    >
      {{vacancy.position}}
    </router-link >
    <div class="price">
      {{vacancy.min_salary ? formatToSalary(vacancy.min_salary) : ''}}
      {{vacancy.min_salary && vacancy.max_salary ? '-' : ''}}
      {{vacancy.max_salary ? formatToSalary(vacancy.max_salary) : ''}}
      {{` ${vacancy.currency?.title ?? ''}`}}
    </div>
  </div>

  <!-- ====================  CENTER  ==================== -->
  <div class="center">
    <div class="company">{{vacancy.manager.company.name}}</div>
    <div class="city">{{vacancy.city?.title ?? ''}}</div>
  </div>

  <!-- ====================  BOTTOM  ==================== -->
  <div class="bottom">
    <div
      v-if="responseId"
      :class="`state ${status}`"
    >
      <span>{{inviteStatus}}</span>
    </div>

    <div
      v-else
      class="state"
    >
      <span>{{vacancyInFavorites ? $t('be_in_favorites') : ''}}</span>
    </div>

    <div class="right">
      <div class="date">
        {{vacancyUpdateDate(vacancy.updated_at)}}
      </div>
      <Button
        v-if="responseId"
        type="gray"
        :name="$t('delete')"
        @click="() => {inviteStatus === 'Rejected' ? deleteResponse() : quastion = true}"
        :loading="loading"
      />
      <Button
        v-else
        type="gray"
        :name="vacancyInFavorites ? $t('remove_from_favorites') : $t('add_to_favorites')"
        @click="vacancyToFavorite"
        :loading="loading"
      />
    </div>
  </div>
</div>
<Modal
  :show="quastion"
  :hide="()=>{quastion = false}"
  :agreement="() => {deleteResponse()}"
  :loading="loading"
  quastion
/>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'

import { defineProps, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'

import { formatToSalary } from '@/helpers/formatSalary'
import { postFavoriteVacancy, deleteFavoriteVacancy, deleteResponseVacancy } from '@/actions/employee'

const props = defineProps({
  vacancy: {
    type: Object,
    default: () => {}
  },
  deleted: {
    type: Function,
    default: () => null
  },
  responseId: {
    type: [Number, String],
    default: null
  },
  status: {
    type: String,
    default: null
  }
})

const i18n = useI18n()
const loading = ref(false)
const quastion = ref(false)

// vacancy In Favorites
const vacancyInFavorites = ref(props.vacancy.favorite_vacancies?.length > 0)

// vacancy Invite Status
const inviteStatus = ref(i18n.t('not_viewed'))
switch (props.status) {
  case 'Accepted':
    inviteStatus.value = i18n.t('you_were_invited')
    break
  case 'Rejected':
    inviteStatus.value = i18n.t('you_were_denied')
    break
  case 'Inprogress':
    inviteStatus.value = i18n.t('viewed')
    break
  default:
    inviteStatus.value = i18n.t('not_viewed')
}

// Example Results => 19 Апрель 2022
const vacancyUpdateDate = (input) => {
  return `${moment(input).format('DD')} ${i18n.t('months.' + moment(input).format('M'))} ${moment(input).format('YYYY')}`
}

const vacancyToFavorite = async () => {
  loading.value = true
  try {
    if (!vacancyInFavorites.value) await postFavoriteVacancy({ vacancy_id: props.vacancy.id })
    else await deleteFavoriteVacancy(props.vacancy.id)
    vacancyInFavorites.value = !vacancyInFavorites.value
  } finally {
    loading.value = false
  }
}

const deleteResponse = async () => {
  loading.value = true
  try {
    await deleteResponseVacancy(props.responseId)
    quastion.value = false
    props.deleted()
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.searchVacanciesClient .vacancies__item{
  & .price{
    font-size: 22px;
    text-transform: uppercase;
    text-align: end;
    color: #212529;
  }
  & .company,
  & .city{
    font-size: 16px;
    color: rgba(33, 37, 41, 0.7);
  }
  & .company{
    text-transform: uppercase;
    &.skeleton{
      margin-bottom: 10px;
    }
  }
  & .state{
    display: none;
    font-size: 16px;
    color: #2CA622;
    white-space: nowrap;

    &.null{
      color: rgba(173, 23, 23, 0.5);
    }
    &.Accepted{
      color: #2CA622;
    }
    &.Rejected{
      color: #AD1717;
    }
    &.Inprogress{
      color: #2283BA;
    }

  }
  & .delete{
    font-size: 16px;
    text-decoration-line: underline;
    color: #979797;
    transition: all .2s linear;
    &:hover{
      color: #AD1717;
    }
    &[disabled]{
      pointer-events: none;
    }
  }
  & .bottom .right .button{
    margin-right: 0 !important;
  }
  @media (max-width: 992px){
    padding: 10px 14px;
    & .price{
      font-size: 20px;
    }
    & .company,
    & .city,
    & .state,
    & .delete{
      font-size: 14px;
    }
  }
  @media (max-width: 768px){
    & .prof{
      display: inline-block;
      margin-bottom: 6px;
    }
    & .price{
      text-align: start;
      font-size: 18px;
    }
    & .bottom{
      align-items: center !important;
    }
    & .bottom .right{
      width: 100%;
      justify-content: space-between;
    }
    & .state{
      margin-right: 15px;
    }
    & .company,
    & .city,
    & .state,
    & .delete{
      font-size: 12px;
    }
  }
  @media (max-width: 576px){
    & .bottom .right{
      display: grid !important;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      & button span{
        margin: 0 auto;
      }

      & button.skeleton{
        width: 100%;
      }
    }
    & .price{
      font-size: 16px;
    }
    & .company,
    & .city,
    & .delete{
      font-size: 11px;
    }
  }

  @media (max-width: 400px){
    padding: 8px 12px;
    & .top{
      flex-direction: column;
    }

    & .bottom{
      display: flex;
      justify-content: flex-end;

      & .right{
        flex-direction: column;
        align-items: flex-end;
        grid-gap: 6px;
      }
    }

    & .date{
      margin-right: 0;
    }

    & .prof{
      margin-right: 0;
      margin-bottom: 4px;
    }
  }
}
</style>
