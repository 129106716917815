<template>
<LayoutRow
  :title="$t('personal_qualities')"
  v-model="formData.personalSkills_on"
>
  <div class="createResume__itemInner">
    <label for="skills">{{$t('describe_in_free_form')}}: <br><span>{{$t('list_separated_by_commas')}}</span></label>
    <Input
      id="title"
      type="textarea"
      v-model="formData.personal_skills"
      required/>
  </div>
</LayoutRow>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'
const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  }
})
const emit = defineEmits(['update:modelValue'])

const formData = ref(props.modelValue)
if (formData.value.personal_skills) formData.value.personalSkills_on = true
else formData.value.personalSkills_on = false

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)
</script>
