<template>
<div class="form__item">
  <div class="createResume__itemInner">
    <label for="title">{{$t('career_objective')}}:</label>
    <Input
      id="title"
      v-model="formData.title"
      v-model:validation="success.title"
      required/>
  </div>
  <div class="createResume__itemInner">
  <label for="industries">{{$t('industry')}}:</label>
    <Select
      id="industries"
      v-model="formData.industry_id"
      v-model:validation="success.industries"
      :options="helpers.industries"/>
  </div>
  <div class="createResume__itemInner">
    <label for="email">*E-mail:</label>
    <Input
      type="email"
      id="email"
      v-model="formData.email"
      v-model:validation="success.email"
      required/>
  </div>
  <div class="createResume__itemInner">
    <label for="phone">*{{$t('phone')}}:</label>
    <Input
      id="phone"
      type="tel"
      v-model="formData.phone"
      v-model:validation="success.phone"
      required/>
  </div>
  <div class="createResume__itemInner">
    <label for="address">{{$t('address_title')}}:</label>
    <Input
      id="address"
      :placeholder="$t('not_required_confidential')"
      v-model="formData.address"/>
  </div>
  <div class="createResume__itemInner">
    <label for="try_salary">{{$t('desired_salary')}}:</label>
    <div class="wages">
      <Input
        type="number"
        id="try_salary"
        trySalary
        v-model="formData.try_salary"/>
      <Select
        v-model="formData.currency_id"
        :options="helpers.currencies"/>
    </div>
  </div>
  <div class="createResume__itemInner">
    <label for="nationality">{{$t('citizenship')}}:</label>
    <Select
      id="nationality"
      v-model="formData.country_id"
      :options="helpers.country"/>
  </div>
  <div class="createResume__itemInner">
    <label for="workPermit">{{$t('work_permit')}}: </label>
    <Select
      id="workPermit"
      :options="helpers.country"
      :placeholder="$t('choose_country')"
      v-model="formData.willing_to_relocate"
      multiSelect/>
  </div>
  <div class="createResume__itemInner">
    <label for="marital_status">{{$t('family_status')}}: </label>
    <Select
      id="marital_status"
      v-model="formData.marital_status"
      :options="helpers.maritalStatus"/>
  </div>
  <div class="createResume__itemInner">
    <label for="children">{{$t('children')}}:</label>
    <Select
      id="children"
      v-model="formData.kids"
      :options="helpers.kids"/>
  </div>
  <div class="createResume__itemInner">
    <label for="professional_skills">*{{$t('rofessional_skills')}}: <br><span>{{$t('list_separated_by_commas')}}</span></label>
    <Input
      type="textarea"
      id="professional_skills"
      v-model="formData.professional_skills"
      v-model:validation="success.professional_skills"
      required/>
  </div>
</div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  helpers: {
    type: Object,
    default: () => {}
  },
  validation: {
    type: Object,
    default: null
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])
const formData = ref(props.modelValue)
const success = ref(props.validation)

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
    emit('update:validation', success.value)
  }
)
</script>
