<template>
<div class="createResume__itemRow">
  <DeleteButton @click="() => {$emit('onDelete')}" />
  <div class="createResume__itemInner">
    <label for="workPeriod">*{{$t('work_period')}}:</label>
    <DatePickerPeriod
      v-model="formData"
      v-model:validation="success"/>
  </div>
  <div class="createResume__itemInner">
    <label for="companyName">*{{$t('name_company')}}:</label>
    <Input
      id="name"
      v-model="formData.company_name"
      v-model:validation="success.name"
      required
    />
  </div>
  <div class="createResume__itemInner">
    <label for="industries">{{$t('industry')}}:</label>
    <Select
      id="industries"
      v-model="formData.industry_id"
      v-model:validation="success.industry_id"
      :options="helpers.industries"/>

  </div>
  <div class="createResume__itemInner">
    <label for="country">{{$t('country')}}:</label>
    <Select
      id="nationality"
      v-model="formData.country_id"
      v-model:validation="success.country_id"
      :options="helpers.country"/>
  </div>
  <div class="createResume__itemInner">
    <label for="cities">{{$t('city')}}:</label>
    <Select
      id="cities"
      v-model="formData.city_id"
      :options="cities"/>
  </div>
  <div class="createResume__itemInner">
    <label for="position">{{$t('position')}}:</label>
    <Input
      id="position"
      v-model="formData.position"
      v-model:validation="success.position"
      required
    />
  </div>
  <div class="createResume__itemInner">
    <label for="requirements">{{$t('functional_responsibilities')}}:</label>
    <Input
      type="textarea"
      id="requirements"
      v-model="formData.requirements"/>
  </div>
  <div class="createResume__itemInner">
    <label for="skills">{{$t('additionally')}}:</label>
    <Input
      type="textarea"
      id="professional_skills"
      v-model="formData.bonuses"/>
  </div>
</div>
</template>

<script setup>
import DeleteButton from '@/components/common/Buttons/DeleteButton.vue'
import DatePickerPeriod from '@/components/common/DatePickers/DatePickerPeriod.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch, onMounted } from 'vue'
import { getCities } from '@/actions/help'

const props = defineProps({
  experience: {
    type: Object,
    default: () => {}
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'onDelete'])

const formData = ref(props.experience)

const success = ref({
  name: false,
  industry_id: false,
  country_id: false,
  position: false
})
watch(
  () => formData.value,
  async () => {
    emit('update:modelValue', formData.value)
    if (formData.value.country_id) cities.value = (await getCities(formData.value.country_id)).data.cities
  },
  {
    deep: true
  }
)

const cities = ref([])
onMounted(
  async () => {
    if (formData.value.country_id) cities.value = (await getCities(formData.value.country_id)).data.cities
  }
)
</script>
