<template>
<div class="nav">
  <router-link
    class="nav__link"
    to="/user/search-vacancy/result?&type&period=month&country=0&salary_type=0&page=1"
  >
      {{$t('vacancies')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/user/my-resumes/result?page=1"
  >
      {{$t('my_resumes')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/user/responses/result?page=1"
  >
      {{$t('responses_and_invitations')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/user/favorites/result?type=vacancies&page=1"
  >
      {{$t('favourites')}}
  </router-link>

  <router-link
    class="nav__link"
    to="/user/services"
  >
      {{$t('services.title')}}
  </router-link>
</div>
</template>
