<template>
<div class="resumeDetail__header">
  <div class="preview">
    <img v-if="resume.user.photo" :src="`${apiUrl}/${resume.user.photo}`" alt="">
    <img v-else src="/img/tmp/defold.png" alt="">
  </div>
  <div class="content">
    <div>
      <div class="top">
        <div class="prof">{{resume.title}}</div>
        <div class="right">
          <div class="payment">{{formatToSalary(resume.try_salary)}} {{resume.currency.title}}</div>
          <div class="date">{{$t('updated')}} {{moment(resume.updated_at).format('DD-MM-YYYY')}}</div>
        </div>
      </div>
      <div class="descriptions">
        <p>{{$t('gender')}}: {{resume.user.sex === 1 ? $t('man') : $t('woman')}}</p>
        <p>{{`${$t('date_birth')}: ${(resume.user.birthday ? moment(resume.user.birthday).format('DD-MM-YYYY') : $t('hidden'))}`}}</p>
      </div>
    </div>
    <ButtonResume
      v-if="documentWidth > 768"
      :resume="resume"
      @click="$emit('showContact')"
    />
  </div>
  <ButtonResume
    v-if="documentWidth <= 768"
    :resume="resume"
    @click="$emit('showContact')"
  />
</div>
</template>

<script setup>
import ButtonResume from '@/components/uiCompany/Resumes/ButtonResume.vue'

import moment from 'moment'
import { apiUrl } from '@/api'
import { defineProps, defineEmits, ref, watch } from 'vue'

import { formatToSalary } from '@/helpers/formatSalary'

defineProps({
  resume: {
    type: Object,
    default: null
  }
})
defineEmits(['showContact'])

const documentWidth = ref(document.body.clientWidth)
watch(
  () => document.body.clientWidth,
  () => {
    documentWidth.value = document.body.clientWidth
  }
)
</script>

<style lang="scss">
.resumeDetail{
  &__header{
    display: grid;
    grid-template-columns: 230px 1fr;
    grid-gap: 60px;
    margin-bottom: 60px;

    & .top{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    & .content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & p.skeleton{
        width: 200px;
        max-width: 100%;
        margin-bottom: 10px;
        display: block !important;
      }
    }

    & .prof{
      font-size: 24px;
      color: #212529;
      &.skeleton{
        height: 35px;
      }
    }

    & .right{
      text-align: end;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    & .payment{
      display: inline-block;
      font-size: 24px;
      color: #212529;
      margin-bottom: 10px;

      &.skeleton{
        height: 35px;
      }
    }
    & .date{
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
    }

    & .descriptions{
      margin-bottom: 40px;
    }
  }
}
@media (max-width: 992px){
  .resumeDetail__header{
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-gap: 35px;
    margin-bottom: 30px;
    & .top{
      margin-bottom: 10px;
    }
    & .prof{
      font-size: 22px;
      margin-bottom: 10px;
    }
    & .right{
      text-align: left;
    }
    & .descriptions{
      margin-bottom: 25px;
    }
    & .payment{
      font-size: 22px;
      margin-bottom: 4px;
    }
  }
}
@media (max-width: 768px){
  .resumeDetail__header{
    grid-template-columns: 160px 1fr;
    grid-gap: 18px;
    & .top{
      display: block;
    }
    & .right{
      text-align: start;
      display: block;
    }
    & .descriptions{
      margin-bottom: 0;
    }
    & .btns{
      grid-column: 1/3;
    }
  }
}
@media (max-width: 576px){
  .resumeDetail__header{
    grid-template-columns: 1fr;
    grid-gap: 18px;
    & .preview{
      width: 180px;
      height: 180px;
      margin-bottom: 15px;
    }
    & .btns{
      grid-column: 1;
    }
  }
}

@media print {
  .resumeDetail{
    &__header{
      display: grid;
      grid-template-columns: 120px 1fr;
      grid-gap: 20px;
      margin-bottom: 30px;

       & .preview img{
          height: 120px;
          width: 120px;
        }

      & .top{
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 0;
      }

      & .content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      & .prof{
        font-size: 18px;
      }
      & .right{
        text-align: end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      & .payment{
        font-size: 18px;
        margin-bottom: 8px;
      }
      & .date{
        font-size: 12px;
      }
      & .descriptions{
        margin-bottom: 0px;
      }
      & .btns{
        display: none;
      }
    }
  }
}
</style>
