<template>
<LayoutRow
  :title="$t('experience')"
  v-model="formData.experience_on"
>
  <RowExperience
    v-for="(experience, i) of formData.experiences"
    :key="experience"
    :experience="experience"
    :helpers="helpers"
    @onDelete="() => {deleteExperience(i)}"
  />

  <Button
    :name="$t('add_more')"
    class="createResume__AddMore"
    type="darkGreen"
    @click="addExperience()"
  />
</LayoutRow>
</template>

<script setup>
import RowExperience from '@/components/uiEmployee/Resume/Edit/Experience/RowExperience.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
formData.value.deleteExperiences = []

// ===================     Checking   ===================
if (formData.value.experiences.length !== 0) formData.value.experience_on = true
else formData.value.experience_on = false

watch(
  () => [formData.value, formData.value.experience_on],
  () => {
    if (formData.value.experience_on && formData.value.experiences.length === 0) addExperience()
    emit('update:modelValue', formData.value)
    // success.forEach(element => {
    //   if (!element) {
    //     emit('update:validation', false)
    //     return
    //   }
    //   emit('update:validation', true)
    // })
    // emit('update:validation', formData.value)
  }
)

// ===================     Click Events    ===================
const addExperience = () => {
  formData.value.experiences.push({
    company_name: null
  })
}

const deleteExperience = i => {
  if (formData.value.experiences[i].id) formData.value.deleteExperiences.push(formData.value.experiences[i].id)
  formData.value.experiences.splice(i, 1)
  if (formData.value.experiences.length === 0) formData.value.experience_on = false
}
</script>
