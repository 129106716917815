<template>
<div class="select">
  <div class="input">
    <div class="input">{{ title }}</div>
    <div class="arrow">
      <svg>
        <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
      </svg>
    </div>
  </div>
  <div class="options">
    <div
      class="option"
      v-for="option of options"
      :key="option"
      @click="clickOption(option)"
    >
      {{option.title}}
    </div>
  </div>
</div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null
  },
  options: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(['update:modelValue', 'search'])

const title = ref()

// Search Option By Id
const searchOptionById = () => {
  props.options.forEach(option => {
    if (option.id === props.modelValue) {
      title.value = option.title
    }
  })
}
searchOptionById()

// Click Event
const clickOption = (option) => {
  emit('update:modelValue', option.id)
  title.value = option.title
}

</script>

<style lang="scss" scoped>
.select{
  display: inline-block;
  width: auto;
  position: relative;
  & .input{
    font-size: 16px;
    line-height: 1.2;
    color: #979797;
    padding-right: 20px;
    cursor: pointer;
  }

  & svg{
    fill: #979797;
  }

  & .options{
    max-height: 200px;
    overflow-y: auto;
    border-color: #979797;
  }

  &:hover{
    & .options{
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    & .arrow svg{
      transform: rotate(270deg);
    }
  }
}
.arrow{
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  & svg{
    display: block;
    height: 16px;
    width: 8px;
    transform: rotate(90deg);
    transition: transform .2s linear;
  }
}
.options{
  width: max-content;
  position: absolute;
  top: 105%;
  left: 0;
  z-index: 10;

  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);

  border: 1px solid rgba(#212529, .7);
  background-color: #fff;
  box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.1);
  transition: all .2s linear;
}

.option{
  cursor: pointer;
  padding: 10px 22px 10px 15px;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  transition: background-color .2s linear;

  &:hover{
    background-color: rgba(#000, 0.15);
  }
}

@media (max-width: 992px){
  .select{
    & .input{
      font-size: 14px;
      padding-right: 18px;
    }
  }
  .option{
    padding: 8px 20px 8px 13px;
    font-size: 14px;
    line-height: 1.2;
  }
}
@media (max-width: 576px){
  .select{
    & .input{
      font-size: 12px;
      padding-right: 16px;
    }
  }
  .arrow{
    right: 18px;
    & svg{
      height: 14px;
      width: 6px;
    }
  }
  .option{
    padding: 6px 18px 6px 11px;
    font-size: 12px;
  }
}
</style>
