<template>
  <Header />
  <section class="searchFilters searchVacanciesClient searchVacnciesUser">
    <div class="container">
      <h3 class="section__title">{{$t('vacancies')}}</h3>
      <HeaderFilter
        v-model="filter"
        @search="fetchData()"
        @showSideBar="() => {showSideBar = true}"
        forVacancy
        withFilterBtn
      />
      <div class="searchFilters__block">
        <SidebarFilter
          v-model="filter"
          @search="fetchData()"
          v-model:showSideBar="showSideBar"
          forVacancy
        />
        <div>
          <ListVacancies
            :vacancies="vacancies"
          />
          <Pagination
            v-if="vacancies"
            v-model="filter.page"
            :perPage="filter.per_page"
            :total="total"
            @newPage="fetchData(filter.page)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import HeaderFilter from '@/components/uiLanding/Filter/Header/HeaderFilter.vue'
import SidebarFilter from '@/components/uiLanding/Filter/Sidebar/ListSidebarFilter.vue'
import ListVacancies from '@/components/uiCompany/Vacancies/Search/ListVacancies.vue'

import { reactive, ref } from '@vue/reactivity'
import { onMounted, watch } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getSearchVacancy } from '@/actions/employee'

const route = useRoute()

const checkArray = input => {
  if (typeof input === 'object') return input
  else if (!input) return []
  return [input]
}

const vacancies = ref(null)
const filter = reactive(
  {
    name: route.query.search,
    period: route.query.period ?? 'month',
    sort_type: route.query.type === 'date_asc' ? 'date_asc' : null,

    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20,

    salary_type: Number(route.query.salary_type),
    salary_min: null,
    salary_max: null,

    country_id: route.query.country ? Number(route.query.country) : null,
    cities_id: checkArray(route.query.cities),

    professional_areas_id: checkArray(route.query.professional_areas),
    min_experiences_id: checkArray(route.query.min_experiences),
    work_schedule_options_id: checkArray(route.query.work_schedule_options),
    employment_types_id: checkArray(route.query.employment_types),
    languages_id: checkArray(route.query.languages)
  }
)
const total = ref(0)
const showSideBar = ref(false)

const fetchData = async (page = 1) => {
  vacancies.value = null
  filter.page = page
  const response = (await getSearchVacancy(filter)).data
  vacancies.value = response.data
  total.value = response.total
  router.push(
    {
      path: '/user/search-vacancy/result',
      query: {
        // ========== Header =============
        search: filter.name,
        type: filter.sort_type,
        period: filter.period,

        // =========== SideBar =============
        country: filter.country_id,
        cities: filter.cities_id,

        // Salary min - max
        salary_type: filter.salary_type,

        min_experiences: filter.min_experiences_id,
        professional_areas: filter.professional_areas_id,
        employment_types: filter.employment_types_id,
        work_schedule_options: filter.work_schedule_options_id,
        languages: filter.languages_id,

        page: page
      }
    }
  )
}

onMounted(
  () => fetchData(filter.page)
)

watch(
  () => route.query,
  () => {
    if (route.name === 'SearchVacancy') {
      filter.page = route.query.page ? Number(route.query.page) : 1
      filter.period = route.query.period
      filter.name = route.query.search

      if (route.query.type !== 'date_asc') filter.sort_type = null
      else filter.sort_type = 'date_asc'

      filter.salary_type = Number(route.query.salary_type)

      filter.country_id = Number(route.query.country) ?? null
      filter.cities_id = route.query.cities

      filter.professional_areas_id = checkArray(route.query.professional_areas)
      filter.min_experiences_id = checkArray(route.query.min_experiences)
      filter.work_schedule_options_id = checkArray(route.query.work_schedule_options)
      filter.employment_types_id = checkArray(route.query.employment_types)
      filter.languages_id = checkArray(route.query.languages)
      fetchData(filter.page)
    }
  }
)
</script>

<style lang="scss">
.searchVacanciesClient .vacancies__item{
  & .bottom{
    align-items: flex-end;
    & .right{
      align-items: flex-end;
    }
  }

  & .date{
    margin-right: 0;
  }
}

.searchVacnciesUser{
  & .bottom{
    & .right{
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
