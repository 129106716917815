<template>
<Modal :hide="hide" :show="show" :loading="loading">
  <div class="modal-register">
    <div class="modal__title">{{$t('registration')}}</div>
    <div class="modal__radioBtns">
      <button @click="showClient">{{$t('applicant')}}</button>
      <button class="active">{{$t('company')}}</button>
    </div>
    <form class="modal__form modal__registerForm" action="#" @submit.prevent="registration()" method="POST">
        <div>
            <label for="country">{{$t('type_of_ownership')}}</label>
            <Select
              id="type_of_ownership"
              :options="typeOwnerShip"
              v-model="formData.type_ownership_id"
              v-model:validation="success.typeOwnerShip"/>
        </div>
        <div>
            <label for="name">{{$t('name_company')}}</label>
            <Input
              id="name"
              v-model="formData.name"
              v-model:validation="success.name"
              required/>
        </div>
        <div>
            <label for="patronymic">{{$t('contact_person')}}</label>
            <Input
              id="patronymic"
              v-model="formData.contact_name"
              v-model:validation="success.contact_name"
              required/>
        </div>
        <div>
            <label for="surname">{{$t('bin_and_iin')}}</label>
            <Input
              id="surname"
              v-model="formData.BIN"
              v-model:validation="success.BIN"
              maskInput="####-####-####-####"
              required/>
        </div>
        <div>
            <label for="country">{{$t('country')}}</label>
            <Select
              id="country"
              :options="country"
              v-model="formData.country_id"
              v-model:validation="success.country"/>
        </div>
        <div>
            <label for="city">{{$t('city')}}</label>
            <Select
              id="city"
              type="city"
              :options="city"
              v-model="formData.city_id"
              v-model:validation="success.city"
              :countryId="formData.country_id"/>
        </div>
        <div>
            <label for="address">{{$t('address_title')}}</label>
            <Input
              id="address"
              v-model="formData.address"
              v-model:validation="success.address"
              required/>
        </div>
        <div>
            <label for="phone">{{$t('phone')}}</label>
            <Input
              id="phone"
              type="tel"
              v-model="formData.phone"
              v-model:validation="success.phone"
              required/>
        </div>
        <div>
            <label for="email">Email</label>
            <Input
              type="email"
              id="email"
              v-model="formData.email"
              v-model:validation="success.email"
              required/>
        </div>
        <div>
            <label for="password">{{$t('password')}}</label>
            <Input
              type="new-password"
              id="password"
              v-model="formData.password"
              v-model:validation="success.password"
              required/>
        </div>
        <div>
            <label for="repeatPassword">{{$t('repeat_password')}}</label>
            <Input
              type="confirm-password"
              id="repeatPassword"
              v-model="formData.password_confirmation"
              v-model:validation="success.confirmPassword"
              :newPassword="formData.password"
              required/>
        </div>
        <div class="checkboxBlock">
          <Checkbox
            v-model:validation="success.agreement"/>
          <div class="text">{{$t('i_have_read_and_agree_with')}} <a href="#">{{$t('terms_of_agreement')}}</a></div>
        </div>
        <Button
          htmlType="submit"
          :name="$t('register_button')"/>
    </form>
  </div>
</Modal>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import Checkbox from '@/components/common/Checkbox.vue'

import { ref } from '@vue/reactivity'
import { defineProps, onMounted, watch } from 'vue'

import { postRegisterCompany } from '@/actions/auth.js'
import { getCountries, getCities, getTypeOwnerShip } from '@/actions/help'

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  showClient: {
    type: Function,
    default: null
  },
  hide: {
    type: Function,
    default: null
  },
  showSendModal: {
    type: Function,
    default: null
  }
})
const formData = ref({})
const success = ref({
  typeOwnerShip: false,
  contact_name: false,
  name: false,
  country: false,
  city: false,
  address: false,
  email: false,
  phone: false,
  password: false,
  confirmPassword: false,
  BIN: false,
  agreement: false
})
const loading = ref(false)
// ====================  Api Country And City / Type-owner-ship  ====================
const typeOwnerShip = ref(null)
const country = ref(null)
const city = ref(null)
const errors = ref(true)

const fetchData = async () => {
  typeOwnerShip.value = (await getTypeOwnerShip()).data
  country.value = (await getCountries()).data
}
onMounted(
  async () => {
    if (props.show) {
      fetchData()
    }
  }
)
watch(
  () => formData.value.country_id,
  async () => {
    city.value = (await getCities(formData.value.country_id)).data.cities
  }
)
watch(
  () => formData.value,
  () => {
    errors.value = false
    for (const field in success) {
      if (!success[field]) errors.value = true
      break
    }
  }
)

watch(
  () => props.show,
  () => {
    if (props.show) {
      fetchData()
    }
  }
)
// ====================  REGISTER  ====================
async function registration () {
  loading.value = true
  try {
    await postRegisterCompany(formData.value)
    props.hide()
  } finally {
    loading.value = false
  }
}
</script>
