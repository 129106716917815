<template>
  <div class="crop__block">
    <div class="modal__title">{{$t('photo_cropping')}}</div>
    <form @submit.prevent="$emit('onSave')">
      <Cropper
        class="cropper"
        :src="image"
        :stencil-props="{
          aspectRatio: 10/10
        }"
        @change="upload"
      />
      <div class="crop__btn">
        <Button
          htmlType="submit"
          :name="$t('save_as_profile_photo')"
        />
      </div>
    </form>
  </div>
</template>

<script setup>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import { ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
  modelValue: [File, Object, String],
  type: {
    type: String,
    default: 'image/png, image/gif, image/jpeg, image/svg'
  }
})
const emit = defineEmits([
  'update:modelValue',
  'onSave'
])

const image = ref(props.modelValue?.link)
const reader = new FileReader()
reader.readAsDataURL(props.modelValue)
reader.onload = () => {
  image.value = reader.result
}

const upload = async ({ coordinates, canvas }) => {
  emit('update:modelValue', canvas.toDataURL('image/jpeg'))
}
</script>

<style scoped lang="scss">
.dropzone {
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
  border: 2px dashed #b6b6b6;
  background-color: #fff;
  transition: 0.2s linear all;
}
.cropper{
  height: 350px;
  width: 100%;
  background-color: #000;
  margin-bottom: 14px;
}
</style>
<style lang="scss">
.crop{
  &__btn{
    display: flex;
    justify-content: flex-end;
    & button{
      padding: 7px 20px 9px !important;
    }
    & span{
      font-size: 14px !important;
      text-transform: none !important;
    }
  }
}

.vue-advanced-cropper__foreground{
  background-color: #000;
}
.vue-preview__wrapper{
  cursor: move;
}
.vue-simple-line-wrapper,
.vue-line-wrapper--east,
.vue-simple-handler--east,
.vue-simple-handler--west{
  cursor: e-resize;
}

.vue-line-wrapper--north,
.vue-line-wrapper--south,
.vue-simple-handler--north,
.vue-simple-handler--south{
  cursor: s-resize;
}

.vue-simple-handler--east-north,
.vue-simple-handler--west-south{
  cursor: ne-resize !important;
}

.vue-simple-handler--west-north,
.vue-simple-handler--east-south{
  cursor: nw-resize !important;
}
</style>
