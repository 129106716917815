<template>
<section class="certificates slider__about" id="certificates">
  <div class="container">
    <div class="slider__head">
      <h3 class="section__title">{{$t('certificates')}}</h3>
      <SliderBtns
        :prev="toPrev"
        :next="toNext"
      />
    </div>

    <div class="certificates__items" v-if="certificates">
      <div
        class="certificates__item"
        v-for="(certificate, i) of certificates"
        :class="{show: i >= show.from && i <= show.to}"
        :key="certificate"
        @click="ShowDetailViewAward(certificate)"
      >
        <img :src="`${apiUrl}/${certificate.image}`" alt="">
      </div>
    </div>

    <div class="certificates__items" v-else>
      <div class="certificates__item skeleton img" v-for="item of show.count" :key="item">
      </div>
    </div>

  </div>
</section>

<transition name="fade">
  <CertificateDetail
    v-if="show.detail"
    :hide="ShowDetailViewAward"
    :img="detailViewAward"
    :images="certificates"
  />
</transition>
</template>

<script setup>
import SliderBtns from '@/components/common/Sliders/SliderBtns.vue'
import CertificateDetail from '@/components/uiLanding/AboutUs/CertificateDetail.vue'

import { reactive, ref } from '@vue/runtime-core'
import { apiUrl } from '@/api'
import { defineProps } from 'vue'

const props = defineProps({
  certificates: {
    type: Array,
    default: null
  }
})

const show = reactive(
  {
    from: 0,
    to: 9,
    detail: false,
    count: 10
  }
)

// Slider Click Events
const toNext = () => {
  if (show.to < props.certificates.length - 1) {
    show.from += show.count
    show.to += show.count
  }
}

const toPrev = () => {
  if (show.from !== 0) {
    show.from -= show.count
    show.to -= show.count
  }
}

// Detail Certificate
const detailViewAward = ref(null)

const ShowDetailViewAward = (certificate) => {
  if (!show.detail) {
    detailViewAward.value = certificate.image
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
  show.detail = !show.detail
}

// Adaptive
if (document.body.clientWidth <= 992) {
  show.to = 7
  show.count = 8
}

if (document.body.clientWidth <= 576) {
  show.to = 5
  show.count = 6
}
</script>

<style lang="scss">
.certificates{
  &__items{
    margin: 0 -8px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 19px;
    padding: 30px 0 110px 0;
  }

  &__item{
    display: none;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    padding: 12px 20px;

    border: 3px solid transparent;
    border-radius: 2px;

    background-color: #fff;
    box-shadow: 0px 0px 50px rgba(218, 218, 218, 0.5);

    transition: border-color .2s linear, transform .4s linear;

    &.skeleton{
      display: block;
      padding-bottom: 120%;
    }

    &.show{
      display: flex;
    }

    &:hover{
      border-color: #4D533C;
      transform: scale(1.05);
      z-index: 2;
    }

    & img{
      width: 180px;
    }
  }
}
@media (max-width: 992px){
  .certificates{
    &__items{
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding: 25px 0 80px 0;
    }
    &__item{
      padding: 10px 14px;
    }
  }
}
@media (max-width: 768px){
  .certificates{
    &__items{
      grid-gap: 15px;
      padding: 20px 0 70px 0;
    }
    &__item{
      padding: 10px 10px;
    }
  }
}
@media (max-width: 576px){
  .certificates{
    &__items{
      grid-template-columns: 1fr 1fr 1fr;
      padding: 18px 0 50px 0;
    }
    & .slider__btns{
      display: flex !important;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
</style>
