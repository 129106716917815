<template>
<Header/>
<section class="editVacancy">
  <div class="container">
      <h3 class="section__title">{{route.params.vacancy_id ? $t('edit_vacancy') : $t('create_vacancy')}}</h3>
      <form
        class="form"
        method="POST"
        @submit.prevent="createOrEditVacancy()"
        v-if="
          formData
          && helpers.professionalsAreas
          && helpers.keySkills
          && helpers.employmentTypes
          && helpers.workSchedule
          && helpers.workExperience
          && helpers.languages
          && helpers.driverCategories
          && helpers.tests
          && helpers.countries
        "
      >

        <CommonData
          v-model="formData"
          v-model:validation="success"
          :helpers="helpers"
        />

        <PlaceOfWork
          v-model="formData"
          v-model:validation="success.city"
          :helpers="helpers"
        />

        <Additionally
          v-model="formData"
          v-model:validation="success"
          :helpers="helpers"
        />

        <div class="checkbox_block hotVacancy__checkbox" v-if="!route.params.vacancy_id">
          <div><Checkbox v-model="formData.hot" dark /> <p>{{$t('hot_vacancy.checkbox')}}</p></div>
        </div>

        <p
          v-if="route.params.vacancy_id"
          class="lastUpdateDate"
        >
          {{`${$t('all_changes_are_saved_in')} ${lastUpdateDate}`}}
        </p>

        <div>
          <div class="btns">
            <Button
              :name="buttonText"
              htmlType="submit"
              :hasError="success" />
            <Button
              :name="$t('cancel')"
              type="gray"
              go="/company/common-data"/>
          </div>
        </div>
      </form>
      <div class="editVacancy__item skeleton__block" v-else>
        <div v-for="item of 9" :key="item">
          <label class="skeleton middle-text"></label>
          <div class="skeleton">
            <Input disabled/>
          </div>
        </div>
      </div>
  </div>
  <Loading class="fixed" :show="loading"/>
</section>
</template>

<script setup>
import Checkbox from '@/components/common/Checkbox.vue'
import CommonData from '@/components/uiCompany/Vacancies/Edit/CommonData.vue'
import PlaceOfWork from '@/components/uiCompany/Vacancies/Edit/PlaceOfWork.vue'
import Additionally from '@/components/uiCompany/Vacancies/Edit/Additionally.vue'

import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { onMounted, watch } from 'vue-demi'
import { reactive, ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { postVacancy, putVacancy, getVacancyDetail, getTestsWithoutPaginations } from '@/actions/company'
import {
  getProfessionalsAreas,
  getKeySkills, getCurrencies,
  getEmploymentTypes,
  getWorkSchedule,
  getWorkExperience,
  getLanguages,
  getDriverCategories,
  getCountries,
  getCities
} from '@/actions/help'

const i18n = useI18n()
const route = useRoute()

const formData = ref({
  before_taxes: true,
  show_address: true,
  languages: [],
  driver_certificate_categories: [],
  hot: 0,
  country: {},
  active: 1,
  status: 1,
  tests: []
})
const success = ref({
  position: false,
  professional_area_id: false,
  employment_type: false,
  city: false,
  min_experience: false
})
const loading = ref(false)
const lastUpdateDate = ref('')

// ==========  Api professionalsAreas / keySkills / currencies / Manager Data ========
const helpers = reactive({
  professionalsAreas: null,
  keySkills: null,
  currencies: null,
  employmentTypes: null,
  workSchedule: null,
  workExperience: null,
  languages: null,
  driverCategories: null,
  tests: null,
  cities: null,
  countries: null
})

onMounted(
  async () => {
    if (route.params.vacancy_id) {
      formData.value = null
      const responce = await (await getVacancyDetail(route.params.vacancy_id)).data

      // currency
      responce.currency_id = responce.currency?.id ?? null
      delete responce.currency

      // professional_area
      responce.professional_area_id = responce.professional_area.id
      delete responce.professional_area

      // employment_type
      responce.employment_type_id = responce.employment_type.id
      delete responce.employment_type

      // temporary_registration
      if (responce.temporary_registration === 0) responce.temporary_registration = false
      else responce.temporary_registration = true

      // work_schedule_option
      responce.work_schedule_option_id = responce.work_schedule_option.id
      delete responce.work_schedule_option

      // min_experience
      responce.min_experience_id = responce.min_experience.id
      delete responce.min_experience

      // driver_certificate_categories
      const driverCategories = []
      responce.driver_certificate_categories.forEach(element => {
        driverCategories.push(element.id)
      })
      responce.driver_certificate_categories = driverCategories

      // Кто может откликаться Radio Buttons
      if (responce.with_disabilities === 0) responce.with_disabilities = false
      else responce.with_disabilities = true

      if (responce.with_incomplete_resume === 0) responce.with_incomplete_resume = false
      else responce.with_incomplete_resume = true

      if (responce.cover_letter === 0) responce.cover_letter = false
      else responce.cover_letter = true

      formData.value = responce

      // Tests
      if (formData.value.tests.length !== 0) {
        formData.value.tests = [formData.value.tests[0].id]
      }

      // Place of Work
      formData.value.country = responce.city.countries
      formData.value.city_id = responce.city.id
      delete formData.value.city
      helpers.cities = (await getCities(formData.value.country.id)).data.cities

      // Languages
      if (responce.languages.length !== 0) {
        const languages = []
        responce.languages.forEach(lang => {
          languages.push({ language_id: lang.id, level: lang.level })
        })
        formData.value.languages = languages
      }

      // Last Update Date
      if (moment().format('DD-MM-YYYY') === moment(formData.updated_at).format('HH-MM')) lastUpdateDate.value = moment(formData.updated_at).format('HH-MM')
      else lastUpdateDate.value = moment(formData.updated_at).format('HH-MM DD-MM-YYYY')

      // Hot
      formData.value.hot = 0
    }

    const [professionalsAreas, keySkills, currencies, employmentTypes, workSchedule, workExperience, languages, driverCategories, tests, countries] = await Promise.all([
      getProfessionalsAreas(),
      getKeySkills(),
      getCurrencies(),
      getEmploymentTypes(),
      getWorkSchedule(),
      getWorkExperience(),
      getLanguages(),
      getDriverCategories(),
      getTestsWithoutPaginations(),
      getCountries()
    ])

    helpers.professionalsAreas = professionalsAreas.data
    helpers.keySkills = keySkills
    helpers.currencies = currencies
    helpers.employmentTypes = employmentTypes
    helpers.workSchedule = workSchedule
    helpers.workExperience = workExperience
    helpers.languages = languages
    helpers.driverCategories = driverCategories.data
    helpers.tests = tests.data.tests
    helpers.countries = countries.data
  }
)

// ====================  Api Create And Edit Vacancy  ====================
const createOrEditVacancy = async () => {
  try {
    loading.value = true
    if (route.params.vacancy_id) await putVacancy(route.params.vacancy_id, formData.value)
    else await postVacancy(formData.value)
    router.push('/company/search-vacancy/result?search=&type&period=year&page=1')
  } finally {
    loading.value = false
  }
}

// Create Button Text
const buttonText = ref(!route.params.vacancy_id ? i18n.t('create') : i18n.t('edit'))

watch(
  () => !route.params.vacancy_id,
  () => {
    if (formData.value.hot) {
      if (formData.value.hot === true) buttonText.value = i18n.t('hot_vacancy.button')
      else buttonText.value = i18n.t('create')
    }
  }
)
</script>

<style lang="scss">
.editVacancy{
  &__item{
    padding-bottom: 50px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.0955529);

    & > div{
      max-width: 820px;
      display: grid;
      grid-template-columns: 310px 1fr;
      grid-gap: 20px;
      margin-bottom: 20px;
    }

    &.skeleton__block{
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
    color: #4D533C;
    margin-bottom: 20px;
  }
  & label{
    font-size: 16px;
    color: rgba(33, 37, 41, 0.7);
    padding-top: 5px;
  }

  & .form{
    & .btns{
      grid-column: 2/3;
      display: flex;
      grid-gap: 20px;
    }
  }
  & .salary{
    &__block{
      display: grid;
      grid-template-columns: 1fr 115px;
      margin-bottom: 10px;
    }
    &__inputs{
      display: grid;
      grid-template-columns: 1fr 1fr;
      & input{
        border-right: none !important;
      }
    }
  }

  & .checkbox_block{
    & > div{
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 15px;
      margin-bottom: 10px;
    }
    & > div:last-child{
      margin-bottom: 0;
    }
    & .title{
      margin-bottom: 4px;
    }
    & .subtitle{
      color: rgba(33, 37, 41, 0.5) !important;
      line-height: 1.1;
    }
  }
  & .lastUpdateDate{
    margin-bottom: 40px;
  }

  & .hotVacancy__checkbox{
    margin-bottom: 40px;
  }

  @media (max-width: 1200px){
    &__item{
      & > div{
        grid-template-columns: 200px 1fr;
      }
    }
  }

  @media (max-width: 992px){
    &__item{
      margin-bottom: 35px !important;

      & > div{
        grid-template-columns: 180px 1fr;
        grid-gap: 20px;
        margin-bottom: 20px;
      }
    }
    &__title{
      font-size: 20px;
      margin-bottom: 18px;
    }
    & label{
      font-size: 14px;
      padding-top: 4px;
    }

    & .form{
      & .btns{
        grid-gap: 18px;
      }
    }
    & .salary{
      &__block{
        grid-template-columns: 1fr 115px;
        margin-bottom: 10px;
      }
      &__inputs{
        display: grid;
        grid-template-columns: 1fr 1fr;
        & input{
          border-right: none !important;
        }
      }
    }

    & .checkbox_block{
      & > div{
        display: grid;
        grid-template-columns: 22px 1fr;
        grid-gap: 12px;
        margin-bottom: 8px;
      }
    }
    & .lastUpdateDate,
    & .hotVacancy__checkbox{
      margin-bottom: 35px;
    }
  }

  @media (max-width: 768px){
    &__item{
      margin-bottom: 30px !important;

      & > div{
        grid-template-columns: 120px 1fr;
        grid-gap: 15px;
        margin-bottom: 15px;
      }
    }
    &__title{
      font-size: 18px;
      margin-bottom: 16px;
    }
    & label{
      font-size: 12px;
      padding-top: 4px;
    }

    & .form{
      & .btns{
        grid-gap: 15px;
      }
    }
    & .salary{
      &__block{
        grid-template-columns: 1fr 115px;
        margin-bottom: 8px;
      }
      &__inputs{
        display: grid;
        grid-template-columns: 1fr 1fr;
        & input{
          border-right: none !important;
        }
      }
    }

    & .checkbox_block{
      & > div{
        grid-template-columns: 20px 1fr;
        grid-gap: 10px;
        margin-bottom: 6px;
      }
    }
    & .lastUpdateDate,
    & .hotVacancy__checkbox{
      margin-bottom: 30px;
    }
  }
  @media (max-width: 568px){
    &__item{
      margin-bottom: 25px !important;

      & > div{
        grid-template-columns: 1fr;
        grid-gap: 6px;
      }
    }
    &__title{
      font-size: 16px;
      margin-bottom: 14px;
    }

    & .form{
      & .btns{
        grid-gap: 13px;
      }
    }
    & .salary{
      &__block{
        grid-template-columns: 1fr 100px;
        margin-bottom: 6px;
      }
      &__inputs{
        display: grid;
        grid-template-columns: 1fr 1fr;
        & input{
          border-right: none !important;
        }
      }
    }

    & .checkbox_block{
      & > div{
        grid-template-columns: 18px 1fr;
        grid-gap: 8px;
        margin-bottom: 4px;
      }
    }
    & .lastUpdateDate,
    & .hotVacancy__checkbox{
      margin-bottom: 25px;
    }
  }
}
</style>
