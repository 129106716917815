<template>
  <form @submit.prevent="createLetter()" method="POST">
    <Input
      v-model="formData.title"
      v-model:validation="success.title"
      :placeholder="$t('enter_template')"
      required/>
    <Input
      type="textarea"
      v-model="formData.text"
      :placeholder="$t('description')"
      v-model:validation="success.text"
      required/>
    <div class="btns">
      <Button
        htmlType="submit"
        :name="$t('create')"
        :hasError="success"/>
      <Button
        type="gray"
        :name="$t('cancel')"
        :noButton="true"
        @click="hide"/>
    </div>
  </form>
  <Loading class="fixed" :show="loading"/>
</template>
<script setup>
import { reactive, ref } from '@vue/reactivity'
import { postLetter } from '@/actions/company'
import { defineProps } from 'vue'

const props = defineProps({
  hide: {
    type: Function,
    default: null
  },
  finally: {
    type: Function,
    default: () => null
  }
})
const formData = reactive({})
const success = reactive({
  title: false,
  text: false
})
const loading = ref(false)

const createLetter = async () => {
  loading.value = true
  try {
    await postLetter(formData)
  } finally {
    delete formData.title
    delete formData.text
    loading.value = false
    props.hide()
    props.finally()
  }
}
</script>
<style lang="scss" scoped>
.btns{
  display: flex;
  grid-gap: 20px;
}
form{
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  max-width: 620px;
  margin-bottom: 40px;
}
</style>
