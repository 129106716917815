<template>
<div class="template">
  <router-link class="link" :to="'/company/edit-test/' + test.id">{{test.title}}</router-link>
  <button class="delete" @click="() => {quastion = true}">
    <svg>
      <use xlink:href="/img/tmp/sprite.svg#delete"></use>
    </svg>
    <span>{{$t('delete')}}</span>
  </button>
</div>

<Modal
  :hide="()=>{quastion = false}"
  :show="quastion"
  quastion
  :agreement="() => {deleteTestFunc()}"
  :loading="loading"
/>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import Modal from '@/components/common/Modal.vue'
import { deleteTest } from '@/actions/company'
import { defineProps } from 'vue'

const props = defineProps({
  test: {
    type: Object,
    default: () => {}
  },
  finally: {
    type: Function,
    default: null
  }
})

const quastion = ref(false)
const loading = ref(false)

const deleteTestFunc = async () => {
  try {
    loading.value = true
    deleteTest = await deleteTest(props.test.id)
  } finally {
    loading.value = false
    quastion.value = false
    props.finally()
  }
}
</script>
<style lang="scss">
.testTemplates{
  & .template{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
    margin-bottom: 15px;
    grid-gap: 20px;
  }
  & .link{
    display: block;
    font-size: 16px;
    color: #4D533C;
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  & .delete{
    padding: 0;
    display: flex;
    grid-gap: 4px;
    align-items: center;
    & span{
      color: #AD1717;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 500;
      position: relative;
      &::before{
        content: "";
        display: block;
        height: 1.5px;
        width: 0;
        background-color: #AD1717;
        position: absolute;
        left: 0;
        top: 98%;
        transition: width .2s linear;
      }
    }
    &:hover{
      & span::before{
        width: 100%;
      }
    }
    & svg{
      display: block;
      width: 18px;
      height: 18px;
      fill: #AD1717;
    }
  }
}
</style>
