import moment from 'moment'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

// ====================  CALCULATOR PERIOD TIME  ====================

// Period To Amount Months
const periodToAmountMonths = period => {
  const periodFrom = Number(moment(period.period_from).format('YYYY')) * 12 + Number(moment(period.period_from).format('M'))
  let periodTo = (Number(moment().format('YYYY')) * 12) + Number(moment().format('M'))
  if (period.period_to) periodTo = Number(moment(period.period_to).format('YYYY')) * 12 + Number(moment(period.period_to).format('M'))
  return periodTo - periodFrom
}

// Months to Amount Years
const monthsToAmountYears = months => {
  const totalYear = ref(0)
  for (months; months > 12; months -= 12) {
    totalYear.value += 1
  }
  if (totalYear.value > 0) totalYear.value = `${totalYear.value} года`
  else totalYear.value = ''
  return totalYear.value
}

// Period Amount Months - Years = months
const amountMonths = months => {
  if (months > 0 && months % 12 !== 0) months = `${months % 12} месяца`
  else months = ''
  return months
}

export const calcExperiencesPeriodTime = (data, type) => {
  const periodTime = ref(0)

  data.forEach(period => {
    if (period.period_from) {
      // Calculator For The Entire Period Months
      const totalMonths = ref(periodToAmountMonths(period))
      periodTime.value += totalMonths.value

      // Calculator Year
      const totalYear = monthsToAmountYears(totalMonths.value)

      // Calculator Months
      totalMonths.value = amountMonths(totalMonths.value)

      // Total Period
      period.period_time = `${totalYear} ${totalMonths.value}`
    }
  })

  // Total For The Entire Period
  const totalYear = monthsToAmountYears(periodTime.value)
  const totalMonths = amountMonths(periodTime.value)
  data.totalPeriod = `${totalYear} ${totalMonths}`
}

// Convert Period To View
export const convertPeriodToView = (data) => {
  const i18n = useI18n()
  const periodFrom = {
    month: i18n.t('months.' + moment(data.period_from).format('M')),
    year: moment(data.period_from).format('YYYY')
  }
  periodFrom.time = `${periodFrom.month}-${periodFrom.year}`

  const periodTo = {}
  if (data.period_to) {
    periodTo.month = i18n.t('months.' + moment(data.period_to).format('M'))
    periodTo.year = moment(data.period_to).format('YYYY')
    periodTo.time = `${periodTo.month}-${periodTo.year}`
  } else {
    periodTo.time = i18n.t('until_now')
  }

  return `${periodFrom.time} — ${periodTo.time}`
}
