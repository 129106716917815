<template>
<button class="filterToggleButtton">
  <span>{{$t('filter')}}</span>
  <svg xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000">
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
    <path d="M1472 4299 c-102 -40 -192 -163 -192 -264 l0 -35 -347 0 c-330 0
    -350 -1 -381 -20 -48 -30 -72 -75 -72 -140 0 -65 24 -110 72 -140 31 -19 51
    -20 381 -20 l347 0 0 -35 c0 -94 77 -207 175 -255 48 -24 58 -25 291 -28 225
    -3 244 -2 298 18 106 40 196 162 196 265 l0 35 1148 0 c1127 0 1148 0 1180 20
    48 30 72 75 72 140 0 65 -24 110 -72 140 -32 20 -53 20 -1180 20 l-1148 0 0
    35 c0 102 -90 225 -195 264 -51 19 -76 21 -288 20 -208 0 -237 -2 -285 -20z"/>
    <path d="M2752 3019 c-102 -40 -192 -163 -192 -264 l0 -35 -987 0 c-968 0
    -989 0 -1021 -20 -48 -30 -72 -75 -72 -140 0 -65 24 -110 72 -140 32 -20 53
    -20 1021 -20 l987 0 0 -35 c0 -94 77 -207 175 -255 48 -24 58 -25 291 -28 225
    -3 244 -2 298 18 106 40 196 162 196 265 l0 35 508 0 c488 0 508 1 540 20 48
    30 72 75 72 140 0 65 -24 110 -72 140 -32 19 -52 20 -540 20 l-508 0 0 35 c0
    60 -38 142 -90 195 -83 82 -118 90 -393 89 -208 0 -237 -2 -285 -20z"/>
    <path d="M1472 1739 c-102 -40 -192 -163 -192 -264 l0 -35 -347 0 c-330 0
    -350 -1 -381 -20 -48 -30 -72 -75 -72 -140 0 -65 24 -110 72 -140 31 -19 51
    -20 381 -20 l347 0 0 -35 c0 -94 77 -207 175 -255 48 -24 58 -25 291 -28 225
    -3 244 -2 298 18 106 40 196 162 196 265 l0 35 1148 0 c1127 0 1148 0 1180 20
    48 30 72 75 72 140 0 65 -24 110 -72 140 -32 20 -53 20 -1180 20 l-1148 0 0
    35 c0 102 -90 225 -195 264 -51 19 -76 21 -288 20 -208 0 -237 -2 -285 -20z"/>
    </g>
  </svg>
</button>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.filterToggleButtton{
  display: none;
  align-items: center;
  grid-gap: 10px;

  & span{
    font-size: 14px;
    color: #979797;
    transition: color .2s linear;
  }

  & svg{
    height: 24px;
    width: 24px;
    fill:#979797;
    transition: fill .2s linear;
  }

  &:hover{
    & span{
      color: #A32D2B;
    }

    & svg{
      fill:#A32D2B;
    }
  }

  @media (max-width: 768px){
    display: flex;
  }
  @media (max-width: 576px){
    grid-gap: 8px;
    & span{
      font-size: 12px;
    }
    & svg{
      height: 20px;
      width: 20px;
    }
  }
  @media (max-width: 480px){
    margin-left: auto;
    grid-gap: 7px;
  }
}
</style>
