import axios from 'axios'

export const api = axios.create({
  baseURL: 'https://api.beta.altynadamagency.com/api'
})
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
export const apiUrl = process.env.VUE_APP_API_URL
