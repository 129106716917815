<template>
<div class="experience" v-if="experiences.length !== 0">
  <div class="title">
    {{`${$t('experience')} ${experiences.totalPeriod}`}}
  </div>
    <div
      class="item"
      v-for="experience of experiences"
      :key="experience"
    >
      <div class="right">
        <div class="timePeriod" v-if="experience.period_from">
          <div class="title">
            <!-- period_from && period_to -->
            {{convertPeriodToView(experience)}}
          </div>
          <!-- period experience -->
          <div class="subtitle">{{experience.period_time}}</div>
        </div>
        <div class="company">
          <div class="name">{{experience.company_name ?? $t('not_set')}}</div>
          <div class="local">
            {{experience.country.title}}
            <span v-if="experience.city_id">{{`, ${experience.city.title}`}}</span>
          </div>
        </div>
        <div class="direction">{{experience.industry.title}}</div>
      </div>
      <div class="left">
        <div class="position">{{experience.position}}</div>
        <div class="description">
          <div>
            <p>{{$t('responsibilities')}}:</p>
            <p class="text">{{experience.requirements}}</p>
          </div>
          <div v-if="experience.bonuses">
            <p>{{$t('additionally')}}:</p>
            <p class="text">{{experience.bonuses}}</p>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script setup>
import { calcExperiencesPeriodTime, convertPeriodToView } from '@/helpers/periodTime'
import { defineProps } from 'vue'

const props = defineProps({
  experiences: {
    type: Array,
    default: null
  }
})

calcExperiencesPeriodTime(props.experiences)
</script>

<style lang="scss">
.resumeDetail .experience{
  padding-top: 0;
  & .item{
    display: grid;
    grid-template-columns: 248px 1fr;
    grid-gap: 40px;
    margin-bottom: 40px;
  }
  & .item:last-child{
    margin-bottom: 0;
  }
  & .timePeriod{
    margin-bottom: 19px;
  }
  & .company{
    margin-bottom: 19px;
    & .name{
      font-size: 16px;
      color: #212529;
      margin-bottom: 4px;
    }
  }
  & .direction{
    font-size: 14px;
    color: rgba(33, 37, 41, 0.7);
  }
  & .position{
    font-weight: bold;
    font-size: 16px;
    color: #212529;
    margin-bottom: 2px;
  }
  & .description p.skeleton{
    display: block !important;
    max-width: 100%;
  }
  @media (max-width: 768px){
    & .item{
      grid-template-columns: 180px 1fr;
      grid-gap: 25px;
      margin-bottom: 45px;
    }
    & .timePeriod,
    & .company{
      margin-bottom: 12px;
    }
  }
  @media (max-width: 576px){
    & .item{
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    & .timePeriod,
    & .company{
      margin-bottom: 12px;
    }
  }
}

@media print {
  .resumeDetail .experience{
    & .item{
      display: grid;
      grid-template-columns: 248px 1fr;
      grid-gap: 30px;
      margin-bottom: 35px;
    }
    & .item:last-child{
      margin-bottom: 0;
    }
    & .timePeriod{
      margin-bottom: 14px;
    }
    & .company{
      margin-bottom: 14px;
      & .name{
        font-size: 13px;
        color: #212529;
        margin-bottom: 4px;
      }
    }
    & .direction{
      font-size: 11px;
      color: rgba(33, 37, 41, 0.7);
    }
    & .position{
      font-size: 13px;
      margin-bottom: 2px;
    }
  }
}
</style>
