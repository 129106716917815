<template>
<Modal :hide="hide" :show="show" :title="$t('login')" :loading="loading">
  <form class="modal__form loginForm" @submit.prevent="login(formData.email, formData.password)" method="POST">
    <div>
      <Input
        type="email"
        placeholder="Email"
        v-model="formData.email"
        v-model:validation="success.email"/>
    </div>
    <div>
      <Input
        type="password"
        :placeholder="$t('password')"
        v-model="formData.password"
        v-model:validation="success.password"/>
    </div>
    <div class="modal__btns">
      <Button
        :name="$t('login_button')"
        htmlType="submit"
        :hasError="success"
      />
      <div
        class="modal__link"
        @click="showForgotPassword"
      >
        {{$t('forgot_your_password')}}
      </div>
    </div>
  </form>
  <SocialNetworks v-model:loading="loading" />
  <Button
    :name="$t('register_on_site')"
    type="white"
    @click="showRegister"
  />
</Modal>
</template>
<script setup>
import Modal from '@/components/common/Modal.vue'
import SocialNetworks from '@/components/Authorization/SocialNetworks.vue'

import { reactive, ref } from '@vue/reactivity'
import { useAuth } from '@/modules/auth.js'
import { getToken } from '@/actions/auth.js'
import { defineProps } from 'vue'

defineProps({
  show: {
    type: Boolean,
    default: false
  },
  hide: {
    type: Function,
    default: null
  },
  showForgotPassword: {
    type: Function,
    default: null
  },
  showRegister: {
    type: Function,
    default: null
  }
})

const formData = reactive({})
const success = reactive({
  email: false,
  password: false
})
const loading = ref(false)

const login = async () => {
  const { setUser } = useAuth()
  loading.value = true
  try {
    const token = await getToken(...Object.values(formData))
    setUser(token.data, formData.email)
  } catch (error) {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.loginForm{
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 992px){
    grid-gap: 18px;
    margin-bottom: 18px;
  }
  @media (max-width: 768px){
    grid-gap: 15px;
    margin-bottom: 15px;
  }
  @media (max-width: 576px){
    grid-gap: 10px;
  }
  @media (max-width: 400px){
    grid-gap: 8px;
  }
}

.modal{
  &__btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__link{
    color: rgba(#212529, .7);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;

    transition: color .2s linear;

    &:hover{
        color: #212529;
        text-decoration: underline;
    }

    @media (max-width: 992px){
      font-size: 14px;
    }
    @media (max-width: 768px){
      font-size: 12px;
    }
    @media (max-width: 576px){
      font-size: 10px;
    }
  }
}
</style>
