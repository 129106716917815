<template>
<NothingHere v-if="resumes && resumes.length === 0"/>
<div class="myResume__items" v-else-if="resumes">
  <RowResume
    v-for="resume of resumes"
    :key="resume"
    :resume="resume"
    :deleted="() => {fetchData()}"/>
</div>

<div class="myResume__items" v-else>
  <div class="myResume__item skeleton__block" v-for="item of 5" :key="item">
    <div class="top">
      <div class="prof skeleton middle-text"></div>
      <div class="top-left">
        <div class="price skeleton middle-text"></div>
      </div>
    </div>
    <div class="center">
      <div class="text skeleton small-text" style="width:100%; max-width: 300px; margin-bottom: 10px"></div>
      <div class="text skeleton small-text" style="width:100%; max-width: 300px;"></div>
    </div>
    <div class="bottom">
      <div class="left">
        <div class="date skeleton small-text"></div>
        <Button class="print skeleton"/>
      </div>
      <div class="right">
        <Button class="look skeleton" style="pointer-events: none" />
        <Button class="look skeleton" style="pointer-events: none" />
      </div>
    </div>
  </div>
</div>
</template>
<script setup>
import { defineProps } from 'vue'
import RowResume from '@/components/uiEmployee/Resume/List/RowResume.vue'

const props = defineProps({
  resumes: {
    type: Array,
    default: () => []
  },
  reload: {
    type: Function,
    default: () => null
  }
})
const fetchData = () => { props.reload() }
</script>

<style>
.myResume__items .myResume__item:last-child{
  margin-bottom: 0;
}
</style>
