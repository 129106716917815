<template>
  <Header />
  <section class="CompanyServices">
    <div class="container">
      <h3 class="section__title">{{$t('favorites_resumes')}}</h3>

      <NothingHere v-if="favorites && favorites.length === 0"/>

      <ListResumes :resumes="favorites"/>
      <Pagination
        v-if="favorites"
        v-model="filter.page"
        :perPage="filter.per_page"
        :total="total"
        @newPage="fetchData(filter.page)"
      />
    </div>
  </section>
</template>

<script setup>
import ListResumes from '@/components/uiCompany/Resumes/ListResumes.vue'

import { ref, watch, reactive } from 'vue'
import { onMounted, provide } from 'vue-demi'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { getFavoriteResumes } from '@/actions/company'

const route = useRoute()

const favorites = ref(null)
const filter = reactive(
  {
    page: route.query.page ? Number(route.query.page) : 1,
    per_page: 20,
    sort_type: false
  }
)
const total = ref(null)

const checkAccess = ref(false)

const fetchData = async () => {
  favorites.value = null
  const response = (await getFavoriteResumes(filter.value)).data
  favorites.value = response.favorite_resumes.data
  total.value = response.favorite_resumes.total
  checkAccess.value = response.check_access
  router.push({ path: '/company/favorites-resumes/result', query: { page: filter.page } })
}

provide('check_access', checkAccess)
onMounted(
  () => fetchData()
)

watch(
  () => route.query,
  () => {
    if (Number(route.query.page) !== filter.page && route.name === 'FavoritesResumes') {
      filter.page = route.query.page ?? 1
      fetchData()
    }
  }
)
</script>

<style lang="scss" scoped>
.row{
  display: grid;
  grid-template-columns: 1fr 128px;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
}
.services__table{
  border-collapse: collapse;
  & .th{
    font-size: 12px;
    font-weight: 400;
    line-height: 1.1;
    color: rgba(33, 37, 41, 0.7);
    padding-left: 0;
    padding-right: 20px;
    background-color: #fff;
    border: none;
  }

  & .td{
    font-size: 16px;
    line-height: 1.2;
    color: rgba(33, 37, 41, 0.7);
    padding: 20px;
    border: 0.5px solid rgba(173, 23, 23, 0.5);
    &.title{
      font-weight: 600;
      font-size: 20px;
      text-transform: uppercase;
      color: #212529;
      background-color: rgba(173, 23, 23, 0.05);
      &.skeleton__block{
        background-color: rgba(#000, .09);
      }
    }
    &.salary{
      font-size: 24px;
      color: #212529;
    }
  }

  & .tr{
    width: 100%;
    display: flex;
    background: #fff;
    margin-bottom: 10px;

    display: grid;
    grid-template-columns: 30% 16.3% 14.8% 19.2% 19%;
  }
}
</style>
