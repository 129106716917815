import api from '../modules/api'

export const getListCompany = (data = {}) =>
  api.asyncGet('/v1/consultants/list-companies', data, false, true)

export const getListServices = () =>
  api.asyncGet('/v1/consultants/packages', null)

export const postConnectService = (data) =>
  api.asyncPost('/v1/consultants/packages', data)
