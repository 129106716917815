<template>
<div class="company">
  <div class="container">
    <div class="company__inner">
      <a class="company__item" href="https://knu.kazatomprom.kz/ru/content/too-sp-katko">
        <img src="/img/tmp/company/01.png" alt="">
      </a>
      <a class="company__item" href="http://www.meda.kz/">
        <img src="/img/tmp/company/02.png" alt="">
      </a>
      <a class="company__item" href="https://www.cisco.com/c/m/ru_kz/cisco-connect.html">
        <img src="/img/tmp/company/03.png" alt="">
      </a>
      <a class="company__item" href="http://intarget.kz/">
        <img src="/img/tmp/company/04.png" alt="">
      </a>
      <a class="company__item" href="https://kzpg.kz/">
        <img src="/img/tmp/company/05.png" alt="">
      </a>
      <a class="company__item" href="https://rgbrands.com/">
        <img src="/img/tmp/company/06.png" alt="">
      </a>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.company{
  position: relative;
  z-index: 10;
  top: -50%;
  transform: translate(0, -50%);

  &__inner{
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;

    padding: 60px 0 54px 30px;
    background-color: #fff;

    position: relative;
    z-index: 100;

    &::before{
      content: "";
      display: block;
      width: 300%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background: #fff;
      box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.1);
    }
  }

  &__item{
    & img{
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.63;

      transition: opacity .2s linear, filter .2s linear;
    }

    &:hover img{
      filter: grayscale(0);
      opacity: 1;
    }
  }
}
@media (max-width: 1005px){
  .company{
    transform: translate(0, -32%);
    &__inner{
      padding: 30px 0 30px 20px;
    }
    &__item{
      display: flex;
      justify-content: center;
    }
  }
}
@media (max-width: 715px){
  .company{
    &__inner{
      grid-gap: 25px;
      padding: 25px 0 25px 20px;
    }
  }
}
@media (max-width: 630px){
  .company{
    position: relative;
    transform: none !important;
    margin-bottom: 80px;

    &__inner{
      padding-left: 0;
      position: static;
    }
  }
}
@media (max-width: 415px){
  .company{
    &__inner{
      flex-wrap: wrap;
    }
    &__item{
      width: 25%;
    }
  }
}
</style>
