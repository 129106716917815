<template>
<LayoutRow
  :title="$t('Interests_and_hobbies')"
  v-model="formData.hobbies_on"
>
  <div class="createResume__itemInner">
    <label for="skills">{{$t('describe_in_free_form')}}: <br><span>{{$t('list_separated_by_commas')}}</span></label>
    <Input
      id="title"
      type="textarea"
      v-model="formData.interests"
      required/>
  </div>
</LayoutRow>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: null
  }
})
const emit = defineEmits(['update:modelValue'])

const formData = ref(props.modelValue)
if (formData.value.interests) formData.value.hobbies_on = true
else formData.value.hobbies_on = false

watch(
  () => formData.value,
  () => {
    emit('update:modelValue', formData.value)
  }
)
</script>
