<template>
  <div class="container">
    <transition-group name="fade" tag="div" class="toast-list" mode="ease-out">
      <div v-for="item in toastList" :key="item.id" :class="'toast ' + item.type">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"> <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none"> <path d="M2305 5114 c-280 -40 -429 -74 -615 -141 -617 -221 -1141 -690 -1430 -1278 -115 -235 -192 -477 -237 -750 -26 -158 -26 -612 0 -770 62 -373 193 -719 385 -1010 104 -159 182 -256 321 -399 390 -399 875 -649 1446 -743 87 -14 167 -18 385 -18 301 0 381 9 620 67 337 81 676 246 960 466 117 91 316 287 413 407 235 291 410 643 495 995 58 239 67 319 67 620 0 301 -9 381 -67 620 -85 351 -261 705 -495 995 -93 115 -282 302 -398 395 -269 214 -613 386 -943 471 -229 59 -333 71 -617 74 -143 2 -273 1 -290 -1z m395 -1185 c169 -79 227 -281 124 -434 -121 -182 -422 -179 -536 5 -77 125 -48 302 64 393 89 71 239 87 348 36z m-8 -847 c73 -36 124 -87 160 -160 l33 -67 0 -740 0 -740 -33 -67 c-36 -73 -87 -124 -161 -160 -37 -18 -63 -23 -131 -23 -74 0 -92 4 -143 29 -70 36 -120 89 -156 165 l-26 56 -3 709 c-2 478 1 727 8 762 23 108 117 215 220 249 63 21 175 14 232 -13z"/> </g> </svg>
        <div class="background"></div>
        <span class="message">{{ item.message }}</span>
        <button class="close-toast" @click="remove(item.id)">+</button>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { useToast } from '@/modules/toast.js'

export default {
  name: 'Toast',

  setup () {
    const { toastList, remove } = useToast()
    return { toastList, remove }
  }
}
</script>

<style lang="scss" scoped>
.toast-list {
  top: 0;
  left: calc(50%);
  transform: translateX(-50%);
  min-width: 500px;
  max-width: 800px;
  position: fixed;
  z-index: 999999;
  display: flex;
  flex-direction: column-reverse;
}

.toast {
  position: relative;
  padding: 15px 50px 15px 50px;
  margin: 10px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 16px;
  border: 2px solid #1c80a5;
  color: #1c80a5;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 5px 25px rgba(#000, .15);
  background: #CBEBFB;
  & svg{
    display: block;
    height: 20px;
    width: 20px;
    fill: #1c80a5;
    position: absolute;
    left: 18px;
  }
}
.close-toast {
  position: absolute;
  cursor: pointer;
  right: 18px;
  background: none;
  transform: rotate(45deg);
  font-size: 24px;
  z-index: 3;
  opacity: .4;
  color: #1c80a5;
}

@media (max-width: 992px){
  .toast-list {
    min-width: 500px;
    max-width: 800px;
  }

  .toast {
    padding: 12px 45px;
    margin: 10px 0;
    font-size: 14px;
    max-width: 500px;
    & svg{
      height: 18px;
      width: 18px;
      left: 16px;
    }
  }
  .close-toast {
    right: 16px;
    font-size: 22px;
  }
}
@media (max-width: 768px){
  .toast-list {
    min-width: 500px;
    max-width: 540px;
  }

  .toast {
    padding: 10px 40px;
    margin: 8px 0;
    font-size: 12px;
    max-width: 500px;
    & svg{
      height: 16px;
      width: 16px;
      left: 14px;
    }
  }
  .close-toast {
    right: 14px;
    font-size: 20px;
  }
}
@media (max-width: 576px){
  .toast-list {
    min-width: 320px;
    max-width: 380px;
  }

  .toast {
    padding: 8px 30px;
    margin: 6px 0;
    font-size: 10px;
    max-width: 300px;
    border: 1.5px solid #1c80a5;
    & svg{
      height: 14px;
      width: 14px;
      left: 12px;
    }
  }
  .close-toast {
    right: 12px;
    font-size: 18px;
  }
}

@media (max-width: 400px){
  .toast-list {
    min-width: 200px;
    max-width: 100%;
  }

  .toast {
    padding: 6px 25px;
    font-size: 10px;
    max-width: 200px;
    border: 1px solid #1c80a5;
    & svg{
      height: 12px;
      width: 12px;
      left: 8px;
    }
  }
  .close-toast {
    right: 10px;
    font-size: 16px;
  }
}

.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(-100px);
  padding: 0;
  height: 0;
  margin: 0;
  font-size: 0;
  opacity: 0;
}
</style>
