import { ref } from '@vue/reactivity'

import api from '../modules/api.js'
import router from '../router/index.js'
import { deleteToken } from '@/actions/auth.js'
const loaded = ref(false)

export const useAuth = () => {
  const logged = () => {
    if (localStorage.getItem('access_token') != null || localStorage.getItem('access_token') != null) {
      return true
    }
    return false
  }

  const setUser = async (data, email) => {
    localStorage.setItem('access_token', data.token)
    localStorage.setItem('role_id', data.role_id)
    localStorage.setItem('email', email)
    api.defaults.headers.common.Authorization = `Bearer ${data.token}`
    if (data.role_id === 3) {
      router.push('/user/personal-data')
    } else if (data.role_id === 4 || data.role_id === 5) {
      router.push('/company/common-data')
    } else if (data.role_id === 2) {
      router.push('/consultant/list-companies/result?page=1')
    }
  }

  const logout = () => {
    deleteToken()
    localStorage.setItem('access_token', null)
    localStorage.setItem('role_id', null)
    localStorage.setItem('email', null)
    loaded.value = false
    router.push('/')
    return true
  }

  return {
    setUser,
    logged,
    logout
  }
}
