export const en = {
  lang_title: 'EN',
  message: {
    logged: 'You are logged in!',
    logout: 'You are logged out!',
    password_reset_link_sent_to_email: 'A link to reset the password has been sent to the mail',
    changed_lang: 'The language has been changed to Russian',
    minTestLength: 'The minimum number of responses is two',
    zero_resume: 'To respond, you need to create a resume',
    your_email_has_been_successfully_verified: 'Your email has been successfully confirmed!',
    your_password_has_been_successfully_changed: 'Your password has been successfully confirmed!',
    request_failed: 'Request error! Please check if you are connected to the Internet.',
    server_error: 'Server error!',
    you_first_need_to_login: 'You need to log in first!',
    we_do_not_have_such_a_page: 'We do not have such a page!',
    you_do_not_have_access_to_this_page: 'You do not have access to this page!',
    an_error_occurred_while_requesting: 'An error occurred during the request! Please try again later.',
    looks_like_it_s_empty: 'Looks like it is empty!',
    you_need_to_log_in_as_a_user: 'You need to log in as a user!',
    pay_the_tariff_for_viewing_contacts: 'Pay the tariff for viewing contacts!',
    first_but_a_package_to_create_a_manager: 'First buy a package to create a manager!',
    first_but_a_package_to_create_a_vacancy: 'First buy a package to create a vacancy!',
    to_respond_you_need_to_pass_a_test: 'To respond, you need to pass a test.'
  },
  us_address_title: 'Our address',
  skype: 'Skype',
  search: 'Search',
  logout: 'Logout',
  filter: 'Filter',

  // Address
  address: 'Republic of Kazakhstan, Almaty city, 210 Dostyk Ave., Business center "Koktem Grand", 2nd block, 2nd floor, office 28',
  we_are_in_social_networks: 'We are in social networks',
  copyright: '© 2022 Altyn Adam. All rights reserved',
  your_city: 'Your city',

  // Upload Image
  photo_cropping: 'Photo cropping',
  select_photo: 'Select photo',
  save_as_profile_photo: 'Save as profile photo',
  profile_photo: 'Profile photo',
  change_your_profile_photo: 'Change your profile photo',
  drag_photo_here: 'Drag the photo here',
  download_from_device: 'Download from device',
  or: 'or',
  by_profile_photo: 'Other people will be able to recognize you by your profile photo, and it will be easier for you to determine which account you are logged into.',

  // ============================= Home Pages ==============================
  // Intro
  intro: {
    title: {
      top: 'HR consulting and recruiting ',
      bottom: 'agency'
    },
    subtitle: 'Work in all regions of Kazakhstan, CIS countries and abroad'
  },

  // Salse
  sales: 'Promotions',
  LastMinuteJobs: 'Hot vacancies',

  // About
  aboutUs: {
    title: 'About company',
    text: {
      text1: 'Since 2005, Altyn Adam Agency has been providing a full range of services in the field of personnel management: search and selection, training and development, outsourcing and outstaffing of personnel. During this period of time, our company has expanded, gained a good reputation in the market of Kazakhstan and abroad for the quality of services, high efficiency and knowledge of its industry.',
      text2: 'The database of applicants of Altyn Adam Agency includes resumes of applicants from all fields of activity: oil and gas, banking, thermal power, uranium mining, financial sector, industry, engineering, FMCG, law, construction and real estate, production and working personnel, agriculture, transport and logistics, etc.',
      text3: 'Applicants resumes in the database are created according to a special scheme that takes into account all the data for compiling a competent, detailed, correct resume, including complete information necessary for the employer to make a decision regarding the candidate.',
      text4: 'Every Company, being a client of our Agency, has the opportunity of profitable cooperation in the field of personnel search and selection, outsourcing and outstaffing, training and staff development.',
      text5: 'Our goal is to create the best conditions for our clients and job seekers when searching and selecting qualified personnel and finding the best place to work.',
      text6: 'Sincerely, the Team of Altyn Adam Agency LLP'
    }
  },

  // Services
  services: {
    title: 'Services',
    button: 'More detailed',
    connected: 'Connected services'
  },
  services_tipes: 'Types of recruitment services:',
  pay: 'To Pay',
  by_SMS: 'by SMS',

  // About us - История
  home: 'Main',
  contacts: 'Contacts',
  history: 'History',
  company_development_stages: 'Important stages of the companys development',
  team: 'Team',
  partners: 'Our partners',
  principles: 'Basic principles',
  certificates: 'Awards, certificates, recommendations',
  outsourcing_personnel: 'Outsourcing of staff',

  // News
  news: 'News',
  show_all: 'Show all',

  // Favorites
  favourites: 'Favourites',
  be_in_favorites: 'In favorites',
  remove_from_favorites: 'Remove from favorites',
  add_to_favorites: 'Add to favorites',

  // Responses and Invites
  responses_and_invitations: 'Responses and invitations',
  you_were_invited: 'You were invited!',
  you_were_denied: 'You were denied',
  viewed: 'Viewed',
  not_viewed: 'Not viewed',
  response: 'Respond',
  cancel_response: 'Cancel response',
  responded: 'Responded',
  invited: 'Invited',
  refusal: 'Refused',
  to_invite: 'To invite',
  refuse: 'Refuse',

  // Helpers
  topic: 'Subject of the appeal',
  help: 'Help',
  message_text: 'Message',
  your_message: 'Your appeal',
  Writeto_the_technical_department: 'Write to the technical department',

  // Quastion Modal
  are_you_sure: 'Are you sure?',

  // Login Modal
  login: 'Login',
  login_button: 'Enter',
  authorization: 'Authorization',
  authorization_button: 'Authorization',
  login_with: 'Log in via:',
  forgot_your_password: 'Forgot your password?',
  register_on_site: 'REGISTER ON THE WEBSITE',

  // Register
  registration: 'Registration',
  register_button: 'Register',
  company: 'Company',
  applicant: 'The applicant',
  i_have_read_and_agree_with: 'I have read and agree with',
  terms_of_agreement: 'terms of the Contract',

  // Reset Password
  enter_your_password_recovery_email: 'Enter the password recovery email',

  // Time
  from: 'From',
  to: 'To',
  before: 'Before',
  after: 'After',

  // =================================== Data ======================================
  // Person Data
  fullName: 'Full name',
  name: 'Name',
  surname: 'Surname',
  patronymic: 'Patronymic',
  gender: 'Gender',
  man: 'Man',
  woman: 'Woman',
  additionally: 'Additionally',
  family_status: 'Marital status',
  children: 'Children',

  // Common Data
  type_of_ownership: 'Form of ownership',
  name_company: 'Company name',
  contact_person: 'Contact person',
  bin_and_iin: 'BIN / IIN',
  bin: 'BIN',
  KBE: 'Beneficiary Code',
  IKK: 'IIC',
  bank: 'Bank',
  BIK: 'BIC',
  KNP: 'PPC',
  legal_address: 'Legal address',
  description: 'Description',
  field_of_activity: 'Field of activity',
  show_location_of_company_on_map: 'Specify the location of the company on the map',
  description_company: 'Company Description',

  // Основная информация
  title: 'Title',
  basic_information: 'Basic information',
  specialization: 'Specialization',

  // Email
  yourEmail: 'Your mail',

  // Password
  password: 'Password',
  repeat_password: 'Repeat password',

  // Phone
  phone: 'Telephone',
  second_phone: 'Additional phone number',
  additional_contacts: 'Additional communication method',

  // Address
  address_title: 'Address',
  city: 'City',
  country: 'A country',
  region: 'Region',
  choose_region: 'Select a region',
  choose_country: 'Select a country',
  choose_city: 'Choose a city',
  citizenship: 'Citizenship',
  country_origin: 'Country of residence',
  city_origin: 'City of residence',
  address_origin: 'Residential address',
  show_address: 'Show address',
  location_on_map: 'Location on the map',
  work_permit: 'Work permit',
  place_of_workd: 'Place of work',
  where_to_look_for_an_employee: 'Where to look for an employee',
  do_not_include_an_address: 'Do not specify the address',
  specify_the_address: 'Specify the address',

  // Date and Birthday
  date_birth: 'Date of birth',
  show_date_birth: 'Display date of birth',
  pass_period: 'Period of passage',
  until_now: 'until now',
  on: 'on',
  day: 'Day',
  days: 'Days',
  month: 'Month',
  year: 'Year',

  // State
  connected: 'Connected',
  disabled: 'Disabled',
  not_set: 'Not set',
  hidden: 'Hidden',
  updated: 'Updated',
  published: 'Published',
  not_published: 'In the archive',
  all_changes_are_saved_in: 'All changes are saved in',

  rofessional_skills: 'Professional knowledge and skills',
  more_about_yourself: 'More about yourself',

  // Hobbies
  Interests_and_hobbies: 'Interests and hobbies',

  // Avto - Car
  availability_of_a_car: 'Availability of car',
  own_car: 'Own car',
  driver_license: 'Driving license',
  categories: 'Categories',
  category_of_rights: 'Driving license category',
  select_categories: 'Select categories',

  // Languages
  lang: 'Language',
  language_skills: 'Knowledge of languages',
  main_languages: 'Main languages',
  specify_another_language: 'Specify another language',

  // Issue Type
  issue_type: 'Type of issue',
  before_taxes: 'Gross',
  on_hand: 'Net',

  // Employment Type
  employment_type: 'Type of employment',
  industry: 'Branch',

  // Work
  working_mode: 'Operating mode',
  temporary_clearance_possible: 'Temporary registration is possible',
  it_is_possible_to_apply_for_gpc_or_part_time: 'It is possible to arrange for civil contract (services, contract, sole proprietors, self-employed) or part-time',
  estimated_income_level_per_month_or_per_amount_of_work: 'Estimated income per month or for the amount of work',
  work_period: 'Period of operation',
  functional_responsibilities: 'Functional responsibilities',

  // Key skills
  key_skills: 'Key skills',
  select_skills: 'Choose skills',

  // Position
  position: 'Post',
  career_objective: 'Desired position',
  wage: 'Wages',
  desired_salary: 'Desired salary',

  // Ownership PC
  ownership_pc: 'Computer Skills',
  main_programs: 'Main programs',

  // Experiences
  required_work_experience: 'Required work experience',
  experience: 'Work experience',
  responsibilities: 'Responsibilities',
  last_job: 'Last place of work',

  // Education
  education: 'Education',
  honors_degree: 'Diploma with honors',
  type_of_education: 'Type of education',
  educational_institution: 'Educational institution',
  faculty: 'Faculty',
  speciality: 'Specialization',
  type_of_diploma: 'Type of diploma',
  training_period: 'Training period',

  // Courses
  courses: 'Courses, trainings, seminars',
  certificate: 'Certificate',

  // Awards
  awards: 'AWARDS, DIPLOMAS',
  file: 'File',

  // Personal Slills
  personal_qualities: 'Personal qualities',

  // Кто может откликаться
  who_can_respond: 'Who can respond',
  applicants_with_disabilities: 'Applicants with disabilities',
  applicants_with_an_incomplete_resume: 'Applicants with incomplete resume',
  only_with_cover_letter: 'Only with a cover letter',

  // ================================= Placeholder =======================
  not_required_confidential: 'Not required to be filled in, confidential',
  list_separated_by_commas: 'List them separated by commas',
  describe_in_free_form: 'Describe it in a free form',

  // =================================== Button Texts ======================================
  // Hide and Not
  show: 'Show',
  hide: 'Hide',
  look: 'Look',
  show_contacts: 'Show contacts',
  buy: 'Buy',
  lets_go: 'Pass',
  show_flyBtn: 'Show',

  // Yes and No
  yes: 'Yes',
  no: 'No',
  there_is: 'There is',
  edit: 'Edit',
  cancel: 'Cancel',
  close: 'Close',
  save_edited: 'SAVE CHANGES',
  send_message: 'Send a message',
  add_more: 'Add more',

  // Buttons
  // ///////////////////////
  send: 'Send',
  save: 'Save',
  create: 'Create',
  delete: 'Delete',
  redact: 'Edit',
  edit_data: 'Edit data',
  reach: 'Reach',
  connect: 'Connect',

  // Inputs
  // ///////////////////////
  enter_template: 'Enter a name',
  name_template: 'Template name',
  enter_text: 'Enter the text',

  // =================================== Select ==============================
  select_a_region_first: 'First select a region',
  select_show_all: 'Show all',
  not_found: 'Not found',

  // =================================== Company Pages ======================================
  // ///////////////////////
  common_data: 'General data',

  // Managers
  manager: 'Manager',
  managers: 'Managers',
  add_meneger: 'Add manager',
  company_menegers: 'Company managers',
  edit_meneger: 'Edit Manager',

  // Tests
  test: 'Test',
  create_test: 'Create a test',
  edit_test: 'Text Editing',
  name_test: 'Name of the test',
  test_templates: 'Test Templates',
  add_quastion: 'Add a question',
  applicant_test: 'Test for the applicant',
  select_tests: 'Select tests',
  quastion: 'Question',
  response_to_the_test: 'Response to the test',
  passing_the_test: 'Passing the test',

  // Letters
  letter_template: 'Letter Template',
  letter_templates: 'Mail Templates',
  create_latter: 'CREATE A letter',

  // Vacancies
  vacancy: 'Vacancy',
  vacancies: 'Vacancies',
  edit_vacancy: 'Edit vacancy',
  create_vacancy: 'Create vacancy',
  about_the_position: 'About the position',
  name_vacancy: 'Job title',
  tell_about_the_vacancy: 'Tell us about the vacancy',
  vacancy_adderss: 'Vacancy address',
  vacancy_codex: 'When publishing a vacancy, it is prohibited to post information containing discriminatory requirements in the field of work (restrictions depending on gender, age, place of residence and other circumstances unrelated to the business qualities and specifics of the candidates work function). According to paragraph 2 of Article 6 of the Labor Code of the Republic of Kazakhstan dated November 23, 2015 No. 414-V and paragraph 3-.2 of Article 14 of the Law of the Republic of Kazakhstan dated July 23, 1999 No. 451-I "On Mass Media".',

  // Hot Vacancy
  hot_vacancy: {
    checkbox: 'Hot vacancy',
    button: 'Create a hot vacancy'
  },

  // Working With The Site
  working_with_site: 'Working with the site',
  about_the_contract: 'About the contract',
  there_is_no_contract: 'There is no service agreement',
  paid_bills: 'Paid bills',
  connected_services: 'Connected services',
  responses_to_a_job: 'Responses to the vacancy',
  service_agreement: 'Service agreement',
  signed: 'Signed',
  deadline_up_to: 'Term up to',
  vacancy_placement: 'Vacancy placement',
  adding_a_manager: 'Adding a manager',
  left: 'Left',
  all_responses: 'All responses',

  // Connected Services
  number_of_available_vacancies: 'Number of available vacancies',
  number_of_available_managers: 'Number of available managers',
  left_days: 'Days left',

  // Search Resumes
  resumes: 'Resumes',
  applicants_resumes: 'Applicants resumes',

  // Favorites Resumes
  favorites_resumes: 'Favorite resumes',

  // Services
  send_a_request_for_payment: 'Send a request for payment',
  denomination: 'Name',
  access_to_the_database_of_resume_of_applicants: 'Access to the database of applicants resumes',
  number_of_vacancies_to_post: 'Number of vacancies for placement',
  number_of_company_managers: 'Number of company managers',
  service_cost: 'Cost of services (KZT, including VAT)',
  full_access: 'Full access',
  no_access: 'No access',

  // =================================== Client Pages ======================================
  // Person data
  personal_data: 'Personal data',
  edit_personal_data: 'Change personal data',

  // Resumes
  resume: 'Resumes',
  my_resumes: 'My resume',
  create_resume: 'Create resume',

  // Companies
  companies: 'Company',
  companies_directory: 'Company catalog',

  // Feedback form
  write_a_letter: 'Write a letter',
  i_want_to_work_here: 'I want to work here',
  enter_your_cover_letter: 'Enter the text of the cover letter',
  select_resume: 'Choose a resume',

  // Vacancies
  vacancies_of_this_company: 'Vacancies of this company',

  // =================================== HELPERS DATAS ======================================
  // Months
  months: {
    1: 'January',
    2: 'February',
    3: 'Martha',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  },

  // Languages Levels
  languages_levels: {
    A1: 'A1 - Initial',
    A2: 'A2 - Elementary',
    B1: 'B1 - Medium',
    B2: 'B2 - Intermediate-Advanced',
    C1: 'C1 - Advanced',
    C2: 'C2 - Perfect'
  },

  // Wages
  wages_option_1: 'to 100 000',
  wages_option_2: '100 000 - 200 000',
  wages_option_3: '200 000 - 300 000',
  wages_option_4: '300 000 - 400 000',
  wages_option_5: '400 000 - 500 000',
  wages_option_6: 'From 500 000',

  // Time Filter
  timeFilters: {
    day: 'For today',
    month: 'For this month',
    three_months: 'In three months',
    year: 'Per year'
  },

  // Date Filter
  dateFilters: {
    date_asc: 'Ascending dates',
    date_descending: 'Descending dates'
  },

  // Filter Company
  filterCompanies: {
    all_companies: 'All companies',
    only_with_open_vacancies: 'Only with open vacancies'
  },

  // Errors
  errors: {
    this_fieldIs_required: 'This field is required',
    enter_the_correct_number: 'Enter the correct number',
    enter_the_correct_email: 'Enter the correct email',
    enter_the_correct_password: 'The password must contain at least 8 characters. It must contain at least one digit, one capital  letter and one small letter',
    passwords_dont_match: 'Passwords dont match'
  }
}
