<template>
<LayoutRow
  :title="$t('courses')"
  v-model="formData.courses_on"
>
  <RowCourse
    v-for="(course, i) of formData.courses"
    :key="course"
    :course="course"
    :helpers="helpers"
    @delete="() => {deleteCourse(i)}"
  />
  <Button
    :name="$t('add_more')"
    class="createResume__AddMore"
    type="darkGreen"
    @click="addCourse()"
  />
</LayoutRow>
</template>

<script setup>
import RowCourse from '@/components/uiEmployee/Resume/Edit/Courses/RowCourse.vue'

import { ref } from '@vue/reactivity'
import { defineProps, defineEmits, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  helpers: {
    type: Object,
    default: () => {}
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation'])

const formData = ref(props.modelValue)
formData.value.deleteCourses = []

// ===================     Checking   ===================
if (formData.value.courses.length !== 0) formData.value.courses_on = true
else formData.value.courses_on = false

watch(
  () => [formData.value, formData.value.courses_on],
  () => {
    if (formData.value.courses_on && formData.value.courses.length === 0) addCourse()
    emit('update:modelValue', formData.value)
  }
)

// ===================     Click Events    ===================
const addCourse = () => {
  formData.value.courses.push({
    title: ''
  })
}

const deleteCourse = i => {
  if (formData.value.courses[i].id) formData.value.deleteCourses.push(formData.value.courses[i].id)
  formData.value.courses.splice(i, 1)
  if (formData.value.courses.length === 0) formData.value.courses_on = false
}
</script>
