<template>
  <Header />
  <section class="invitationsDetail">
    <div class="container" v-if="invitation">
      <h2 class="section__title" style="margin-bottom: 20px">{{company.name}}</h2>
      <div class="invitationsDetail__header">
        <div class="invitationsDetail__left">
          <p class="date">{{vacancyCreateDate(vacancy.created_at)}}</p>
          <p>{{`${vacancy.city.countries.title}, ${vacancy.city.title}`}}</p>
          <p>{{`${$t('field_of_activity')}: ${vacancy.professional_area.title}`}}</p>
        </div>
        <div class="invitationsDetail__right">
          <!-- <img src="/img/tmp/detailVacancy.png" alt=""> -->
        </div>
      </div>
      <h2 class="section__title vacancy">{{vacancy.position}}</h2>

      <div
        class="price"
        v-if="vacancy.min_salary || vacancy.max_salary"
      >
        {{vacancy.min_salary ? formatToSalary(vacancy.min_salary) : ''}}
        {{vacancy.min_salary && vacancy.max_salary ? '-' : ''}}
        {{vacancy.max_salary ? formatToSalary(vacancy.max_salary) : ''}}
        {{` ${vacancy.currency?.title ?? ''}`}}
      </div>

      <p class="text" v-if="invitation.letter.text">{{invitation.letter.text}}</p>
      <div class="invitationsDetail__inner">
          <div class="invitationsDetail__col">
              <div class="invitationsDetail__item">
                  <div class="invitationsDetail__title">{{$t('us_address_title')}}:</div>
                  <p class="invitationsDetail__text" v-if="company.address">{{company.address}}</p>
              </div>
              <div class="invitationsDetail__item">
                  <div class="invitationsDetail__title">{{$t('phone')}}:</div>
                  <a
                    class="invitationsDetail__text"
                    :href="'tel:' + company.phone"
                  >
                    {{formatToPhone(company.phone)}}
                  </a>
              </div>
          </div>
          <div class="invitationsDetail__map" v-if="company.width">
              <yandexMap :coords="[company.width, company.height]" :zoom="14">
                <ymapMarker :coords="[company.width, company.height]" marker-id="123" hint-content="some hint"/>
              </yandexMap>
          </div>
      </div>
    </div>

    <!-- Skeleton -->
    <div class="container" v-else>
      <div class="detailVacancy__header">
        <div class="detailVacancy__left" style="max-width:700px;">
          <div class="skeleton middle-text" style="width:150px;margin-bottom:10px"></div>
          <div class="skeleton middle-text" style="width:70%;margin-bottom:10px"></div>
          <div class="skeleton middle-text" style="width:50%;margin-bottom:10px"></div>
          <div class="skeleton middle-text" style="width:200px;margin-bottom:10px"></div>
        </div>
        <div class="detailVacancy__right">
          <div class="img skeleton skeleton-img"></div>
        </div>
      </div>
      <div class="section__title vacancy skeleton small-text" style="width:400px;max-width:100%"></div>
      <div class="price skeleton small-text" style="width:200px;max-width:100%"></div>
      <div v-for="el of 2" :key="el" style="margin-bottom:40px">
        <div class="skeleton middle-text" style="width:100px;max-width:100%;margin-bottom:10px"></div>
        <div class="skeleton middle-text" style="width:750px;max-width:100%;margin-bottom:10px" v-for="item of 3" :key="item"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

// import { apiUrl } from '@/api'
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { onMounted } from 'vue-demi'
import { useI18n } from 'vue-i18n'
import moment from 'moment'

import { formatToPhone } from '@/helpers/formatPhone'
import { formatToSalary } from '@/helpers/formatSalary'
import { getResponseVacancy } from '@/actions/employee'

const i18n = useI18n()
const route = useRoute()

const invitation = ref(null)
const vacancy = ref(null)
const manager = ref(null)
const company = ref(null)

onMounted(
  async () => {
    const response = (await getResponseVacancy(route.params.invitation_id)).data
    vacancy.value = response.vacancy
    manager.value = response.vacancy.manager
    company.value = response.vacancy.manager.company
    invitation.value = response
  }
)

// Example Results => 19 Апрель 2022
const vacancyCreateDate = (input) => {
  return `${moment(input).format('DD')} ${i18n.t('months.' + moment(input).format('M'))} ${moment(input).format('YYYY')}`
}
</script>

<style lang="scss" scoped>
.invitationsDetail{
  &__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    & .section__title{
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    & .left{
      max-width: 740px;
      margin-right: 100px;
    }
  }

  & .date{
    color: #000;
  }

  .vacancy{
    margin-bottom: 20px;
  }

  & .price{
    font-size: 24px;
    text-transform: uppercase;
    color: #212529;
    margin-bottom: 35px;
  }

  & .text{
    margin-bottom: 15px;
    word-wrap: break-word;
  }

    &__item{
      max-width: 475px;
        margin-bottom: 30px;
    }

    &__title,
    &__text{
        color: #212529;
        font-size: 16px;
        line-height: 1.5;
    }

    &__title{
        line-height: 1.2;
        margin-bottom: 5px;
    }

    &__text{
        color: rgba(#212529, .7);
    }

    &__map{
        height: 300px;
        background-color: #ebebeb;
    }
}
@media (max-width: 576px){
  .section__title{
    margin-bottom: 10px;
  }
  .invitationsDetail__header {
    display: grid;
    grid-template-columns: 1fr;
  }
  & .invitationsDetail__left{
    margin-right: 0;
    grid-row: 2;
  }
  & .invitationsDetail__right{
    width: 200px;
  }
}
</style>
