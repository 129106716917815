<template>
<section class="services" id="services">
    <div class="container">
      <h3 class="section__title">{{$t('services.title')}}</h3>
    </div>
    <div class="container">
      <div class="services__inner" v-if="services.data">
        <div class="services__item" v-for="(service, i) of services.data" :key="i">
            <div class="services__preview" v-if="i % 2 !== 1">
                <div>
                    <img class="img" :src="`${apiUrl}/${service.image}`" alt="">
                </div>
                <div class="services__cart" v-if="service.benefits" v-html="service.benefits">
                </div>
            </div>
            <div class="services__content">
                <div class="services__title" v-if="service.title">{{service.title}}</div>
                <p class="services__text" v-if="service.description">{{service.description}}</p>
                <router-link :to="'/services/' + (service.id === 13 ? 'outsourcing-personnel' : service.id)" class="services__link">
                    <span>{{$t('services.button')}}</span>
                    <svg class="arrow">
                        <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
                    </svg>
                </router-link>
            </div>
            <div class="services__preview" v-if="i % 2 === 1">
                <div>
                    <img class="img" :src="`${apiUrl}/${service.image}`" alt="">
                </div>
                <div class="services__cart" v-if="service.benefits" v-html="service.benefits">
                </div>
            </div>
        </div>
      </div>

      <!-- Skeleton -->
      <div class="services__inner" v-else>
        <div class="services__item" v-for="el of 2" :key="el">
            <div class="services__preview" v-if="el % 2 !== 1">
                <div>
                    <div class="img skeleton skeleton-img"></div>
                </div>
                <div class="services__cart">
                  <p class="skeleton small-text" style="margin-bottom:10px;width:70%;" v-for="k of 4" :key="k"></p>
                </div>
            </div>
            <div class="services__content">
                <div class="services__title skeleton middle-text"></div>
                <div class="services__text">
                  <span class="skeleton middle-text" style="margin-bottom:10px;width:100%;" v-for="k of 7" :key="k"></span>
                  <span class="skeleton middle-text" style="margin-bottom:10px;width:80%;"></span>
                  <span class="skeleton middle-text" style="margin-bottom:10px;width:30%;"></span>
                </div>
            </div>
            <div class="services__preview" v-if="el % 2 === 1">
                <div>
                    <div class="img skeleton skeleton-img"></div>
                </div>
                <div class="services__cart">
                  <p class="skeleton small-text" style="margin-bottom:10px;width:70%;" v-for="k of 4" :key="k"></p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue-demi'
import { getServices } from '@/actions/home'
import { apiUrl } from '@/api'

const services = ref({})
onMounted(
  async () => {
    const response = await getServices()
    services.value = response.data.services
    services.value.data.forEach(item => {
      item.title = item.title[0].toUpperCase() + item.title.slice(1, item.title.length).toLowerCase()
    })
  }
)
</script>

<style lang="scss" scoped>
.services{
  & .container:first-child{
    padding-bottom: 0;
    border-bottom: none;
  }

  & .container:last-child{
    padding-top: 0;
    @media (min-width: 993px){
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__inner{
      display: grid;
      grid-gap: 50px;

      & .services__item:nth-child(2n+1){
          & .services__cart{
            right: -35px;
            animation: cartPosition 6500ms;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
      }

      & .services__item:nth-child(2n){
          & .services__cart{
            left: -35px;
            animation: cartPosition 5500ms;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
      }
  }

  &__item{
      display: grid;
      grid-template-columns: 1fr 1fr;
  }

  &__preview{
    position: relative;
    & div:first-child{
      position: relative;
      height: 425px;
      overflow: hidden;

      & .img{
        min-height: 540px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ededed;
      }
    }
  }

  &__cart{
      width: 450px;
      max-width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 2;

      padding: 30px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 50px 50px 100px rgba(0, 0, 0, 0.145947);
  }

  @keyframes cartPosition {
      0%{
          transform: translateY(5px);
      }
      100%{
          transform: translateY(45px);
      }
  }

  &__content{
      padding: 0 95px;
  }

  &__title{
      color:#4D533C;
      font-size: 36px;
      font-weight: 700;
      line-height: 1.3;

      margin-bottom: 30px;
  }

  &__text{
    font-size: 16px;
    text-align: justify;
      margin-bottom: 40px;
  }

  &__link{
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      color: #4D533C;
      cursor: pointer;

      & span{
          margin-right: 28px;
          cursor: pointer;
      }

      & svg{
          display: block;
          width: 10px;
          height: 18px;
          fill: #000;

          transition: transform .2s linear;
      }

      &:hover{
          & span{
              text-decoration: underline;
          }
          & svg{
              transform: translateX(10px);
          }
      }
  }
}
@media (max-width: 1200px){
  .services{
    &__content{
      padding: 0 45px;
    }
    &__cart{
      padding: 20px;
    }
    &__title{
      font-size: 30px;
      margin-bottom: 20px;
    }
    &__text{
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 992px){
  .services{
    &__inner{
      grid-gap: 20px;
    }
    &__item{
      display: block;
      min-height: 120px;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
    }
    &__preview{
      & div:first-child{
        position: static;
        height: auto;
      }
      & .img{
        min-height: 80px;
        position: absolute;
        z-index: -1;
        background-color: #ededed;
      }
    }
    &__content{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      background-color: rgba(#212529, .5);
    }
    &__title{
      display: inline-block;
      max-width: calc(100% - 40px);
      font-weight: 500;
      font-size: 22px;
      color: #FFFFFF;
      margin-bottom: 0;
    }
    &__cart,
    &__text{
      display: none;
    }
    &__link{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;

      & span{
        display: none;
      }
      & svg{
          display: block;
          width: 28px;
          height: 37px;
          fill: #fff;

          transition: transform .2s linear;
      }
    }
  }
}

@media (max-width: 576px){
  .services{
    &__inner{
      grid-gap: 10px;
    }
    &__item{
      min-height: 80px;
    }
    &__title{
      font-size: 16px;
    }
    &__link{
      & svg{
          width: 13px;
          height: 24px;
      }
    }
  }
}
</style>

<style lang="scss">
.services__cart ul{
    display: block;
    list-style-type: disc;
    padding-left: 24px;
    margin: 0;

    & li{
        margin-bottom: 0;
    }
}

.services__cart ul li {
    color: #4D533C !important;
    font-style: italic;
    margin-bottom: 0 !important;
}
</style>
