<template>
  <Header />
  <section class="fillingInBillingInformation">
      <div class="container">
          <h3 class="section__title">Заполните платежные данные</h3>
          <img src="/img/tmp/Mask Group.png" alt="">
      </div>
  </section>
</template>

<script>
import { reactive } from '@vue/reactivity'

export default {
  name: 'Home',
  components: {
  },
  setup () {
    const formData = reactive({
      surname: '',
      name: '',
      patronymic: '',
      country: '',
      address: '',
      date: '',
      month: '',
      year: '',
      phone: '',
      additionalPhone: '',
      email: '',
      additionalCommunication: '',
      showBirthdayDate: false,
      showAddress: false,
      gender: ''
    })
    return {
      formData
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
