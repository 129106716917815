<template>
  <Header />
  <section class="createTest">
    <div class="container">
      <h3 class="section__title">{{!route.params.test_id ? $t('create_test') : $t('edit_test')}}</h3>
      <form class="createTest__form" @submit.prevent="createOrEditTest()" method="POST" v-if="formData">
        <div class="createTest__header">
          <label>{{$t('name_test')}}</label>
          <Input v-model="formData.title" :placeholder="$t('enter_text')" required/>
        </div>

        <QuestionTest
          v-for="(quastion, i) of formData.questions"
          :key="quastion"
          :num="i"
          :edit="route.params.test_id"
          @addQuastion="addQuastion()"
          @deleteQuastion="deleteQuastion(i)"
          :lengthQuastions="formData.questions.length"
          :deleteVariants="deleteVariants"
          :modelValue="quastion"
        />

        <div>
          <button
            class="create"
            type="button"
            @click="addQuastion()"
          >
            <svg>
              <use xlink:href="/img/tmp/sprite.svg#plus"></use>
            </svg>
            <span>{{$t('add_quastion')}}</span>
          </button>
        </div>

        <div>
          <div class="btns">
            <Button :name="$t('save')" htmlType="submit"/>
            <Button :name="$t('cancel')" type="gray" go="/company/test-templates"/>
          </div>
        </div>
      </form>
      <div class="createTest__form" v-else>
        <div class="createTest__header">
          <label class="skeleton middle-text"></label>
          <div class="skeleton">
            <Input disabled/>
          </div>
        </div>
        <div class="question" v-for="item of 2" :key="item">
          <label class="skeleton middle-text"></label>
          <div class="variants">
            <div class="skeleton" v-for="item of 4" :key="item" style="max-width:85%">
              <Input disabled/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading class="fixed" :show="loading"/>
  </section>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'
import { onMounted } from 'vue-demi'
import { getTest, putTest, postTest } from '@/actions/company'

import QuestionTest from '@/components/uiCompany/Tests/Edit/QuestionTest.vue'

const route = useRoute()
const formData = ref(route.params.test_id ? null : {})
const newQuestion = { title: '', variations: [{ right_variation: 1, title: '' }, { right_variation: 0, title: '' }] }
const loading = ref(false)
const deleteQuestions = reactive([])
const deleteVariants = ref([])

onMounted(
  async () => {
    if (route.params.test_id) {
      const response = (await getTest(route.params.test_id)).data
      response.questions.forEach(question => {
        question.question_id = question.id
        delete question.id
        question.variations.forEach(variant => {
          variant.variation_id = variant.id
          delete variant.id
        })
      })
      formData.value = response
    } else {
      formData.value.questions = [newQuestion]
    }
  }
)

const addQuastion = () => {
  if (route.params.test_id) {
    formData.value.questions.push(
      {
        questionId: formData.value.questions.length + 1,
        test_id: Number(route.params.test_id),
        title: '',
        variations: [
          {
            id: 1,
            question_id: formData.value.questions.length + 1,
            right_variation: 1,
            title: ''
          },
          {
            id: 2,
            question_id: formData.value.questions.length + 1,
            right_variation: 0,
            title: ''
          }
        ]
      }
    )
  } else {
    formData.value.questions.push(
      {
        title: '',
        variations:
        [
          {
            right_variation: 1,
            title: ''
          },
          {
            right_variation: 0,
            title: ''
          }
        ]
      }
    )
  }
}

const deleteQuastion = i => {
  deleteQuestions.push(formData.value.questions[i].question_id)
  formData.value.questions.splice(i, 1)
}

// ====================  Api Create And Edit Test  ====================
const createOrEditTest = async () => {
  try {
    loading.value = true
    if (!route.params.test_id) await postTest(formData.value)
    else await putTest(route.params.test_id, formData.value, deleteQuestions, deleteVariants.value)
    router.push('/company/test-templates')
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.createTest{
  & .createTest__header{
    margin-bottom: 40px;
  }
  & .btns{
    grid-column: 2/3;
    display: flex;
    grid-gap: 20px;
  }
  &__form  > div,
  & .question__title{
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
  }
  &__form  > div{
    max-width: 830px;
    margin-bottom: 20px;
  }
  &__form .question:last-of-type{
    margin-bottom: 20px;
  }
  & label{
    color: rgba(#212529, .7);
    font-size: 16px;
  }
  & .create{
    grid-column: 2/3;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    cursor: pointer !important;
    & svg{
      width: 13px;
      height: 13px;
      margin-right: 10px;
      fill: #0082FA ;
    }
    & span{
      font-weight: 500;
      font-size: 14px;
      color: #0082FA;
    }
  }
  @media (max-width: 992px){
    &__form  > div,
    & .question__title{
      grid-template-columns: 150px 1fr;
    }
  }
  @media (max-width: 768px){
    &__form  > div{
      grid-template-columns: 1fr;
      grid-gap: 15px 0;
    }
    & label{
      font-size: 14px;
    }
  }
  @media (max-width: 576px){
    & label{
      font-size: 12px;
    }
  }
}
</style>
