// ====================  FORMAT PHONE NUMBER  ====================
export const formatToPhone = (input) => {
  if (!input) return ''
  let select = '+7'
  const number = input.substring(input.length - 10, input.length)
  const areaCode = number.substring(0, 3)
  const middle = number.substring(3, 6)
  const last = number.substring(6, 10)

  countriesPhoneNumber.forEach(element => {
    if (element.id === input.substring(0, input.length - 10)) {
      select = element.title
    }
  })
  return `${select} (${areaCode}) ${middle} ${last}`
}

// ====================  Delete FORMAT PHONE NUMBER  ====================
export const deleteFormatToPhone = input => {
  if (!input || input.length === 0) return ''
  const number = input.substring(input.length - 13, input.length)
  const areaCode = number.substring(0, 3)
  const middle = number.substring(5, 8)
  const last = number.substring(9, 13)
  return `${areaCode}${middle}${last}`
}

// ====================  Countries PHONE NUMBER  ====================
export const countriesPhoneNumber = [
  {
    title: '+7 840',
    id: '+7840'
  },
  {
    title: '+93',
    id: '+93'
  },
  {
    title: '+355',
    id: '+355'
  },
  {
    title: '+213',
    id: '+213'
  },
  {
    title: '+1 684',
    id: '+1684'
  },
  {
    title: '+376',
    id: '+376'
  },
  {
    title: '+244',
    id: '+244'
  },
  {
    title: '+1 264',
    id: '+1264'
  },
  {
    title: '+1 268',
    id: '+1268'
  },
  {
    title: '+54',
    id: '+54'
  },
  {
    title: '+374',
    id: '+374'
  },
  {
    title: '+297',
    id: '+297'
  },
  {
    title: '+247',
    id: '+247'
  },
  {
    title: '+61',
    id: '+61'
  },
  {
    title: '+672',
    id: '+672'
  },
  {
    title: '+43',
    id: '+43'
  },
  {
    title: '+994',
    id: '+994'
  },
  {
    title: '+1 242',
    id: '+1242'
  },
  {
    title: '+973',
    id: '+973'
  },
  {
    title: '+880',
    id: '+880'
  },
  {
    title: '+1 246',
    id: '+1246'
  },
  {
    title: '+1 268',
    id: '+1268'
  },
  {
    title: '+375',
    id: '+375'
  },
  {
    title: '+32',
    id: '+32'
  },
  {
    title: '+501',
    id: '+501'
  },
  {
    title: '+229',
    id: '+229'
  },
  {
    title: '+1 441',
    id: '+1441'
  },
  {
    title: '+975',
    id: '+975'
  },
  {
    title: '+591',
    id: '+591'
  },
  {
    title: '+387',
    id: '+387'
  },
  {
    title: '+267',
    id: '+267'
  },
  {
    title: '+55',
    id: '+55'
  },
  {
    title: '+246',
    id: '+246'
  },
  {
    title: '+1 284',
    id: '+1284'
  },
  {
    title: '+673',
    id: '+673'
  },
  {
    title: '+359',
    id: '+359'
  },
  {
    title: '+226',
    id: '+226'
  },
  {
    title: '+257',
    id: '+257'
  },
  {
    title: '+855',
    id: '+855'
  },
  {
    title: '+237',
    id: '+237'
  },
  {
    title: '+1',
    id: '+1'
  },
  {
    title: '+238',
    id: '+238'
  },
  {
    title: '+ 345',
    id: '+345'
  },
  {
    title: '+236',
    id: '+236'
  },
  {
    title: '+235',
    id: '+235'
  },
  {
    title: '+56',
    id: '+56'
  },
  {
    title: '+86',
    id: '+86'
  },
  {
    title: '+61',
    id: '+61'
  },
  {
    title: '+61',
    id: '+61'
  },
  {
    title: '+57',
    id: '+57'
  },
  {
    title: '+269',
    id: '+269'
  },
  {
    title: '+242',
    id: '+242'
  },
  {
    title: '+243',
    id: '+243'
  },
  {
    title: '+682',
    id: '+682'
  },
  {
    title: '+506',
    id: '+506'
  },
  {
    title: '+385',
    id: '+385'
  },
  {
    title: '+53',
    id: '+53'
  },
  {
    title: '+599',
    id: '+599'
  },
  {
    title: '+537',
    id: '+537'
  },
  {
    title: '+420',
    id: '+420'
  },
  {
    title: '+45',
    id: '+45'
  },
  {
    title: '+246',
    id: '+246'
  },
  {
    title: '+253',
    id: '+253'
  },
  {
    title: '+1 767',
    id: '+1767'
  },
  {
    title: '+1 809',
    id: '+1809'
  },
  {
    title: '+670',
    id: '+670'
  },
  {
    title: '+56',
    id: '+56'
  },
  {
    title: '+593',
    id: '+593'
  },
  {
    title: '+20',
    id: '+20'
  },
  {
    title: '+503',
    id: '+503'
  },
  {
    title: '+240',
    id: '+240'
  },
  {
    title: '+291',
    id: '+291'
  },
  {
    title: '+372',
    id: '+372'
  },
  {
    title: '+251',
    id: '+251'
  },
  {
    title: '+500',
    id: '+500'
  },
  {
    title: '+298',
    id: '+298'
  },
  {
    title: '+679',
    id: '+679'
  },
  {
    title: '+358',
    id: '+358'
  },
  {
    title: '+33',
    id: '+33'
  },
  {
    title: '+596',
    id: '+596'
  },
  {
    title: '+594',
    id: '+594'
  },
  {
    title: '+689',
    id: '+689'
  },
  {
    title: '+241',
    id: '+241'
  },
  {
    title: '+220',
    id: '+220'
  },
  {
    title: '+995',
    id: '+995'
  },
  {
    title: '+49',
    id: '+49'
  },
  {
    title: '+233',
    id: '+233'
  },
  {
    title: '+350',
    id: '+350'
  },
  {
    title: '+30',
    id: '+30'
  },
  {
    title: '+299',
    id: '+299'
  },
  {
    title: '+1 473',
    id: '+1473'
  },
  {
    title: '+590',
    id: '+590'
  },
  {
    title: '+1 671',
    id: '+1671'
  },
  {
    title: '+502',
    id: '+502'
  },
  {
    title: '+224',
    id: '+224'
  },
  {
    title: '+245',
    id: '+245'
  },
  {
    title: '+595',
    id: '+595'
  },
  {
    title: '+509',
    id: '+509'
  },
  {
    title: '+504',
    id: '+504'
  },
  {
    title: '+852',
    id: '+852'
  },
  {
    title: '+36',
    id: '+36'
  },
  {
    title: '+354',
    id: '+354'
  },
  {
    title: '+91',
    id: '+91'
  },
  {
    title: '+62',
    id: '+62'
  },
  {
    title: '+98',
    id: '+98'
  },
  {
    title: '+964',
    id: '+964'
  },
  {
    title: '+353',
    id: '+353'
  },
  {
    title: '+972',
    id: '+972'
  },
  {
    title: '+39',
    id: '+39'
  },
  {
    title: '+225',
    id: '+225'
  },
  {
    title: '+1 876',
    id: '+1876'
  },
  {
    title: '+81',
    id: '+81'
  },
  {
    title: '+962',
    id: '+962'
  },
  {
    title: '+7 7',
    id: '+77'
  },
  {
    title: '+254',
    id: '+254'
  },
  {
    title: '+686',
    id: '+686'
  },
  {
    title: '+965',
    id: '+965'
  },
  {
    title: '+996',
    id: '+996'
  },
  {
    title: '+856',
    id: '+856'
  },
  {
    title: '+371',
    id: '+371'
  },
  {
    title: '+961',
    id: '+961'
  },
  {
    title: '+266',
    id: '+266'
  },
  {
    title: '+231',
    id: '+231'
  },
  {
    title: '+218',
    id: '+218'
  },
  {
    title: '+423',
    id: '+423'
  },
  {
    title: '+370',
    id: '+370'
  },
  {
    title: '+352',
    id: '+352'
  },
  {
    title: '+853',
    id: '+853'
  },
  {
    title: '+389',
    id: '+389'
  },
  {
    title: '+261',
    id: '+261'
  },
  {
    title: '+265',
    id: '+265'
  },
  {
    title: '+60',
    id: '+60'
  },
  {
    title: '+960',
    id: '+960'
  },
  {
    title: '+223',
    id: '+223'
  },
  {
    title: '+356',
    id: '+356'
  },
  {
    title: '+692',
    id: '+692'
  },
  {
    title: '+596',
    id: '+596'
  },
  {
    title: '+222',
    id: '+222'
  },
  {
    title: '+230',
    id: '+230'
  },
  {
    title: '+262',
    id: '+262'
  },
  {
    title: '+52',
    id: '+52'
  },
  {
    title: '+691',
    id: '+691'
  },
  {
    title: '+1 808',
    id: '+1808'
  },
  {
    title: '+373',
    id: '+373'
  },
  {
    title: '+377',
    id: '+377'
  },
  {
    title: '+976',
    id: '+976'
  },
  {
    title: '+382',
    id: '+382'
  },
  {
    title: '+1664',
    id: '+1664'
  },
  {
    title: '+212',
    id: '+212'
  },
  {
    title: '+95',
    id: '+95'
  },
  {
    title: '+264',
    id: '+264'
  },
  {
    title: '+674',
    id: '+674'
  },
  {
    title: '+977',
    id: '+977'
  },
  {
    title: '+31',
    id: '+31'
  },
  {
    title: '+599',
    id: '+599'
  },
  {
    title: '+1 869',
    id: '+1869'
  },
  {
    title: '+687',
    id: '+687'
  },
  {
    title: '+64',
    id: '+64'
  },
  {
    title: '+505',
    id: '+505'
  },
  {
    title: '+227',
    id: '+227'
  },
  {
    title: '+234',
    id: '+234'
  },
  {
    title: '+683',
    id: '+683'
  },
  {
    title: '+672',
    id: '+672'
  },
  {
    title: '+850',
    id: '+850'
  },
  {
    title: '+1 670',
    id: '+1670'
  },
  {
    title: '+47',
    id: '+47'
  },
  {
    title: '+968',
    id: '+968'
  },
  {
    title: '+92',
    id: '+92'
  },
  {
    title: '+680',
    id: '+680'
  },
  {
    title: '+970',
    id: '+970'
  },
  {
    title: '+507',
    id: '+507'
  },
  {
    title: '+675',
    id: '+675'
  },
  {
    title: '+595',
    id: '+595'
  },
  {
    title: '+51',
    id: '+51'
  },
  {
    title: '+63',
    id: '+63'
  },
  {
    title: '+48',
    id: '+48'
  },
  {
    title: '+351',
    id: '+351'
  },
  {
    title: '+1 787',
    id: '+1787'
  },
  {
    title: '+974',
    id: '+974'
  },
  {
    title: '+262',
    id: '+262'
  },
  {
    title: '+40',
    id: '+40'
  },
  {
    title: '+7',
    id: '+7'
  },
  {
    title: '+250',
    id: '+250'
  },
  {
    title: '+685',
    id: '+685'
  },
  {
    title: '+378',
    id: '+378'
  },
  {
    title: '+966',
    id: '+966'
  },
  {
    title: '+221',
    id: '+221'
  },
  {
    title: '+381',
    id: '+381'
  },
  {
    title: '+248',
    id: '+248'
  },
  {
    title: '+232',
    id: '+232'
  },
  {
    title: '+65',
    id: '+65'
  },
  {
    title: '+421',
    id: '+421'
  },
  {
    title: '+386',
    id: '+386'
  },
  {
    title: '+677',
    id: '+677'
  },
  {
    title: '+27',
    id: '+27'
  },
  {
    title: '+500',
    id: '+500'
  },
  {
    title: '+82',
    id: '+82'
  },
  {
    title: '+34',
    id: '+34'
  },
  {
    title: '+94',
    id: '+94'
  },
  {
    title: '+249',
    id: '+249'
  },
  {
    title: '+597',
    id: '+597'
  },
  {
    title: '+268',
    id: '+268'
  },
  {
    title: '+46',
    id: '+46'
  },
  {
    title: '+41',
    id: '+41'
  },
  {
    title: '+963',
    id: '+963'
  },
  {
    title: '+886',
    id: '+886'
  },
  {
    title: '+992',
    id: '+992'
  },
  {
    title: '+255',
    id: '+255'
  },
  {
    title: '+66',
    id: '+66'
  },
  {
    title: '+670',
    id: '+670'
  },
  {
    title: '+228',
    id: '+228'
  },
  {
    title: '+690',
    id: '+690'
  },
  {
    title: '+676',
    id: '+676'
  },
  {
    title: '+1 868',
    id: '+1868'
  },
  {
    title: '+216',
    id: '+216'
  },
  {
    title: '+90',
    id: '+90'
  },
  {
    title: '+993',
    id: '+993'
  },
  {
    title: '+1 649',
    id: '+1649'
  },
  {
    title: '+688',
    id: '+688'
  },
  {
    title: '+1 340',
    id: '+1340'
  },
  {
    title: '+256',
    id: '+256'
  },
  {
    title: '+380',
    id: '+380'
  },
  {
    title: '+971',
    id: '+971'
  },
  {
    title: '+44',
    id: '+44'
  },
  {
    title: '+1',
    id: '+1'
  },
  {
    title: '+598',
    id: '+598'
  },
  {
    title: '+998',
    id: '+998'
  },
  {
    title: '+678',
    id: '+678'
  },
  {
    title: '+58',
    id: '+58'
  },
  {
    title: '+84',
    id: '+84'
  },
  {
    title: '+1 808',
    id: '+1808'
  },
  {
    title: '+681',
    id: '+681'
  },
  {
    title: '+967',
    id: '+967'
  },
  {
    title: '+260',
    id: '+260'
  },
  {
    title: '+255',
    id: '+255'
  },
  {
    title: '+263',
    id: '+263'
  }
]
