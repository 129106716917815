<template>
  <div class="select" :class="{showOptions: select.showOptions, disabled: disabled}" v-click-outside="() => { select.showOptions = false }">
    <div
      class="input"
      @click="disabled ? null : onShowOptions()"
      :style="{marginBottom: ( select.selectedOptions.length > 0 ? '12px' : '0')}"
    >
      <input
        class="selectInput"
        type="text"
        v-model="select.input"
        :placeholder="placeholder"
        :disabled="disabled"
        readonly
      />
      <div class="arrow">
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
        </svg>
      </div>
    </div>
    <transition name="drop">
      <div class="drop"
        v-if="select.showOptions"
        v-click-outside="() => { select.showOptions = false }"
      >
        <input
          class="selectSearch"
          type="text"
          v-model="select.search"
          :placeholder="$t('search')"
        />
        <div class="options">
          <div class="option" v-if="type === 'city' && !options">
            {{$t('select_a_region_first')}}
          </div>

          <div v-for="option of select.options" :key="option" v-else>
            <button
              v-if="(select.selectedOptions.indexOf(option) === -1 || !multiSelect) && !checkOption(option)"
              type="button"
              class="option"
              @click="() => {clickOption(option)}"
            >
              {{option.title}}
            </button>
          </div>
        </div>
      </div>
    </transition>
    <TransitionGroup name="list" tag="div" class="selectedOptions" v-if="multiSelect">
      <button
        v-for="(option, i) in select.selectedOptions"
        :key="option"
        type="button"
        @click="deleteSelectedOption(i)"
      >
        <span>{{ option.title }}</span>
        <svg>
          <use xlink:href="/img/tmp/sprite.svg#delete"></use>
        </svg>
      </button>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { reactive } from '@vue/reactivity'
import { watch, defineProps, defineEmits } from 'vue'

const props = defineProps({
  modelValue: {
    type: [Number, Array, String],
    default: null
  },
  placeholder: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  checkedOptions: {
    type: Array,
    default: null
  },
  type: {
    type: String,
    default: null
  },
  multiSelect: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  optionText: {
    type: String,
    default: null
  }
})
const emit = defineEmits(['update:modelValue', 'update:validation', 'update:option', 'change'])

const select = reactive({
  input: '',
  search: '',
  showOptions: false,
  options: props.options,
  selectedOptions: (props.multiSelect && props.modelValue) ? props.modelValue : []
})

const searchOption = () => {
  select.options = []
  const search = (select.search.toLowerCase()).trim()
  const l = search.length

  props.options.forEach((item, i) => {
    if (item.title.toLowerCase().slice(0, l) === search && item.title.toLowerCase() !== search) select.options.push(item)
  })

  props.options.forEach((item, i) => {
    if (item.title.indexOf(search) !== -1 && select.options.indexOf(item) === -1) select.options.push(item)
  })

  if (select.options.length === 0) {
    props.options.forEach(item => {
      if (item.title.toLowerCase().slice(0, 1) === search.slice(0, 1) && item.title.toLowerCase() !== search) select.options.push(item)
    })
  }
  if (select.options.length === 0) select.options = props.options
  if (props.multiSelect) checkSelectOptions()
}

watch(
  () => select.search,
  () => {
    searchOption()
  }
)
watch(
  () => props.options,
  () => {
    if (props.type === 'city') {
      emit('update:modelValue', null)
      emit('update:validation', false)
      emit('update:option', null)
    }
    searchOptionById()
    searchOption()
  }
)

watch(
  () => props.optionText,
  () => {
    select.input = props.optionText
  }
)

const onShowOptions = () => {
  select.showOptions = true
  setTimeout(x => {
    document.querySelector('input.selectSearch').focus()
  }, 50)
}
// ====================  VALIDATE DATA  ====================
const clickOption = (option) => {
  select.search = ''
  select.showOptions = false

  if (!props.multiSelect) {
    emit('update:modelValue', option.id)
    select.input = option.title
  } else {
    select.selectedOptions.push(option)
    emit('update:modelValue', select.selectedOptions)
  }
  emit('update:validation', true)
  emit('update:option', option)
  emit('change')
}
const deleteSelectedOption = (i) => {
  select.selectedOptions.splice(i, 1)
  emit('update:modelValue', select.selectedOptions)
  if (select.selectedOptions.length === 0) emit('update:validation', false)
}

const searchOptionById = () => {
  if (props.modelValue && props.options) {
    props.options.forEach(item => {
      if (item.id === props.modelValue) {
        select.selected = true
        select.input = item.title
        emit('update:validation', true)
        emit('update:option', item)
      }
    })
  }
}
if (!props.multiSelect) searchOptionById()

// ====================  Multi Select Options  ====================
const checkOption = option => {
  let thereIs = false
  if (props.checkedOptions) {
    props.checkedOptions.forEach(model => {
      if (model.language_id === option.id) thereIs = true
    })
  } else if (props.modelValue && props.multiSelect) {
    props.modelValue.forEach(model => {
      if (model.id === option.id) thereIs = true
    })
  }
  return thereIs
}

const checkSelectOptions = () => {
  select.options.forEach(option => {
    if (checkOption(option)) {
      select.options.splice(select.options.indexOf(option), 1)
    }
  })
}
if (props.multiSelect) checkSelectOptions()
</script>

<style lang="scss" scoped>
input{
  width: 100%;
  font-size: 16px;
  line-height: 1.5;

  padding: 10px 20px 11px;
  border: 0.5px solid rgba(33, 37, 41, 0.7);

  &::placeholder{
    color: rgba(#212529, .5);
  }
  &:focus,
  &:hover{
    border-color: #0077FF;
  }
  &[disabled]{
    opacity: .7;
    cursor: default;
    border: 0.5px solid rgba(33, 37, 41, 0.7);
  }
}
.select{
    position: relative;
    width: 100%;

    &.showOptions{
      & .arrow svg{
        position: relative;
        z-index: 10;
        transform: rotate(270deg);
        fill: #0077FF;
      }
    }

    &.disabled{
      opacity: .7;
      & .selectInput{
        cursor: default !important;
      }
    }

    & .selectInput{
      cursor: pointer;
    }
}
.input{
  position: relative;
  transition: all .2s linear;
  & input{
    width: 100%;
    padding-right: 40px;
  }
}

.drop{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid rgba(#212529, .7);
  box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.1);

  & input{
    border: none;
    border-bottom: 1px solid rgba(#212529, .1);
  }
}
.arrow{
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  & svg{
    display: block;
    height: 16px;
    width: 8px;
    transform: rotate(90deg);
    transition: transform .2s linear;
  }
}
.options{
  display: block;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  background-color: #fff;
}

.option{
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  text-align: left;
  transition: background-color .2s linear;

  &:hover,
  &:focus{
    background-color: rgba(#0077FF, 0.15) !important;
  }
}

.selectedOptions {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px 12px;
  margin-bottom: 20px;

  & button {
    color: #929292;
    font-size: 14px;

    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 8px 10px;
    border-radius: 3px;
    background-color: #e0e0e0;
    transition: all .2s linear;

    &:hover{
      color: #AD1717;
      background-color: #f9bbbb;

      & svg{
        fill: #AD1717;
      }
    }
  }

  & svg{
    display: block;
    width: 15px;
    height: 15px;
    fill: #929292;
    transition: all .2s linear;
  }
}

// Adaptive
@media (max-width: 992px){
  input{
    font-size: 14px;
    padding: 9px 18px 10px;
  }
  .input{
    & input{
      padding-right: 35px;
    }
  }
  .option{
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.2;
  }
  .selectedOptions {
    grid-gap: 7px 10px;
    margin-bottom: 18px;

    & button {
      font-size: 12px;
      grid-gap: 8px;
      padding: 6px 8px;
    }

    & svg{
      width: 12px;
      height: 12px;
    }
  }
}

@media (max-width: 768px){
  input{
    font-size: 13px;
    padding: 8px 16px 9px;
  }
  .input{
    & input{
      padding-right: 30px;
    }
  }
  .option{
    padding: 7px 10px;
    font-size: 13px;
  }

  .selectedOptions {
    grid-gap: 6px 9px;
    margin-bottom: 16px;

    & button {
      font-size: 10px;
      grid-gap: 6px;
    }

    & svg{
      width: 10px;
      height: 10px;
    }
  }
}

@media (max-width: 576px){
  input{
    font-size: 12px;
    padding: 7px 14px 8px;
  }
}

// Helpers
.drop-enter-active,
.drop-leave-active {
   transition: all.2s linear;
}
.drop-enter-from,
.drop-leave-to {
  opacity: 0;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.list-leave-active {
  position: absolute;
}
</style>
