<template>
<div  v-click-outside="hideSearch">
  <div :class="'header__MainNav' + (login.company || login.employee ? ' login ' : ' ')">
    <div class="container container__main" v-if="login.employee || login.company">
      <div class="left header__bottomLeft">
        <NavCompany v-if="login.company"/>
        <NavEmployee v-else/>
      </div>

      <div class="right header__bottomRight">

          <button class="search__btn" @click="$emit('search')">
              <div class="icon">
                  <svg>
                      <use xlink:href="/img/tmp/sprite.svg#search"></use>
                  </svg>
              </div>
              <div class="link">{{$t('search')}}</div>
          </button>

          <Button
            v-if="login.employee"
            class="nav__btn"
            type="darkGreen"
            :name="$t('create_resume')"
            go="/user/create-resume"
          />
          <Button
            v-else
            class="nav__btn"
            type="darkGreen"
            :name="$t('create_vacancy')"
            :loading="loading"
            @click="$emit('createVacancy')"
          />
      </div>
    </div>
    <NavGeneral v-else />
    <div class="container" v-if="clientWidth > 1200">
      <div class="header__searchBlock">
        <Search v-if="login.company || login.employee" class="header__search" :class="{show: showSearchInput}" />
      </div>
    </div>
  </div>
</div>
<transition name="fade">
  <div class="fixedSearch" v-if="showSearchInput" @click="() => {$emit('update:showSearchInput', false)}">
    <div class="container">
      <Search @click.stop v-if="login.company || login.employee" class="header__search" />
    </div>
  </div>
</transition>
</template>

<script setup>
import Search from '@/components/common/Search.vue'
import NavCompany from '@/components/uiLanding/layouts/Header/Navs/NavCompany.vue'
import NavEmployee from '@/components/uiLanding/layouts/Header/Navs/NavEmployee.vue'
import NavGeneral from '@/components/uiLanding/layouts/Header/Navs/NavGeneral.vue'

import { onMounted, reactive } from 'vue-demi'
import { defineProps, defineEmits } from 'vue'

defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  showSearchInput: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:showSearchInput', 'createVacancy', 'search'])

const login = reactive(
  {
    company: false,
    employee: false,
    consultant: false
  }
)

onMounted(
  async () => {
    const userData = localStorage.getItem('role_id')
    if (userData === '3') login.employee = true
    else if (userData === '4' || userData === '5') login.company = true
    else if (userData === '2') login.consultant = true
  }
)
const clientWidth = document.body.clientWidth
const hideSearch = () => {
  if (document.body.clientWidth > 1200) {
    emit('update:showSearchInput', false)
  }
}
</script>

<style lang="scss">
@import '@/assets/css/Header/SearchBtn.scss';
@import '@/assets/css/Header/MainNav.scss';

.header{
  & .nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 0 20px;
    flex-wrap: wrap;

    &__link{
      display: inline-block;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.6;
      color: rgba(#212529, .7);
      text-transform: uppercase;
      padding: 15px 0;
      position: relative;
      transition: color .2s linear;

      &::before{
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background-color: rgba(#212529, .7);
        position: absolute;
        left: 0;
        bottom: 0;
        transition: width .2s linear, background-color .2s linear;
      }

      &:hover{
        color: #A32D2B;
        &::before{
          width: 100%;
          background-color: #A32D2B;
        }
      }
    }
  }
  & .nav__btn{
    margin: 6px 0;
  }
  &__searchBlock{
    width: 100%;
    position: relative;

    & .search{
      position: absolute !important;
      top: -60px;
      left: 0;
      z-index: -2;
      transition: all .2s linear;
      &.show{
        top: calc(100% + 10px);
      }
    }
  }

  & .fixedSearch{
    display: none;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(#000, .7);
    backdrop-filter:blur(5px);
    transition: all .4s ease;

    & .container{
      max-width: 790px;
      padding: 75px 120px 95px;
      margin: 50px auto;
    }
  }

  @media (max-width: 1200px){
    & .nav__link{
      font-size: 14px !important;
      padding: 12px 0;
    }
    &.login{
      & .nav{
        width: 100%;
        grid-gap: 10px;
        margin: 0;
        padding: 18px 0;
        justify-content: space-between;
      }
      & .header__bottomLeft{
        width: 100%;
      }
      & .header__bottomRight{
        display: none;
      }
      & .nav__link{
        padding: 0;
      }
    }

    &__searchBlock{
      display: none;
    }
    & .fixedSearch{
      display: block;
    }
  }

  @media (max-width: 992px){
    & .nav__link{
      padding: 10px 0;
    }
  }

  @media (max-width: 768px){
    & .nav{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    & .nav__link{
      font-size: 12px !important;
    }
    &.login{
      & .nav{
        justify-content: space-between;
        grid-gap: 15px;
      }
      & .nav__link{
        font-size: 14px !important;
      }
    }
  }
  @media (max-width: 568px){
    & .nav{
      grid-template-columns: 1fr 1fr;
    }
    & .nav__link{
      font-size: 12px !important;
    }
  }
  @media (max-width: 380px){
    & .nav{
      grid-template-columns: 1fr;
    }
    &.login{
      & .nav{
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
</style>
