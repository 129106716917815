<template>
<div class="listServices__item" v-if="service">
  <div class="name">{{service.title}}</div>
  <Button
    :name="$t('connect')"
    :loading="loading"
    @click="() => {conncetService(service.id)}"
  />
</div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router/index.js'

import { postConnectService } from '@/actions/consultant'

defineProps({
  service: {
    type: Object,
    default: () => {}
  }
})

const route = useRoute()
const loading = ref(false)

// Click Connect Service
const conncetService = async (packageId) => {
  loading.value = true
  try {
    await postConnectService(
      {
        company_id: Number(route.params.company_id),
        package_id: packageId
      }
    )
    router.push('/consultant/list-companies/result?page=1')
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.listServices__item{
  padding: 20px;
  border: 0.5px solid #919599;
  border-top-width: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 15px;

  &.skeleton__block{
    border-color: rgba(0, 0, 0, 0.0955529) !important;
  }
  & .name{
    display: block;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #212529;
    position: relative;

    &.skeleton{
      width: 100%;
      max-width: 220px;
      &::before{
        border-bottom: none;
      }
    }
  }
  & button{
    height: fit-content;
    &.skeleton{
      width: 100%;
      max-width: 160px;
      &::before{
        border-bottom: none;
      }
    }
  }
  @media (max-width: 576px){
    padding: 15px;
    & .name{
      font-size: 18px;
    }
  }
  @media (max-width: 450px){
    flex-direction: column;
    align-items: flex-start;

    & button,
    & .button.skeleton{
      width: 100%;
      max-width: 100%;
    }
  }
}

</style>
