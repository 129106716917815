<template>
<div>
  <div v-if="country.id">
    <button
      class="placeSelect__title"
      @click="deleteCountry"
      type="button"
    >
      <svg>
        <use xlink:href="/img/tmp/sprite.svg#arrow"></use>
      </svg>
      <span>{{country.title}}</span>
    </button>
    <!-- ---------------- Cities - Города --------------- -->
    <RowCheckboxFilter
      v-if="optionsCity"
      :title="$t('city')"
      v-model="citiesId"
      :options="optionsCity"
      multiFilter
    />
  </div>
  <!-- ---------------- Countries - Страны --------------- -->
  <RowCheckboxFilter
    v-else
    v-model="country.id"
    v-model:optionTitle="country.title"
    :title="$t('region')"
    :options="optionsCountry"
  />
</div>
</template>

<script setup>
import RowCheckboxFilter from '@/components/uiLanding/Filter/Sidebar/RowCheckboxFilter.vue'

import { defineProps, defineEmits, ref, watch, reactive } from 'vue'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  countryId: {
    type: Number,
    default: null
  },
  countryTitle: {
    type: String,
    default: null
  },
  optionsCountry: {
    type: Array,
    default: null
  },
  optionsCity: {
    type: Array,
    default: null
  }
})
const emit = defineEmits(['update:modelValue', 'update:countryId'])

const country = reactive(
  {
    title: props.countryTitle,
    id: props.countryId
  }
)
const citiesId = ref(props.modelValue)

watch(
  () => [country.id, citiesId.value],
  () => {
    emit('update:modelValue', citiesId.value)
    emit('update:countryId', country.id)
  }
)

watch(
  () => props.countryTitle,
  () => {
    country.title = props.countryTitle
  }
)

const deleteCountry = () => {
  country.id = null
  citiesId.value = []
}
</script>

<style lang="scss" scoped>
.placeSelect{
  &__title{
    font-size: 16px;
    font-weight: 700;
    color: rgba(33, 37, 41, 0.7);
    margin-bottom: 10px;

    display: flex;
    grid-gap: 10px;
    align-items: center;
    & svg{
      display: block;
      height: 12px;
      width: 10px;
      fill: rgba(33, 37, 41, 0.7);
      stroke: rgba(33, 37, 41, 0.7);
      transform: rotate(180deg);
      transition: all .2s ease-in-out;
    }
  }
}
</style>
