<template>
<div class="companyManagers__item">
  <div class="name"><p>{{manager.surname}} {{manager.name}}</p></div>
  <div class="contact">
      <div class="title">{{$t('phone')}}:</div>
      <div class="link">{{formatToPhone(manager.phone)}}</div>
  </div>
  <div class="contact">
      <div class="title">e-mail:</div>
      <div class="link">{{manager.email}}</div>
  </div>
  <div class="companyManagers__btns" v-if="userRoleId === '5'">
    <router-link class="btn" :to="'/company/edit-manager/' + manager.id">{{$t('edit')}}</router-link>
    <button class="btn delete" @click="quastion = true">
      <svg>
        <use xlink:href="/img/tmp/sprite.svg#delete"></use>
      </svg>
      <span>{{$t('delete')}}</span>
    </button>
  </div>
</div>

<Modal
  :hide="()=>{quastion = false}"
  :show="quastion"
  :agreement="() => {deleteManagers()}"
  :loading="loading"
  quastion
/>
</template>

<script setup>
import Modal from '@/components/common/Modal.vue'
import { defineProps, ref } from 'vue'
import { formatToPhone } from '@/helpers/formatPhone'
import { deleteManager } from '@/actions/company'

const props = defineProps({
  manager: {
    type: Object,
    default: () => {}
  },
  deleted: {
    type: Function,
    default: () => null
  }
})
const quastion = ref(false)
const userRoleId = localStorage.getItem('role_id')
const loading = ref(false)
const deleteManagers = async () => {
  loading.value = true
  try {
    await deleteManager(props.manager.id)
    quastion.value = false
    props.deleted()
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss">
.companyManagers{
  &__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 15px;
    border: 0.5px solid rgba(33, 37, 41, 0.7);
    padding: 24px 30px;
  }

  & .name,
  & .contact{
    margin-right: 20px;
  }

  & .name{
    width: 33.5%;
    & p{
      display: block;
      width: 10px;
      font-weight: bold;
      font-size: 20px;
      line-height: 1.25;
      text-transform: uppercase;
      color: #212529;
    }
  }

  & .contact{
    width: 22%;
  }

  & .title,
  & .link{
    font-size: 16px;
    line-height: 1.4;
    color: rgba(33, 37, 41, 0.7);
  }
  &__btns{
    display: flex;
    grid-gap: 15px;
  }
  & .btn{
    padding: 0;
    font-size: 16px;
    line-height: 1.4;
    color: #2283BA;
    position: relative;
    &::before{
      content: "";
      display: block;
      height: 2px;
      width: 0;
      background-color: #2283BA;
      position: absolute;
      left: 0;
      top: 98%;
      transition: width .2s linear;
    }
    &:hover{
      &::before{
        width: 100%;
      }
    }
  }
  .delete{
    display: flex;
    grid-gap: 4px;
    align-items: center;
    & span{
      color: #AD1717;
      font-size: 16px;
      line-height: 1.2;
      font-weight: 500;
    }
    & svg{
      display: block;
      width: 18px;
      height: 18px;
      fill: #AD1717;
    }
    &::before{
      background-color: #AD1717;
    }
  }
  @media (max-width: 992px){
    &__item{
      padding: 15px 20px;
    }
    & .name{
      width: 22.5%;
      & p{
        font-size: 18px;
      }
    }
    & .contact{
      width: auto;
    }

    & .title,
    & .link,
    & .btn,
    & .delete span{
      font-size: 14px;
    }
    .delete{
      grid-gap: 2px;
      & svg{
        width: 16px;
        height: 16px;
      }
    }

    &__btns{
      align-items: flex-end;
      flex-direction: column;
      grid-gap: 10px;
    }
  }
  @media (max-width: 768px){
    &__item{
      display: block;
      padding: 10px 15px !important;
      & .contact:last-child{
        margin-bottom: 9px;
      }
      &.skeleton__block{
        & .name p{
          & span:first-child{
            width: 200px !important;
            margin-bottom: 0;
          }
          & span:nth-child(2){
            display: none;
          }
        }
      }
    }
    & .name,
    & .contact{
      margin-right: 0;
    }
    & .name{
      width: 100%;
      margin-bottom: 12px;

      & p{
        font-size: 16px;
        width: auto;
      }
    }
    & .title,
    & .link,
    & .btn,
    & .delete span{
      font-size: 12px;
    }
    .delete{
      & svg{
        width: 14px;
        height: 14px;
      }
    }
    & .contact{
      display: flex;
      justify-content: space-between;
      margin-bottom: 3px;
    }
    &__btns{
      align-items: center;
      flex-direction: row;
      justify-content: flex-end;
    }
    & .btn{
      display: flex;
      justify-content: flex-end;
    }
  }
  @media (max-width: 576px){
    & .name{
      margin-bottom: 10px;
      & p{
        font-size: 14px;
      }
    }
    & .title,
    & .link,
    & .btn,
    & .delete span{
      font-size: 11px;
    }
    .delete{
      & svg{
        width: 12px;
        height: 12px;
      }
    }
  }

  @media (max-width: 400px){
    & .name{
      margin-bottom: 8px;
      & p{
        font-size: 12px;
      }
    }
    & .title,
    & .link,
    & .btn,
    & .delete span{
      font-size: 10px;
    }
    .delete{
      & svg{
        width: 11px;
        height: 11px;
      }
    }
  }
}
</style>
